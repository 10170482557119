import { useCallback, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Breadcrumbs } from "@/components/Breadcrumbs";
import DatasetGroupModal from "@/components/DatasetGroupModal";
import { Button } from "@/components/ui/button";
import { useUser } from "@/context/user-context";
import { ToastType } from "@/enums";
import useActiveWorkspace from "@/hooks/useActiveWorkspace";
import { displayToast } from "@/utils/toast";
import { keepRelevantSearchParams } from "@/utils/utils";
import NewDatasetGroupGrid from "./NewDatasetGroupGrid";

const Datasets = () => {
  const [isDatasetGroupModalOpen, setDatasetGroupModalOpen] = useState(false);
  const activeWorkspace = useActiveWorkspace();
  const navigate = useNavigate();
  const userContext = useUser();

  const [searchParams] = useSearchParams();
  const keepRelevantParams = useCallback(() => {
    return keepRelevantSearchParams(searchParams);
  }, [searchParams]);

  const handleDatasetGroupClick = (datasetGroupId: number) => {
    navigate({
      pathname: `/workspace/${
        activeWorkspace!.id
      }/dataset-groups/${datasetGroupId}`,
      search: keepRelevantParams(),
    });
  };

  const handleDatasetCreated = (response: any) => {
    navigate({
      pathname: `/workspace/${activeWorkspace!.id}/dataset-groups/${
        response.dataset_group.id
      }/dataset/${response.dataset.id}`,
      search: keepRelevantParams(),
    });
    displayToast("Dataset created", ToastType.success);
  };
  return (
    <>
      <div className="bg-white p-1">
        <Breadcrumbs
          items={["Datasets"]}
          navigateUrl={`/workspace/${userContext.activeWorkspaceId}/datasets`}
        />
        <div className="flex pt-4">
          <div className="flex-1 pt-4">
            <h1 className="text-2xl text-gray-900">Datasets</h1>
            <h2 className="pt-1 text-lg text-gray-500">
              View or create new datasets. Datasets are used for evaluations.
            </h2>
          </div>
          <div className="px-1 pt-4">
            <Button onClick={() => setDatasetGroupModalOpen(true)}>
              Create Dataset
            </Button>
          </div>
        </div>
      </div>
      <div className="mt-4 flex-1 pb-8">
        <NewDatasetGroupGrid
          handleDatasetGroupClick={handleDatasetGroupClick}
        />
      </div>
      {isDatasetGroupModalOpen && (
        <DatasetGroupModal
          handleDatasetCreated={handleDatasetCreated}
          setOpen={setDatasetGroupModalOpen}
        />
      )}
    </>
  );
};

export default Datasets;
