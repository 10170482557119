import { useCallback } from "react";
import { useContentArea } from "../../content-area-context";

const useKeyboardHandling = (isComposing: boolean, widgetsEnabled = true) => {
  const content = useContentArea();

  const handleInput = useCallback(
    (event: React.FormEvent<HTMLDivElement>) => {
      let toBeInputted = event.currentTarget.innerHTML;
      if (toBeInputted === "\n") toBeInputted = ""; // onSelectAll + Backspace, default browser injects a default newline
      content.setContent(toBeInputted);
    },
    [content],
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (widgetsEnabled && event.key === "/" && !isComposing) {
        const range = content.savedSelection!;
        let rect = range.getBoundingClientRect();
        if (rect.width === 0 && rect.height === 0) {
          // If the range has no dimensions, get the position of the cursor
          const span = document.createElement("span");
          range.insertNode(span);
          rect = span.getBoundingClientRect();
          span.parentNode?.removeChild(span);
        }
        if (rect) {
          content.setContextMenuPosition({
            top: rect.bottom,
            left: rect.left,
          });
          content.setShowContextMenu(true);
        }
        return;
      } else if (
        content.showContextMenu &&
        event.key !== "ArrowUp" &&
        event.key !== "ArrowDown"
      ) {
        content.setShowContextMenu(false);
      }

      if (event.key === "Enter") {
        return;
        /*
        try {

          event.preventDefault();

          const selection = window.getSelection();
          const containerElement = containerRef.current;

          if (!selection || !containerElement || selection.rangeCount === 0) {
            return;
          }

          const range = selection.getRangeAt(0);

          // Clean up any empty text nodes or unwanted selections first
          range.deleteContents();

          // Create a proper block-level break with placeholder content
          const breakElement = document.createElement("div");
          breakElement.innerHTML = "<br>";

          // Insert break and ensure it's properly positioned
          const fragment = document.createDocumentFragment();
          fragment.appendChild(breakElement);
          range.insertNode(fragment);

          // Create and position the new range after the break
          const newRange = document.createRange();
          newRange.setStartAfter(breakElement);
          newRange.setEndAfter(breakElement);

          // Update selection
          selection.removeAllRanges();
          selection.addRange(newRange);

          // Handle scrolling with error checking
          const cursorMarker = document.createElement("span");
          cursorMarker.innerHTML = "&#8203;"; // Zero-width space
          newRange.insertNode(cursorMarker);

          const cursorRect = cursorMarker.getBoundingClientRect();
          const containerRect = containerElement.getBoundingClientRect();

          if (
            cursorRect &&
            containerRect &&
            cursorRect.bottom > containerRect.bottom
          ) {
            containerElement.scrollTop += Math.min(
              cursorRect.bottom - containerRect.bottom + 10,
              containerElement.scrollHeight - containerElement.clientHeight,
            );
          }

          cursorMarker.remove();

          // Clean up the DOM
          containerElement.normalize();

          // Update content
          requestAnimationFrame(() => {
            content.setContent(containerElement.innerHTML);
          });
        } catch (error) {
          console.error("Error handling Enter key:", error);
        }
        return; */
      }

      if (event.key !== "Backspace" && event.key !== "Delete") {
        return;
      }
      /*
      if (!event.currentTarget.innerHTML) {
        event.preventDefault();
        return;
      }

      const selection = window.getSelection();
      if (!selection || selection.rangeCount === 0) return;

      const range = selection.getRangeAt(0);

      const { startContainer, startOffset } = range;
      const currentNode = range.startContainer;
      const offset = startOffset;
      const deleteLineShortcut =
        event.key === "Backspace" && (event.ctrlKey || event.metaKey);
      const deleteWordShortcut =
        event.key === "Backspace" && (event.shiftKey || event.altKey);

      if (deleteLineShortcut) {
        if (currentNode.nodeType === Node.TEXT_NODE) {
          const textContent = currentNode.textContent;
          if (textContent && textContent.length > 0) {
            currentNode.textContent = textContent.slice(0, -1);
            range.setStart(currentNode, textContent.length - 1);
            range.collapse(true);
            selection.removeAllRanges();
            selection.addRange(range);
            content.setContent(event.currentTarget.innerHTML);
          }
        }
      } else if (deleteWordShortcut) {
        if (currentNode.nodeType === Node.TEXT_NODE) {
          const textContent = currentNode.textContent;
          if (textContent && textContent.length > 0) {
            currentNode.textContent = textContent.slice(0, -1);
            range.setStart(currentNode, textContent.length - 1);
            range.collapse(true);
            selection.removeAllRanges();
            selection.addRange(range);
            content.setContent(event.currentTarget.innerHTML);
          }
        }
      } else if (range.collapsed) {
        if (startContainer.nodeType === Node.ELEMENT_NODE) {
          if (offset === 0 && currentNode.previousSibling) {
            const scrollPosition = window.scrollY;
            event.preventDefault();
            if (currentNode.previousSibling.nodeType === Node.TEXT_NODE) {
              const textContent = currentNode.previousSibling.textContent;
              if (textContent && textContent.length > 0) {
                currentNode.previousSibling.textContent = textContent.slice(
                  0,
                  -1,
                );
              } else {
                currentNode.previousSibling.remove();
              }
            } else {
              currentNode.previousSibling.remove();
            }
            window.scrollTo(0, scrollPosition);
            content.setContent(event.currentTarget.innerHTML);
            return;
          } else if (offset > 0 && currentNode.childNodes[offset - 1]) {
            const scrollPosition = window.scrollY;
            event.preventDefault();
            const targetNode = currentNode.childNodes[offset - 1];
            if (targetNode.nodeType === Node.TEXT_NODE) {
              const textContent = targetNode.textContent;
              if (textContent && textContent.length > 0) {
                targetNode.textContent = textContent.slice(0, -1);
              } else {
                targetNode.remove();
              }
            } else {
              targetNode.remove();
            }
            window.scrollTo(0, scrollPosition);
            content.setContent(event.currentTarget.innerHTML);
            return;
          }
        } else if (startContainer.nodeType === Node.TEXT_NODE) {
          if (offset > 0) {
            event.preventDefault();
            const textContent = startContainer.textContent;
            if (textContent) {
              startContainer.textContent =
                textContent.slice(0, offset - 1) + textContent.slice(offset);
              range.setStart(startContainer, offset - 1);
              range.setEnd(startContainer, offset - 1);
              selection.removeAllRanges();
              selection.addRange(range);
              content.setContent(event.currentTarget.innerHTML);
            }
            return;
          }
        }
        selection.removeAllRanges();
        selection.addRange(range);
        content.setContent(event.currentTarget.innerHTML);
      } else {
        event.preventDefault();
        const scrollPosition = window.scrollY;
        if (
          range.toString() ||
          range.startContainer.nodeType === Node.ELEMENT_NODE ||
          range.startContainer.nodeType === Node.TEXT_NODE
        ) {
          // Handle selected text deletion
          range.deleteContents();
          // Ensure cursor stays in container by setting range to start of container
          const container = event.currentTarget;
          if (!container.hasChildNodes()) {
            container.appendChild(document.createElement("br"));
          }
          range.setStart(container.firstChild!, 0);
          range.setEnd(container.firstChild!, 0);
          selection.removeAllRanges();
          selection.addRange(range);
        } else if (offset === 0 && currentNode.previousSibling) {
          try {
            // Handle deletion at start of node
            const previousSibling = currentNode.previousSibling;
            range.setStartBefore(previousSibling);
            range.setEndBefore(previousSibling);

            if (previousSibling.nodeType === Node.TEXT_NODE) {
              const textContent = previousSibling.textContent;
              if (textContent && textContent.length > 0) {
                previousSibling.textContent = textContent.slice(0, -1);
                // If text node is now empty, remove it
                if (!previousSibling.textContent) {
                  previousSibling.remove();
                }
              } else {
                previousSibling.remove();
              }
            } else {
              previousSibling.remove();
            }

            // Restore selection
            selection.removeAllRanges();
            selection.addRange(range);
          } catch (error) {
            console.error("Error handling node deletion:", error);
            // Fallback to basic deletion
            currentNode.previousSibling?.remove();
          }
        }

        // Restore scroll position
        window.scrollTo(0, scrollPosition);
        content.setContent(event.currentTarget.innerHTML);
      }
      */
    },
    [content, isComposing, widgetsEnabled],
  );

  return { handleKeyDown, handleInput };
};

export default useKeyboardHandling;
