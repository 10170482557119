import { ENDPOINTS } from "@/api/application-api";
import { ToastType } from "@/enums";
import { displayToast } from "@/utils/toast";
import { QueryClient } from "@tanstack/react-query";
import {
  action,
  makeAutoObservable,
  observable,
  reaction,
  runInAction,
  transaction,
} from "mobx";
import {
  NodeOutput,
  ObservableWorkflowNode,
  SaveWorkflowMutationResponse,
  Workflow,
  WorkflowNode,
  WorkflowNodeStatus,
  WorkflowNodeType,
} from "../types";
import {
  nodeHasSource,
  withEvalsCompatibilityLayer,
  withIdToNameRemapping,
  withIdToNameRemappingForOneNode,
  withNameToIdRemapping,
  withoutEvalsCompatibilityLayer,
} from "../utils";
import {
  createAddWorkflowLabelMutation,
  createDeleteWorkflowLabelMutation,
  createDeleteWorkflowMutation,
  createExecuteWorkflowMutation,
  createPlayFromHereMutation,
  createSaveMutation,
  createSaveSingleNodeMutation,
  createUpdateWorkflowLabelMutation,
  createUpdateWorkflowNameMutation,
} from "./workflow-queries";
interface WorkflowStoreProps extends Workflow {
  readonly?: boolean;
  userToken: string | null;
}

class WorkflowStore implements Workflow {
  readonly: boolean;
  isConnecting = false;
  activeNodeName?: string;
  activeNodeId?: string;
  workflow_id: number | null = -1;
  workspace_id: number = -1;
  id: number = -1;
  @observable name: string = "";
  commit_message: string = "";
  nodes = observable.array<ObservableWorkflowNode>();

  input_variables: Record<string, string> = {};
  queryClient = new QueryClient();
  version_id?: number;
  is_draft: boolean = false;
  // UI
  isSidebarOpen = false;
  isStarted = false;

  userToken: string | null;

  errors: SaveWorkflowMutationResponse["message"] | null = null;
  [key: string]: any;

  constructor(workflow: WorkflowStoreProps) {
    this.workflow_id = workflow.workflow_id;
    this.workspace_id = workflow.workspace_id;
    this.name = workflow.name;
    this.commit_message = workflow.commit_message;
    this.nodes = observable.array(
      withoutEvalsCompatibilityLayer(withNameToIdRemapping(workflow.nodes)),
    );
    this.input_variables = workflow.input_variables;
    this.readonly = !!workflow.readonly;
    this.userToken = workflow.userToken;
    this.version_id = workflow.version_id!;
    this.saveMutation = createSaveMutation(this.userToken!, this.queryClient);
    this.executeWorkflowMutation = createExecuteWorkflowMutation(
      this.userToken!,
      this.queryClient,
    );
    this.saveSingleNodeMutation = createSaveSingleNodeMutation(
      this.userToken!,
      this.queryClient,
    );
    this.deleteMutation = createDeleteWorkflowMutation(
      this.userToken!,
      this.queryClient,
    );

    this.playFromHereMutation = createPlayFromHereMutation(
      this.userToken!,
      this.queryClient,
    );

    this.addReleaseLabelMutation = createAddWorkflowLabelMutation(
      this.userToken!,
      this.queryClient,
    );

    this.removeReleaseLabelMutation = createDeleteWorkflowLabelMutation(
      this.userToken!,
      this.queryClient,
    );

    this.updateWorkflowNameMutation = createUpdateWorkflowNameMutation(
      this.userToken!,
      this.queryClient,
    );

    this.updateWorkflowLabelMutation = createUpdateWorkflowLabelMutation(
      this.userToken!,
      this.queryClient,
    );

    this.disposeIsRunningReaction = reaction(
      () => this.isRunning,
      (isRunning, previousIsRunning) => {
        if (previousIsRunning && !isRunning) {
          this.resetTriggeredStates();
        }
      },
    );
    makeAutoObservable(this);
  }

  // Workflow Management Methods
  // ---------------------------

  printDebug() {
    console.log("Workflow Debug Information:");
    console.log("---------------------------");
    console.log(`Workflow ID: ${this.workflow_id}`);
    console.log(`Workspace ID: ${this.workspace_id}`);
    console.log(`Name: ${this.name}`);
    console.log(`Commit Message: ${this.commit_message}`);
    console.log(`Is Draft: ${this.is_draft}`);
    console.log(`Active Node ID: ${this.activeNodeId}`);
    console.log(`Is Connecting: ${this.isConnecting}`);
    console.log(`Is Sidebar Open: ${this.isSidebarOpen}`);
    console.log(`Is Started: ${this.isStarted}`);
    console.log("\nNodes:");
    this.nodes.forEach((node, index) => {
      console.log(`\nNode ${index + 1}:`);
      console.log(`  ID: ${node.id}`);
      console.log(`  Name: ${node.name}`);
      console.log(`  Type: ${node.node_type}`);
      console.log(`  Status: ${node.status}`);
      console.log(`  Is Output Node: ${node.is_output_node}`);
      console.log(`  Dependencies: ${JSON.stringify(node.dependencies)}`);
      console.log(`  Errors: ${JSON.stringify(node.errors)}`);
      console.log(`  Triggered: ${node.triggered}`);
      console.log(
        `  Configuration: ${JSON.stringify(node.configuration, null, 2)}`,
      );
    });
    console.log("\nInput Variables:");
    console.log(JSON.stringify(this.input_variables, null, 2));
  }

  toSchema() {
    return {
      workflow_id: this.workflow_id === -1 ? null : this.workflow_id,
      workspace_id: this.workflow_id === -1 ? this.workspace_id : undefined,
      name: this.name,
      commit_message: this.commit_message,
      nodes: withEvalsCompatibilityLayer(withIdToNameRemapping(this.nodes)),
      required_input_variables: this.input_variables,
      is_draft: this.is_draft,
    };
  }

  delete(callback?: () => void) {
    console.log("Deleteing", this.toSchema());
    this.deleteMutation.mutate(
      { workflow_id: this.workflow_id! },
      {
        onSuccess: (data: any) => {
          if (data.success) {
            displayToast("Workflow deleted", ToastType.success);
            Promise.all([
              this.queryClient.invalidateQueries([ENDPOINTS.workflows]),
              this.queryClient.invalidateQueries([ENDPOINTS.workflow_versions]),
            ]).then(() => callback?.());
          } else
            this.handleError(
              data.error || data.message || data || "Unknown error",
              (reason) => {
                console.error("Error deleting workflow:", reason);
              },
            );
        },
        onError: (error: any) => {
          this.handleError(
            error.message || error.error || error || "Unknown error",
            (reason) => {
              console.error("Error deleting workflow:", reason);
            },
          );
        },
      },
    );
  }

  save(
    commitMessage: string,
    existing = false,
  ): Promise<SaveWorkflowMutationResponse> {
    this.errors = null;
    this.commit_message = commitMessage;
    this.nodes.replace(this.nodes.filter((node) => node.saved));
    return new Promise((resolve, reject) => {
      this.saveMutation.mutate(
        { is_existing: existing, workflowSchema: this.toSchema() },
        {
          onSuccess: (data: SaveWorkflowMutationResponse) => {
            if (data.success) {
              displayToast("Workflow saved", ToastType.success);
              Promise.all([
                this.queryClient.invalidateQueries([ENDPOINTS.workflows]),
                this.queryClient.invalidateQueries([
                  ENDPOINTS.workflow_versions,
                ]),
              ]).then(() => resolve(data));
            } else
              this.handleError(
                data.error || data.message || data || "Unknown error",
                reject,
              );
          },
          onError: (error: any) => {
            this.handleError(
              error.error || error.message || error || "Unknown error",
              reject,
            );
          },
        },
      );
    });
  }

  set(workflow: Workflow) {
    this.workflow_id = workflow.workflow_id;
    this.workspace_id = workflow.workspace_id;
    this.name = workflow.name;
    this.commit_message = workflow.commit_message;
    this.nodes.replace(workflow.nodes);
    this.input_variables = workflow.input_variables;
    this.version_id = workflow.version_id!;
  }

  updateFromProps(workflow: WorkflowStoreProps) {
    runInAction(() => {
      this.workflow_id = workflow.workflow_id;
      this.workspace_id = workflow.workspace_id;
      this.name = workflow.name;
      this.commit_message = workflow.commit_message;
      this.nodes.replace(workflow.nodes);
      this.input_variables = workflow.input_variables;
      this.readonly = !!workflow.readonly;
    });
  }

  dispose() {
    this.saveMutation.dispose();
    if (this.disposeIsRunningReaction) {
      this.disposeIsRunningReaction();
    }
  }

  // Node Management Methods
  // -----------------------

  get activeNode(): ObservableWorkflowNode | undefined {
    return this.nodes.find((node) => node.id === this.activeNodeId);
  }

  @action
  addNode(node: WorkflowNode): ObservableWorkflowNode {
    return this.nodes[this.nodes.push(node) - 1];
  }

  @action
  removeNode(id: string) {
    const updatedNodes = this.nodes.filter((node) => node.id !== id);
    updatedNodes.forEach((node) => {
      node.dependencies = node.dependencies.filter((dep) => dep !== id);
    });
    this.nodes.replace(updatedNodes);
  }

  @action
  updateNode(name: string, updateFn: (node: WorkflowNode) => WorkflowNode) {
    const index = this.nodes.findIndex((node) => node.name === name);
    if (index !== -1) {
      const updatedNode = updateFn(this.nodes[index]);
      this.nodes.splice(index, 1, updatedNode);
    }
  }

  replaceNode(name: string, replacementNode: Omit<WorkflowNode, "id">) {
    runInAction(() => {
      const index = this.nodes.findIndex((node) => node.name === name);
      if (index !== -1) {
        const currentNode = this.nodes[index];
        const hasChanged = Object.keys(replacementNode).some(
          (key) =>
            JSON.stringify((currentNode as any)[key]) !==
            JSON.stringify((replacementNode as any)[key]),
        );
        const updatedNode = {
          ...currentNode,
          ...replacementNode,
          saved: hasChanged ? false : currentNode.saved,
        };
        this.nodes.splice(index, 1, updatedNode);
      }
    });
  }

  @action
  addOutput(nodeId: string, output: NodeOutput) {
    const node = this.getNodeById(nodeId);
    if (node) {
      node.outputs = node.outputs || [];
      node.outputs.push(output);
    }
  }

  setOutputNode(id: string, is_output_node = true) {
    const updatedNodes = this.nodes.map((node) => {
      if (node.id === id) {
        return {
          ...node,
          is_output_node,
        };
      } else {
        return {
          ...node,
          is_output_node: false,
        };
      }
    });
    this.nodes.replace(updatedNodes);
  }

  addDependency(nodeId: string, dependencyId: string) {
    const associatedNode = this.getNodeById(nodeId);
    if (associatedNode) {
      associatedNode.dependencies = [
        ...associatedNode.dependencies,
        dependencyId,
      ];
    }
    this.saveNode(associatedNode!);
  }

  removeDependency(nodeId: string, dependencyId: string) {
    const associatedNode = this.getNodeById(nodeId);
    if (associatedNode)
      associatedNode.dependencies = associatedNode.dependencies.filter(
        (dep: string) => dep !== dependencyId,
      );
    this.saveNode(associatedNode!);
  }

  @action
  setNodeStatus(workflowNodeId: string, status: WorkflowNodeStatus) {
    const node = this.getNodeById(workflowNodeId);
    if (node) node.status = status;
  }

  // Node Query Methods
  // ------------------

  getNodeByName(name: string): ObservableWorkflowNode | undefined {
    const node = this.nodes?.find((node) => node.name === name);
    return node;
  }

  getNodeById(workflowNodeId: string) {
    return this.nodes.find((node) => node.id === workflowNodeId);
  }

  indexOf(nodeId: string) {
    return this.nodes.findIndex((node) => node.id === nodeId);
  }

  isOutputNode(id: string) {
    return this.nodes.find((node) => node.id === id)?.is_output_node;
  }

  // Input Variable Management Methods
  // ---------------------------------

  addInputVariable(key: string, value: string) {
    this.input_variables[key] = value;
  }

  updateInputVariable(key: string, field: "key" | "value", newValue: string) {
    if (field === "key") {
      const value = this.input_variables[key];
      delete this.input_variables[key];
      this.input_variables[newValue] = value;
    } else {
      this.input_variables[key] = newValue;
    }
  }

  removeInputVariable(key: string) {
    delete this.input_variables[key];
  }

  updateInputVariables(inputVariables: Record<string, string>) {
    this.input_variables = inputVariables;
  }

  // Error Handling Methods
  // ----------------------

  @action
  setErrors(errors: SaveWorkflowMutationResponse["message"] | null) {
    transaction(() => {
      if (errors === null) {
        this.errors = null;
        this.nodes.forEach((node) => {
          node.errors = [];
        });
      } else {
        this.errors = errors;
        const nodeErrors =
          (typeof errors !== "string" &&
            errors?.filter((error) => error.loc.includes("nodes"))) ||
          [];

        this.nodes.forEach((node) => {
          const existingErrors = node.errors || [];
          const newErrors = nodeErrors.filter((error) => {
            const errorIndex = Number(error.loc[1]);
            return (
              errorIndex ===
              this.nodes.findIndex(({ name }) => node.name === name)
            );
          });
          node.errors = [...existingErrors, ...newErrors];
        });
      }
    });
  }

  private handleError(errorMessage: any, reject: (reason?: any) => void) {
    if (typeof errorMessage === "string") {
      errorMessage = [
        {
          loc: [],
          msg: errorMessage,
          type: "error",
        },
      ];
    }
    runInAction(() => this.setErrors(errorMessage));
    reject({ success: false, message: errorMessage });
  }

  getNodeErrors(nodeName: string) {
    return this.nodeErrors.filter((error) => {
      const errorIndex = Number(error.loc[1]);
      return (
        errorIndex === this.nodes.findIndex((node) => node.name === nodeName)
      );
    });
  }

  getGeneralErrors() {
    if (typeof this.errors === "string" || !this.errors) return [];
    return this.errors.filter(
      (error) =>
        !error.loc.includes("nodes") ||
        (error.loc.includes("nodes") && error.loc.length === 1),
    );
  }

  get nextNodeLabel() {
    const prefix = "Node";
    let counter = 1;
    const usedLabels = new Set(this.nodes.map((node) => node.name));
    while (true) {
      const newLabel = `${prefix} ${counter}`;
      if (!usedLabels.has(newLabel)) {
        return newLabel;
      }
      counter++;
    }
  }

  // UI Methods
  // ----------

  @action
  setName(name: string, save = false) {
    const originalName = this.name;
    runInAction(() => (this.name = name));
    if (save) {
      this.updateWorkflowNameMutation.mutate(
        {
          id: this.workflow_id!,
          name,
        },
        {
          onSuccess: (res: any) => {
            if (!res.success) {
              runInAction(() => {
                this.name = originalName; // Revert the name change
              });
              this.handleError(
                res.error || res.message || res || "Unknown error",
                (reason) => {
                  console.error("Error updating workflow name:", reason);
                },
              );
            }
          },
          onError: (error: any) => {
            runInAction(() => {
              this.name = originalName; // Revert the name change
            });
            this.handleError(
              error.message || error.error || error || "Unknown error",
              (reason) => {
                console.error("Error updating workflow name:", reason);
              },
            );
          },
        },
      );
    }
  }

  isNodeSelected(id: string): boolean {
    return this.activeNodeId === id;
  }

  setIsConnecting(isConnecting: boolean) {
    this.isConnecting = isConnecting;
  }

  @action
  setActiveNode(node: ObservableWorkflowNode | undefined) {
    this.activeNodeId = node?.id;
  }

  @action
  removeActiveNode() {
    this.activeNodeId = undefined;
  }

  openSidebar() {
    this.isSidebarOpen = true;
  }

  closeSidebar() {
    this.isSidebarOpen = false;
  }

  @action
  setSidebarOpen(isSidebarOpen: boolean) {
    this.isSidebarOpen = isSidebarOpen;
  }

  // Execution Methods
  // -----------------

  @action
  resetNodeStatuses() {
    this.nodes.forEach((node) => {
      node.status = undefined;
      node.errors = [];
    });
  }

  @action
  resetTriggeredStates() {
    this.nodes.forEach((node) => {
      node.triggered = false;
    });
    this.isStarted = false;
  }

  @action
  run = () => {
    if (this.isStarted) return;
    this.resetNodeStatuses();
    this.isStarted = true;
    this.executeWorkflowMutation.mutate(
      {
        workflow_version_id: this.version_id!,
        input_variables: this.input_variables,
        workflow_id: this.workflow_id,
      },
      {
        onSuccess: () =>
          runInAction(() => {
            this.isStarted = false;
          }),
        onError: () =>
          runInAction(() => {
            this.isStarted = false;
          }),
      },
    );
  };

  @action
  playFromHere = (node: ObservableWorkflowNode) => {
    runInAction(() => {
      node.triggered = true;
      node.errors = [];
      this.isStarted = true;
    });
    this.playFromHereMutation.mutate(
      {
        workflow_node_id: node.id!,
        workflow_id: this.workflow_id,
      },
      {
        onSuccess: (data: any) => {
          if (!data.success) {
            runInAction(() => {
              node.triggered = false;
              this.isStarted = false;
            });
            this.handleError(
              data.error || data.message || data || "Unknown error",
              (reason) => {
                console.error("Play from here failed:", reason);
              },
            );
          }
        },
        onError: (error: any) => {
          runInAction(() => {
            node.triggered = false;
            this.isStarted = false;
          });
          this.handleError(
            error.message || error.error || error || "Unknown error",
            (reason) => {
              console.error("Play from here failed:", reason);
            },
          );
        },
      },
    );
  };

  // Release Labels
  // --------------

  addReleaseLabel(name: string, versionId: number) {
    return new Promise((resolve, reject) => {
      this.addReleaseLabelMutation.mutate(
        {
          name,
          workflow_id: this.workflow_id!,
          workflow_version_id: versionId!,
        },
        {
          onSuccess: (data: any) => {
            this.queryClient.invalidateQueries([
              ENDPOINTS.workflow_versions,
              this.workflow_id,
            ]);

            if (!data.success && data.existing_workflow_label_id) {
              this.updateWorkflowLabelMutation.mutate(
                {
                  workflow_version_id: versionId!,
                  id: data.existing_workflow_label_id,
                },
                {
                  onSuccess: (data: any) => {
                    this.queryClient.invalidateQueries([
                      ENDPOINTS.workflow_versions,
                      this.workflow_id,
                    ]);
                    resolve(data);
                  },
                },
              );
            } else if (data.success) {
              resolve(data);
            } else {
              reject(data);
            }
          },
          onError: (error: any) => {
            reject(error);
          },
        },
      );
    });
  }

  removeReleaseLabel(labelId: number) {
    return new Promise((resolve, reject) => {
      this.removeReleaseLabelMutation.mutate(
        {
          id: labelId,
        },
        {
          onSuccess: (data: any) => {
            this.queryClient.invalidateQueries([
              ENDPOINTS.workflow_versions,
              this.workflow_id,
            ]);
            resolve(data);
          },
          onError: (error: any) => {
            reject(error);
          },
        },
      );
    });
  }

  // Utility Methods
  // ---------------
  async saveNode(node: WorkflowNode, nameChanged = false): Promise<boolean> {
    const nodesToSave = [node];
    if (nameChanged) {
      const dependentNodes = this.nodes.filter(
        (n) => n.dependencies.includes(node.id) || nodeHasSource(n, node.id),
      );
      nodesToSave.push(...dependentNodes);
    }
    const results = await Promise.all(
      nodesToSave.map((n) => {
        return new Promise((resolve, reject) => {
          // Convert node ids in dependencies to node names
          const nodeWithNameDependencies = withIdToNameRemappingForOneNode(
            n,
            this.nodes,
          );
          this.saveSingleNodeMutation.mutateAsync(
            withEvalsCompatibilityLayer([nodeWithNameDependencies])[0],
            {
              onSuccess: (res: any) => {
                if (!res.success) {
                  const nodeIndex = this.indexOf(node.id);
                  this.setErrors(
                    res.message.map((e: any) => {
                      console.log(e);
                      if (e.type === "missing") {
                        e.msg = `Field required: '${e.loc[e.loc.length - 1]}'`;
                      }

                      return {
                        ...e,
                        loc: ["nodes", nodeIndex, ...e.loc],
                      };
                    }),
                  );
                  resolve(false);
                } else {
                  const associatedNode = this.getNodeById(node.id);
                  runInAction(() => {
                    if (associatedNode) {
                      associatedNode.errors = [];
                      associatedNode.saved = true;
                    }
                  });
                  resolve(true);
                }
              },
              onError: (error: any) => {
                this.setErrors(error.message || error.error);
                reject(false);
              },
            },
          );
        });
      }),
    );

    return results.every((result) => result === true);
  }

  // Computed Properties
  // -------------------

  get allNodesCompleted() {
    return this.nodes.every(
      (node) =>
        !node?.status ||
        node.status === WorkflowNodeStatus.COMPLETED ||
        node.status === WorkflowNodeStatus.FAILED,
    );
  }

  get activeNodeType(): WorkflowNodeType | undefined {
    return this.activeNode?.node_type;
  }

  get nodeErrors() {
    if (typeof this.errors === "string" || !this.errors) return [];
    return this.errors.filter((error) => error.loc.includes("nodes"));
  }

  get nodeCount() {
    return this.nodes.length;
  }

  get rootNodes() {
    return this.nodes.filter((node) => node.dependencies.length === 0);
  }

  get isRunning() {
    return this.nodes.some(
      (node) =>
        node.status === WorkflowNodeStatus.RUNNING ||
        node.status === WorkflowNodeStatus.QUEUED,
    );
  }
}

type IWorkflow = WorkflowStore & Workflow;

export { IWorkflow, WorkflowStore, WorkflowStoreProps };
