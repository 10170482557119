import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { useCallback, useEffect, useState } from "react";

interface DefaultNameDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (name: string) => void;
  dialogTitle: string;
  dialogDescription?: string;
  inputPlaceholder: string;
}

const DefaultNameDialog: React.FC<DefaultNameDialogProps> = ({
  isOpen,
  onClose,
  onSubmit,
  dialogTitle,
  inputPlaceholder,
  dialogDescription,
}) => {
  const [entryName, setEntryName] = useState("");
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    setIsValid(entryName.trim().length > 0);
  }, [entryName]);

  const handleSubmit = useCallback(() => {
    if (isValid) {
      onSubmit(entryName);
      onClose();
    }
  }, [isValid, entryName, onSubmit, onClose]);

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === "Enter" && isValid) {
        handleSubmit();
      }
    },
    [isValid, handleSubmit],
  );

  const buttonClassName = `
    text-white transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2
    ${
      isValid
        ? "bg-blue-600 hover:bg-blue-700 focus:ring-blue-500"
        : "cursor-not-allowed bg-gray-300"
    }
  `;

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{dialogTitle}</DialogTitle>
          {dialogDescription && (
            <DialogDescription>{dialogDescription}</DialogDescription>
          )}
        </DialogHeader>
        <div>
          <Input
            autoFocus
            id="name"
            placeholder={inputPlaceholder}
            type="text"
            value={entryName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setEntryName(e.target.value)
            }
            onKeyPress={handleKeyPress}
            className="pr-10"
          />
        </div>
        <DialogFooter className="sm:justify-end">
          <Button variant="outline" onClick={onClose} className="mr-2">
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={!isValid}
            className={buttonClassName}
          >
            Next
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default DefaultNameDialog;
