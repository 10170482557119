const Checkbox = ({
  id = "",
  label = "",
  checked = false,
  onChange = () => {},
  onClick = () => {},
  disabled = false,
  required = false,
  error = false,
  className = "",
}) => {
  return (
    <div className={`group flex items-center ${className}`}>
      <div className="relative flex items-center">
        <input
          type="checkbox"
          id={id}
          checked={checked}
          onChange={onChange}
          onClick={onClick}
          disabled={disabled}
          required={required}
          className={`
            h-[18px] w-[18px]
            rounded border
            transition-all duration-200 ease-in-out
            focus:ring-2 focus:ring-blue-200 focus:ring-offset-1
            disabled:cursor-not-allowed disabled:opacity-50
            ${error ? "border-red-500" : "border-gray-200"}
            ${
              checked
                ? "border-blue-500 bg-blue-500 shadow-sm"
                : "bg-white hover:bg-gray-50"
            }
            ${!disabled && "cursor-pointer hover:border-blue-400"}
            group-hover:shadow-sm
          `}
        />
      </div>
      {label && (
        <label
          htmlFor={id}
          className={`
            ml-2 text-sm font-medium
            transition-colors duration-200
            ${disabled ? "text-gray-400" : "text-gray-700"}
            ${!disabled && "cursor-pointer hover:text-gray-900"}
          `}
        >
          {label}
          {required && <span className="ml-1 text-red-500">*</span>}
        </label>
      )}
    </div>
  );
};

export default Checkbox;
