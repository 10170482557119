import { ViewportPortal } from "@xyflow/react";
import BackgroundContextMenu from "./BackgroundContextMenu";
import NodeContextMenu from "./NodeContextMenu";
export enum ContextMenuType {
  Node = "node",
  Background = "background",
}
interface ContextMenuProps {
  id: string;
  type: ContextMenuType;
  top?: number;
  left?: number;
  right?: number;
  bottom?: number;
  onSelect?: () => void;
  [key: string]: any;
}

const ContextMenuBody = ({
  id,
  type,
  onSelect,
  position,
}: ContextMenuProps) => {
  if (type === ContextMenuType.Node) {
    return <NodeContextMenu id={id} onClose={onSelect} />;
  } else if (type === ContextMenuType.Background) {
    return <BackgroundContextMenu onClose={onSelect} position={position} />;
  }
  return null;
};

export default function ContextMenu({
  id,
  type,
  top,
  left,
  right,
  bottom,
  onSelect,
  ...props
}: ContextMenuProps) {
  return (
    <ViewportPortal>
      <div
        className="pointer-events-auto relative z-[1001] w-fit rounded-md border border-gray-200 bg-white shadow-md"
        tabIndex={0}
        style={{
          top,
          left,
        }}
        {...props}
      >
        <ContextMenuBody
          id={id}
          type={type}
          onSelect={onSelect}
          position={{ x: left, y: top }}
        />
      </div>
    </ViewportPortal>
  );
}
