import AddEnums from "@/components/FunctionsModal/InteractiveFunctionEditor/AddEnums";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { XIcon } from "@heroicons/react/outline";
import React from "react";
import { ItemProperties, ParameterType, Properties } from "./Types";

interface RegularParamFormProps {
  index: number;
  parameter: Properties;
  parameters: Properties[];
  setParameters: React.Dispatch<React.SetStateAction<Properties[]>>;
  handleParameterChange: <K extends keyof Properties>(
    index: number,
    key: K,
    val: Properties[K] | ItemProperties[K],
    parameters: Properties[],
    setParameters: (params: Properties[]) => void,
    isItemDescription?: boolean,
  ) => void;
  handleRemoveRow: (
    index: number,
    parameters: Properties[],
    setParameters: React.Dispatch<React.SetStateAction<Properties[]>>,
  ) => void;
  depth: number;
  isItemDescription?: boolean;
  children?: React.ReactNode;
  readonly?: boolean;
}

export const RegularParamForm: React.FC<RegularParamFormProps> = ({
  index,
  parameter,
  parameters,
  setParameters,
  handleParameterChange,
  handleRemoveRow,
  depth,
  isItemDescription,
  children,
  readonly = false,
}) => {
  const onFieldChange = <K extends keyof Properties>(
    key: K,
    value: Properties[K] | ItemProperties[K],
  ) => {
    handleParameterChange(
      index,
      key,
      value,
      parameters,
      setParameters,
      isItemDescription,
    );
  };

  const onEnumToggle = (checked: boolean) => {
    onFieldChange("isEnum", checked);
  };

  return (
    <div
      key={index}
      className={`${index % 2 === 1 ? "bg-gray-50" : ""} rounded-md px-2`}
    >
      <div className="grid grid-cols-8 items-center gap-4 py-2">
        {/* Remove Row Icon */}
        <div className="col-span-1 flex justify-center">
          <XIcon
            className="h-5 w-5 cursor-pointer text-red-500"
            onClick={
              readonly
                ? undefined
                : () => handleRemoveRow(index, parameters, setParameters)
            }
          />
        </div>

        {/* Required Checkbox */}
        <div className="col-span-1 flex justify-center">
          <input
            type="checkbox"
            className="rounded text-blue-600 focus:ring-blue-500"
            checked={parameter.isRequired || false}
            onChange={(e) => onFieldChange("isRequired", e.target.checked)}
            disabled={readonly}
          />
        </div>

        {/* Name Input */}
        <div className="col-span-2">
          <Input
            className="rounded-sm border-gray-300 text-xs focus:border-blue-500 focus:ring-blue-500"
            placeholder="Name"
            value={parameter.name}
            onChange={(e) => onFieldChange("name", e.target.value)}
            autoComplete="off"
            disabled={readonly}
          />
        </div>

        {/* Type Dropdown */}
        <div className="relative col-span-2">
          <DropdownMenu>
            <DropdownMenuTrigger
              disabled={readonly}
              className="w-full border-gray-300 text-sm font-normal text-gray-500 focus:border-blue-500 focus:ring-blue-500"
            >
              {parameter.type}
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              {Object.values(ParameterType).map((format) => (
                <DropdownMenuItem
                  className="w-full text-center"
                  key={format}
                  onSelect={() => onFieldChange("type", format)}
                >
                  {format}
                </DropdownMenuItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>

        {/* Title Input */}
        <div className="col-span-2">
          <Input
            className="rounded-sm border-gray-300 text-xs shadow-none focus:border-blue-500 focus:ring-blue-500"
            placeholder="Title"
            value={parameter.title}
            onChange={(e) => onFieldChange("title", e.target.value)}
            autoComplete="off"
            disabled={readonly}
          />
        </div>

        {/* Description and Enum Toggle */}
        {parameter.type !== "array" && parameter.type !== "object" && (
          <div
            className={`flex flex-col gap-2 ${
              depth === 0 ? "col-span-6 col-start-3" : "col-span-6 col-start-2"
            }`}
          >
            <Textarea
              className="w-full rounded-sm border-gray-300 text-xs focus:border-blue-500 focus:ring-blue-500"
              placeholder="Description"
              value={parameter.description}
              onChange={(e) => onFieldChange("description", e.target.value)}
              disabled={readonly}
            />
            <div className="flex items-center gap-3">
              <input
                id={`isEnum-${index}`}
                type="checkbox"
                className="rounded text-blue-600 focus:ring-blue-500"
                checked={parameter.isEnum || false}
                onChange={(e) => onEnumToggle(e.target.checked)}
                disabled={readonly}
              />
              <label
                htmlFor={`isEnum-${index}`}
                className="text-xs text-gray-500"
              >
                Include Enum
              </label>
            </div>
            {parameter.isEnum && (
              <AddEnums
                readonly={readonly}
                enums={parameter.enum ?? []}
                setEnums={(val: string[]) => onFieldChange("enum", val)}
              />
            )}
          </div>
        )}

        {/* Child Elements (if any) */}
        {children}
      </div>
    </div>
  );
};

export default RegularParamForm;
