import { useAuth } from "@/context/auth-context";
import { useRequestStats } from "../../queries";
import { useStatBoxes } from "./hooks/useStatsBoxes";

type PromptTemplateVersionStatsProps = {
  promptId: string;
  versionNumber: number;
  workspaceId: number;
};

export const PromptTemplateVersionStats = ({
  promptId,
  versionNumber,
  workspaceId,
}: PromptTemplateVersionStatsProps) => {
  const authContext = useAuth();

  const {
    data: { stats },
  } = useRequestStats(
    String(promptId),
    String(versionNumber),
    authContext?.userToken || "",
    String(workspaceId),
  );
  return useStatBoxes(stats);
};
