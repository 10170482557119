import EditJsonSchemaModal from "@/components/ModelProviderSelection/EditJsonSchemaModal";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { SchemaDefinition } from "@/types";
import { ModelConfigMap, ParamConfig } from "../../modelConfig";
import { CustomParameters } from "../ModelProviderSelection/CustomParameters";
import { useState } from "react";

// Needed as a separate component to unlink the input and slider values
const SliderWithInput = ({
  id,
  name,
  min,
  max,
  step,
  value,
  onChange,
}: {
  id: string;
  name: string;
  min?: number;
  max?: number;
  step?: number;
  value: number;
  onChange: (value: number) => void;
}) => {
  const [localValue, setLocalValue] = useState(value.toString());
  const [sliderValue, setSliderValue] = useState(value);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalValue(e.target.value);
  };

  const handleSliderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseFloat(e.target.value);
    setSliderValue(newValue);
    setLocalValue(newValue.toString());
    onChange(newValue);
  };
  const handleInputBlur = () => {
    const parsed = parseFloat(localValue);
    if (!isNaN(parsed)) {
      setSliderValue(parsed);
      setLocalValue(parsed.toString());
      onChange(parsed);
    } else {
      setLocalValue(value.toString());
    }
  };

  return (
    <div className="mt-1 flex items-center space-x-1">
      <input
        type="range"
        id={`${id}-slider`}
        name={name}
        min={min}
        max={max}
        step={step}
        value={sliderValue}
        className="mr-2 h-5 w-full rounded-md bg-blue-600 outline-none"
        onChange={handleSliderChange}
      />
      <input
        id={`${id}-input`}
        name={name}
        value={localValue}
        className="w-[40px] rounded-md border border-gray-300 px-1.5 py-1 text-xs"
        onChange={handleInputChange}
        onBlur={handleInputBlur}
      />
    </div>
  );
};

function AdvancedControls({
  isCustomModel,
  selectedProvider,
  selectedModel,
  modelParams,
  handleModelParamChange,
  handleModelParamChangeDropDown,
  updateCandidate,
  customParameters,
  setCustomParameters,
  modelConfigs,
  showRateLimitDelay = true,
  showChatTypeToggle = true,
}: {
  isCustomModel: boolean;
  selectedProvider: string;
  selectedModel: string;
  modelParams: { [key: string]: any };
  handleModelParamChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleModelParamChangeDropDown: (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => void;
  updateCandidate: (key: string, value: any) => void;
  customParameters: { paramKey: string; paramValue: number; id: number }[];
  setCustomParameters: (
    params: { paramKey: string; paramValue: number; id: number }[],
  ) => void;
  modelConfigs: ModelConfigMap;
  showRateLimitDelay?: boolean;
  showChatTypeToggle?: boolean;
}) {
  const getSelector = (key: string, value: ParamConfig) => {
    switch (value.selectorType) {
      case "slider":
        return (
          <>
            <div className="flex justify-between">
              <label htmlFor={key} className="block pl-1 text-xs font-medium">
                {value.name}
              </label>
            </div>
            <SliderWithInput
              id={`${selectedProvider}:::${selectedModel}:::${key}`}
              name={key}
              min={value?.range?.[0]}
              max={value?.range?.[1]}
              step={value.isInt ? 1 : 0.01}
              value={
                modelParams?.[key] !== undefined
                  ? modelParams[key]
                  : value.default
              }
              onChange={(newValue) => {
                // Needed for type safety
                const syntheticEvent = {
                  target: {
                    name: key,
                    value: newValue,
                    getAttribute: (attrName: string) =>
                      attrName === "value" ? newValue.toString() : null,
                  } as any,
                  currentTarget: {
                    value: newValue.toString(),
                  },
                  preventDefault: () => {},
                  stopPropagation: () => {},
                } as React.ChangeEvent<HTMLInputElement>;

                handleModelParamChange(syntheticEvent);
              }}
            />
          </>
        );
      case "input":
        return (
          <>
            <label htmlFor={key} className="block pl-1 text-xs font-medium">
              {value.name}
            </label>
            <div className="mt-1">
              <input
                type="number"
                id={key}
                key={`${selectedProvider}:::${selectedModel}:::${key}`}
                name={key}
                min={value?.range ? value.range[0] : undefined}
                max={value?.range ? value.range[1] : undefined}
                step={value.isInt ? 1 : 0.01}
                value={modelParams?.[key] || value.default}
                className="w-full rounded-lg border border-gray-300 px-2 py-1 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                onChange={handleModelParamChange}
              />
            </div>
          </>
        );
      case "dropdown":
        let selectedDropdownValue;
        if (modelParams[key]) {
          selectedDropdownValue = Object.keys(value?.options || {}).find(
            (_k) =>
              typeof modelParams[key] === "string"
                ? JSON.stringify(value?.options?.[_k]) === modelParams[key]
                : value?.options?.[_k]?.type === modelParams[key]?.type,
          );
        }
        return (
          <>
            <label htmlFor={key} className="block pl-1 text-xs font-medium">
              {value.name}
            </label>
            <div className="mb-2 mt-1">
              <DropdownMenu>
                <DropdownMenuTrigger
                  id={key}
                  name={key}
                  key={`${selectedProvider}:::${selectedModel}:::${key}`}
                  className="w-full border-gray-300 text-sm font-normal text-gray-500 focus:border-blue-500 focus:ring-blue-500"
                >
                  {selectedDropdownValue || "Select a response format"}
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  {Object.entries(value?.options || {}).map(
                    ([optionKey, optionValue]) => {
                      return (
                        <DropdownMenuItem
                          className="w-full text-center"
                          key={optionKey}
                          onSelect={() => {
                            handleModelParamChangeDropDown({
                              target: {
                                name: key,
                                value: optionValue,
                              },
                            } as React.ChangeEvent<HTMLSelectElement>);
                          }}
                        >
                          {optionKey}
                        </DropdownMenuItem>
                      );
                    },
                  )}
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
            {selectedDropdownValue === "JSON Schema" && (
              <EditJsonSchemaModal
                initialValues={modelParams[key]?.json_schema}
                handleSubmit={(schema: SchemaDefinition) => {
                  handleModelParamChangeDropDown({
                    target: {
                      name: key,
                      value: {
                        ...modelParams[key],
                        json_schema: { ...schema },
                      },
                    },
                  } as React.ChangeEvent<HTMLSelectElement>);
                }}
              />
            )}
          </>
        );
      case "textInput":
        return (
          <>
            <label htmlFor={key} className="block pl-1 text-xs font-medium">
              {value.name}
            </label>
            <div className="mt-1">
              <input
                type="text"
                id={key}
                key={`${selectedProvider}:::${selectedModel}:::${key}`}
                name={key}
                value={modelParams?.[key] || value.default}
                className="w-full rounded-lg border border-gray-300 px-2 py-1 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                onChange={handleModelParamChange}
              />
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="mb-6 grid grid-cols-2 gap-6 border-t border-gray-200 p-3 pt-6 text-gray-500">
      {!isCustomModel && (
        <>
          {Object.entries(
            modelConfigs[selectedProvider][selectedModel].params,
          ).map(([key, value]: [string, ParamConfig]) => (
            <div key={key}>{getSelector(key, value)}</div>
          ))}
        </>
      )}
      {showRateLimitDelay && (
        <div key={"rate-limit-delay"}>
          <label
            htmlFor={"rate-limit-delay"}
            className="block pl-1 text-xs font-medium"
          >
            Delay (s)
          </label>
          <div className="mt-1">
            <input
              type="number"
              id={"rate-limit-delay"}
              key={`${selectedProvider}:::${selectedModel}:::rate-limit-delay`}
              name={"key"}
              min={0}
              max={3600}
              defaultValue={0}
              className="w-full rounded-lg border border-gray-300 px-2 py-1 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
              onChange={(e) =>
                updateCandidate("rateLimitDelay", Number(e.target.value))
              }
            />
          </div>
        </div>
      )}
      <CustomParameters
        customParams={customParameters}
        setCustomParams={setCustomParameters}
        isCustomModel={isCustomModel}
        showChatTypeToggle={showChatTypeToggle}
        setIsChatModel={(isChatModel: boolean) => {
          updateCandidate("isChatModel", isChatModel);
        }}
      />
    </div>
  );
}

export default AdvancedControls;
