import { DotsVerticalIcon } from "@radix-ui/react-icons";
import { useState } from "react";

interface ContextMenuButtonProps {
  iconBackgroundColor: string;
  headerRef: React.RefObject<HTMLDivElement>;
}

const ContextMenuButton: React.FC<ContextMenuButtonProps> = ({
  iconBackgroundColor,
  headerRef,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleContextMenu = (
    event: React.MouseEvent<HTMLDivElement & HTMLButtonElement>,
  ) => {
    event.preventDefault();
    event.stopPropagation();
    if (!headerRef.current) return;

    const element = headerRef.current;

    const customEvent = new MouseEvent("contextmenu", {
      bubbles: true,
      cancelable: true,
      view: window,
      button: 2,
      buttons: 2,
      clientX: event.clientX,
      clientY: event.clientY,
    });

    element.dispatchEvent(customEvent);
  };

  return (
    <button
      onClick={handleContextMenu}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      className={`rounded-full ${iconBackgroundColor} p-2 transition-all duration-200 ease-in-out hover:brightness-95 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 ${
        isFocused ? "ring-2 ring-blue-500 ring-opacity-50" : ""
      }`}
    >
      <DotsVerticalIcon className="h-5 w-5 text-gray-600" />
    </button>
  );
};

export default ContextMenuButton;
