import { useParams } from "react-router-dom";
import { useAuth } from "./context/auth-context";
import { useInferenceClientsList } from "./queries";

export interface ParamConfig {
  name: string;
  range?: [number, number];
  default?: number;
  isInt?: boolean;
  isJSON?: boolean;
  selectorType: "slider" | "input" | "dropdown" | "textInput";
  required?: boolean;
  options?: { [key: string]: any };
}

export interface ModelConfig {
  metadata: {
    _whitelist_ids?: number[];
    isChat?: boolean;
  };
  params: {
    [param: string]: ParamConfig;
  };
}

export interface ModelConfigMap {
  [provider: string]: {
    [model: string]: ModelConfig;
  };
}

export const modelIsAccessible = (model: ModelConfig, userId: number) => {
  if (!model.metadata?._whitelist_ids) {
    return true;
  }
  return model.metadata._whitelist_ids.includes(userId);
};

export const getHuggingFaceParams = (isChat: boolean) => {
  return isChat
    ? ({
        model: {
          name: "Model",
          selectorType: "textInput",
        },
        temperature: {
          name: "Temperature",
          range: [0, 2],
          default: 1,
          selectorType: "slider",
        },
        frequency_penalty: {
          name: "Frequency Penalty",
          range: [-2, 2],
          default: 0,
          selectorType: "slider",
        },
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 20,
          isInt: true,
          selectorType: "input",
        },
        presence_penalty: {
          name: "Presence Penalty",
          range: [-2, 2],
          default: 0,
          selectorType: "slider",
        },
        seed: {
          name: "Seed",
          range: [0, 2147483647],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
        stopSequences: {
          name: "Stop Sequences",
          selectorType: "input",
          isInt: false,
          range: [0, 4],
        },
        top_p: {
          name: "Top P",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
      } as { [key: string]: ParamConfig })
    : ({
        model: {
          name: "Model",
          selectorType: "textInput",
        },
        frequency_penalty: {
          name: "Frequency Penalty",
          range: [-2, 2],
          default: 0,
          selectorType: "slider",
        },

        seed: {
          name: "Seed",
          range: [0, 2147483647],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
        stopSequences: {
          name: "Stop Sequences",
          selectorType: "input",
          isInt: true,
          default: 0,
          range: [0, 4],
        },
        temperature: {
          name: "Temperature",
          range: [0, 2],
          default: 1,
          selectorType: "slider",
        },
        top_k: {
          name: "Top K",
          range: [0, 2048],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
        top_p: {
          name: "Top P",
          range: [0, 1],
          default: 0,
          selectorType: "slider",
        },
      } as { [key: string]: ParamConfig });
};

export const useAccessibleModels = (
  userId: number,
  isChat = false,
): ModelConfigMap => {
  const accessibleModels: ModelConfigMap = {};
  const { workspaceId } = useParams();
  const userToken = useAuth()?.userToken || "";
  const { data: inferenceClients } = useInferenceClientsList(
    userToken,
    workspaceId!,
  );

  const huggingFaceModels: ModelConfigMap = {
    huggingface: {
      ...(inferenceClients?.inference_clients || []).reduce<
        Record<string, ModelConfig>
      >((acc, client) => {
        acc[client.name.toString()] = {
          metadata: {},
          params: getHuggingFaceParams(isChat),
        };
        return acc;
      }, {}),
    },
  };

  const aggregateModels: ModelConfigMap = {
    ...huggingFaceModels,
    ...modelConfigs,
  };

  for (const provider in aggregateModels) {
    accessibleModels[provider] = {};

    for (const model in aggregateModels[provider]) {
      if (modelIsAccessible(aggregateModels[provider][model], userId)) {
        accessibleModels[provider][model] = aggregateModels[provider][model];
      }
    }
  }
  return accessibleModels;
};

export const defaultModelsCompletion: Record<string, string> = {
  openai: "gpt-3.5-turbo-instruct",
  anthropic: "claude-2",
  "anthropic.bedrock": "anthropic.claude-v2",
  cohere: "command",
  google: "",
  mistral: "",
  cloudflare: "",
};

export const defaultModelsChat: Record<string, string> = {
  openai: "gpt-4o",
  "openai.azure": "gpt-4o",
  anthropic: "claude-3-5-sonnet-latest",
  "anthropic.bedrock": "anthropic.claude-3-5-sonnet-20240620-v1:0",
  cohere: "",
  google: "gemini-1.5-pro-latest",
  mistral: "open-mixtral-8x7b",
  cloudflare: "@cf/meta/llama-3.1-8b-instruct",
};

export const modelConfigs: ModelConfigMap = {
  openai: {
    "o1-preview": {
      params: {},
      metadata: {
        isChat: true,
      },
    },
    "o1-mini": {
      params: {},
      metadata: {
        isChat: true,
      },
    },
    "gpt-dv-speak": {
      params: {
        temperature: {
          name: "Temperature",
          range: [0, 2],
          default: 1,
          selectorType: "slider",
        },
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
        top_p: {
          name: "Top P",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        frequency_penalty: {
          name: "Frequency Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
        presence_penalty: {
          name: "Presence Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
      },
      metadata: {
        _whitelist_ids: [4651],
        isChat: true,
      },
    },
    "gpt-3.5-turbo-1106": {
      params: {
        temperature: {
          name: "Temperature",
          range: [0, 2],
          default: 1,
          selectorType: "slider",
        },
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
        seed: {
          name: "Seed",
          range: [0, 2147483647],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
        response_format: {
          name: "Response Format",
          selectorType: "dropdown",
          options: {
            None: null,
            json: { type: "json_object" },
            text: { type: "text" },
          },
          isJSON: true,
        },
        top_p: {
          name: "Top P",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        frequency_penalty: {
          name: "Frequency Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
        presence_penalty: {
          name: "Presence Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "gpt-3.5-turbo": {
      params: {
        temperature: {
          name: "Temperature",
          range: [0, 2],
          default: 1,
          selectorType: "slider",
        },
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
        seed: {
          name: "Seed",
          range: [0, 2147483647],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
        top_p: {
          name: "Top P",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        frequency_penalty: {
          name: "Frequency Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
        presence_penalty: {
          name: "Presence Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "gpt-3.5-turbo-0125": {
      params: {
        temperature: {
          name: "Temperature",
          range: [0, 2],
          default: 1,
          selectorType: "slider",
        },
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
        seed: {
          name: "Seed",
          range: [0, 2147483647],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
        top_p: {
          name: "Top P",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        frequency_penalty: {
          name: "Frequency Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
        presence_penalty: {
          name: "Presence Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "gpt-3.5-turbo-16k": {
      params: {
        temperature: {
          name: "Temperature",
          range: [0, 2],
          default: 1,
          selectorType: "slider",
        },
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
        seed: {
          name: "Seed",
          range: [0, 2147483647],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
        response_format: {
          name: "Response Format",
          selectorType: "dropdown",
          options: {
            None: null,
            json: { type: "json_object" },
            text: { type: "text" },
          },
          isJSON: true,
        },
        top_p: {
          name: "Top P",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        frequency_penalty: {
          name: "Frequency Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
        presence_penalty: {
          name: "Presence Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "gpt-4-1106-preview": {
      params: {
        temperature: {
          name: "Temperature",
          range: [0, 2],
          default: 1,
          selectorType: "slider",
        },
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
        seed: {
          name: "Seed",
          range: [0, 2147483647],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
        response_format: {
          name: "Response Format",
          selectorType: "dropdown",
          options: {
            None: null,
            json: { type: "json_object" },
            text: { type: "text" },
          },
          isJSON: true,
        },
        top_p: {
          name: "Top P",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        frequency_penalty: {
          name: "Frequency Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
        presence_penalty: {
          name: "Presence Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "gpt-4-0125-preview": {
      params: {
        temperature: {
          name: "Temperature",
          range: [0, 2],
          default: 1,
          selectorType: "slider",
        },
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
        seed: {
          name: "Seed",
          range: [0, 2147483647],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
        response_format: {
          name: "Response Format",
          selectorType: "dropdown",
          options: {
            None: null,
            json: { type: "json_object" },
            text: { type: "text" },
          },
          isJSON: true,
        },
        top_p: {
          name: "Top P",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        frequency_penalty: {
          name: "Frequency Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
        presence_penalty: {
          name: "Presence Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "gpt-4-turbo": {
      params: {
        temperature: {
          name: "Temperature",
          range: [0, 2],
          default: 1,
          selectorType: "slider",
        },
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
        seed: {
          name: "Seed",
          range: [0, 2147483647],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
        response_format: {
          name: "Response Format",
          selectorType: "dropdown",
          options: {
            None: null,
            json: { type: "json_object" },
            text: { type: "text" },
          },
          isJSON: true,
        },
        top_p: {
          name: "Top P",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        frequency_penalty: {
          name: "Frequency Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
        presence_penalty: {
          name: "Presence Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "gpt-4-turbo-preview": {
      params: {
        temperature: {
          name: "Temperature",
          range: [0, 2],
          default: 1,
          selectorType: "slider",
        },
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
        seed: {
          name: "Seed",
          range: [0, 2147483647],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
        response_format: {
          name: "Response Format",
          selectorType: "dropdown",
          options: {
            None: null,
            json: { type: "json_object" },
            text: { type: "text" },
          },
          isJSON: true,
        },
        top_p: {
          name: "Top P",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        frequency_penalty: {
          name: "Frequency Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
        presence_penalty: {
          name: "Presence Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "gpt-4-turbo-2024-04-09": {
      params: {
        temperature: {
          name: "Temperature",
          range: [0, 2],
          default: 1,
          selectorType: "slider",
        },
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
        seed: {
          name: "Seed",
          range: [0, 2147483647],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
        top_p: {
          name: "Top P",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        frequency_penalty: {
          name: "Frequency Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
        presence_penalty: {
          name: "Presence Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "gpt-4o": {
      params: {
        temperature: {
          name: "Temperature",
          range: [0, 2],
          default: 1,
          selectorType: "slider",
        },
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
        seed: {
          name: "Seed",
          range: [0, 2147483647],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
        response_format: {
          name: "Response Format",
          selectorType: "dropdown",
          options: {
            None: null,
            "JSON Schema": { type: "json_schema" },
            json: { type: "json_object" },
            text: { type: "text" },
          },
          isJSON: true,
        },
        top_p: {
          name: "Top P",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        frequency_penalty: {
          name: "Frequency Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
        presence_penalty: {
          name: "Presence Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "gpt-4o-mini": {
      params: {
        temperature: {
          name: "Temperature",
          range: [0, 2],
          default: 1,
          selectorType: "slider",
        },
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
        seed: {
          name: "Seed",
          range: [0, 2147483647],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
        response_format: {
          name: "Response Format",
          selectorType: "dropdown",
          options: {
            None: null,
            "JSON Schema": { type: "json_schema" },
            json: { type: "json_object" },
            text: { type: "text" },
          },
          isJSON: true,
        },
        top_p: {
          name: "Top P",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        frequency_penalty: {
          name: "Frequency Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
        presence_penalty: {
          name: "Presence Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "gpt-4o-mini-2024-07-18": {
      params: {
        temperature: {
          name: "Temperature",
          range: [0, 2],
          default: 1,
          selectorType: "slider",
        },
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
        seed: {
          name: "Seed",
          range: [0, 2147483647],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
        response_format: {
          name: "Response Format",
          selectorType: "dropdown",
          options: {
            None: null,
            "JSON Schema": { type: "json_schema" },
            json: { type: "json_object" },
            text: { type: "text" },
          },
          isJSON: true,
        },
        top_p: {
          name: "Top P",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        frequency_penalty: {
          name: "Frequency Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
        presence_penalty: {
          name: "Presence Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "gpt-4o-2024-05-13": {
      params: {
        temperature: {
          name: "Temperature",
          range: [0, 2],
          default: 1,
          selectorType: "slider",
        },
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
        seed: {
          name: "Seed",
          range: [0, 2147483647],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
        response_format: {
          name: "Response Format",
          selectorType: "dropdown",
          options: {
            None: null,
            json: { type: "json_object" },
            text: { type: "text" },
          },
          isJSON: true,
        },
        top_p: {
          name: "Top P",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        frequency_penalty: {
          name: "Frequency Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
        presence_penalty: {
          name: "Presence Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "gpt-4o-2024-08-06": {
      params: {
        temperature: {
          name: "Temperature",
          range: [0, 2],
          default: 1,
          selectorType: "slider",
        },
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
        seed: {
          name: "Seed",
          range: [0, 2147483647],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
        response_format: {
          name: "Response Format",
          selectorType: "dropdown",
          options: {
            None: null,
            "JSON Schema": { type: "json_schema" },
            json: { type: "json_object" },
            text: { type: "text" },
          },
          isJSON: true,
        },
        top_p: {
          name: "Top P",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        frequency_penalty: {
          name: "Frequency Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
        presence_penalty: {
          name: "Presence Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "gpt-4": {
      params: {
        temperature: {
          name: "Temperature",
          range: [0, 2],
          default: 1,
          selectorType: "slider",
        },
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
        seed: {
          name: "Seed",
          range: [0, 2147483647],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
        top_p: {
          name: "Top P",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        frequency_penalty: {
          name: "Frequency Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
        presence_penalty: {
          name: "Presence Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "gpt-4-32k": {
      params: {
        temperature: {
          name: "Temperature",
          range: [0, 2],
          default: 1,
          selectorType: "slider",
        },
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
        seed: {
          name: "Seed",
          range: [0, 2147483647],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
        response_format: {
          name: "Response Format",
          selectorType: "dropdown",
          options: {
            None: null,
            json: { type: "json_object" },
            text: { type: "text" },
          },
          isJSON: true,
        },
        top_p: {
          name: "Top P",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        frequency_penalty: {
          name: "Frequency Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
        presence_penalty: {
          name: "Presence Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
      },
      metadata: {
        _whitelist_ids: [26, 24, 4651],
        isChat: true,
      },
    },
    "gpt-3.5-turbo-instruct": {
      params: {
        temperature: {
          name: "Temperature",
          range: [0, 2],
          default: 1,
          selectorType: "slider",
        },
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
        top_p: {
          name: "Top P",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        frequency_penalty: {
          name: "Frequency Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
        presence_penalty: {
          name: "Presence Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
        best_of: {
          name: "Best Of",
          range: [1, 20],
          default: 1,
          isInt: true,
          selectorType: "slider",
        },
      },
      metadata: {
        isChat: false,
      },
    },
    "gpt-4-vision-preview": {
      params: {
        temperature: {
          name: "Temperature",
          range: [0, 2],
          default: 1,
          selectorType: "slider",
        },
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
        seed: {
          name: "Seed",
          range: [0, 2147483647],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
        top_p: {
          name: "Top P",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        frequency_penalty: {
          name: "Frequency Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
        presence_penalty: {
          name: "Presence Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
      },
      metadata: {
        isChat: true,
      },
    },
  },
  "openai.azure": {
    "gpt-3.5-turbo-1106": {
      params: {
        temperature: {
          name: "Temperature",
          range: [0, 2],
          default: 1,
          selectorType: "slider",
        },
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
        seed: {
          name: "Seed",
          range: [0, 2147483647],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
        response_format: {
          name: "Response Format",
          selectorType: "dropdown",
          options: {
            None: null,
            json: { type: "json_object" },
            text: { type: "text" },
          },
          isJSON: true,
        },
        top_p: {
          name: "Top P",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        frequency_penalty: {
          name: "Frequency Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
        presence_penalty: {
          name: "Presence Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "gpt-3.5-turbo": {
      params: {
        temperature: {
          name: "Temperature",
          range: [0, 2],
          default: 1,
          selectorType: "slider",
        },
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
        seed: {
          name: "Seed",
          range: [0, 2147483647],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
        top_p: {
          name: "Top P",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        frequency_penalty: {
          name: "Frequency Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
        presence_penalty: {
          name: "Presence Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "gpt-3.5-turbo-0125": {
      params: {
        temperature: {
          name: "Temperature",
          range: [0, 2],
          default: 1,
          selectorType: "slider",
        },
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
        seed: {
          name: "Seed",
          range: [0, 2147483647],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
        top_p: {
          name: "Top P",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        frequency_penalty: {
          name: "Frequency Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
        presence_penalty: {
          name: "Presence Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "gpt-3.5-turbo-16k": {
      params: {
        temperature: {
          name: "Temperature",
          range: [0, 2],
          default: 1,
          selectorType: "slider",
        },
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
        seed: {
          name: "Seed",
          range: [0, 2147483647],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
        response_format: {
          name: "Response Format",
          selectorType: "dropdown",
          options: {
            None: null,
            json: { type: "json_object" },
            text: { type: "text" },
          },
          isJSON: true,
        },
        top_p: {
          name: "Top P",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        frequency_penalty: {
          name: "Frequency Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
        presence_penalty: {
          name: "Presence Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "gpt-4-1106-preview": {
      params: {
        temperature: {
          name: "Temperature",
          range: [0, 2],
          default: 1,
          selectorType: "slider",
        },
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
        seed: {
          name: "Seed",
          range: [0, 2147483647],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
        response_format: {
          name: "Response Format",
          selectorType: "dropdown",
          options: {
            None: null,
            json: { type: "json_object" },
            text: { type: "text" },
          },
          isJSON: true,
        },
        top_p: {
          name: "Top P",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        frequency_penalty: {
          name: "Frequency Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
        presence_penalty: {
          name: "Presence Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "gpt-4-0125-preview": {
      params: {
        temperature: {
          name: "Temperature",
          range: [0, 2],
          default: 1,
          selectorType: "slider",
        },
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
        seed: {
          name: "Seed",
          range: [0, 2147483647],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
        response_format: {
          name: "Response Format",
          selectorType: "dropdown",
          options: {
            None: null,
            json: { type: "json_object" },
            text: { type: "text" },
          },
          isJSON: true,
        },
        top_p: {
          name: "Top P",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        frequency_penalty: {
          name: "Frequency Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
        presence_penalty: {
          name: "Presence Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "gpt-4-turbo": {
      params: {
        temperature: {
          name: "Temperature",
          range: [0, 2],
          default: 1,
          selectorType: "slider",
        },
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
        seed: {
          name: "Seed",
          range: [0, 2147483647],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
        response_format: {
          name: "Response Format",
          selectorType: "dropdown",
          options: {
            None: null,
            json: { type: "json_object" },
            text: { type: "text" },
          },
          isJSON: true,
        },
        top_p: {
          name: "Top P",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        frequency_penalty: {
          name: "Frequency Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
        presence_penalty: {
          name: "Presence Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "gpt-4-turbo-preview": {
      params: {
        temperature: {
          name: "Temperature",
          range: [0, 2],
          default: 1,
          selectorType: "slider",
        },
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
        seed: {
          name: "Seed",
          range: [0, 2147483647],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
        response_format: {
          name: "Response Format",
          selectorType: "dropdown",
          options: {
            None: null,
            json: { type: "json_object" },
            text: { type: "text" },
          },
          isJSON: true,
        },
        top_p: {
          name: "Top P",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        frequency_penalty: {
          name: "Frequency Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
        presence_penalty: {
          name: "Presence Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "gpt-4-turbo-2024-04-09": {
      params: {
        temperature: {
          name: "Temperature",
          range: [0, 2],
          default: 1,
          selectorType: "slider",
        },
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
        seed: {
          name: "Seed",
          range: [0, 2147483647],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
        top_p: {
          name: "Top P",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        frequency_penalty: {
          name: "Frequency Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
        presence_penalty: {
          name: "Presence Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "gpt-4o": {
      params: {
        temperature: {
          name: "Temperature",
          range: [0, 2],
          default: 1,
          selectorType: "slider",
        },
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
        seed: {
          name: "Seed",
          range: [0, 2147483647],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
        response_format: {
          name: "Response Format",
          selectorType: "dropdown",
          options: {
            None: null,
            "JSON Schema": { type: "json_schema" },
            json: { type: "json_object" },
            text: { type: "text" },
          },
          isJSON: true,
        },
        top_p: {
          name: "Top P",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        frequency_penalty: {
          name: "Frequency Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
        presence_penalty: {
          name: "Presence Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "gpt-4o-mini": {
      params: {
        temperature: {
          name: "Temperature",
          range: [0, 2],
          default: 1,
          selectorType: "slider",
        },
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
        seed: {
          name: "Seed",
          range: [0, 2147483647],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
        response_format: {
          name: "Response Format",
          selectorType: "dropdown",
          options: {
            None: null,
            "JSON Schema": { type: "json_schema" },
            json: { type: "json_object" },
            text: { type: "text" },
          },
          isJSON: true,
        },
        top_p: {
          name: "Top P",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        frequency_penalty: {
          name: "Frequency Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
        presence_penalty: {
          name: "Presence Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "gpt-4o-mini-2024-07-18": {
      params: {
        temperature: {
          name: "Temperature",
          range: [0, 2],
          default: 1,
          selectorType: "slider",
        },
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
        seed: {
          name: "Seed",
          range: [0, 2147483647],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
        response_format: {
          name: "Response Format",
          selectorType: "dropdown",
          options: {
            None: null,
            "JSON Schema": { type: "json_schema" },
            json: { type: "json_object" },
            text: { type: "text" },
          },
          isJSON: true,
        },
        top_p: {
          name: "Top P",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        frequency_penalty: {
          name: "Frequency Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
        presence_penalty: {
          name: "Presence Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "gpt-4o-2024-05-13": {
      params: {
        temperature: {
          name: "Temperature",
          range: [0, 2],
          default: 1,
          selectorType: "slider",
        },
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
        seed: {
          name: "Seed",
          range: [0, 2147483647],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
        response_format: {
          name: "Response Format",
          selectorType: "dropdown",
          options: {
            None: null,
            json: { type: "json_object" },
            text: { type: "text" },
          },
          isJSON: true,
        },
        top_p: {
          name: "Top P",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        frequency_penalty: {
          name: "Frequency Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
        presence_penalty: {
          name: "Presence Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "gpt-4o-2024-08-06": {
      params: {
        temperature: {
          name: "Temperature",
          range: [0, 2],
          default: 1,
          selectorType: "slider",
        },
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
        seed: {
          name: "Seed",
          range: [0, 2147483647],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
        response_format: {
          name: "Response Format",
          selectorType: "dropdown",
          options: {
            None: null,
            "JSON Schema": { type: "json_schema" },
            json: { type: "json_object" },
            text: { type: "text" },
          },
          isJSON: true,
        },
        top_p: {
          name: "Top P",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        frequency_penalty: {
          name: "Frequency Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
        presence_penalty: {
          name: "Presence Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "gpt-4": {
      params: {
        temperature: {
          name: "Temperature",
          range: [0, 2],
          default: 1,
          selectorType: "slider",
        },
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
        seed: {
          name: "Seed",
          range: [0, 2147483647],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
        top_p: {
          name: "Top P",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        frequency_penalty: {
          name: "Frequency Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
        presence_penalty: {
          name: "Presence Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "gpt-4-32k": {
      params: {
        temperature: {
          name: "Temperature",
          range: [0, 2],
          default: 1,
          selectorType: "slider",
        },
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
        seed: {
          name: "Seed",
          range: [0, 2147483647],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
        response_format: {
          name: "Response Format",
          selectorType: "dropdown",
          options: {
            None: null,
            json: { type: "json_object" },
            text: { type: "text" },
          },
          isJSON: true,
        },
        top_p: {
          name: "Top P",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        frequency_penalty: {
          name: "Frequency Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
        presence_penalty: {
          name: "Presence Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
      },
      metadata: {
        _whitelist_ids: [26, 24, 4651],
        isChat: true,
      },
    },
    "gpt-3.5-turbo-instruct": {
      params: {
        temperature: {
          name: "Temperature",
          range: [0, 2],
          default: 1,
          selectorType: "slider",
        },
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
        top_p: {
          name: "Top P",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        frequency_penalty: {
          name: "Frequency Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
        presence_penalty: {
          name: "Presence Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
        best_of: {
          name: "Best Of",
          range: [1, 20],
          default: 1,
          isInt: true,
          selectorType: "slider",
        },
      },
      metadata: {
        isChat: false,
      },
    },
    "gpt-4-vision-preview": {
      params: {
        temperature: {
          name: "Temperature",
          range: [0, 2],
          default: 1,
          selectorType: "slider",
        },
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
        seed: {
          name: "Seed",
          range: [0, 2147483647],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
        top_p: {
          name: "Top P",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        frequency_penalty: {
          name: "Frequency Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
        presence_penalty: {
          name: "Presence Penalty",
          range: [0, 2],
          default: 0,
          selectorType: "slider",
        },
      },
      metadata: {
        isChat: true,
      },
    },
  },
  anthropic: {
    "claude-v1": {
      params: {
        max_tokens_to_sample: {
          name: "Max Tokens to Sample",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
          required: true,
        },
        temperature: {
          name: "Temperature",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        top_k: {
          name: "Top K",
          range: [0, 2048],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
        top_p: {
          name: "Top P",
          range: [0, 1],
          default: 0,
          selectorType: "slider",
        },
      },
      metadata: {
        isChat: false,
      },
    },
    "claude-2": {
      params: {
        max_tokens_to_sample: {
          name: "Max Tokens to Sample",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
          required: true,
        },
        temperature: {
          name: "Temperature",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        top_k: {
          name: "Top K",
          range: [0, 2048],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
        top_p: {
          name: "Top P",
          range: [0, 1],
          default: 0,
          selectorType: "slider",
        },
      },
      metadata: {
        isChat: false,
      },
    },
    "claude-v1-100k": {
      params: {
        max_tokens_to_sample: {
          name: "Max Tokens to Sample",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
          required: true,
        },
        temperature: {
          name: "Temperature",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        top_k: {
          name: "Top K",
          range: [0, 2048],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
        top_p: {
          name: "Top P",
          range: [0, 1],
          default: 0,
          selectorType: "slider",
        },
      },
      metadata: {
        isChat: false,
      },
    },
    "claude-instant-v1": {
      params: {
        max_tokens_to_sample: {
          name: "Max Tokens to Sample",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
          required: true,
        },
        temperature: {
          name: "Temperature",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        topK: {
          name: "Top K",
          range: [0, 2048],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
        topP: {
          name: "Top P",
          range: [0, 1],
          default: 0,
          selectorType: "slider",
        },
      },
      metadata: {
        isChat: false,
      },
    },
    "claude-instant-v1-100k": {
      params: {
        max_tokens_to_sample: {
          name: "Max Tokens to Sample",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
          required: true,
        },
        temperature: {
          name: "Temperature",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        top_k: {
          name: "Top K",
          range: [0, 2048],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
        top_p: {
          name: "Top P",
          range: [0, 1],
          default: 0,
          selectorType: "slider",
        },
      },
      metadata: {
        isChat: false,
      },
    },
    "claude-instant-1.2": {
      params: {
        max_tokens: {
          name: "Max Tokens",
          range: [1, 4096],
          default: 256,
          isInt: true,
          selectorType: "input",
          required: true,
        },
        temperature: {
          name: "Temperature",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        top_k: {
          name: "Top K",
          range: [0, 2048],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
        top_p: {
          name: "Top P",
          range: [0, 1],
          default: 0,
          selectorType: "slider",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "claude-2.1": {
      params: {
        max_tokens: {
          name: "Max Tokens",
          range: [1, 4096],
          default: 256,
          isInt: true,
          selectorType: "input",
          required: true,
        },
        temperature: {
          name: "Temperature",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        top_k: {
          name: "Top K",
          range: [0, 2048],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
        top_p: {
          name: "Top P",
          range: [0, 1],
          default: 0,
          selectorType: "slider",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "claude-3-sonnet-20240229": {
      params: {
        max_tokens: {
          name: "Max Tokens",
          range: [1, 4096],
          default: 256,
          isInt: true,
          selectorType: "input",
          required: true,
        },
        temperature: {
          name: "Temperature",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        top_k: {
          name: "Top K",
          range: [0, 2048],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
        top_p: {
          name: "Top P",
          range: [0, 1],
          default: 0,
          selectorType: "slider",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "claude-3-opus-20240229": {
      params: {
        max_tokens: {
          name: "Max Tokens",
          range: [1, 4096],
          default: 256,
          isInt: true,
          selectorType: "input",
          required: true,
        },
        temperature: {
          name: "Temperature",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        top_k: {
          name: "Top K",
          range: [0, 2048],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
        top_p: {
          name: "Top P",
          range: [0, 1],
          default: 0,
          selectorType: "slider",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "claude-3-haiku-20240307": {
      params: {
        max_tokens: {
          name: "Max Tokens",
          range: [1, 4096],
          default: 256,
          isInt: true,
          selectorType: "input",
          required: true,
        },
        temperature: {
          name: "Temperature",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        top_k: {
          name: "Top K",
          range: [0, 2048],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
        top_p: {
          name: "Top P",
          range: [0, 1],
          default: 0,
          selectorType: "slider",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "claude-3-5-sonnet-20240620": {
      params: {
        max_tokens: {
          name: "Max Tokens",
          range: [1, 4096],
          default: 1024,
          isInt: true,
          selectorType: "input",
          required: true,
        },
        temperature: {
          name: "Temperature",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        top_k: {
          name: "Top K",
          range: [0, 2048],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
        top_p: {
          name: "Top P",
          range: [0, 1],
          default: 0,
          selectorType: "slider",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "claude-3-5-sonnet-20241022": {
      params: {
        max_tokens: {
          name: "Max Tokens",
          range: [1, 8192],
          default: 256,
          isInt: true,
          selectorType: "input",
          required: true,
        },
        temperature: {
          name: "Temperature",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        top_k: {
          name: "Top K",
          range: [0, 2048],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
        top_p: {
          name: "Top P",
          range: [0, 1],
          default: 0,
          selectorType: "slider",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "claude-3-5-sonnet-latest": {
      params: {
        max_tokens: {
          name: "Max Tokens",
          range: [1, 8192],
          default: 256,
          isInt: true,
          selectorType: "input",
          required: true,
        },
        temperature: {
          name: "Temperature",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        top_k: {
          name: "Top K",
          range: [0, 2048],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
        top_p: {
          name: "Top P",
          range: [0, 1],
          default: 0,
          selectorType: "slider",
        },
      },
      metadata: {
        isChat: true,
      },
    },
  },
  "anthropic.bedrock": {
    "anthropic.claude-v2": {
      params: {
        max_tokens_to_sample: {
          name: "Max Tokens to Sample",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
          required: true,
        },
        temperature: {
          name: "Temperature",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        top_k: {
          name: "Top K",
          range: [0, 2048],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
        top_p: {
          name: "Top P",
          range: [0, 1],
          default: 0,
          selectorType: "slider",
        },
      },
      metadata: {
        isChat: false,
      },
    },
    "anthropic.claude-v2:1": {
      params: {
        max_tokens_to_sample: {
          name: "Max Tokens to Sample",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
          required: true,
        },
        temperature: {
          name: "Temperature",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        top_k: {
          name: "Top K",
          range: [0, 2048],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
        top_p: {
          name: "Top P",
          range: [0, 1],
          default: 0,
          selectorType: "slider",
        },
      },
      metadata: {
        isChat: false,
      },
    },
    "anthropic.claude-3-sonnet-20240229-v1:0": {
      params: {
        max_tokens: {
          name: "Max Tokens",
          range: [1, 4096],
          default: 256,
          isInt: true,
          selectorType: "input",
          required: true,
        },
        temperature: {
          name: "Temperature",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        top_k: {
          name: "Top K",
          range: [0, 2048],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
        top_p: {
          name: "Top P",
          range: [0, 1],
          default: 0,
          selectorType: "slider",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "anthropic.claude-3-5-sonnet-20240620-v1:0": {
      params: {
        max_tokens: {
          name: "Max Tokens",
          range: [1, 4096],
          default: 256,
          isInt: true,
          selectorType: "input",
          required: true,
        },
        temperature: {
          name: "Temperature",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        top_k: {
          name: "Top K",
          range: [0, 2048],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
        top_p: {
          name: "Top P",
          range: [0, 1],
          default: 0,
          selectorType: "slider",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "anthropic.claude-instant-v1": {
      params: {
        max_tokens: {
          name: "Max Tokens",
          range: [1, 4096],
          default: 256,
          isInt: true,
          selectorType: "input",
          required: true,
        },
        temperature: {
          name: "Temperature",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        top_k: {
          name: "Top K",
          range: [0, 2048],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
        top_p: {
          name: "Top P",
          range: [0, 1],
          default: 0,
          selectorType: "slider",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "anthropic.claude-3-5-sonnet-20241022-v2:0": {
      params: {
        max_tokens: {
          name: "Max Tokens",
          range: [1, 8192],
          default: 256,
          isInt: true,
          selectorType: "input",
          required: true,
        },
        temperature: {
          name: "Temperature",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        top_k: {
          name: "Top K",
          range: [0, 2048],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
        top_p: {
          name: "Top P",
          range: [0, 1],
          default: 0,
          selectorType: "slider",
        },
      },
      metadata: {
        isChat: true,
      },
    },
  },
  mistral: {
    "open-mistral-nemo": {
      params: {
        temperature: {
          name: "Temperature",
          range: [0, 1],
          default: 0.3,
          selectorType: "slider",
        },
        top_p: {
          name: "Top P",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        max_tokens: {
          name: "Max Tokens",
          range: [1, 4095],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
        safe_prompt: {
          name: "Safe Prompt",
          selectorType: "dropdown",
          options: {
            None: null,
            True: true,
            False: false,
          },
        },
        random_seed: {
          name: "Random Seed",
          range: [0, 2147483647],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "pixtral-12b-2409": {
      params: {
        temperature: {
          name: "Temperature",
          range: [0, 1],
          default: 0.3,
          selectorType: "slider",
        },
        top_p: {
          name: "Top P",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        max_tokens: {
          name: "Max Tokens",
          range: [1, 128000],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
        safe_prompt: {
          name: "Safe Prompt",
          selectorType: "dropdown",
          options: {
            None: null,
            True: true,
            False: false,
          },
        },
        random_seed: {
          name: "Random Seed",
          range: [0, 2147483647],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "open-mistral-7b": {
      params: {
        temperature: {
          name: "Temperature",
          range: [0, 1],
          default: 0.3,
          selectorType: "slider",
        },
        top_p: {
          name: "Top P",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        max_tokens: {
          name: "Max Tokens",
          range: [1, 4095],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
        safe_prompt: {
          name: "Safe Prompt",
          selectorType: "dropdown",
          options: {
            None: null,
            True: true,
            False: false,
          },
        },
        random_seed: {
          name: "Random Seed",
          range: [0, 2147483647],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "open-mixtral-8x7b": {
      params: {
        temperature: {
          name: "Temperature",
          range: [0, 1],
          default: 0.3,
          selectorType: "slider",
        },
        top_p: {
          name: "Top P",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        max_tokens: {
          name: "Max Tokens",
          range: [1, 4095],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
        safe_prompt: {
          name: "Safe Prompt",
          selectorType: "dropdown",
          options: {
            None: null,
            True: true,
            False: false,
          },
        },
        random_seed: {
          name: "Random Seed",
          range: [0, 2147483647],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "open-mixtral-8x22b": {
      params: {
        temperature: {
          name: "Temperature",
          range: [0, 1],
          default: 0.3,
          selectorType: "slider",
        },
        top_p: {
          name: "Top P",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        max_tokens: {
          name: "Max Tokens",
          range: [1, 4095],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
        safe_prompt: {
          name: "Safe Prompt",
          selectorType: "dropdown",
          options: {
            None: null,
            True: true,
            False: false,
          },
        },
        random_seed: {
          name: "Random Seed",
          range: [0, 2147483647],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "ministral-3b-latest": {
      params: {
        temperature: {
          name: "Temperature",
          range: [0, 1],
          default: 0.3,
          selectorType: "slider",
        },
        top_p: {
          name: "Top P",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        max_tokens: {
          name: "Max Tokens",
          range: [1, 128000],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
        safe_prompt: {
          name: "Safe Prompt",
          selectorType: "dropdown",
          options: {
            None: null,
            True: true,
            False: false,
          },
        },
        random_seed: {
          name: "Random Seed",
          range: [0, 2147483647],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "ministral-8b-latest": {
      params: {
        temperature: {
          name: "Temperature",
          range: [0, 1],
          default: 0.3,
          selectorType: "slider",
        },
        top_p: {
          name: "Top P",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        max_tokens: {
          name: "Max Tokens",
          range: [1, 128000],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
        safe_prompt: {
          name: "Safe Prompt",
          selectorType: "dropdown",
          options: {
            None: null,
            True: true,
            False: false,
          },
        },
        random_seed: {
          name: "Random Seed",
          range: [0, 2147483647],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "mistral-moderation-latest": {
      params: {
        temperature: {
          name: "Temperature",
          range: [0, 1],
          default: 0.3,
          selectorType: "slider",
        },
        top_p: {
          name: "Top P",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        max_tokens: {
          name: "Max Tokens",
          range: [1, 8000],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
        safe_prompt: {
          name: "Safe Prompt",
          selectorType: "dropdown",
          options: {
            None: null,
            True: true,
            False: false,
          },
        },
        random_seed: {
          name: "Random Seed",
          range: [0, 2147483647],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "mistral-small-latest": {
      params: {
        temperature: {
          name: "Temperature",
          range: [0, 1],
          default: 0.3,
          selectorType: "slider",
        },
        top_p: {
          name: "Top P",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        max_tokens: {
          name: "Max Tokens",
          range: [1, 4095],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
        safe_prompt: {
          name: "Safe Prompt",
          selectorType: "dropdown",
          options: {
            None: null,
            True: true,
            False: false,
          },
        },
        random_seed: {
          name: "Random Seed",
          range: [0, 2147483647],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "mistral-medium-latest": {
      params: {
        temperature: {
          name: "Temperature",
          range: [0, 1],
          default: 0.3,
          selectorType: "slider",
        },
        top_p: {
          name: "Top P",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        max_tokens: {
          name: "Max Tokens",
          range: [1, 4095],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
        safe_prompt: {
          name: "Safe Prompt",
          selectorType: "dropdown",
          options: {
            None: null,
            True: true,
            False: false,
          },
        },
        random_seed: {
          name: "Random Seed",
          range: [0, 2147483647],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "mistral-large-latest": {
      params: {
        temperature: {
          name: "Temperature",
          range: [0, 1],
          default: 0.3,
          selectorType: "slider",
        },
        top_p: {
          name: "Top P",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        max_tokens: {
          name: "Max Tokens",
          range: [1, 4095],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
        safe_prompt: {
          name: "Safe Prompt",
          selectorType: "dropdown",
          options: {
            "": null,
            True: true,
            False: false,
          },
        },
        random_seed: {
          name: "Random Seed",
          range: [0, 2147483647],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
      },
      metadata: {
        isChat: true,
      },
    },
  },
  cohere: {
    command: {
      params: {
        max_tokens: {
          name: "Max Tokens",
          range: [1, 4095],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
        temperature: {
          name: "Temperature",
          range: [0, 5],
          default: 5,
          selectorType: "slider",
        },
        k: {
          name: "K",
          range: [0, 500],
          default: 0,
          selectorType: "slider",
        },
        p: {
          name: "P",
          range: [0, 0.99],
          default: 0,
          selectorType: "slider",
        },
        presence_penalty: {
          name: "Presence Penalty",
          range: [0, 1],
          default: 0,
          selectorType: "slider",
        },
        frequency_penalty: {
          name: "Frequency Penalty",
          range: [0, 1],
          default: 0,
          selectorType: "slider",
        },
      },
      metadata: {
        isChat: false,
      },
    },
    "command-light": {
      params: {
        max_tokens: {
          name: "Max Tokens",
          range: [1, 4095],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
        temperature: {
          name: "Temperature",
          range: [0, 5],
          default: 5,
          selectorType: "slider",
        },
        k: {
          name: "K",
          range: [0, 500],
          default: 0,
          selectorType: "slider",
        },
        p: {
          name: "P",
          range: [0, 0.99],
          default: 0,
          selectorType: "slider",
        },
        presence_penalty: {
          name: "Presence Penalty",
          range: [0, 1],
          default: 0,
          selectorType: "slider",
        },
        frequency_penalty: {
          name: "Frequency Penalty",
          range: [0, 1],
          default: 0,
          selectorType: "slider",
        },
      },
      metadata: {
        isChat: false,
      },
    },
    "command-nightly": {
      params: {
        max_tokens: {
          name: "Max Tokens",
          range: [1, 4095],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
        temperature: {
          name: "Temperature",
          range: [0, 5],
          default: 5,
          selectorType: "slider",
        },
        k: {
          name: "K",
          range: [0, 500],
          default: 0,
          selectorType: "slider",
        },
        p: {
          name: "P",
          range: [0, 0.99],
          default: 0,
          selectorType: "slider",
        },
        presence_penalty: {
          name: "Presence Penalty",
          range: [0, 1],
          default: 0,
          selectorType: "slider",
        },
        frequency_penalty: {
          name: "Frequency Penalty",
          range: [0, 1],
          default: 0,
          selectorType: "slider",
        },
      },
      metadata: {
        isChat: false,
      },
    },
    "command-light-nightly": {
      params: {
        max_tokens: {
          name: "Max Tokens",
          range: [1, 4095],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
        temperature: {
          name: "Temperature",
          range: [0, 5],
          default: 5,
          selectorType: "slider",
        },
        k: {
          name: "K",
          range: [0, 500],
          default: 0,
          selectorType: "slider",
        },
        p: {
          name: "P",
          range: [0, 0.99],
          default: 0,
          selectorType: "slider",
        },
        presence_penalty: {
          name: "Presence Penalty",
          range: [0, 1],
          default: 0,
          selectorType: "slider",
        },
        frequency_penalty: {
          name: "Frequency Penalty",
          range: [0, 1],
          default: 0,
          selectorType: "slider",
        },
      },
      metadata: {
        isChat: false,
      },
    },
  },
  google: {
    "gemini-1.0-pro": {
      params: {
        candidateCount: {
          name: "Candidate Count",
          range: [1, 10],
          default: 1,
          isInt: true,
          selectorType: "input",
        },
        stopSequences: {
          name: "Stop Sequences",
          selectorType: "input",
          isInt: false,
        },
        maxOutputTokens: {
          name: "Max Output Tokens",
          range: [1, 8192],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
        temperature: {
          name: "Temperature",
          range: [0, 1],
          default: 0,
          selectorType: "slider",
        },
        topP: {
          name: "Top P",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        topK: {
          name: "Top K",
          range: [0, 8192],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "gemini-1.5-pro-latest": {
      params: {
        response_format: {
          name: "Response Format",
          selectorType: "dropdown",
          options: {
            None: null,
            "JSON Schema": { type: "json_schema" },
            text: { type: "text" },
          },
          isJSON: true,
        },
        candidateCount: {
          name: "Candidate Count",
          range: [1, 10],
          default: 1,
          isInt: true,
          selectorType: "input",
        },
        stopSequences: {
          name: "Stop Sequences",
          selectorType: "input",
          isInt: false,
        },
        maxOutputTokens: {
          name: "Max Output Tokens",
          range: [1, 8192],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
        temperature: {
          name: "Temperature",
          range: [0, 1],
          default: 0,
          selectorType: "slider",
        },
        topP: {
          name: "Top P",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        topK: {
          name: "Top K",
          range: [0, 8192],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "gemini-1.5-flash": {
      params: {
        response_format: {
          name: "Response Format",
          selectorType: "dropdown",
          options: {
            None: null,
            "JSON Schema": { type: "json_schema" },
            text: { type: "text" },
          },
          isJSON: true,
        },
        candidateCount: {
          name: "Candidate Count",
          range: [1, 10],
          default: 1,
          isInt: true,
          selectorType: "input",
        },
        stopSequences: {
          name: "Stop Sequences",
          selectorType: "input",
          isInt: false,
        },
        maxOutputTokens: {
          name: "Max Output Tokens",
          range: [1, 8192],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
        temperature: {
          name: "Temperature",
          range: [0, 1],
          default: 0,
          selectorType: "slider",
        },
        topP: {
          name: "Top P",
          range: [0, 1],
          default: 1,
          selectorType: "slider",
        },
        topK: {
          name: "Top K",
          range: [0, 8192],
          default: 0,
          isInt: true,
          selectorType: "input",
        },
      },
      metadata: {
        isChat: true,
      },
    },
  },
  cloudflare: {
    "@cf/meta/llama-2-7b-chat-fp16": {
      params: {
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "@cf/meta/llama-2-7b-chat-int8": {
      params: {
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "@cf/meta/llama-3-8b-instruct": {
      params: {
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "@cf/meta/llama-3.1-8b-instruct": {
      params: {
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "@cf/mistral/mistral-7b-instruct-v0.1": {
      params: {
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "@hf/thebloke/deepseek-coder-6.7b-base-awq": {
      params: {
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "@hf/thebloke/deepseek-coder-6.7b-instruct-awq": {
      params: {
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "@cf/deepseek-ai/deepseek-math-7b-base": {
      params: {
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "@cf/deepseek-ai/deepseek-math-7b-instruct": {
      params: {
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "@cf/thebloke/discolm-german-7b-v1-awq": {
      params: {
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "@cf/tiiuae/falcon-7b-instruct": {
      params: {
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "@cf/google/gemma-2b-it-lora": {
      params: {
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "@hf/google/gemma-7b-it": {
      params: {
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "@cf/google/gemma-7b-it-lora": {
      params: {
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "@hf/nousresearch/hermes-2-pro-mistral-7b": {
      params: {
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "@hf/thebloke/llama-2-13b-chat-awq": {
      params: {
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "@cf/meta-llama/llama-2-7b-chat-hf-lora": {
      params: {
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "@hf/thebloke/llamaguard-7b-awq": {
      params: {
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "@hf/thebloke/mistral-7b-instruct-v0.1-awq": {
      params: {
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "@hf/mistralai/mistral-7b-instruct-v0.2": {
      params: {
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "@cf/mistral/mistral-7b-instruct-v0.2-lora": {
      params: {
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "@hf/thebloke/neural-chat-7b-v3-1-awq": {
      params: {
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "@cf/openchat/openchat-3.5-0106": {
      params: {
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "@hf/thebloke/openhermes-2.5-mistral-7b-awq": {
      params: {
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "@cf/microsoft/phi-2": {
      params: {
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "@cf/qwen/qwen1.5-0.5b-chat": {
      params: {
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "@cf/qwen/qwen1.5-1.8b-chat": {
      params: {
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "@cf/qwen/qwen1.5-14b-chat-awq": {
      params: {
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "@cf/qwen/qwen1.5-7b-chat-awq": {
      params: {
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "@cf/defog/sqlcoder-7b-2": {
      params: {
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "@hf/nexusflow/starling-lm-7b-beta": {
      params: {
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "@cf/tinyllama/tinyllama-1.1b-chat-v1.0": {
      params: {
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
      },
      metadata: {
        isChat: true,
      },
    },
    "@hf/thebloke/zephyr-7b-beta-awq": {
      params: {
        max_tokens: {
          name: "Maximum Tokens",
          range: [1, 2048],
          default: 256,
          isInt: true,
          selectorType: "input",
        },
      },
      metadata: {
        isChat: true,
      },
    },
  },
};
