import { keepRelevantSearchParams } from "@/utils/utils";
import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

export const useKeepRelevantParams = () => {
  const [searchParams] = useSearchParams();
  const keepRelevantParams = useCallback(() => {
    return keepRelevantSearchParams(searchParams);
  }, [searchParams]);
  return keepRelevantParams;
};
