import { organizeTemplates } from "@/components/Registry/utils";
import { useIntersectionObserver } from "@/hooks";
import { usePromptRegistryObjects } from "@/queries";
import { Folder } from "@/types/folders";
import { PromptRegistry } from "@/types/prompt-registry";
import { useRef } from "react";

export function useTemplatesData({
  templates,
  folderId,
  folders,
  promptRegistryObjects,
  searchQuery,
}: {
  templates: PromptRegistry[];
  folderId: string | undefined;
  folders: Folder[];
  promptRegistryObjects: ReturnType<typeof usePromptRegistryObjects>;
  searchQuery: string;
}) {
  const ref = useRef<HTMLDivElement>(null);
  const entry = useIntersectionObserver(ref, {});

  const displayHeading = !!templates.length || (!folderId && !!folders.length);

  const templateFolderMap = organizeTemplates(templates);
  const foldersArray = Array.from(templateFolderMap.values());
  const foldersWithTemplates = foldersArray.filter(
    (folder) =>
      folder.id !== null &&
      folder.templates.length > 0 &&
      folder.id !== parseInt(folderId ?? ""),
  );

  // Fetch next page when the sentinel is intersecting
  if (entry?.isIntersecting) {
    promptRegistryObjects.fetchNextPage();
  }

  // Filter templates to exclude those already displayed in folders
  const filteredTemplates =
    searchQuery === ""
      ? folderId
        ? templates.filter(
            (template) => template.folder_id === parseInt(folderId ?? ""),
          )
        : templates
      : templates?.filter((template) =>
          foldersWithTemplates.every(
            (folder) => template.folder_id !== folder?.id,
          ),
        );

  return { ref, displayHeading, filteredTemplates };
}
