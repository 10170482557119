import { makeAutoObservable } from "mobx";
import { useLocalObservable } from "mobx-react-lite";
import { createContext, useContext } from "react";
import { Example } from "./types";

export class ExamplesStore {
  selectedDataset: string = "";
  selectedVersion: number = -1;
  visibleColumns: string[] = [];
  removedColumns: string[] = [];
  selectedExamples: Example[] = [];
  dataType: string = "";

  constructor(match?: string) {
    if (match) {
      match = match?.replaceAll("«««", "")?.replaceAll("»»»", "");
      this.selectedDataset = match.split(":")[1] || "";
      this.selectedVersion = Number(match.split(":")[2]) || -1;

      this.selectedExamples =
        match
          ?.split(":")[3]
          ?.split(",")
          .filter((id) => id !== "")
          .map((id: string) => ({
            id: parseInt(id),
            value: {},
            text: "Example " + id,
            category: "Category A",
            difficulty: "Easy",
          })) || [];
    }
    this.visibleColumns =
      match
        ?.split(":")[4]
        ?.split(",")
        .filter((column) => column !== "") || [];
    this.dataType = match?.split(":")[5] || "json";
    makeAutoObservable(this);
  }

  setDataType(dataType: string) {
    this.dataType = dataType;
  }

  setRemovedColumns(removedColumns: string[]) {
    this.removedColumns = removedColumns;
  }

  setVisibleColumns(columns: string[]) {
    this.visibleColumns = columns;
  }

  toggleColumn(columnName: string) {
    const isAlreadyVisible = this.visibleColumns.includes(columnName);
    if (isAlreadyVisible) {
      this.visibleColumns = this.visibleColumns.filter((c) => c !== columnName);
      this.removedColumns = [...this.removedColumns, columnName];
    } else {
      this.removedColumns = this.removedColumns.filter((c) => c !== columnName);
      this.visibleColumns = [...this.visibleColumns, columnName];
    }
  }

  setSelectedDataset(dataset: string) {
    this.selectedDataset = dataset;
    this.selectedVersion = -1;
    this.selectedExamples = [];
    this.visibleColumns = [];
  }

  setSelectedVersion(version: number | string) {
    this.selectedVersion = Number(version);
    this.selectedExamples = [];
    this.visibleColumns = [];
  }

  addExample(example: Example) {
    const isAlreadySelected = this.selectedExamples.some(
      (e) => e.id === example.id,
    );
    if (isAlreadySelected) {
      this.selectedExamples = this.selectedExamples.filter(
        (e) => e.id !== example.id,
      );
    } else {
      this.selectedExamples = [...this.selectedExamples, example];
    }
  }

  removeExample(example: Example) {
    this.selectedExamples = this.selectedExamples.filter(
      (e) => e.id !== example.id,
    );
  }

  get widgetValue() {
    const dataset = this.selectedDataset || "";
    const version = this.selectedVersion > 0 ? this.selectedVersion : "";
    const examples = this.selectedExamples?.map(({ id }) => id).join(",") || "";
    const columns = this.visibleColumns.join(",") || "";
    const dataType = this.dataType.toLowerCase();

    return `«««dataset:${dataset}:${version}:${examples}:${columns}:${dataType}»»»`;
  }
}

const ExamplesWidgetContext = createContext<ExamplesStore | null>(null);
const ExamplesWidgetProvider = ({
  children,
  match,
}: {
  children: React.ReactNode;
  match?: string;
}) => {
  const store = useLocalObservable(() => new ExamplesStore(match), []);
  return (
    <ExamplesWidgetContext.Provider value={store}>
      {children}
    </ExamplesWidgetContext.Provider>
  );
};

export const useExamples = () => {
  const context = useContext(ExamplesWidgetContext);
  if (!context) {
    throw new Error("useExamplesStore must be used within a ExamplesWidget");
  }
  return context;
};

export default ExamplesWidgetProvider;
