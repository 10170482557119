import { ENDPOINTS } from "@/api/application-api";
import { useRefetch } from "@/components/SidebarSearchLayout/hooks/useRefetch";
import { Button, ButtonProps } from "@/components/ui/button";
import { MAX_SIDEBAR_NEW_REQUESTS_THRESHOLD } from "@/constants";
import {
  useNewRequests,
  useNewSpans,
  useRequestLogs,
  useSpansQuery,
} from "@/queries";
import { RefreshIcon } from "@heroicons/react/outline";
import { useQueryClient } from "@tanstack/react-query";

const RefreshRequests = () => {
  const queryClient = useQueryClient();
  const requestLogs = useRequestLogs();
  const newRequests = useNewRequests();
  const countRequests = newRequests.data?.count_requests ?? 0;
  useRefetch(countRequests, requestLogs.refetch);
  const handleClick = () => {
    requestLogs.refetch();
    queryClient.setQueriesData([ENDPOINTS.new_requests], {
      count_requests: 0,
    });
  };
  return (
    <RefreshButton
      isLoading={requestLogs.isFetching}
      count={countRequests}
      onClick={handleClick}
    />
  );
};

const RefreshSpans = () => {
  const queryClient = useQueryClient();
  const spansQuery = useSpansQuery();
  const newSpans = useNewSpans();
  const countSpans = newSpans.data?.count_spans ?? 0;
  useRefetch(countSpans, spansQuery.refetch);
  const handleClick = () => {
    spansQuery.refetch();
    queryClient.setQueriesData([ENDPOINTS.new_spans], {
      count_spans: 0,
    });
  };
  return (
    <RefreshButton
      isLoading={spansQuery.isFetching}
      count={countSpans}
      onClick={handleClick}
    />
  );
};

const Refresh = ({
  isSidebarCollapsed,
  requestsOn,
}: {
  isSidebarCollapsed: boolean;
  requestsOn: boolean;
}) => {
  if (isSidebarCollapsed) return null;
  if (requestsOn) return <RefreshRequests />;
  return <RefreshSpans />;
};

interface BaseRefreshButtonProps extends ButtonProps {
  isLoading: boolean;
  count: number;
}

const RefreshButton = ({
  isLoading,
  count,
  ...props
}: BaseRefreshButtonProps) => {
  const hasNewData = count >= MAX_SIDEBAR_NEW_REQUESTS_THRESHOLD;
  return (
    <div className="relative">
      <Button variant="ghost" {...props}>
        <RefreshIcon
          className={`meticulous-ignore h-4 w-4 ${
            isLoading || hasNewData ? "text-blue-500" : "text-gray-400"
          } ${isLoading ? "animate-spin" : "cursor-pointer"}`}
        />
      </Button>
      {hasNewData ? (
        <span className="absolute right-1 top-1 h-2 w-2 rounded-full bg-primary" />
      ) : null}
    </div>
  );
};

export default Refresh;
