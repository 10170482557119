import { BracesIcon } from "lucide-react";

export const JsonBadge = () => {
  return (
    <div className="flex items-center justify-center pb-2 text-center text-xs">
      <div className="mx-auto flex items-center justify-center gap-x-1 rounded-full bg-gray-100 px-3 py-1 text-xs text-gray-400">
        <BracesIcon className="h-3 w-3" />
        JSON
      </div>
    </div>
  );
};
