import {
  Conditional,
  DynamicReleaseLabel,
  OperationConditional,
} from "@/types/conditionals";
import { PencilIcon, TrashIcon } from "lucide-react";
import React from "react";
import { CONDITIONAL_OPERATOR_TO_STRING } from ".";
import ReleaseLabelTable from "./ReleaseLabelTable";
interface DynamicReleaseLabelCluster {
  [key: number]: DynamicReleaseLabel[];
}
const ConditionalRenderer: React.FC<{
  conditional: Conditional;
  index: number;
  type: "workflow" | "prompt";
  promptRegistryIds: number[];
  activeTab: number | null;
  filteredLabels: DynamicReleaseLabel[];
  handleEditConditionalIconClick: (
    conditional: OperationConditional,
    index: number,
  ) => void;
  handleDeleteConditionalIconClick: (conditional: Conditional) => void;
  setConditionals: React.Dispatch<React.SetStateAction<Conditional[]>>;
  promptLabelName: string;
}> = ({
  conditional,
  index,
  promptRegistryIds,
  activeTab,
  type,
  filteredLabels,
  handleEditConditionalIconClick,
  handleDeleteConditionalIconClick,
  setConditionals,
  promptLabelName,
}) => {
  const innerTds = Object.entries(
    promptRegistryIds.reduce((acc: DynamicReleaseLabelCluster, id: number) => {
      acc[id] = filteredLabels.filter(
        (label) =>
          (type === "workflow"
            ? label.workflow_id
            : label.prompt_registry_id) === id,
      );
      return acc;
    }, {}),
  ).map(
    ([promptRegistryId, dynamicReleaseLabels]: [
      string,
      DynamicReleaseLabel[],
    ]) => (
      <td className="border px-4 py-4 text-xs" key={promptRegistryId}>
        <ReleaseLabelTable
          conditional={conditional}
          dynamicReleaseLabels={dynamicReleaseLabels}
          promptLabelName={promptLabelName}
          promptRegistryId={promptRegistryId}
          setConditionals={setConditionals}
          type={type}
        />
      </td>
    ),
  );

  return (
    <React.Fragment>
      <tr>
        <td
          className="border bg-gray-100 px-4 py-2"
          colSpan={promptRegistryIds.length}
        >
          <div className="flex justify-between">
            <div className="flex items-center">
              {conditional.is_default ? (
                <span className="text-sm font-semibold uppercase text-gray-700">
                  Default
                </span>
              ) : (
                <div className="flex items-center">
                  <span className="text-sm font-semibold uppercase text-gray-700">
                    If
                  </span>
                  <div className="mx-2 rounded-sm border border-gray-300 bg-gray-200 px-2 py-1 font-mono text-xs text-gray-600">
                    {`${conditional.field} ${
                      CONDITIONAL_OPERATOR_TO_STRING[conditional.operator]
                    } ${
                      conditional.operator === "in" ||
                      conditional.operator === "not_in"
                        ? `[${conditional.value}]`
                        : conditional.value
                    }`}
                  </div>
                </div>
              )}
              {filteredLabels.length === 0 && (
                <span className="ml-3 text-sm text-red-600">
                  (minimum of 1 version required)
                </span>
              )}
            </div>
            <div className="flex items-center space-x-2">
              {!conditional.is_default && (
                <>
                  <PencilIcon
                    className="h-5 w-5 cursor-pointer text-gray-500 hover:text-gray-700"
                    onClick={() =>
                      handleEditConditionalIconClick(
                        conditional as OperationConditional,
                        index,
                      )
                    }
                  />
                  <TrashIcon
                    className="h-5 w-5 cursor-pointer text-gray-500 hover:text-gray-700"
                    onClick={() =>
                      handleDeleteConditionalIconClick(conditional)
                    }
                  />
                </>
              )}
            </div>
          </div>
        </td>
      </tr>
      <tr>{[...innerTds]}</tr>
    </React.Fragment>
  );
};

export default ConditionalRenderer;
