import AddParameterButton from "@/components/FunctionsModal/InteractiveFunctionEditor/AddParameterButton";
import ArrayParameterEditor from "@/components/FunctionsModal/InteractiveFunctionEditor/ArrayParameterEditor";
import { BooleanParameterEditor } from "@/components/FunctionsModal/InteractiveFunctionEditor/BooleanParameterEditor";
import { DescriptionItemParamForm } from "@/components/FunctionsModal/InteractiveFunctionEditor/DescriptionItemParamForm";
import { NumberParameterEditor } from "@/components/FunctionsModal/InteractiveFunctionEditor/NumberParameterEditor";
import ObjectParameterEditor from "@/components/FunctionsModal/InteractiveFunctionEditor/ObjectParameterEditor";
import ParameterHeader from "@/components/FunctionsModal/InteractiveFunctionEditor/ParameterHeader";
import { StringParameterEditor } from "@/components/FunctionsModal/InteractiveFunctionEditor/StringParameterEditor";
import { InteractiveEditorSwitch } from "@/components/FunctionsModal/InteractiveFunctionEditor/Switch";
import {
  ParameterType,
  Properties,
} from "@/components/FunctionsModal/InteractiveFunctionEditor/Types";
import {
  getItemDescriptionState,
  handleItemSwitch,
  handleJsonToFormParamChange,
  handleParameterChange,
  parseParams,
} from "@/components/FunctionsModal/InteractiveFunctionEditor/utils/InteractiveParameterUtils";
import { FC, useEffect, useState } from "react";

const InteractiveFunctionParametersEditor = ({
  initialValue,
  onChange,
  // extra params that will be passed from recursive call
  depth = 0,
  outerType,
  readonly = false,
}: {
  initialValue: any;
  onChange: (value: any) => void;
  // extra params that will be passed from recursive call
  depth?: number;
  outerType?: string;
  readonly?: boolean;
}) => {
  const [isItemDescription, setIsItemDescription] = useState<
    boolean | undefined
  >(getItemDescriptionState(initialValue, depth));

  const [parameters, setParameters] = useState<Properties[]>(
    parseParams(initialValue, isItemDescription, outerType),
  );

  useEffect(() => {
    handleJsonToFormParamChange(
      parameters,
      onChange,
      isItemDescription,
      outerType,
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parameters, isItemDescription]);

  useEffect(() => {
    if (outerType === ParameterType.Object) {
      setIsItemDescription(false);
    }
  }, [outerType]);

  const handleOnToggleChange = (isChecked: boolean) => {
    setIsItemDescription(isChecked);
    handleItemSwitch(isChecked, setParameters, onChange);
  };

  // Limit recursion depth and prompt user to switch to JSON editor if needed
  const maxDepth = 3;

  if (depth > maxDepth) {
    return (
      <div className="text-xs text-gray-500">
        Maximum depth reached. Please switch to JSON mode to edit further.
      </div>
    );
  }

  return (
    <div className="px-3">
      {depth > 0 && outerType !== ParameterType.Object && (
        <InteractiveEditorSwitch
          isItemDescription={isItemDescription}
          handleOnChange={handleOnToggleChange}
          readonly={readonly}
        />
      )}
      {!isItemDescription && <ParameterHeader />}
      {parameters?.map((parameter, index) => (
        <DynamicRenderParameterEditor
          parameter={parameter}
          onChange={onChange}
          key={index}
          index={index}
          parameters={parameters}
          setParameters={setParameters}
          depth={depth}
          isItemDescription={isItemDescription}
          readonly={readonly}
        />
      ))}
      {!readonly && !isItemDescription && (
        <AddParameterButton setParameters={setParameters} />
      )}
    </div>
  );
};

type DynamicRenderParameterEditorProps = {
  parameter: Properties;
  onChange: (value: any) => void;
  index: number;
  parameters: Properties[];
  setParameters: React.Dispatch<React.SetStateAction<Properties[]>>;
  depth?: number;
  isItemDescription?: boolean;
  readonly?: boolean;
};

export const DynamicRenderParameterEditor: FC<
  DynamicRenderParameterEditorProps
> = ({
  parameter,
  isItemDescription,
  index,
  parameters,
  setParameters,
  depth = 0,
  readonly = false,
}) => {
  if (isItemDescription) {
    return (
      <DescriptionItemParamForm
        parameters={parameters}
        setParameters={setParameters}
        handleParameterChange={handleParameterChange}
        isItemDescription={isItemDescription}
        readonly={readonly}
      />
    );
  }

  switch (parameter.type) {
    case ParameterType.String:
      return (
        <StringParameterEditor
          parameter={parameter}
          index={index}
          parameters={parameters}
          setParameters={setParameters}
          readonly={readonly}
        />
      );
    case ParameterType.Number:
      return (
        <NumberParameterEditor
          parameter={parameter}
          index={index}
          parameters={parameters}
          setParameters={setParameters}
          readonly={readonly}
        />
      );
    case ParameterType.Boolean:
      return (
        <BooleanParameterEditor
          parameter={parameter}
          index={index}
          parameters={parameters}
          setParameters={setParameters}
          readonly={readonly}
        />
      );
    case ParameterType.Array:
      return (
        <ArrayParameterEditor
          parameters={parameters}
          parameter={parameter}
          setParameters={setParameters}
          depth={depth}
          index={index}
          isItemDescription={isItemDescription}
          readonly={readonly}
        />
      );
    case ParameterType.Object:
      return (
        <ObjectParameterEditor
          parameters={parameters}
          parameter={parameter}
          setParameters={setParameters}
          depth={depth}
          index={index}
          readonly={readonly}
        />
      );
    default:
      return null;
  }
};

export default InteractiveFunctionParametersEditor;
