import { AnimatePresence, motion } from "framer-motion";
import React, { useCallback, useEffect, useRef, useState } from "react";

import Widgets, { WidgetTypes } from "@/components/Widgets";
import { WidgetItem } from "../types";
import ContextMenuItem from "./ContextMenuItem";
import useDropdownPosition from "./hooks/useDropdownPosition";
import useKeyboardNavigation from "./hooks/useKeyboardNavigation";
import useSelectionHandler from "./hooks/useSelectionHandler";

interface ContextMenuProps {
  isVisible: boolean;
  position: { top: number; left: number };
  onSelect: (widgetType: WidgetTypes) => void;
  onClose: () => void;
  contentRef: React.RefObject<HTMLDivElement>;
}

const ContextMenuHeader: React.FC = () => (
  <div className="flex items-center justify-between border-b border-gray-100 bg-gray-50/50 px-3 py-2">
    <span className="text-xs font-medium  tracking-wider text-gray-500">
      Add Widget
    </span>
  </div>
);

const ContextMenu: React.FC<ContextMenuProps> = ({
  isVisible,
  position,
  onSelect,
  onClose,
  contentRef,
}) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { saveSelection, restoreSelection } = useSelectionHandler(contentRef);
  const [hasInitiallySaved, setHasInitiallySaved] = useState(false);

  const widgetItems: WidgetItem[] = Object.entries(Widgets).map(
    ([type, widgetConfig]) => {
      const [, , Icon, description] = widgetConfig;
      return {
        type: type as WidgetTypes,
        icon: <Icon className="h-5 w-5 text-gray-400" />,
        description: description,
      };
    },
  );

  const selectedIndex = useKeyboardNavigation(
    isVisible,
    widgetItems,
    onSelect,
    onClose,
    restoreSelection,
  );

  const dropdownPosition = useDropdownPosition(
    dropdownRef,
    position,
    contentRef,
  );

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        onClose();
        restoreSelection();
      }
    },
    [onClose, restoreSelection],
  );

  useEffect(() => {
    if (isVisible && !hasInitiallySaved) {
      saveSelection();
      setHasInitiallySaved(true);
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside, isVisible, saveSelection, hasInitiallySaved]);

  const itemVariants = {
    hidden: { opacity: 0, y: 20, scale: 0.95, rotate: -2 },
    visible: {
      opacity: 1,
      y: 0,
      scale: 1,
      rotate: 0,
      transition: {
        type: "spring",
        stiffness: 400,
        damping: 25,
        mass: 0.5,
      },
    },
    exit: {
      opacity: 0,
      y: -20,
      scale: 0.95,
      rotate: 2,
      transition: {
        duration: 0.2,
      },
    },
  };

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          ref={dropdownRef}
          className="fixed z-50 w-80 overflow-hidden rounded-lg border border-gray-200 bg-white shadow-lg"
          style={{
            top: `${dropdownPosition.top}px`,
            left: `${dropdownPosition.left}px`,
          }}
          variants={{
            hidden: { opacity: 0, scale: 0.95, y: -5, rotate: -1 },
            visible: {
              opacity: 1,
              scale: 1,
              y: 0,
              rotate: 0,
              transition: {
                type: "spring",
                stiffness: 500,
                damping: 25,
                mass: 0.8,
              },
            },
            exit: {
              opacity: 0,
              scale: 0.95,
              y: 5,
              rotate: 1,
              transition: {
                duration: 0.2,
              },
            },
          }}
          initial="hidden"
          animate="visible"
          exit="exit"
          onClick={(e) => e.stopPropagation()}
        >
          <ContextMenuHeader />
          <motion.div
            className="scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-transparent flex max-h-[420px] flex-col gap-y-1 overflow-y-auto p-1"
            variants={{
              hidden: { opacity: 0 },
              visible: {
                opacity: 1,
                transition: {
                  staggerChildren: 0.03,
                  delayChildren: 0.05,
                },
              },
            }}
            initial="hidden"
            animate="visible"
          >
            {widgetItems.map((widget, index) => (
              <motion.div
                key={widget.type}
                variants={itemVariants}
                className="overflow-hidden"
              >
                <ContextMenuItem
                  widget={widget}
                  isSelected={index === selectedIndex}
                  onSelect={() => {
                    onSelect(widget.type);
                    restoreSelection();
                  }}
                />
              </motion.div>
            ))}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default ContextMenu;
