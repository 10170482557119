import { memo, useMemo } from "react";

interface HeaderProps {
  title?: string;
  VersionSelector: React.ComponentType;
  isDraft?: boolean;
  children?: React.ReactNode | null;
}

const Header = ({ children, isDraft, title, VersionSelector }: HeaderProps) => {
  const draftModeStyle = "rounded-lg border-orange-200 bg-orange-100";

  const normalModeStyle = "border-gray-300 bg-gray-50";

  const style = useMemo(
    () => (isDraft ? draftModeStyle : normalModeStyle),
    [isDraft],
  );

  return (
    <div className="box-border flex h-auto w-full items-center justify-between">
      <div
        className={`flex w-full flex-col items-start gap-x-2 gap-y-2 rounded-lg border px-6 py-6 ${style}`}
      >
        <h1 className=" mb-1 self-start text-4xl font-bold">{title}</h1>
        <div className="flex items-center space-x-2">
          {isDraft && (
            <span className={`rounded-full bg-orange-200 px-2 py-1 text-xs`}>
              You are in Draft Mode
            </span>
          )}
          <VersionSelector />
        </div>
        {children}
      </div>
    </div>
  );
};

export default memo(Header);
