import { Switch } from "@/components/switch";

export const InteractiveEditorSwitch = ({
  isItemDescription,
  handleOnChange,
  readonly = false,
}: {
  isItemDescription?: boolean;
  handleOnChange: (isItemDescription: boolean) => void;
  readonly?: boolean;
}) => {
  return (
    <div className="flex items-center justify-center gap-4 pb-2 text-xs">
      <span>Composition</span>
      <Switch
        checked={isItemDescription}
        onChange={handleOnChange}
        className="h-4 w-4"
        disabled={readonly}
      />
      <span>Description</span>
    </div>
  );
};
