import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import useActiveWorkspace from "@/hooks/useActiveWorkspace";
import { DatasetGroupRead } from "@/types/dataset-groups";
import { truncate } from "@/utils/strings";
import { AlertTriangleIcon, ExternalLink } from "lucide-react";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { useCallback, useRef } from "react";
import { useExamples } from "../example-widget-store";

const DatasetSelect = observer(
  ({
    isReadOnly,
    datasetId,
    datasetGroups,
  }: {
    isReadOnly: boolean;
    datasetId: string;
    datasetGroups: DatasetGroupRead[];
  }) => {
    const store = useExamples();
    const selectRef = useRef<HTMLButtonElement>(null);

    const dataset_groups = datasetGroups || [];
    const datasets = dataset_groups.map(({ name }: any) => name);

    const handleDatasetChange = useCallback(
      (value: string) => {
        runInAction(() => {
          store.setSelectedDataset(value);
        });
      },
      [store],
    );

    const datasetGroupId = dataset_groups.find(
      ({ name }: { name: string }) => name === store.selectedDataset,
    )?.id;

    const activeWorkspace = useActiveWorkspace()?.id;
    const isValidReference = !!(
      datasetId &&
      datasetGroupId &&
      store.selectedDataset
    );

    const InvalidReferenceMessage = () => (
      <div className="mb-2 text-sm text-red-600">Invalid dataset reference</div>
    );

    const getDatasetUrl = () =>
      `/workspace/${activeWorkspace}/dataset-groups/${datasetGroupId}/dataset/${datasetId}/`;

    const getCommonClasses = (isValid: boolean) =>
      isValid ? "bg-white" : "border-red-300 bg-red-50";

    const DatasetDisplay = () => (
      <span className={isValidReference ? "" : "text-red-600"}>
        {truncate(store.selectedDataset, 20)}
      </span>
    );

    if (isReadOnly && store.selectedDataset) {
      return (
        <div>
          {!isValidReference && <InvalidReferenceMessage />}
          <Button
            variant="outline"
            className={`flex w-full items-center justify-between ${getCommonClasses(
              isValidReference,
            )} ${
              isValidReference
                ? "hover:bg-gray-50"
                : "cursor-not-allowed hover:bg-red-100"
            }`}
            onClick={() => {
              if (isValidReference) {
                window.open(getDatasetUrl(), "_blank");
              }
            }}
            title={isValidReference ? undefined : "Invalid dataset reference"}
          >
            <DatasetDisplay />
            {isValidReference ? (
              <ExternalLink className="ml-2 h-4 w-4 opacity-70" />
            ) : (
              <AlertTriangleIcon className="ml-2 h-4 w-4 text-red-600" />
            )}
          </Button>
        </div>
      );
    }

    return (
      <div>
        {!isValidReference && store.selectedDataset && (
          <InvalidReferenceMessage />
        )}
        <Select
          onValueChange={handleDatasetChange}
          value={store.selectedDataset || ""}
          disabled={isReadOnly}
        >
          <SelectTrigger
            className={`w-full ${getCommonClasses(
              isValidReference || !store.selectedDataset,
            )}`}
            ref={selectRef}
          >
            <SelectValue>
              <span
                className={
                  !isValidReference && store.selectedDataset
                    ? "text-red-600"
                    : ""
                }
              >
                {store.selectedDataset ? (
                  truncate(store.selectedDataset, 20)
                ) : (
                  <span className="text-muted-foreground">
                    Select a dataset
                  </span>
                )}
              </span>
            </SelectValue>
          </SelectTrigger>
          <SelectContent className="max-h-[300px] overflow-y-auto">
            {datasets.map((dataset: string) => {
              const group = dataset_groups.find(
                ({ name }: { name: string }) => name === dataset,
              );
              const versions =
                group?.datasets
                  ?.map(
                    ({ version_number }: { version_number: number }) =>
                      version_number,
                  )
                  ?.filter((version: number) => version > 0) || [];

              return versions.length ? (
                <SelectItem key={dataset} value={dataset}>
                  {truncate(dataset, 25)}
                </SelectItem>
              ) : null;
            })}
          </SelectContent>
        </Select>
      </div>
    );
  },
);

export default DatasetSelect;
