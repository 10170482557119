import GroupContentNavbar from "./GroupContentNavbar";
import RequestContentNavbar from "./RequestContentNavbar";

export const ContentNavbar = ({
  isGroup,
  onHistoryPage,
  setSidebarOpen,
}: {
  isGroup: boolean;
  onHistoryPage: boolean;
  setSidebarOpen: (open: boolean) => void;
}) => {
  if (!isGroup && !onHistoryPage) return null;

  if (isGroup) {
    return (
      <GroupContentNavbar
        onHistoryPage={onHistoryPage}
        setSidebarOpen={setSidebarOpen}
      />
    );
  }

  return (
    <RequestContentNavbar
      onHistoryPage={onHistoryPage}
      setSidebarOpen={setSidebarOpen}
    />
  );
};
