import { ReportColumn } from "@/types/evaluate";
import React, { createContext, useContext, useState } from "react";

interface ModalRouterContextType {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  selectedColumn: ReportColumn | null;
  setSelectedColumn: (column: ReportColumn | null) => void;
}

const ModalRouterContext = createContext<ModalRouterContextType | undefined>(
  undefined,
);

export const ModalRouterProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedColumn, setSelectedColumn] = useState<ReportColumn | null>(
    null,
  );

  return (
    <ModalRouterContext.Provider
      value={{
        isOpen,
        setIsOpen,
        selectedColumn,
        setSelectedColumn,
      }}
    >
      {children}
    </ModalRouterContext.Provider>
  );
};

export const useModalRouter = () => {
  const context = useContext(ModalRouterContext);
  if (context === undefined) {
    throw new Error("useModalRouter must be used within a ModalRouterProvider");
  }
  return context;
};
