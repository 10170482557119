import { DocumentReportIcon } from "@heroicons/react/outline";
import { useMemo, useState } from "react";

import DatasetGroupModal from "@/components/DatasetGroupModal";
import LoadingSpinner from "@/components/LoadingSpinner";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { useDatasetGroups } from "@/queries";
import { DatasetGroupRead } from "@/types/dataset-groups";
import { Link, useNavigate } from "react-router-dom";
import { Button, DivButton } from "../ui/button";

interface DatasetGroupSelectorModalProps {
  handleDatasetGroupIdClick: (datasetGroupId: number) => void | Promise<void>;
  isLoading?: boolean;
  minVersionNumber?: number;
  setOpen: (isOpen: boolean) => void;
  showCreateButton?: boolean;
}

const DatasetGroupSelectorModal = ({
  handleDatasetGroupIdClick,
  isLoading,
  minVersionNumber = 0,
  setOpen,
  showCreateButton = false,
}: DatasetGroupSelectorModalProps) => {
  const authContext = useAuth();
  const userContext = useUser();
  const navigate = useNavigate();
  const [isDatasetGroupModalOpen, setDatasetGroupModalOpen] = useState(false);
  const { isFetching, data: newDatasetGroupsData } = useDatasetGroups(
    authContext!.userToken!,
    userContext.activeWorkspaceId!,
    minVersionNumber,
  );

  const datasetGroupList = useMemo(() => {
    return (
      newDatasetGroupsData?.dataset_groups?.sort(
        (a: DatasetGroupRead, b: DatasetGroupRead) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
      ) ?? []
    );
  }, [newDatasetGroupsData]);

  const handleDatasetGroupClick = async (datasetGroupId: number) => {
    await handleDatasetGroupIdClick(datasetGroupId);
    setOpen(false);
  };

  const renderDatasetGroupGridItem = (datasetGroupRead: DatasetGroupRead) => {
    return (
      <li
        className="col-span-1 flex flex-col rounded-md border-2 border-gray-200 bg-white hover:border-blue-300"
        key={datasetGroupRead.id}
        onClick={() => handleDatasetGroupClick(datasetGroupRead.id)}
      >
        <div className="w-full flex-1 cursor-pointer px-4 pt-4 text-gray-900 hover:text-blue-500">
          <div className="flex items-center pb-2">
            <DocumentReportIcon
              aria-hidden="true"
              className="mr-3 h-5 w-5 flex-shrink-0"
            />
            <h3
              className="title cursor-pointer break-all pr-2 text-sm font-normal"
              title={datasetGroupRead.name}
            >
              {datasetGroupRead.name}
            </h3>
          </div>
          <div className="pb-2 pr-2 text-right text-xs text-gray-400">
            {new Date(datasetGroupRead.created_at).toLocaleDateString("en-US", {
              month: "numeric",
              day: "numeric",
              year: "numeric",
            })}
          </div>
        </div>
      </li>
    );
  };

  return (
    <Dialog onOpenChange={setOpen} open={true}>
      <DialogContent>
        <DialogHeader>
          <div className="mb-2 flex flex-col gap-y-1">
            <DialogTitle>Select a Dataset</DialogTitle>
            <DialogDescription>
              <span className="block max-w-md">
                Select a dataset for batch runs and evaluations. You can upload
                a new dataset on the{" "}
                <Link
                  to={`/workspace/${userContext.activeWorkspaceId}/datasets`}
                  className="text-blue-500 hover:text-blue-600"
                >
                  datasets page
                </Link>
                .
              </span>
            </DialogDescription>
          </div>
        </DialogHeader>
        {isFetching || isLoading ? (
          <div className="mt-4 text-center">
            <LoadingSpinner size={5} />
          </div>
        ) : (
          <>
            {showCreateButton && (
              <Button onClick={() => setDatasetGroupModalOpen(true)}>
                Create New Dataset
              </Button>
            )}
            <ul className="mb-5 mt-4 grid max-h-[500px] grid-cols-2 gap-3 overflow-y-auto pb-3">
              {datasetGroupList.map(renderDatasetGroupGridItem)}
              {datasetGroupList.length === 0 && (
                <div className="col-span-2 flex flex-col items-center justify-center gap-y-2 text-center text-gray-400">
                  No datasets found.
                  <DivButton
                    variant="outline"
                    onClick={() => {
                      navigate(
                        `/workspace/${userContext.activeWorkspaceId}/datasets`,
                      );
                    }}
                    size="sm"
                  >
                    Go to Datasets
                  </DivButton>
                </div>
              )}
            </ul>
          </>
        )}
        {isDatasetGroupModalOpen && (
          <DatasetGroupModal
            handleDatasetCreated={(res) =>
              handleDatasetGroupClick(res.dataset_group.id)
            }
            setOpen={setDatasetGroupModalOpen}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default DatasetGroupSelectorModal;
