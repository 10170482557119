import { ModalContent } from "@/components/Evaluate/TableComponents/NewColumnModal/ModalRouter";
import { BandaidProvider } from "@/components/Evaluate/TableComponents/utils/BandaidContext";
import { Button } from "@/components/ui/button";
import { ToastType } from "@/enums";
import { ReportColumn } from "@/types/evaluate";
import { displayToast } from "@/utils/toast";
import { XIcon } from "@heroicons/react/solid";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { WorkflowNode } from "../../types";
import {
  getAllLinkedDependencies,
  withConfigurationIdToNameRemapping,
} from "../../utils";
import EditableNodeLabel from "../Canvas/Node/NodeTypes/BasicNode/EditableNodeLabel";
import { useWorkflow } from "../workflow-context";
import NodeTypeSelector from "./NodeTypeSelector";

interface SidebarProps {
  readonly?: boolean;
}

const Sidebar = observer((props: SidebarProps) => {
  const { readonly } = props;
  const workflow = useWorkflow();
  const { isSidebarOpen, activeNode, input_variables } = workflow;

  const patchColumnData = (patchData: any) => {
    if (activeNode && typeof patchData === "function") {
      workflow.replaceNode(
        activeNode.name,
        patchData(activeNode) as WorkflowNode,
      );
    } else if (activeNode) {
      workflow.replaceNode(activeNode.name, {
        ...patchData,
      });
    }
  };

  const inputVariables = Object.keys(input_variables || {}).map((key, i) => ({
    id: i,
    name: key,
    column_type: "DATASET",
    configuration: {},
    position: -1,
    is_part_of_score: false,
    score: null,
    report_id: -2,
  })) as ReportColumn[];

  const handleCloseSidebar = () => {
    runInAction(() => {
      workflow.setActiveNode(undefined);
      workflow.closeSidebar();
    });
  };

  return (
    <div
      className={`absolute right-0 top-0 z-[2] h-full w-[350px] overflow-y-auto bg-white p-4 pt-12 shadow-md transition-transform duration-300 ease-in-out ${
        isSidebarOpen ? "translate-x-0" : "translate-x-full"
      }`}
    >
      <Button
        variant={"ghost"}
        size={"tinyIcon"}
        className="absolute right-4 top-4"
        onClick={handleCloseSidebar}
      >
        <XIcon className="h-5 w-5" />
      </Button>
      <div className="mb-4 flex flex-col">
        <div className="flex-grow">
          <EditableNodeLabel
            workflow={workflow}
            initialLabel={activeNode?.name || ""}
            node={activeNode!}
            key={activeNode?.name}
            className="text-lg font-semibold"
          />
        </div>
        {readonly && (
          <div className="mt-2 flex items-center justify-center whitespace-nowrap rounded-full bg-gray-100 px-3 py-1 text-xs font-medium text-gray-600">
            Read-only
          </div>
        )}
      </div>
      <NodeTypeSelector readonly={readonly} />
      <BandaidProvider readonly={readonly}>
        <div
          className={`mb-2 ${readonly && "pointer-events-none select-none"}`}
        >
          <ModalContent
            key={activeNode?.name}
            currentModalNavigation={"setup-column"}
            newColumnData={
              activeNode
                ? {
                    ...activeNode,
                    configuration: withConfigurationIdToNameRemapping(
                      activeNode.configuration || {},
                      workflow.nodes,
                    ),
                  }
                : ({ configuration: {} } as any)
            }
            patchColumnData={patchColumnData}
            setCurrentModalNavigation={function (step: string): void {
              throw new Error("Function not implemented.");
            }}
            editable={true}
            availableColumns={(() => {
              const allDependencies = activeNode?.dependencies
                ? getAllLinkedDependencies(
                    workflow.getNodeById.bind(workflow),
                    activeNode.id,
                  )
                : [];

              return [
                ...allDependencies.map((d) => {
                  const node = workflow.getNodeById(d);
                  return {
                    id: node?.id || d, // fallback to input variables
                    name: node?.name || d,
                    column_type: node?.node_type,
                  } as unknown as ReportColumn;
                }),
                ...inputVariables,
              ];
            })()}
            saveColumnAction={(args) => {
              patchColumnData({ ...args, id: activeNode?.id });
              const newReference = workflow.getNodeById(activeNode?.id!);
              try {
                if (activeNode) {
                  workflow.saveNode(newReference!).then((success) => {
                    if (success)
                      displayToast(
                        "Node saved successfully",
                        ToastType.success,
                      );
                  });
                }
              } catch (error) {
                console.error("Error saving node:", error);
              }
            }}
            setOpen={function (open: boolean): void {
              throw new Error("Function not implemented.");
            }}
          />
        </div>
      </BandaidProvider>
    </div>
  );
});

export default Sidebar;
