import { ReactFlowProvider } from "@xyflow/react";

interface ContainerProps {
  children: React.ReactNode;
}

const Container = ({ children }: ContainerProps) => {
  return (
    <ReactFlowProvider>
      <div
        className={`relative mx-auto  flex h-full w-full flex-col items-center overflow-hidden rounded-2xl border`}
      >
        {children}
      </div>
    </ReactFlowProvider>
  );
};

export default Container;
