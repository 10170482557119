import { WorkflowNodeStatus } from "@/components/Workflows/types";
import { getEdgeColor } from "@/components/Workflows/utils";
import {
  BaseEdge,
  EdgeProps,
  getSmoothStepPath,
  useReactFlow,
} from "@xyflow/react";
import { observer } from "mobx-react-lite";
import React, { useCallback, useState } from "react";
import { useWorkflow } from "../../workflow-context";

function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  target,
  source,
}: EdgeProps) {
  const { setEdges } = useReactFlow();
  const workflow = useWorkflow();
  const [hoverPosition, setHoverPosition] = useState<{
    x: number;
    y: number;
  } | null>(null);

  const [edgePath] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
    borderRadius: 20,
  });

  const status = workflow.getNodeById(target)?.status;
  const stroke = getEdgeColor(status as WorkflowNodeStatus);

  const onEdgeClick = useCallback(
    (evt: React.MouseEvent<SVGGElement>) => {
      evt.stopPropagation();
      workflow.removeDependency(target, source);
      setEdges((edges) => edges.filter((edge) => edge.id !== id));
    },
    [id, setEdges, source, target, workflow],
  );

  const handleMouseMove = useCallback(
    (event: React.MouseEvent<SVGPathElement>) => {
      const svgElement = event.currentTarget.ownerSVGElement;
      if (svgElement) {
        const point = svgElement.createSVGPoint();
        point.x = event.clientX;
        point.y = event.clientY;
        const cursorPoint = point.matrixTransform(
          svgElement.getScreenCTM()?.inverse(),
        );
        setHoverPosition({ x: cursorPoint.x, y: cursorPoint.y });
      }
    },
    [],
  );

  const handleMouseLeave = useCallback(() => {
    setHoverPosition(null);
  }, []);

  return (
    <>
      <BaseEdge
        path={edgePath}
        style={{
          ...style,
          stroke,
          strokeWidth: 2,
        }}
      />
      <path
        d={edgePath}
        fill="none"
        strokeWidth={20}
        stroke="transparent"
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        onClick={onEdgeClick}
        className="cursor-pointer"
      />
      {hoverPosition && !workflow.readonly && (
        <g transform={`translate(${hoverPosition.x}, ${hoverPosition.y})`}>
          <circle
            r="12"
            fill="white"
            stroke="gray"
            strokeWidth="1"
            className="cursor-pointer"
          />
          <text
            dy="4"
            textAnchor="middle"
            fontSize="16"
            fill="gray"
            className="pointer-events-none select-none font-bold"
          >
            ×
          </text>
        </g>
      )}
    </>
  );
}

export default observer(CustomEdge);
