import { autorun } from "mobx";
import { useCallback, useEffect } from "react";
import { useContentArea } from "../ContentArea/content-area-context";

const useWidget = (id: string, value: string) => {
  const content = useContentArea();

  useEffect(() => {
    const disposer = autorun(() => {
      content.setWidget(id, value);
    });

    return disposer;
  }, [content, id, value]);

  const deleteWidget = useCallback(() => {
    const widgetElement = document.querySelector(
      `[data-custom-component-id='${id}']`,
    );

    if (widgetElement) {
      widgetElement.remove();
      content.removeWidget(id);
      const range = content.savedSelection;
      const selection = window.getSelection();
      if (range && selection && selection.rangeCount > 0) {
        selection.removeAllRanges();
        selection.addRange(range);
      }
    }
  }, [content, id]);
  return { deleteWidget };
};

export default useWidget;
