import { motion } from "framer-motion";
import { RedoIcon, UndoIcon } from "lucide-react";
import { observer } from "mobx-react-lite";
import React from "react";
import { useHistory } from "../hooks/useUndoRedo";

const UndoRedoComponent: React.FC = () => {
  const { store, undo, redo } = useHistory();

  const canUndo = store.canUndo;
  const canRedo = store.canRedo;

  const handleAction = (action: () => void, canExecute: boolean) => {
    if (canExecute) action();
  };

  const renderButton = (
    onClick: () => void,
    disabled: boolean,
    Icon: React.ElementType,
  ) => (
    <motion.button
      onClick={onClick}
      disabled={disabled}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
      className={`flex h-8 w-8 items-center justify-center rounded-full transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 ${
        !disabled
          ? "bg-blue-500 text-white hover:bg-blue-600"
          : "cursor-not-allowed bg-gray-300 text-gray-500"
      }`}
    >
      <Icon className="h-4 w-4" />
    </motion.button>
  );

  return (
    <div className="pointer-events-auto flex space-x-2">
      {renderButton(() => handleAction(undo, canUndo), !canUndo, UndoIcon)}
      {renderButton(() => handleAction(redo, canRedo), !canRedo, RedoIcon)}
    </div>
  );
};

export default observer(UndoRedoComponent);
