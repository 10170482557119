import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { usePromptVersions, useWorkflowVersions } from "@/queries";
import { ListPromptVersion } from "@/types";
import { Conditional } from "@/types/conditionals";
import { WorkflowVersion } from "../Workflows/types";

function ChooseVersion({
  registryId,
  conditional,
  selectedVersion,
  setSelectedVersion,
  isWorkflow,
}: {
  registryId: number;
  conditional: Conditional;
  selectedVersion: ListPromptVersion | WorkflowVersion | null;
  setSelectedVersion: (version: ListPromptVersion | WorkflowVersion) => void;
  isWorkflow: boolean;
}) {
  const auth = useAuth();
  const userToken = auth?.userToken!;
  const userContext = useUser();

  const promptVersionsQuery = usePromptVersions(userToken, {
    workspaceId: userContext.activeWorkspaceId!,
    promptRegistryId: registryId,
    perPage: Number.MAX_SAFE_INTEGER,
  });

  const { data: workflowVersionsData } = useWorkflowVersions(
    registryId.toString(),
    userToken,
  );

  const versionOptions = isWorkflow
    ? workflowVersionsData?.workflow_versions || []
    : promptVersionsQuery.data?.pages.flatMap((page) => page.items) ?? [];

  const getVersionNames = (version: ListPromptVersion | WorkflowVersion) => {
    const namesList =
      "labels" in version && version.labels.length > 0
        ? version.labels.map((label) => label.name)
        : [];
    return namesList.map((label) => (
      <span
        key={label}
        className="mx-1 inline-block rounded-sm border border-gray-200 px-2 py-0.5 text-xs font-medium text-gray-500"
      >
        {label}
      </span>
    ));
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="w-full">
        {selectedVersion ? (
          <span>
            Version {selectedVersion?.number}
            {getVersionNames(selectedVersion)}
          </span>
        ) : (
          <span className="text-gray-400">Select version...</span>
        )}
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {versionOptions.map((version) =>
          conditional.dynamic_release_labels.some(
            (label) =>
              (isWorkflow
                ? label.workflow_version_id
                : label.prompt_version_id) === version.id,
          ) ? (
            <DropdownMenuItem key={version.id} disabled>
              Version {version.number} (already added){" "}
              {getVersionNames(version)}
            </DropdownMenuItem>
          ) : (
            <DropdownMenuItem
              key={version.id}
              onSelect={() => setSelectedVersion(version)}
            >
              Version {version.number} {getVersionNames(version)}
            </DropdownMenuItem>
          ),
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export default ChooseVersion;
