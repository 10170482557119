import DeleteAllRowsButton from "@/components/DatasetEditor/Header/DeleteAllRowsButton";
import Header from "@/components/ui/header";
import { useCallback, useContext, useMemo } from "react";
import { DatasetEditorContext } from "..";
import AddFromRequestHistoryButton from "./AddFromRequestHistoryButton";
import ArchiveButton from "./ArchiveButton";
import DeleteButton from "./DeleteButton";
import DownloadButton from "./DownloadButton";
import EditButton from "./EditButton";
import FilterParamsButton from "./FilterParamsButton";
import UploadDataButton from "./UploadDataButton";
import VersionHistoryMenu from "./VersionHistoryMenu";
import useDatasets from "./VersionHistoryMenu/hooks/useDatasets";

const DatasetHeader = ({
  datasetId,
  isLoading,
}: {
  datasetId: string | undefined;
  isLoading: boolean;
}) => {
  const {
    datasetGroup,
    datasets,
    activeDataset,
    isDraft,
    handleDeleteDatasetGroup,
  } = useContext(DatasetEditorContext);

  const { currentVersion, sortedDatasets } = useDatasets(datasets, datasetId);

  const isArchived = useMemo(() => !!datasetGroup?.is_deleted, [datasetGroup]);

  const VersionSelector = useCallback(
    () => (
      <>
        {!isLoading && (
          <VersionHistoryMenu
            currentVersion={currentVersion}
            sortedDatasets={sortedDatasets}
            datasets={datasets}
            isDraft={isDraft}
          />
        )}
        {!isLoading && isArchived && !isDraft && (
          <ArchiveButton onDelete={handleDeleteDatasetGroup} />
        )}
      </>
    ),
    [
      isDraft,
      isLoading,
      currentVersion,
      sortedDatasets,
      datasets,
      isArchived,
      handleDeleteDatasetGroup,
    ],
  );

  return (
    <Header
      isDraft={isDraft}
      title={datasetGroup?.name}
      VersionSelector={VersionSelector}
    >
      <div
        className={`flex ${
          (isDraft && "w-full") || "w-fit"
        } flex-col items-start gap-2.5 whitespace-nowrap sm:flex-col sm:items-start sm:gap-x-2.5  md:flex-row lg:flex-row lg:justify-between`}
      >
        {!isLoading && isDraft && (
          <div className="flex gap-2.5">
            <AddFromRequestHistoryButton />
            <UploadDataButton />
          </div>
        )}
        <div
          className={`${
            !isDraft && "ml-auto"
          } flex flex-col gap-2.5 sm:flex-row sm:gap-x-2.5 md:flex-col lg:flex-row`}
        >
          {!isArchived && !isLoading && !isDraft && (
            <ArchiveButton onDelete={handleDeleteDatasetGroup} />
          )}
          <FilterParamsButton activeDataset={activeDataset} />
          <DownloadButton />
          <DeleteAllRowsButton />
          <DeleteButton />
          <EditButton />
        </div>
      </div>
    </Header>
  );
};

export default DatasetHeader;
