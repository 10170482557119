import { Button } from "@/components/ui/button";
import { useWorkflow } from "@/components/Workflows/WorkflowCanvas/workflow-context";
import { FastForwardIcon, PlayIcon } from "@heroicons/react/solid";
import { motion } from "framer-motion";
import { observer } from "mobx-react-lite";
import { useNode } from "../../node-context";

interface PlayFromHereButtonProps {
  showPlayFromHere: boolean;
}

const PlayFromHereButton: React.FC<PlayFromHereButtonProps> = ({
  showPlayFromHere,
}) => {
  const workflow = useWorkflow();
  const node = useNode();

  if (workflow.readonly) {
    return null;
  }

  return (
    <div
      className={`relative ml-auto ${
        showPlayFromHere ? "opacity-100" : "pointer-events-none opacity-50"
      }`}
    >
      <motion.div
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        className="relative"
      >
        <Button
          onClick={(e) => {
            e.stopPropagation();
            workflow.playFromHere(node!);
          }}
          variant="outline"
        >
          {node?.triggered ? (
            <>
              <FastForwardIcon className="mr-2 h-5 w-5 animate-pulse text-primary group-hover:text-primary-foreground" />
              <span className="font-medium group-hover:text-primary-foreground">
                Running...
              </span>
            </>
          ) : (
            <>
              <PlayIcon className="mr-2 h-5 w-5 text-primary group-hover:text-primary-foreground" />
              <span className="font-medium group-hover:text-primary-foreground">
                Start Here
              </span>
            </>
          )}
        </Button>
      </motion.div>
    </div>
  );
};

export default observer(PlayFromHereButton);
