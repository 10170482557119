import { keepRelevantSearchParams } from "@/utils/utils";
import { UseQueryResult } from "@tanstack/react-query";
import { useCallback, useContext, useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { DatasetEditorContext } from "..";
import DatasetTable from "./DatasetTable";

interface DatasetContentProps {
  datasetRowsQuery: UseQueryResult<any, any>;
  page: number;
  pageSize: number;
  setPage: (page: number) => void;
  setPageSize: (pageSize: number) => void;
}

const DatasetContent = ({
  page,
  pageSize,
  setPage,
  setPageSize,
  datasetRowsQuery,
}: DatasetContentProps) => {
  const { datasets, activeDataset, rows } = useContext(DatasetEditorContext);

  const { datasetId, datasetGroupId, workspaceId } = useParams();

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const keepRelevantParams = useCallback(() => {
    return keepRelevantSearchParams(searchParams);
  }, [searchParams]);

  // Redirect to specific dataset from default URL
  useEffect(() => {
    if (datasetId === undefined && datasets.length) {
      const mostRecentDataset = datasets[0];
      navigate(
        {
          pathname: `/workspace/${workspaceId}/dataset-groups/${datasetGroupId}/dataset/${mostRecentDataset.id}`,
          search: keepRelevantParams(),
        },
        {
          replace: true,
        },
      );
    }
  }, [
    datasetGroupId,
    datasetId,
    datasets,
    navigate,
    workspaceId,
    keepRelevantParams,
  ]);

  return (
    <div className="relative w-full rounded-md bg-white ">
      <DatasetTable
        key={datasetId}
        activeDataset={activeDataset}
        page={page}
        pageSize={pageSize}
        pages={datasetRowsQuery.data?.pages || 0}
        rows={rows}
        setPage={setPage}
        setPageSize={setPageSize}
        total={datasetRowsQuery.data?.total || 0}
      />
    </div>
  );
};

export default DatasetContent;
