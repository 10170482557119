import SaveWithCommitButton from "@/components/SaveWithCommitButton";
import { XIcon } from "@heroicons/react/outline";
import { HammerIcon, TrashIcon } from "lucide-react";
import { MutableRefObject } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IWorkflow } from "../../WorkflowCanvas/workflow-store";

const useHeaderActions = (
  isEditing: boolean,
  workflowRef: MutableRefObject<IWorkflow | undefined>,
  toggleDeleteModal: () => void,
) => {
  const navigate = useNavigate();
  const { workflowId, versionId: versionNumber, workspaceId } = useParams();

  return !isEditing
    ? [
        {
          label: "Delete",
          icon: <TrashIcon className="h-4 w-auto pr-1" aria-hidden="true" />,
          onClick: toggleDeleteModal,
          variant: "destructiveOutline" as const,
        },

        {
          label: "Go to Workflow Builder",
          icon: <HammerIcon className="h-4 w-auto pr-1" aria-hidden="true" />,
          onClick: () =>
            navigate(
              `/workspace/${workspaceId}/workflow/${workflowId}/version/${versionNumber}/edit`,
            ),
          variant: "outline" as const,
        },
      ]
    : [
        {
          label: "Cancel Edit",
          icon: <XIcon className="h-4 w-auto pr-1" />,
          onClick: () => {
            const confirmExit = window.confirm(
              "Are you sure you want to cancel editing? Any unsaved changes will be lost.",
            );
            if (confirmExit) {
              navigate(
                `/workspace/${workspaceId}/workflow/${workflowId}/version/${versionNumber}`,
              );
            }
          },
          variant: "destructiveOutline" as const,
        },
        <SaveWithCommitButton
          name="Save as New Version"
          onSave={async (commitMessage) => {
            try {
              const response = await workflowRef.current?.save(
                commitMessage,
                true,
              );
              if (response && response.success) {
                navigate(
                  `/workspace/${workspaceId}/workflow/${workflowId}/version/${
                    Number(versionNumber) + 1
                  }`,
                );
              } else {
                // Handle unsuccessful save
                // console.error("Failed to save workflow:", response?.message);
              }
            } catch (error) {
              // Handle any errors
              //console.error("Error saving workflow:", error);
            }
          }}
        />,
      ];
};

export default useHeaderActions;
