import { WidgetItem } from "../types";

const ContextMenuItem: React.FC<{
  widget: WidgetItem;
  isSelected: boolean;
  onSelect: () => void;
}> = ({ widget, isSelected, onSelect }) => (
  <div
    onClick={onSelect}
    className={`group flex cursor-pointer items-center rounded-lg px-4 py-3 transition-all duration-200 ease-in-out hover:bg-opacity-80 ${
      isSelected
        ? "bg-gray-100 dark:bg-gray-800"
        : "hover:bg-gray-50 dark:hover:bg-gray-900"
    }`}
  >
    <div
      className={`flex items-center justify-center rounded-full p-2 transition-colors ${
        isSelected
          ? "bg-gray-200 dark:bg-gray-700"
          : "bg-gray-100 group-hover:bg-gray-200 dark:bg-gray-800 dark:group-hover:bg-gray-700"
      }`}
    >
      <div className="text-gray-500 dark:text-gray-400">{widget.icon}</div>
    </div>
    <div className="ml-3">
      <h3 className="text-sm font-medium text-gray-900 dark:text-gray-100">
        {widget.type}
      </h3>
      <p className="mt-0.5 text-xs text-gray-500 dark:text-gray-400">
        {widget.description}
      </p>
    </div>
  </div>
);

export default ContextMenuItem;
