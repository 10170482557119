import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { useIntersectionObserver } from "@/hooks";
import {
  usePromptRegistryObjects,
  usePromptVersions,
  useWorkflows,
  useWorkflowVersions,
} from "@/queries";
import { ListPromptVersions } from "@/types";
import { Conditional, DynamicReleaseLabel } from "@/types/conditionals";
import {
  PaginatedPromptRegistryObjects,
  PromptRegistry,
} from "@/types/prompt-registry";
import {
  ExclamationCircleIcon,
  PlusIcon,
  XIcon,
} from "@heroicons/react/outline";
import { InfiniteData } from "@tanstack/react-query";
import { FC, useMemo, useRef, useState } from "react";
import LoadingSpinner from "./LoadingSpinner";
import {
  Workflow,
  WorkflowVersion,
  WorkflowVersionResponse,
} from "./Workflows/types";

type EditRegistryObjectsModalProps = {
  initialRegistryIds: number[];
  isOpen: boolean;
  labelName: string;
  setConditionals: (conditionals: any) => void;
  setOpen: (isOpen: boolean) => void;
  isWorkflow?: boolean;
};

const EditRegistryObjectsModal: FC<EditRegistryObjectsModalProps> = ({
  initialRegistryIds,
  isOpen,
  labelName,
  setConditionals,
  setOpen,
  isWorkflow = false,
}) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [selectedRegistryIds, setSelectedRegistryIds] =
    useState<number[]>(initialRegistryIds);
  const [selectedRegistry, setSelectedRegistry] = useState<any>(null);
  const authContext = useAuth();
  const userToken = authContext!.userToken!;
  const userContext = useUser();
  const workspaceId = userContext.activeWorkspaceId!;
  const ref = useRef<HTMLDivElement>(null);
  const entry = useIntersectionObserver(ref, {});
  const [hasChanges, setHasChanges] = useState(false);

  const workflowsQuery = useWorkflows(
    workspaceId.toString(),
    userToken,
    labelName,
  );
  const promptRegistryQuery = usePromptRegistryObjects(userToken, {
    workspaceId,
    promptLabelName: labelName,
  });

  const registryObjects = useMemo(() => {
    return isWorkflow ? workflowsQuery : promptRegistryQuery;
  }, [isWorkflow, workflowsQuery, promptRegistryQuery]);

  const workflowVersionsQuery = useWorkflowVersions(
    selectedRegistry?.id,
    userToken,
  );
  const promptVersionsQuery = usePromptVersions(userToken, {
    workspaceId,
    label: labelName,
    perPage: Number.MAX_SAFE_INTEGER,
  });

  const versionsQuery = useMemo<
    typeof workflowVersionsQuery | typeof promptVersionsQuery
  >(() => {
    return isWorkflow ? workflowVersionsQuery : promptVersionsQuery;
  }, [isWorkflow, workflowVersionsQuery, promptVersionsQuery]);

  const versions = useMemo(() => {
    if (isWorkflow) {
      return (
        (versionsQuery.data as WorkflowVersionResponse)?.workflow_versions || []
      );
    } else {
      return (
        (
          versionsQuery.data as InfiniteData<ListPromptVersions>
        )?.pages?.flatMap((page: ListPromptVersions) => page.items) || []
      );
    }
  }, [isWorkflow, versionsQuery.data]);

  const registryObjectsList = useMemo(() => {
    if (isWorkflow) {
      return (
        registryObjects.data?.pages?.flatMap(
          (page) =>
            (
              page as {
                success: boolean;
                workflows: Workflow[];
              }
            ).workflows,
        ) || []
      );
    } else {
      return (
        registryObjects.data?.pages?.flatMap(
          (page) => (page as PaginatedPromptRegistryObjects).items,
        ) || []
      );
    }
  }, [isWorkflow, registryObjects.data]);

  if (entry?.isIntersecting) {
    registryObjects.fetchNextPage();
  }

  const getVersionWithLabel = (registryId: number) => {
    const registry = registryObjectsList.find(
      (template) => template.id === registryId,
    );

    if (registry) {
      const filteredVersions = isWorkflow
        ? versions.filter(
            (version) =>
              (version as WorkflowVersion).workflow_id === registry.id,
          )
        : versions.filter(
            (version) => (version as any).prompt_id === registry.id,
          );
      for (const version of filteredVersions) {
        if (
          version.labels.some(
            (label: { name: string }) => label.name === labelName,
          )
        ) {
          return version;
        }
      }
    }

    throw new Error(
      `Error finding ${isWorkflow ? "workflow" : "prompt"} version.`,
    );
  };

  const handleAddRegistry = () => {
    if (selectedRegistry) {
      setSelectedRegistryIds((prev) => [...prev, selectedRegistry.id]);
      setSelectedRegistry(null);
      setHasChanges(true);
    }
  };

  const handleRemoveRegistry = (id: number) => {
    setSelectedRegistryIds((prev) => prev.filter((item) => item !== id));
    setHasChanges(true);
  };

  const handleSubmit = () => {
    try {
      const initialRegistryIdsSet = new Set(initialRegistryIds);
      const selectedRegistryIdsSet = new Set(selectedRegistryIds);

      const newRegistryIds = Array.from(selectedRegistryIdsSet).filter(
        (id) => !initialRegistryIdsSet.has(id),
      );

      const deletedRegistryIds = Array.from(initialRegistryIdsSet).filter(
        (id) => !selectedRegistryIdsSet.has(id),
      );

      setConditionals((prevConditionals: Conditional[]) =>
        prevConditionals.map((conditional) => ({
          ...conditional,
          dynamic_release_labels: conditional.dynamic_release_labels.filter(
            (label) =>
              !deletedRegistryIds.includes(
                isWorkflow
                  ? (label as any).workflow_registry_id
                  : label.prompt_registry_id,
              ),
          ),
        })),
      );

      setConditionals((prevConditionals: Conditional[]) =>
        prevConditionals.map((conditional) => {
          if (conditional.is_default) {
            const newDynamicReleaseLabels: DynamicReleaseLabel[] =
              newRegistryIds.map((registryId) => {
                const version = getVersionWithLabel(registryId);

                return {
                  is_real_label: true,
                  percentage: 100,
                  ...(isWorkflow
                    ? {
                        workflow_registry_id: registryId,
                        workflow_version_id: version.id,
                        workflow_version_number: version.number,
                      }
                    : {
                        prompt_registry_id: registryId,
                        prompt_version_id: version.id,
                        prompt_version_number: version.number,
                      }),
                } as DynamicReleaseLabel;
              });

            return {
              ...conditional,
              dynamic_release_labels: [
                ...conditional.dynamic_release_labels,
                ...newDynamicReleaseLabels,
              ],
            };
          }

          return conditional;
        }),
      );

      setOpen(false);
    } catch (error: unknown) {
      setErrorMessage(
        `Error updating ${isWorkflow ? "workflow" : "prompt"} templates`,
      );
    }
  };

  const isLoadingRegistryObjects = registryObjects.hasNextPage;

  return (
    <Dialog onOpenChange={setOpen} open={isOpen}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            Edit {isWorkflow ? "Workflow" : "Prompt"} Templates
          </DialogTitle>
          <DialogDescription>
            Select the {isWorkflow ? "workflow" : "prompt"} templates to include
            in this experiment.
          </DialogDescription>
        </DialogHeader>
        <div className="space-y-4">
          {isLoadingRegistryObjects && (
            <div ref={ref} className="mt-4 flex justify-center py-2">
              <LoadingSpinner size={5} />
            </div>
          )}
          {!isLoadingRegistryObjects && selectedRegistryIds.length > 0 && (
            <div className="flex max-h-[400px] flex-wrap gap-2 overflow-y-auto">
              {registryObjectsList
                .filter((registry) => selectedRegistryIds.includes(registry.id))
                .map((registry) => (
                  <div
                    key={registry.id}
                    className="group flex items-center rounded-full border border-gray-200 bg-gray-100 px-3 py-1 text-xs transition-colors duration-200 hover:bg-gray-200"
                  >
                    <span className="mr-0.5 break-all">
                      {isWorkflow
                        ? (registry as Workflow).name || "Unnamed Workflow"
                        : (registry as PromptRegistry).prompt_name}
                    </span>
                    <button
                      onClick={() => handleRemoveRegistry(registry.id)}
                      className="p-0.75 ml-2 flex-shrink-0 rounded-full transition-colors duration-200 hover:bg-gray-300"
                    >
                      <XIcon className="h-3.5 w-3.5 text-gray-400 transition-colors duration-200 group-hover:text-red-500" />
                    </button>
                  </div>
                ))}
            </div>
          )}
          <div className="space-y-2 pb-2">
            <label
              htmlFor="registry-select"
              className="block text-sm font-medium text-gray-700"
            >
              Available {isWorkflow ? "Workflow" : "Prompt"} Templates
            </label>
            <div className="flex items-center space-x-2">
              <DropdownMenu>
                <DropdownMenuTrigger className="w-full rounded-md border border-gray-300 bg-white px-3 py-2 text-left shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500">
                  {selectedRegistry ? (
                    isWorkflow ? (
                      selectedRegistry.name || "Unnamed Workflow"
                    ) : (
                      selectedRegistry.prompt_name
                    )
                  ) : (
                    <span className="text-gray-500">
                      Select a {isWorkflow ? "workflow" : "prompt"} template
                    </span>
                  )}
                </DropdownMenuTrigger>
                <DropdownMenuContent className="mt-1 w-full">
                  {registryObjectsList
                    .filter(
                      (registry) => !selectedRegistryIds.includes(registry.id),
                    )
                    .map((registry) => (
                      <DropdownMenuItem
                        key={registry.id}
                        onSelect={() => setSelectedRegistry(registry)}
                        className="cursor-pointer px-3 py-2 hover:bg-blue-50"
                      >
                        {isWorkflow
                          ? (registry as any).name || "Unnamed Workflow"
                          : (registry as PromptRegistry).prompt_name}
                      </DropdownMenuItem>
                    ))}
                  {registryObjectsList.filter(
                    (registry) => !selectedRegistryIds.includes(registry.id),
                  ).length === 0 && (
                    <DropdownMenuItem className="cursor-not-allowed px-3 py-2 italic text-gray-400">
                      All {isWorkflow ? "workflow" : "prompt"} templates have
                      been added.
                    </DropdownMenuItem>
                  )}
                </DropdownMenuContent>
              </DropdownMenu>
              <Button onClick={handleAddRegistry} disabled={!selectedRegistry}>
                <PlusIcon className="h-5 w-5" />
              </Button>
            </div>
          </div>
        </div>
        {isLoadingRegistryObjects && (
          <div ref={ref} className="mt-4 flex justify-center py-2">
            <LoadingSpinner size={5} />
          </div>
        )}
        {errorMessage && (
          <div className="mt-6 max-h-[500px] overflow-y-auto rounded-md border border-red-300 bg-red-50 p-4">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <ExclamationCircleIcon
                  className="h-6 w-6 text-red-500"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-4">
                <h3 className="text-lg font-medium text-red-800">Error</h3>
                <div className="mt-2 text-base text-red-700">
                  <p>{errorMessage}</p>
                </div>
              </div>
            </div>
          </div>
        )}
        <DialogFooter>
          <Button variant="secondary" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={selectedRegistryIds.length === 0 || !hasChanges}
          >
            Update Experiment
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default EditRegistryObjectsModal;
