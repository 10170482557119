import {
  ExclamationCircleIcon,
  PlusIcon,
  XIcon,
} from "@heroicons/react/outline";
import { FC, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { ToastType } from "@/enums";
import { useIntersectionObserver } from "@/hooks";
import {
  useCreateReleaseLabelGroup,
  useCreateWorkflowReleaseLabelGroup,
  usePromptRegistryObjects,
  useWorkflows,
} from "@/queries";
import { displayToast } from "@/utils/toast";
import moment from "moment";
import LoadingSpinner from "./LoadingSpinner";

type SelectPromptRegistryObjectsModalProps = {
  isOpen: boolean;
  selectedLabelName: string;
  selectedLabelType: "prompt" | "workflow";
  setOpen: (isOpen: boolean) => void;
};

const SelectPromptRegistryObjectsModal: FC<
  SelectPromptRegistryObjectsModalProps
> = ({
  isOpen,
  selectedLabelName: selectedPromptLabelName,
  setOpen,
  selectedLabelType,
}) => {
  const { workspaceId } = useParams();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [selectedRegistryIds, setSelectedRegistryIds] = useState<number[]>([]);
  const [selectedRegistry, setSelectedRegistry] = useState<any>(null);
  const authContext = useAuth();
  const navigate = useNavigate();
  const userToken = authContext!.userToken;
  const userContext = useUser();
  const { mutateAsync: createReleaseLabelGroup, isLoading } =
    useCreateReleaseLabelGroup(userToken!);

  const {
    mutateAsync: createWorkflowLabelGroup,
    isLoading: isLoadingWorkflow,
  } = useCreateWorkflowReleaseLabelGroup(userToken!);

  const ref = useRef<HTMLDivElement>(null);
  const entry = useIntersectionObserver(ref, {});
  const promptRegistryObjects = usePromptRegistryObjects(userToken!, {
    workspaceId: userContext.activeWorkspaceId!,
    promptLabelName: selectedPromptLabelName,
  });

  const { data } = useWorkflows(
    workspaceId!,
    userToken!,
    selectedPromptLabelName,
  );

  const workflowRegistryObjectsList =
    data?.pages.flatMap((page) => page.workflows) || [];

  console.log(data);

  const promptRegistryObjectsList =
    promptRegistryObjects.data?.pages.flatMap((page) => page.items) || [];

  const registryObjectsList =
    selectedLabelType === "workflow"
      ? workflowRegistryObjectsList
      : promptRegistryObjectsList;

  if (entry?.isIntersecting) {
    promptRegistryObjects.fetchNextPage();
  }

  const handleAddRegistry = () => {
    if (selectedRegistry) {
      setSelectedRegistryIds((prev) => [...prev, selectedRegistry.id]);
      setSelectedRegistry(null);
    }
  };

  const handleRemoveRegistry = (id: number) => {
    setSelectedRegistryIds((prev) => prev.filter((item) => item !== id));
  };

  const handleSubmit = async () => {
    try {
      let response;
      if (selectedLabelType === "workflow") {
        response = await createWorkflowLabelGroup({
          workflow_label_name: selectedPromptLabelName,
          workflow_ids: selectedRegistryIds,
          workspace_id: userContext.activeWorkspaceId!,
        });
      } else {
        response = await createReleaseLabelGroup({
          prompt_label_name: selectedPromptLabelName,
          prompt_registry_ids: selectedRegistryIds,
          workspace_id: userContext.activeWorkspaceId!,
        });
      }

      if (response.success) {
        displayToast("A/B release created!", ToastType.success);
        navigate(
          `/workspace/${userContext.activeWorkspaceId!}/ab-releases/${
            selectedLabelType === "workflow"
              ? `workflow-release-group/${response.workflow_release_label_group?.id}`
              : `prompt-release-group/${response.release_label_group?.id}`
          }`,
        );
      } else {
        setErrorMessage(response.error || "Error creating A/B release");
      }
    } catch (error: unknown) {
      console.log(error, "ereeee");
      setErrorMessage("Network error or bad server response");
    }
  };

  return (
    <Dialog onOpenChange={setOpen} open={isOpen}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            Add{" "}
            {selectedLabelType === "workflow"
              ? "Workflows"
              : "Prompt Templates"}
          </DialogTitle>
          <DialogDescription>
            Given the release label you just selected, the following are{" "}
            {selectedLabelType === "workflow"
              ? "workflows"
              : "prompt templates"}{" "}
            that have it applied. Select the ones to include in this experiment.
          </DialogDescription>
        </DialogHeader>
        <div className="space-y-4">
          {selectedRegistryIds.length > 0 && (
            <div className="grid grid-cols-3 gap-3">
              {registryObjectsList
                .filter((registry) => selectedRegistryIds.includes(registry.id))
                .map((registry) => (
                  <div
                    key={registry.id}
                    className="group relative cursor-pointer break-words rounded-md border border-gray-200 bg-white p-3 duration-200 ease-in-out hover:bg-red-50"
                    onClick={() => handleRemoveRegistry(registry.id)}
                  >
                    <div className="absolute right-1 top-1">
                      <XIcon className="hidden h-4 w-4 group-hover:block group-hover:text-red-500" />
                    </div>
                    <h3 className="mb-1 truncate text-sm font-semibold text-gray-800">
                      {selectedLabelType === "workflow"
                        ? (registry as any).name
                        : (registry as any).prompt_name}
                    </h3>
                    <p className="text-xs text-gray-500">
                      {moment((registry as any).last_version_created_at).format(
                        "MMMM DD, YYYY",
                      )}
                    </p>
                  </div>
                ))}
            </div>
          )}
          <div className="space-y-2 pb-2">
            <label
              htmlFor="registry-select"
              className="block text-sm font-medium text-gray-700"
            >
              Available{" "}
              {selectedLabelType === "workflow"
                ? "Workflows"
                : "Prompt Templates"}
            </label>
            <div className="flex items-center space-x-2">
              <DropdownMenu>
                <DropdownMenuTrigger className="w-full rounded-md border border-gray-300 bg-white px-3 py-2 text-left shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500">
                  {selectedRegistry ? (
                    selectedLabelType === "workflow" ? (
                      (selectedRegistry as any).name
                    ) : (
                      (selectedRegistry as any).prompt_name
                    )
                  ) : (
                    <span className="text-gray-500">
                      Select a{" "}
                      {selectedLabelType === "workflow"
                        ? "workflow"
                        : "prompt template"}
                    </span>
                  )}
                </DropdownMenuTrigger>
                <DropdownMenuContent className="mt-1 w-full">
                  {registryObjectsList
                    .filter(
                      (registry) => !selectedRegistryIds.includes(registry.id),
                    )
                    .map((registry) => (
                      <DropdownMenuItem
                        key={registry.id}
                        onSelect={() => setSelectedRegistry(registry)}
                        className="cursor-pointer px-3 py-2 hover:bg-blue-50"
                      >
                        {selectedLabelType === "workflow"
                          ? (registry as any).name
                          : (registry as any).prompt_name}
                      </DropdownMenuItem>
                    ))}
                  {registryObjectsList.filter(
                    (registry) => !selectedRegistryIds.includes(registry.id),
                  ).length === 0 && (
                    <DropdownMenuItem className="cursor-not-allowed px-3 py-2 italic text-gray-400">
                      All{" "}
                      {selectedLabelType === "workflow"
                        ? "workflows"
                        : "prompt templates"}{" "}
                      have been added.
                    </DropdownMenuItem>
                  )}
                </DropdownMenuContent>
              </DropdownMenu>
              <Button onClick={handleAddRegistry} disabled={!selectedRegistry}>
                <PlusIcon className="h-5 w-5" />
              </Button>
            </div>
          </div>
        </div>
        {promptRegistryObjects.hasNextPage && (
          <div ref={ref} className="mt-4 flex justify-center py-2">
            <LoadingSpinner size={5} />
          </div>
        )}
        {errorMessage && (
          <div className="mt-6 max-h-[500px] overflow-y-auto rounded-md border border-red-300 bg-red-50 p-4">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <ExclamationCircleIcon
                  className="h-6 w-6 text-red-500"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-4">
                <h3 className="text-lg font-medium text-red-800">Error</h3>
                <div className="mt-2 text-base text-red-700">
                  <p>{errorMessage}</p>
                </div>
              </div>
            </div>
          </div>
        )}
        <DialogFooter>
          <Button variant="secondary" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button
            isLoading={isLoading || isLoadingWorkflow}
            onClick={handleSubmit}
            disabled={selectedRegistryIds.length === 0}
          >
            Build Experiment
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default SelectPromptRegistryObjectsModal;
