import { IWorkflow } from "../../WorkflowCanvas/workflow-store";
import AddReleaseLabelButton from "./AddReleaseLabelButton";
interface WorkflowAddReleaseLabelButtonProps {
  workflow: IWorkflow;
  versionId: number;
  refreshVersions: () => void;
}
const WorkflowAddReleaseLabelButton = ({
  workflow,
  versionId,
  refreshVersions,
}: WorkflowAddReleaseLabelButtonProps) => {
  return (
    <AddReleaseLabelButton
      onSave={async (labelName) => {
        await workflow.addReleaseLabel(labelName, versionId);
        refreshVersions();
      }}
    />
  );
};

export default WorkflowAddReleaseLabelButton;
