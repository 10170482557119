import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { ContainsConfiguration, ReportColumn } from "@/types/evaluate";
import { formatInputVariable } from "@/utils/evaluate";
import { useState } from "react";
import { useBandaid } from "../../utils/BandaidContext";
import { ModalStep } from "../ModalRouter";

const ContainsBuilder = ({
  columnData,
  availableColumns,
  navigatePrevious,
  saveColumnAction,
  editable,
}: {
  columnData: Partial<ReportColumn>;
  availableColumns: ReportColumn[];
  navigatePrevious: () => void;
  saveColumnAction: (newColumnDataArg: ReportColumn) => void;
  editable: boolean;
}) => {
  const [name, setName] = useState<string | null>(columnData.name || null);
  const [value, setValue] = useState<string | null>(
    columnData.configuration?.value ?? null,
  );
  const [column, setColumn] = useState<string | null>(
    columnData.configuration?.column || null,
  );

  const [dataIsValid, setDataIsValid] = useState<{
    name: boolean;
    value: boolean;
    column: boolean;
  }>({
    name: true,
    value: true,
    column: true,
  });

  const saveEndpointAction = () => {
    if (!name) {
      setDataIsValid({ name: false, value: true, column: true });
      return;
    } else if (!value) {
      setDataIsValid({ name: true, value: false, column: true });
      return;
    } else if (!column) {
      setDataIsValid({ name: true, value: true, column: false });
      return;
    }

    setDataIsValid({ name: true, value: true, column: true });

    saveColumnAction({
      ...columnData,
      name: name,
      configuration: {
        value,
        column,
      } as ContainsConfiguration,
    } as ReportColumn);
  };

  const bandaid = useBandaid();

  return (
    <ModalStep
      navigatePrevious={navigatePrevious}
      navigateNext={saveEndpointAction}
      nextButtonText={editable ? "Save Step" : "Done"}
    >
      <div className="grid grid-cols-3 items-center gap-4">
        <div className="col-span-3">
          <div className="text-lg font-semibold">Configure Contains</div>
          <div className="max-w-md text-sm text-gray-500">
            This step will check if a {bandaid ? "node" : "column"} contains a
            specific value (case insensitive).
          </div>
        </div>
        <div style={{ display: bandaid ? "none" : "contents" }}>
          <label htmlFor="name" className="col-span-1">
            Column name:
          </label>
          <input
            id="name"
            className={`col-span-2 rounded border ${
              !dataIsValid.name ? "border-red-500" : "border-gray-300"
            } px-2 py-1 disabled:cursor-not-allowed disabled:bg-gray-50`}
            value={name || ""}
            onKeyDown={(e) => {
              if (e.key === " ") {
                // Only prevent default and handle manually for spaces
                e.preventDefault();
                setName(name + " ");
              }
              // Let all other keys (including backspace) work normally
            }}
            onChange={(e) => setName(e.target.value)}
            disabled={!editable}
          />
        </div>
        {!dataIsValid.name && (
          <>
            <div className="col-span-1 -mt-4">&nbsp;</div>
            <div className="col-span-2 -mt-4 text-left text-xs text-red-500">
              Name is required
            </div>
          </>
        )}

        <label htmlFor="json-path" className="col-span-1">
          Value:
        </label>
        <input
          id="json-path"
          className={`col-span-2 rounded border ${
            !dataIsValid.value ? "border-red-500" : "border-gray-300"
          } px-2 py-1 disabled:cursor-not-allowed disabled:bg-gray-50`}
          value={value || ""}
          onChange={(e) => setValue(e.target.value)}
          disabled={!editable}
        />
        {!dataIsValid.value && (
          <>
            <div className="col-span-1 -mt-4">&nbsp;</div>
            <div className="col-span-2 -mt-4 text-left text-xs text-red-500">
              Value is required
            </div>
          </>
        )}

        <label htmlFor="json-path" className="col-span-1">
          {bandaid ? "Node:" : "Column:"}
        </label>
        <DropdownMenu>
          <DropdownMenuTrigger
            disabled={!editable}
            className="col-span-2 w-full"
          >
            {column || (
              <span className="font-normal text-gray-500">
                Select a {bandaid ? "node" : "column"}...
              </span>
            )}
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            {availableColumns.map((column) => (
              <DropdownMenuItem
                key={column.name}
                onSelect={() => setColumn(column.name)}
              >
                {formatInputVariable(column.column_type, column.name)}
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
        {!dataIsValid.column && (
          <>
            <div className="col-span-1 -mt-4">&nbsp;</div>
            <div className="col-span-2 -mt-4 text-left text-xs text-red-500">
              {bandaid ? "Node" : "Column"} is required for this step
            </div>
          </>
        )}
      </div>
    </ModalStep>
  );
};

export default ContainsBuilder;
