import NodeErrors from "./NodeErrors";
import NodeOutputs from "./NodeOutputs";

const NodeFooter = () => {
  return (
    <div className="flex flex-col">
      <NodeErrors />
      <NodeOutputs />
    </div>
  );
};

export default NodeFooter;
