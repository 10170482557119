const ShimmerEffect: React.FC = () => {
  return (
    <div className="flex animate-pulse flex-col rounded-lg border bg-white shadow-sm">
      <div className="p-6">
        <div className="flex items-center space-x-3">
          <div className="h-6 w-6 rounded bg-gray-200"></div>
          <div className="h-4 w-2/3 rounded bg-gray-200"></div>
        </div>
        <div className="mt-1 h-3 w-1/3 rounded bg-gray-200"></div>
      </div>
      <div className="border-t border-gray-100 bg-gray-50 px-6 py-4">
        <div className="h-4 w-3/4 rounded bg-gray-200"></div>
      </div>
    </div>
  );
};

export default ShimmerEffect;
