import logoCakeColor from "@/assets/logo/promptlayer-cake-color.svg";
import logoCake from "@/assets/logo/promptlayer-cake.svg";
import logoLong from "@/assets/logo/promptlayer-long.png";
import Refresh from "@/components/SidebarSearchLayout/components/RefreshButton";
import Settings from "@/components/SidebarSearchLayout/components/Settings";
import { useIsCurrentPageNumber } from "@/components/SidebarSearchLayout/hooks/useIsCurrentPageNumber";
import { useKeepRelevantParams } from "@/components/SidebarSearchLayout/hooks/useKeepRelevantParams";
import { WorkspaceSelector } from "@/components/WorkspaceSelector";
import { NAVIGATION } from "@/constants";
import { useUser } from "@/context/user-context";
import { classNames } from "@/utils/strings";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import { Dispatch, SetStateAction } from "react";
import { Link, useNavigate } from "react-router-dom";

const Header = ({
  sidebarCollapsed,
  setSidebarCollapsed,
  requestsOn,
}: {
  sidebarCollapsed: boolean;
  setSidebarCollapsed: Dispatch<SetStateAction<boolean>>;
  requestsOn: boolean;
}) => {
  const navigate = useNavigate();

  return (
    <div className="bg-blue fixed hidden w-full sm:block">
      <div className="flex items-center">
        <div
          className={`flex flex-shrink-0 items-center px-3 text-gray-700 transition-all duration-100 ease-in-out ${
            sidebarCollapsed ? "w-20" : "w-80"
          }`}
        >
          {sidebarCollapsed ? (
            <img
              src={logoCake}
              alt="PromptLayer Logo"
              className="h-10 w-10 cursor-pointer transition duration-300 ease-in-out hover:animate-spin hover:text-gray-600"
              onClick={() => navigate("/")}
              onMouseOver={(e) => (e.currentTarget.src = logoCakeColor)}
              onMouseOut={(e) => (e.currentTarget.src = logoCake)}
            />
          ) : (
            <div className="flex-1 text-xl font-bold tracking-wide">
              <span
                className="cursor-pointer hover:text-gray-600"
                onClick={() => navigate("/")}
              >
                <img
                  src={logoLong}
                  alt="PromptLayer Logo"
                  width={180}
                  className="transition duration-100 ease-in-out"
                />
              </span>
            </div>
          )}
          <button
            className="mr-1 rounded-md p-2 hover:bg-gray-200 focus:outline-none"
            onClick={() => setSidebarCollapsed(!sidebarCollapsed)}
          >
            {sidebarCollapsed ? (
              <ChevronRightIcon className="h-5 w-5" />
            ) : (
              <ChevronLeftIcon className="h-5 w-5" />
            )}
          </button>
          <Refresh
            isSidebarCollapsed={sidebarCollapsed}
            requestsOn={requestsOn}
          />
        </div>
        <Navbar />
      </div>
    </div>
  );
};

const Navbar = () => {
  const userContext = useUser();
  const keepRelevantParams = useKeepRelevantParams();
  const isCurrentPageName = useIsCurrentPageNumber();
  return (
    <nav className="flex w-full items-center px-8 lg:py-4" aria-label="Global">
      <div className="flex flex-1 space-x-4 2xl:space-x-8">
        <WorkspaceSelector />
        {NAVIGATION.map((item) => {
          let link = `/workspace/${userContext!.activeWorkspace?.id}/${
            item.href
          }/`;
          return (
            <Link key={item.name} to={`${link}?${keepRelevantParams()}`}>
              <button
                className={classNames(
                  isCurrentPageName(item)
                    ? "bg-gray-200 text-black hover:text-gray-900"
                    : "font-normal text-gray-800 hover:bg-gray-50 hover:text-gray-900",
                  "inline-flex cursor-pointer items-center rounded-md px-3 py-2 text-sm",
                )}
              >
                <item.icon
                  className="mr-2 h-5 w-auto text-gray-700"
                  aria-hidden="true"
                />
                {item.name}
              </button>
            </Link>
          );
        })}
      </div>
      {/* Settings dropdown */}
      <Settings />
    </nav>
  );
};

export default Header;
