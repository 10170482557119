import { WorkflowNodeStatus } from "@/components/Workflows/types";
import { motion } from "framer-motion";
import React from "react";

interface StatusConfig {
  color: string;
  bgColor: string;
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  label: string;
}

const AnimatedIcon: React.FC<{
  path: string;
  animation: Record<string, any>;
  transition: Record<string, any>;
}> = ({ path, animation, transition }) => (
  <motion.svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="h-4 w-4"
    animate={animation}
    transition={transition}
  >
    <path strokeLinecap="round" strokeLinejoin="round" d={path} />
  </motion.svg>
);

const statusConfig: Record<WorkflowNodeStatus, StatusConfig> = {
  QUEUED: {
    color: "text-yellow-600",
    bgColor: "bg-yellow-100",
    Icon: () => (
      <AnimatedIcon
        path="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
        animation={{ rotate: 360 }}
        transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
      />
    ),
    label: "Queued",
  },
  RUNNING: {
    color: "text-blue-600",
    bgColor: "bg-blue-100",
    Icon: () => (
      <motion.svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="h-4 w-4 animate-spin"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
        />
      </motion.svg>
    ),
    label: "Running",
  },
  SUCCESS: {
    color: "text-green-600",
    bgColor: "bg-green-100",
    Icon: () => (
      <AnimatedIcon
        path="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        animation={{ scale: [1, 1.1, 1] }}
        transition={{ duration: 0.3 }}
      />
    ),
    label: "Completed",
  },
  FAILED: {
    color: "text-red-600",
    bgColor: "bg-red-100",
    Icon: () => (
      <AnimatedIcon
        path="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
        animation={{ scale: [1, 1.1, 1] }}
        transition={{ duration: 0.3 }}
      />
    ),
    label: "Failed",
  },
};

const defaultConfig: StatusConfig = {
  color: "text-gray-600",
  bgColor: "bg-gray-100",
  Icon: () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="h-4 w-4"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
      />
    </svg>
  ),
  label: "Unknown",
};

const StatusText: React.FC<{ status?: WorkflowNodeStatus | string }> = ({
  status,
}) => {
  if (!status) return null;
  const { color, bgColor, Icon, label } = statusConfig[
    status as WorkflowNodeStatus
  ] || { ...defaultConfig, label: status };

  return (
    <span
      className={`pointer-events-none z-[0] inline-flex items-center font-semibold ${color} ${bgColor} rounded-full px-3 py-1.5 text-sm shadow-sm transition-all duration-200 ease-in-out hover:bg-opacity-75`}
    >
      <Icon />
      <span className="ml-1.5">{label}</span>
    </span>
  );
};

export default StatusText;
