import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { useCallback } from "react";
import { useExamples } from "../example-widget-store";

const DataTypeSelect = observer(({ isReadOnly }: { isReadOnly: boolean }) => {
  const store = useExamples();
  const handleDataTypeChange = useCallback(
    (value: string) => {
      runInAction(() => {
        store.setDataType(value.toLowerCase());
      });
    },
    [store],
  );

  return (
    <Select
      onValueChange={handleDataTypeChange}
      value={store.dataType}
      disabled={isReadOnly}
    >
      <SelectTrigger className="w-full bg-white">
        <SelectValue placeholder="Select Data Type">
          {store.dataType.toUpperCase() || (
            <span className="text-muted-foreground">Select a dataset</span>
          )}
        </SelectValue>
      </SelectTrigger>
      <SelectContent>
        <SelectItem value="json">JSON</SelectItem>
        <SelectItem value="xml">XML</SelectItem>
      </SelectContent>
    </Select>
  );
});

export default DataTypeSelect;
