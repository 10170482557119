import { PlusIcon } from "@heroicons/react/outline";
import { ArrowRightIcon } from "@heroicons/react/solid";
import { Handle as FlowHandle, HandleProps } from "@xyflow/react";
import { useCallback, useState } from "react";

const Handle = (props: HandleProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const isSource = props.type === "source";
  const baseColor = isSource
    ? "bg-gray-200 border-gray-300"
    : "border-blue-300";
  const hoverColor = isSource
    ? "bg-gray-400 border-gray-500"
    : "border-blue-500";
  const color = isHovered ? hoverColor : baseColor;

  const handleMouseEnter = useCallback(() => setIsHovered(true), []);
  const handleMouseLeave = useCallback(() => setIsHovered(false), []);

  const tooltipText = isSource ? "Add connection" : "Connect here";

  return (
    <>
      <FlowHandle
        onMouseDown={(e) => e.preventDefault()} // fixes bug that makes canvas draggable
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={`
          ${color}
          group h-6
          w-6
          cursor-pointer
          border-2
          ${isSource ? "" : "bg-transparent"}
          absolute
          z-[1]
          transform
          rounded-full
          transition-all duration-200
          ease-in-out
          hover:scale-110
          focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2
          ${props.position === "left" ? "-left-3 top-1/2 -translate-y-1/2" : ""}
          ${
            props.position === "right"
              ? "-right-3 top-1/2 -translate-y-1/2"
              : ""
          }
          ${props.position === "top" ? "-top-3" : ""}
          ${props.position === "bottom" ? "-bottom-3" : ""}
          flex items-center justify-center
        `}
        {...props}
      >
        {!isSource ? (
          <ArrowRightIcon className="pointer-events-none h-3 w-3 text-gray-600" />
        ) : (
          <PlusIcon className="pointer-events-none h-3 w-3 text-blue-600" />
        )}
        <div className="pointer-events-none absolute top-8 whitespace-nowrap rounded bg-gray-800 px-2 py-1 text-xs text-white opacity-0 transition-opacity duration-300 group-hover:opacity-100">
          {tooltipText}
        </div>
      </FlowHandle>
    </>
  );
};

export default Handle;
