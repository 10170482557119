import { DivButton } from "@/components/ui/button";
import { Input } from "@/components/ui/input";

import { Trash2Icon } from "lucide-react";
import { observer } from "mobx-react-lite";

interface InputVariableItemProps {
  id: string;
  keyProp: string;
  value: string;
  onUpdate: (id: string, field: "key" | "value", newValue: string) => void;
  onRemove: (id: string) => void;
}

const InputVariableItem = observer(
  ({ id, keyProp, value, onUpdate, onRemove }: InputVariableItemProps) => {
    return (
      <div className="flex items-center space-x-4 rounded-lg border border-gray-200 bg-gray-50 p-4 shadow-sm transition-all duration-200 hover:border-blue-300 hover:bg-blue-50">
        <Input
          type="text"
          value={keyProp}
          onChange={(e) => onUpdate(id, "key", e.target.value)}
          placeholder="Variable name"
          className="w-1/3 rounded-md border-gray-300 bg-white p-2 font-mono text-sm text-gray-800 focus:border-blue-500 focus:ring-2 focus:ring-blue-500"
        />
        <Input
          type="text"
          value={value}
          onChange={(e) => onUpdate(id, "value", e.target.value)}
          placeholder="Variable value"
          className="flex-1 rounded-md border-gray-300 bg-white p-2 font-mono text-sm text-gray-800 focus:border-blue-500 focus:ring-2 focus:ring-blue-500"
        />
        <DivButton
          variant="ghost"
          onClick={() => onRemove(id)}
          className="rounded-full p-2 text-gray-500 hover:bg-red-100 hover:text-red-600"
        >
          <Trash2Icon size={20} />
        </DivButton>
      </div>
    );
  },
);

export default InputVariableItem;
