import { WorkflowNodeType } from "@/components/Workflows/types";
import { ColumnType, ColumnTypeIcon } from "@/types/evaluate";
interface NodeTypeIconProps {
  type: WorkflowNodeType;
  backgroundColor: string;
  textColor: string;
}
const NodeTypeIcon: React.FC<NodeTypeIconProps> = ({
  type,
  backgroundColor,
  textColor,
}) => {
  const TypeIcon =
    type &&
    (ColumnTypeIcon[type as unknown as ColumnType] as React.ElementType);
  if (!TypeIcon) return null;
  return (
    <TypeIcon
      className={`h-6 w-6 rounded-full ${backgroundColor} p-1 ${textColor} transition-all duration-300 ease-in-out`}
    />
  );
};

export default NodeTypeIcon;
