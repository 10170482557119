import { useCallback, useEffect, useState } from "react";

const useCompositionHandling = (ref: React.RefObject<HTMLDivElement>) => {
  const [isComposing, setIsComposing] = useState(false);
  const handleCompositionStart = useCallback(() => setIsComposing(true), []);
  const handleCompositionEnd = useCallback(() => setIsComposing(false), []);

  useEffect(() => {
    const currentRef = ref.current;
    if (currentRef) {
      currentRef.addEventListener("compositionstart", handleCompositionStart);
      currentRef.addEventListener("compositionend", handleCompositionEnd);
    }
    return () => {
      if (currentRef) {
        currentRef.removeEventListener(
          "compositionstart",
          handleCompositionStart,
        );
        currentRef.removeEventListener("compositionend", handleCompositionEnd);
      }
    };
  }, [handleCompositionEnd, handleCompositionStart, ref]);

  return { isComposing, handleCompositionStart, handleCompositionEnd };
};

export default useCompositionHandling;
