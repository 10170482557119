import { useReactFlow, useViewport } from "@xyflow/react";
import { useCallback, useState } from "react";
import BasicNode from "../Node";

const useNodePreview = (isConnecting: boolean) => {
  const { screenToFlowPosition } = useReactFlow();
  const { x, y, zoom } = useViewport();

  const [previewNode, setPreviewNode] = useState<{
    x: number;
    y: number;
  } | null>(null);

  const onMouseMove = useCallback(
    (event: React.MouseEvent) => {
      if (isConnecting) {
        const { clientX, clientY } = event;
        const position = screenToFlowPosition({ x: clientX, y: clientY });
        setPreviewNode(position);
      } else {
        setPreviewNode(null);
      }
    },
    [isConnecting, screenToFlowPosition],
  );

  const NodePreview = useCallback(
    () =>
      (isConnecting && previewNode && (
        <div
          style={{
            position: "absolute",
            left: previewNode.x * zoom + x,
            top: previewNode.y * zoom + y,
            transform: `translate(-50%, -50%) scale(${zoom})`,
            opacity: 0.6,
            pointerEvents: "none",
          }}
        >
          <BasicNode
            data={{ label: "" }}
            isConnectable={false}
            preview
            id={"node_preview"}
            type={""}
            dragging={false}
            zIndex={0}
            positionAbsoluteX={0}
            positionAbsoluteY={0}
          />
        </div>
      )) ||
      null,
    [isConnecting, previewNode, zoom, x, y],
  );

  return [NodePreview, onMouseMove] as const;
};

export default useNodePreview;
