import { useIntersectionObserver } from "@/hooks";
import { useRef, useEffect } from "react";

export const useInfiniteScroll = (fetchNextPage: () => void) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(ref, {});
  const isVisible = !!entry?.isIntersecting;

  useEffect(() => {
    if (isVisible) {
      fetchNextPage();
    }
  }, [isVisible, fetchNextPage]);

  return ref;
};
