import { FC } from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { useUser } from "@/context/user-context";

type ExperimentModalProps = {
  releaseLabelGroupId: number;
  setOpen: (isOpen: boolean) => void;
  promptId?: string;
};

const ExperimentModal: FC<ExperimentModalProps> = ({
  releaseLabelGroupId,
  setOpen,
  promptId,
}) => {
  const navigate = useNavigate();
  const userContext = useUser();

  const handleClick = () => {
    navigate(
      `/workspace/${userContext.activeWorkspaceId!}/ab-releases/prompt-release-group/${releaseLabelGroupId}?prompt=${promptId}`,
    );
  };

  return (
    <Dialog onOpenChange={setOpen} open={true}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Experiment</DialogTitle>
          <DialogDescription>
            This is a dynamic release label. A portion of traffic is being
            re-routed away from the original release label. Continue to
            configure the experiment.
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <Button
            className="bg-transparent text-sm"
            onClick={() => setOpen(false)}
            variant="outline"
          >
            Cancel
          </Button>
          <Button onClick={handleClick}>Go to Experiment</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ExperimentModal;
