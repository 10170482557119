import FilterInput from "@/components/ui/table/Filter/FilterInput";
import {
  useColumnSearch,
  useSearch,
} from "@/components/ui/table/Search/search-context";
import { useCollapsibleColumnContext } from "@/components/ui/table/collapsible-columns/useCollapsibleColumns";
import { useAuth } from "@/context/auth-context";
import {
  useDeleteReportColumn,
  useDuplicateReportColumn,
  usePatchReportColumn,
} from "@/queries";
import { ColumnType, ReportColumn } from "@/types/evaluate";
import { displayErrorToast } from "@/utils/toast";
import { Menu, Transition } from "@headlessui/react";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  DocumentDuplicateIcon,
  ExclamationIcon,
  EyeIcon,
  FilterIcon,
  LinkIcon,
  PencilIcon,
  SparklesIcon,
  XIcon,
} from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  EyeClosedIcon,
} from "@radix-ui/react-icons";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useModalRouter } from "../modal-router-context";
import { SortConfigItem, useReportContext } from "../report-context";

interface HeaderCellProps {
  column: ReportColumn;
  reportId: number;
  availableColumns: ReportColumn[];
  isEditable: boolean;
  setShowFilterInput?: (value: boolean) => void;
}

const HeaderIcon: React.FC<{ columnType: ColumnType }> = ({ columnType }) => {
  switch (columnType) {
    case ColumnType.ENDPOINT:
      return <LinkIcon className="mr-1 inline-block h-4 w-4 text-gray-600" />;
    case ColumnType.PROMPT_TEMPLATE:
      return (
        <SparklesIcon className="mr-1 inline-block h-4 w-4 text-gray-600" />
      );
    default:
      return null;
  }
};

const HeaderContent: React.FC<{ column: ReportColumn }> = ({ column }) => (
  <div className="flex h-full w-full cursor-text items-center justify-center gap-x-1 text-center font-semibold text-gray-700">
    <HeaderIcon columnType={column.column_type} />
    {column.name}
  </div>
);

const DatasetColumnCell: React.FC<{ column: ReportColumn }> = ({ column }) => (
  <div className="flex h-full w-full flex-row items-center justify-between">
    <div className="text-center font-mono">{`{${column.name}}`}</div>
  </div>
);

const ErrorColumnCell: React.FC<{ column: ReportColumn }> = ({ column }) => (
  <div className="flex h-full w-full flex-col items-center justify-center gap-y-2 rounded-md border border-red-300 p-2 hover:border-red-400 hover:bg-red-50 hover:shadow-sm">
    <div className="flex items-center">
      <HeaderContent column={column} />
    </div>
    <div className="rounded border border-red-500 bg-red-500 px-2 py-1 text-sm font-normal text-white">
      <ExclamationIcon className="mr-1 inline-block h-4 w-4" />
      {column.errors![0].message}
    </div>
  </div>
);

const DefaultColumnCell: React.FC<{ column: ReportColumn }> = ({ column }) => (
  <div className="flex h-full w-full flex-row justify-between">
    <div className="pointer-events-none flex h-full w-full flex-col items-start justify-center gap-y-2">
      <div className="flex items-start border-b-2 border-gray-500">
        <HeaderContent column={column} />
      </div>
    </div>
  </div>
);

const Cell: React.FC<{ column: ReportColumn }> = ({ column }) => {
  if (column.column_type === ColumnType.DATASET) {
    return <DatasetColumnCell column={column} />;
  } else if (column.errors) {
    return <ErrorColumnCell column={column} />;
  } else {
    return <DefaultColumnCell column={column} />;
  }
};

const DropdownMenuItems: React.FC<
  HeaderCellProps & { setModalOpen: (open: boolean) => void }
> = ({
  column,
  reportId,
  availableColumns,
  isEditable,
  setShowFilterInput,
  setModalOpen,
}) => {
  const { toggleColumn } = useCollapsibleColumnContext();
  const { enabled } = useSearch();
  const userToken = useAuth()?.userToken || "";
  const { mutateAsync: duplicateColumn } = useDuplicateReportColumn(
    userToken,
    reportId,
  );
  const { mutate: patchColumn } = usePatchReportColumn(userToken, reportId);
  const { mutate: deleteColumn } = useDeleteReportColumn(userToken, reportId);

  const handleDuplicateColumn = async () => {
    if (column.id && column.position !== undefined) {
      try {
        const response = await duplicateColumn(column.id);
        if (!response.success) {
          displayErrorToast(
            response.message || "There was an error duplicating the column",
          );
        }
      } catch (error) {
        displayErrorToast("There was an error duplicating the column");
      }
    }
  };

  const handleMoveColumn = (direction: "left" | "right") => {
    if (column.id && column.position !== undefined) {
      const newPosition =
        direction === "left" ? column.position - 1 : column.position + 1;
      column.position = newPosition;
      patchColumn(column);
    }
  };

  const handleDeleteColumn = () => {
    if (column.id && column.id !== undefined) {
      if (window.confirm("Are you sure you want to delete this column?")) {
        deleteColumn(column.id);
      }
    }
  };
  const { sortConfig, sortColumn } = useReportContext();

  const handleSort = useCallback(
    (direction: "asc" | "desc") => {
      return (event: React.MouseEvent, close: () => void) => {
        event.preventDefault();
        event.stopPropagation();
        const isSorted = sortConfig.find(
          (item: SortConfigItem) => item.columnId === column.id,
        );
        if (isSorted && isSorted.direction === direction) {
          sortColumn(column.id, null);
        } else {
          sortColumn(column.id, direction);
        }
        close();
      };
    },
    [column.id, sortConfig, sortColumn],
  );

  const availablePreviousColumns = availableColumns.filter(
    (c) => c.position < column.position,
  );

  return (
    <>
      {!isEditable && column.column_type !== ColumnType.DATASET && (
        <Menu.Item>
          {({ active }) => (
            <button
              className={`${
                active ? "bg-gray-100 text-gray-900" : "text-gray-700"
              } group flex w-full items-center rounded-md px-2 py-2 text-sm transition-colors duration-150`}
              onClick={() => setModalOpen(true)}
            >
              <EyeIcon className="mr-2 h-5 w-5" aria-hidden="true" />
              Inspect
            </button>
          )}
        </Menu.Item>
      )}

      {isEditable && (
        <Menu.Item>
          {({ active }) => (
            <button
              className={`${
                active ? "bg-gray-100 text-gray-900" : "text-gray-700"
              } group flex w-full items-center rounded-md px-2 py-2 text-sm transition-colors duration-150 hover:bg-gray-100 hover:text-gray-900`}
              onClick={() => setModalOpen(true)}
            >
              <PencilIcon className="mr-2 h-5 w-5" aria-hidden="true" />
              Edit
            </button>
          )}
        </Menu.Item>
      )}

      {isEditable && (
        <Menu.Item>
          {({ active }) => (
            <button
              className={`${
                active ? "bg-gray-100 text-gray-900" : "text-gray-700"
              } group flex w-full items-center rounded-md px-2 py-2 text-sm transition-colors duration-150 hover:bg-gray-100 hover:text-gray-900`}
              onClick={handleDuplicateColumn}
            >
              <DocumentDuplicateIcon
                className="mr-2 h-5 w-5"
                aria-hidden="true"
              />
              Duplicate
            </button>
          )}
        </Menu.Item>
      )}

      <Menu.Item>
        {({ active }) => (
          <button
            className={`${
              active ? "bg-gray-100 text-gray-900" : "text-gray-700"
            } group flex w-full items-center rounded-md px-2 py-2 text-sm transition-colors duration-150`}
            onClick={() => toggleColumn(column.name)}
          >
            <EyeClosedIcon className="mr-2 h-5 w-5" aria-hidden="true" />
            Hide
          </button>
        )}
      </Menu.Item>

      {isEditable && (
        <Menu.Item>
          {({ active }) => (
            <button
              className={`${
                active ? "bg-gray-100 text-gray-900" : "text-gray-700"
              } group flex w-full items-center rounded-md px-2 py-2 text-sm transition-colors duration-150`}
              onClick={handleDeleteColumn}
            >
              <XIcon className="mr-2 h-5 w-5 text-red-500" aria-hidden="true" />
              Delete
            </button>
          )}
        </Menu.Item>
      )}

      {isEditable && (
        <>
          {column &&
            availablePreviousColumns.length > 0 &&
            availablePreviousColumns[availablePreviousColumns.length - 1]
              .column_type !== ColumnType.DATASET && (
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700"
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm transition-colors duration-150`}
                    onClick={() => handleMoveColumn("left")}
                  >
                    <ArrowLeftIcon
                      className="mr-2 h-5 w-5"
                      aria-hidden="true"
                    />
                    Move left
                  </button>
                )}
              </Menu.Item>
            )}
          {availableColumns.length > 0 &&
            availableColumns.indexOf(column) < availableColumns.length - 1 && (
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700"
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm transition-colors duration-150`}
                    onClick={() => handleMoveColumn("right")}
                  >
                    <ArrowRightIcon
                      className="mr-2 h-5 w-5"
                      aria-hidden="true"
                    />
                    Move right
                  </button>
                )}
              </Menu.Item>
            )}
        </>
      )}
      {enabled && (
        <>
          <Menu.Item>
            {({ active }) => (
              <button
                className={`${
                  active ? "bg-gray-100 text-gray-900" : "text-gray-700"
                } group flex w-full items-center rounded-md px-2 py-2 text-sm transition-colors duration-150`}
                onClick={() => setShowFilterInput!(true)}
              >
                <FilterIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                Filter
              </button>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active, close }) => (
              <button
                className={`${
                  active ? "bg-gray-100 text-gray-900" : "text-gray-700"
                } group flex w-full items-center rounded-md px-2 py-2 text-sm transition-colors duration-150`}
                onClick={(event) => handleSort("asc")(event, close)}
              >
                <ArrowUpIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                Sort ascending
              </button>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active, close }) => (
              <button
                className={`${
                  active ? "bg-gray-100 text-gray-900" : "text-gray-700"
                } group flex w-full items-center rounded-md px-2 py-2 text-sm transition-colors duration-150`}
                onClick={(event) => handleSort("desc")(event, close)}
              >
                <ArrowDownIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                Sort descending
              </button>
            )}
          </Menu.Item>
        </>
      )}
    </>
  );
};

const HeaderCell: React.FC<HeaderCellProps> = (props) => {
  const [, showFilterInput, setShowFilterInput] = useColumnSearch(
    props.column?.id!.toString(),
  );
  const { setIsOpen, setSelectedColumn } = useModalRouter();
  const menuRef = useRef<HTMLDivElement>(null);
  const [menuPosition, setMenuPosition] = useState<"bottom" | "top">("bottom");

  useEffect(() => {
    const updatePosition = () => {
      if (menuRef.current) {
        const rect = menuRef.current.getBoundingClientRect();
        const spaceBelow = window.innerHeight - rect.bottom;
        setMenuPosition(spaceBelow < 200 ? "top" : "bottom");
      }
    };

    window.addEventListener("scroll", updatePosition);
    window.addEventListener("resize", updatePosition);

    return () => {
      window.removeEventListener("scroll", updatePosition);
      window.removeEventListener("resize", updatePosition);
    };
  }, []);

  const handleOpenModal = () => {
    setSelectedColumn(props.column);
    setIsOpen(true);
  };

  return (
    <div className="relative h-[72.5px] w-full select-none" ref={menuRef}>
      <Menu as="div" className="relative inline-block h-full w-full text-left">
        {({ open }) => (
          <>
            <Menu.Button className="flex h-full w-full items-center justify-between focus:outline-none">
              <Cell column={props.column} />
              <ChevronDownIcon
                className={`-mr-1 ml-2 h-5 w-5 text-gray-400 transition-transform duration-200 ${
                  open ? "rotate-180 transform" : ""
                }`}
                aria-hidden="true"
              />
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                className={`absolute ${
                  menuPosition === "bottom" ? "top-full" : "bottom-full"
                } left-0 right-0 z-50 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
              >
                <div className="px-1 py-1">
                  <DropdownMenuItems
                    {...props}
                    setShowFilterInput={setShowFilterInput}
                    setModalOpen={handleOpenModal}
                  />
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
      {showFilterInput && (
        <FilterInput
          column={props.column}
          onClose={() => setShowFilterInput(false)}
        />
      )}
    </div>
  );
};

export default HeaderCell;
