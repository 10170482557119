import { useAuth } from "@/context/auth-context";
import { usePromptRegistry, useWorkflowDetails } from "@/queries";

function usePromptRegistryName(
  promptRegistryId: number,
  type: "workflow" | "prompt",
) {
  const auth = useAuth();
  const userToken = auth?.userToken!;
  const promptRegistryQuery = usePromptRegistry(userToken, promptRegistryId);
  const { data } = useWorkflowDetails(String(promptRegistryId), userToken);
  const workflowName = data?.workflow?.name;
  const promptRegistry = promptRegistryQuery.data;
  return type === "workflow" ? workflowName : promptRegistry?.prompt_name;
}

export function RegistryNameSpan({
  id,
  type,
}: {
  id: number;
  type: "workflow" | "prompt";
}) {
  return <span>{usePromptRegistryName(id, type)}</span>;
}

export function RegistryName({
  id,
  type,
}: {
  id: number;
  type: "workflow" | "prompt";
}) {
  return <div>{usePromptRegistryName(id, type)}</div>;
}

export default RegistryName;
