import React, { memo, useEffect, useState } from "react";

import { EyeIcon } from "@heroicons/react/outline";
import { InlineEdit } from ".";
import LoadingSpinner from "./LoadingSpinner";
import TagsEditor from "./TagsEditor";
import { Button } from "./ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "./ui/dialog";

interface ResourceHeaderProps {
  title: string;
  onTitleEdit: (newTitle: string) => void;
  id: string | number;
  createdAt: string;
  versionInfo?: {
    current: number;
    total: number;
  };
  format?: string;
  tags?: string[];
  onTagsEdit?: (newTags: string[]) => void;
  actions: Array<
    | {
        label: string;
        icon?: React.ReactNode;
        onClick: () => void;
        variant?:
          | "default"
          | "destructive"
          | "outline"
          | "secondary"
          | "ghost"
          | "link"
          | "destructiveOutline"
          | "secondaryOutline";
      }
    | React.ReactNode
  >;
  showVersions?: boolean;
  onToggleVersions?: () => void;
  isLoading?: boolean;
}

const Title: React.FC<{
  title: string;
  onTitleEdit: (newTitle: string) => void;
}> = ({ title, onTitleEdit }) => {
  const [showWarningDialog, setShowWarningDialog] = useState(false);
  const [pendingTitle, setPendingTitle] = useState(title || null);

  useEffect(() => {
    if (pendingTitle === null && title) {
      setPendingTitle(title);
    }
  }, [pendingTitle, title]);

  const handleEdit = (newTitle: string) => {
    if (!newTitle) {
      alert("Title must not be empty");
      return;
    }
    setPendingTitle(newTitle);
    setShowWarningDialog(true);
  };

  const handleConfirm = () => {
    onTitleEdit(pendingTitle!);
    setShowWarningDialog(false);
  };

  const handleCancel = () => {
    setPendingTitle(title);
    setShowWarningDialog(false);
  };

  return (
    <>
      <InlineEdit.Root
        initialValue={pendingTitle || ""}
        onEdit={handleEdit}
        className="p-0"
      >
        <InlineEdit.Trigger>
          {!pendingTitle ? (
            <div className="h-8 w-64 animate-pulse rounded bg-gray-50"></div>
          ) : (
            <h1
              className={`cursor-text ${
                pendingTitle.length > 30 ? "text-2xl" : "text-3xl"
              } whitespace-pre-wrap break-words font-semibold ${
                pendingTitle !== title ? "text-blue-600" : ""
              }`}
            >
              {pendingTitle}
            </h1>
          )}
        </InlineEdit.Trigger>
        <InlineEdit.Input className="whitespace-pre-wrap py-1 text-base" />
        <InlineEdit.Rename>Rename</InlineEdit.Rename>
        <InlineEdit.Cancel>Cancel</InlineEdit.Cancel>
      </InlineEdit.Root>

      <Dialog open={showWarningDialog} onOpenChange={handleCancel}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Warning</DialogTitle>
            <DialogDescription>
              Changing the title from "{title}" to "{pendingTitle}" may affect
              existing relationships. Are you sure you want to continue?
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button variant="outline" onClick={handleCancel}>
              Cancel
            </Button>
            <Button onClick={handleConfirm}>Confirm</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

const CopyableId: React.FC<{ id: string | number }> = memo(({ id }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(id.toString());
    setCopied(true);
    setTimeout(() => setCopied(false), 1000);
  };

  return (
    <span
      className="group cursor-pointer text-sm text-gray-600"
      onClick={handleCopy}
    >
      <span className={!copied ? "group-hover:hidden" : ""}>
        {copied ? "Copied to clipboard ✅" : `ID: ${id}`}
      </span>
      <span className={`hidden ${copied ? "" : "group-hover:block"}`}>
        Click to Copy...
      </span>
    </span>
  );
});

const VersionInfo: React.FC<{
  versionInfo?: { current: number; total: number };
}> = memo(({ versionInfo }) => {
  if (!versionInfo || (versionInfo.current === 1 && versionInfo.total <= 1))
    return null;

  if (!versionInfo.total)
    return <div className="h-4 w-20 animate-pulse rounded bg-gray-50"></div>;

  return (
    <>
      <div className="h-1 w-1 rounded-full bg-gray-300"></div>
      <span className="text-sm text-gray-600">
        Version {versionInfo.current} of {versionInfo.total}
      </span>
    </>
  );
});

const FormatInfo: React.FC<{ format?: string }> = memo(({ format }) => {
  if (!format) return null;
  return (
    <>
      <div className="h-1 w-1 rounded-full bg-gray-300"></div>
      <div className="text-sm text-gray-600">{format}</div>
    </>
  );
});

const ToggleVersions: React.FC<{
  showVersions?: boolean;
  onToggleVersions?: () => void;
  versionTotal?: number;
}> = memo(({ showVersions, onToggleVersions, versionTotal }) => {
  if (showVersions || !onToggleVersions || versionTotal! <= 1) return null;
  return (
    <>
      <div className="h-1 w-1 rounded-full bg-gray-300"></div>
      <div onClick={onToggleVersions}>
        <Button onClick={onToggleVersions} variant="link" size="sm">
          <EyeIcon className="mr-1 inline h-4 w-auto" />
          Show Previous Versions
        </Button>
      </div>
    </>
  );
});

const MetaInfo: React.FC<{
  createdAt: string;
  id: string | number;
  versionInfo?: { current: number; total: number };
  format?: string;
  showVersions?: boolean;
  onToggleVersions?: () => void;
  isLoading?: boolean;
}> = memo(
  ({
    createdAt,
    id,
    versionInfo,
    format,
    showVersions,
    onToggleVersions,
    isLoading,
  }) => {
    return (
      <div className="flex items-center space-x-2">
        {createdAt && (
          <>
            <h4 className="text-sm text-gray-600" title={createdAt}>
              {versionInfo && versionInfo.total > 1
                ? "Last Updated: "
                : "Created: "}
              {createdAt}
            </h4>
            <div className="h-1 w-1 rounded-full bg-gray-300"></div>
          </>
        )}

        {id !== -1 && <CopyableId id={id} />}
        <VersionInfo versionInfo={versionInfo} />
        <FormatInfo format={format} />
        <ToggleVersions
          showVersions={showVersions}
          onToggleVersions={onToggleVersions}
          versionTotal={versionInfo?.total}
        />
        {isLoading && <LoadingSpinner size={4} />}
      </div>
    );
  },
);

const Actions: React.FC<{ actions: ResourceHeaderProps["actions"] }> = memo(
  ({ actions }) => (
    <div className="flex gap-x-2.5">
      {actions.map((action, index) =>
        React.isValidElement(action) ? (
          React.cloneElement(action, { key: `action-${index}` })
        ) : (
          <Button
            key={`action-${(action as { label: string }).label}-${index}`}
            onClick={(action as { onClick: () => void }).onClick}
            variant={
              (action as { variant?: string }).variant || ("outline" as any)
            }
            size="sm"
            className="inline-flex items-center"
          >
            {(action as { icon?: React.ReactNode }).icon}
            {(action as { label: string }).label}
          </Button>
        ),
      )}
    </div>
  ),
);

export const ResourceHeader: React.FC<ResourceHeaderProps> = ({
  title,
  onTitleEdit,
  id,
  createdAt,
  versionInfo,
  format,
  tags,
  onTagsEdit,
  actions,
  showVersions,
  onToggleVersions,
  isLoading = false,
}) => {
  const hasMetaInfo =
    createdAt ||
    id !== -1 ||
    (versionInfo && versionInfo.total > 1) ||
    format ||
    showVersions ||
    isLoading;

  return (
    <div className="flex">
      <div className="flex-1">
        {hasMetaInfo ? (
          <>
            <div className="flex items-center space-x-1">
              <Title title={title} onTitleEdit={onTitleEdit} />
              {tags && onTagsEdit && (
                <div className="-mt-1 px-2">
                  <TagsEditor tags={tags} onEdit={onTagsEdit} maxTags={10} />
                </div>
              )}
            </div>
            <div className="flex items-center justify-between pb-3 xl:pb-8">
              <MetaInfo
                createdAt={createdAt}
                id={id}
                versionInfo={versionInfo}
                format={format}
                showVersions={showVersions}
                onToggleVersions={onToggleVersions}
                isLoading={isLoading}
              />
              <Actions actions={actions} />
            </div>
          </>
        ) : (
          <div className="flex items-center justify-between pb-3">
            <Title title={title} onTitleEdit={onTitleEdit} />
            <Actions actions={actions} />
          </div>
        )}
      </div>
    </div>
  );
};
