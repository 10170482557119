import { ExclamationCircleIcon } from "@heroicons/react/outline";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@radix-ui/react-dropdown-menu";
import { observer } from "mobx-react-lite";
import { useNode } from "../../../node-context";

const NodeErrors = () => {
  const node = useNode();
  const errors = node?.errors || [];
  const hasErrors = errors.length > 0;
  return (
    <div className="h-8 px-4">
      <div className="relative flex flex-row">
        {hasErrors && (
          <DropdownMenu>
            <DropdownMenuTrigger
              onClick={(e) => e.stopPropagation()}
              className="relative flex items-center whitespace-nowrap rounded-full bg-red-100 px-3 py-1.5 text-xs font-semibold text-red-600 shadow-sm transition-colors duration-200 ease-in-out hover:bg-red-200"
            >
              <ExclamationCircleIcon className="mr-1 h-4 w-4" />
              {errors.length} Errors
            </DropdownMenuTrigger>
            <DropdownMenuContent className="nowheel relative z-[1] max-h-[300px] w-full max-w-md overflow-y-auto rounded-md border border-gray-200 bg-white p-2 shadow-lg">
              {errors.map((error, index) => (
                <DropdownMenuItem
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  className="w-full rounded-md p-2 hover:bg-gray-100 focus:bg-gray-100 focus:outline-none"
                  key={`node_error_${index}`}
                >
                  <div className="flex flex-col">
                    <div className="mb-1 flex items-center space-x-2">
                      <ExclamationCircleIcon className="h-5 w-5 flex-shrink-0 text-red-500" />
                      <div className="text-sm font-medium text-red-700">
                        {error.type}
                      </div>
                    </div>
                    <div className="ml-7 break-words text-sm text-red-600">
                      <div className="scrollbar-thin scrollbar-thumb-red-200 scrollbar-track-red-100 max-h-20 overflow-y-auto">
                        {error.msg}
                      </div>
                    </div>
                    {error.loc && error.loc.length > 0 && (
                      <div className="ml-7 mt-1 text-xs text-red-400">
                        Location: {error.loc.join(".")}
                      </div>
                    )}
                  </div>
                </DropdownMenuItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
        )}
      </div>
    </div>
  );
};

export default observer(NodeErrors);
