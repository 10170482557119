import { useUser } from "@/context/user-context";

const adminIds: number[] = [24, 26, 1231, 6109, 6525, 10732, 11943, 11636];

const useIsAdmin = () => {
  const userContext = useUser();
  const userId = userContext?.user?.id;
  return adminIds.includes(userId);
};

export default useIsAdmin;
