import { CalculatorIcon } from "@heroicons/react/outline";
import { useParams } from "react-router-dom";

import { useRequest } from "@/queries";
import HistoryPageNavbar from "./HistoryPageNavbar";

const RequestContentNavbar = ({
  onHistoryPage,
  setSidebarOpen,
}: {
  onHistoryPage: boolean;
  setSidebarOpen: (open: boolean) => void;
}) => {
  const { requestId } = useParams();

  return (
    <div
      className={`top-0 flex h-16 flex-shrink-0 border-b border-gray-200 bg-white md:border-0`}
    >
      <button
        className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500 md:hidden"
        onClick={() => setSidebarOpen(true)}
        type="button"
      >
        <span className="sr-only">Open sidebar</span>
        <CalculatorIcon className="h-6 w-6" aria-hidden="true" />
      </button>
      {onHistoryPage && requestId ? <PageNavbar requestId={requestId} /> : null}
    </div>
  );
};

const PageNavbar = ({ requestId }: { requestId: string }) => {
  const request = useRequest(requestId!);
  const requestInfo = request.data;
  return <HistoryPageNavbar requestId={requestId} requestInfo={requestInfo} />;
};

export default RequestContentNavbar;
