import { useState, useCallback, useEffect } from "react";

const useDropdownPosition = (
  dropdownRef: React.RefObject<HTMLDivElement>,
  position: { top: number; left: number },
  contentRef: React.RefObject<HTMLDivElement>,
) => {
  const [dropdownPosition, setDropdownPosition] = useState(position);

  const updatePosition = useCallback(() => {
    if (dropdownRef.current && contentRef.current) {
      const rect = dropdownRef.current.getBoundingClientRect();
      const contentRect = contentRef.current.getBoundingClientRect();
      const viewportHeight = window.innerHeight;
      const viewportWidth = window.innerWidth;
      const padding = 8; // Minimum padding from viewport edges

      let newTop = position.top;
      let newLeft = position.left;

      // Check if dropdown would go off bottom of screen
      if (position.top + rect.height > viewportHeight - padding) {
        // Position above the anchor point if there's more space
        if (position.top - rect.height > padding) {
          newTop = position.top - rect.height;
        } else {
          // Center vertically if can't fit above or below
          newTop = Math.max(padding, (viewportHeight - rect.height) / 2);
        }
      }

      // Check if dropdown would go off right of screen
      if (position.left + rect.width > viewportWidth - padding) {
        // Align to right edge of content or viewport
        newLeft = Math.min(
          contentRect.right - rect.width,
          viewportWidth - rect.width - padding,
        );
      }

      // Prevent going off left side
      newLeft = Math.max(padding, newLeft);

      // Ensure top position stays within viewport
      newTop = Math.max(
        padding,
        Math.min(newTop, viewportHeight - rect.height - padding),
      );

      setDropdownPosition({ top: newTop, left: newLeft });
    }
  }, [dropdownRef, contentRef, position.left, position.top]);

  useEffect(() => {
    updatePosition();
    window.addEventListener("resize", updatePosition);

    return () => {
      window.removeEventListener("resize", updatePosition);
    };
  }, [updatePosition]);

  return dropdownPosition;
};

export default useDropdownPosition;
