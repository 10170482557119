import { useFunctionDialogContext } from "@/components/FunctionsModal";
import { FunctionsList } from "@/components/FunctionsModal/FunctionsList";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { prompt } from "@/schemas";
import { FunctionsType, functionsTypes } from "@/types";
import { PlusIcon, TerminalIcon } from "lucide-react";

const FunctionOverviewDialog = () => {
  const {
    isEditing,
    functionCallValue,
    functionsType,
    isAddingFunction,
    setIsAddingFunction,
  } = useFunctionDialogContext();

  const functionsTypeLabel =
    functionsType === "functions" ? "function_call:" : "tool_choice";

  return (
    <>
      <div className="flex flex-col">
        <SelectFunctionType />
        {functionCallValue !== undefined || isEditing ? (
          <>
            <div
              className={`flex flex-row items-center gap-x-2 pt-2 text-sm ${
                isEditing ? "pb-3" : "pb-1"
              }`}
            >
              <div className="font-mono">{functionsTypeLabel}</div>
              <div>
                {isEditing ? (
                  <SelectFunctionCall />
                ) : (
                  <div className="border-gray-500 font-mono text-sm text-gray-700">
                    <TerminalIcon className="mr-1 inline h-4 w-4" />
                    {functionCallValue}
                  </div>
                )}
              </div>
            </div>
            <p className="text-xs text-gray-400">
              {`Controls how the model responds to function calls, with options for "none" (model doesn't call a function), "auto" (model can choose between end-user or function call), and specifying a function forces the model to call it.`}
              <a
                href="https://platform.openai.com/docs/api-reference/chat/create#chat/create-function_call"
                target="_blank"
                rel="noreferrer"
                className="pl-1 text-xs text-blue-400 hover:text-blue-300"
              >
                Learn more here.
              </a>
            </p>
          </>
        ) : null}
      </div>
      <div>
        <div className="mt-3 flex items-center pb-1">
          <Label className="text-md flex-1 text-gray-800">
            Function definitions:
          </Label>
          {isEditing && (
            <Button
              size="sm"
              onClick={() => setIsAddingFunction(!isAddingFunction)}
            >
              <PlusIcon className="mr-2 inline h-4 w-4" />
              Add new function
            </Button>
          )}
        </div>
        <FunctionsList />
      </div>
    </>
  );
};

const SelectFunctionType = () => {
  const { functionsType, setFunctionsType } = useFunctionDialogContext();

  return (
    <div className="flex items-center gap-2">
      <Label className="font-bold">Functions Type: </Label>
      <Select
        value={functionsType}
        onValueChange={(type: FunctionsType) => setFunctionsType?.(type)}
        disabled={!setFunctionsType}
      >
        <SelectTrigger className="max-w-xs capitalize">
          <SelectValue placeholder="Functions Type" />
        </SelectTrigger>
        <SelectContent>
          {functionsTypes.map((type) => (
            <SelectItem key={type} value={type} className="capitalize">
              {type}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
};

const SelectFunctionCall = () => {
  const { functionCallValue, setFunctionCall, functions, functionsType } =
    useFunctionDialogContext();
  let placeholder = "Select tool choice";
  if (functionsType === "functions") placeholder = "Select function call";
  return (
    <Select
      value={functionCallValue}
      onValueChange={(functionCall) => {
        if (["auto", "none"].includes(functionCall)) {
          setFunctionCall!(
            functionCall as (typeof prompt.functionCall)[number],
          );
        } else {
          setFunctionCall!({
            name: functionCall,
          });
        }
      }}
    >
      <SelectTrigger className="max-w-xs rounded-sm py-3">
        <SelectValue placeholder={placeholder} />
      </SelectTrigger>
      <SelectContent className="rounded-sm">
        <SelectGroup>
          {prompt.functionCall.map((functionCall) => (
            <SelectItem key={functionCall} value={functionCall}>
              {functionCall}
            </SelectItem>
          ))}
          {functions.map((_function) => (
            <SelectItem
              key={_function.name}
              value={_function.name}
              className="font-mono"
            >
              {`>_ `}
              {_function.name}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};

export default FunctionOverviewDialog;
