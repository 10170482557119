import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Textarea } from "@/components/ui/textarea";
import { ItemProperties, ParameterType, Properties } from "./Types";

export const DescriptionItemParamForm = ({
  parameters,
  setParameters,
  handleParameterChange,
  isItemDescription,
  readonly = false,
}: {
  parameters: any[];
  setParameters: (params: any[]) => void;
  handleParameterChange: <K extends keyof Properties>(
    index: number,
    key: K,
    val: Properties[K] | ItemProperties[K],
    parameters: any[],
    setParameters: (params: any[]) => void,
    isItemDescription?: boolean,
  ) => void;
  readonly?: boolean;
  isItemDescription: boolean;
}) => {
  return (
    <div className="grid grid-cols-6 gap-4">
      <div className="col-span-1 flex items-center text-right">
        <span className="p-1 text-xs text-gray-700">Type</span>
      </div>

      <div className="col-span-5">
        <DropdownMenu>
          <DropdownMenuTrigger
            disabled={readonly}
            className="w-full border-gray-300 text-sm font-normal text-gray-500 focus:border-blue-500 focus:ring-blue-500"
          >
            {parameters[0]?.type}
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            {Object.values(ParameterType).map((format) => (
              <DropdownMenuItem
                className="w-full text-center"
                key={format}
                onSelect={() => {
                  handleParameterChange(
                    0,
                    "type",
                    format,
                    parameters,
                    setParameters,
                    isItemDescription,
                  );
                }}
              >
                {format}
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <div className="col-span-1 flex items-center text-right">
        <span className="p-1 text-xs text-gray-700">Description</span>
      </div>
      <div className="col-span-5">
        <Textarea
          className="w-full rounded-sm border-gray-300 text-xs focus:border-blue-500 focus:ring-blue-500"
          placeholder="Description"
          disabled={readonly}
          value={parameters[0]?.description as string}
          onChange={(e) => {
            handleParameterChange(
              0,
              "description",
              e.target.value,
              parameters,
              setParameters,
              isItemDescription,
            );
          }}
        />
      </div>
    </div>
  );
};
