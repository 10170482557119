import FunctionEditorDialog from "@/components/FunctionsModal/FunctionEditorDialog";
import FunctionOverviewDialog from "@/components/FunctionsModal/FunctionOverviewDialog";
import {
  FunctionDialogContextType,
  FunctionDialogProps,
  ParametersEditorModeEnum,
} from "@/components/FunctionsModal/Types";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Function_, SchemaDefinition } from "@/types";
import { Tab } from "@headlessui/react";
import { ExclamationCircleIcon, TerminalIcon } from "@heroicons/react/outline";
import { CurlyBracesIcon, Link } from "lucide-react";
import { createContext, useContext, useMemo, useState } from "react";
import { SchemaEditor } from "../ModelProviderSelection/EditJsonSchemaModal";
import useJSONSchema from "../PromptBlueprint/components/hooks/useJSONSchema";
import { usePromptBlueprint } from "../PromptBlueprint/hooks";
import { Label } from "../ui/label";

const DEFAULT_CONTEXT: FunctionDialogContextType = {
  functions: [],
  setFunctions: () => {},
  functionCall: undefined,
  setFunctionCall: () => {},
  functionCallValue: "",
  functionsType: "functions",
  setFunctionsType: () => {},
  isAddingFunction: false,
  setIsAddingFunction: () => {},
  editingFunction: null,
  setEditingFunction: () => {},
  isEditing: false,
  parameterEditorMode: ParametersEditorModeEnum.INTERACTIVE,
  setParameterEditorMode: () => {},
  onSubmit: () => {},
};

const FunctionDialogContext =
  createContext<FunctionDialogContextType>(DEFAULT_CONTEXT);

export const FunctionDialog = (props: FunctionDialogProps) => {
  const [parameterEditorMode, setParameterEditorMode] = useState(
    ParametersEditorModeEnum.INTERACTIVE,
  );
  const [isAddingFunction, setIsAddingFunction] = useState(false);
  const [editingFunction, setEditingFunction] = useState<Function_ | null>(
    null,
  );
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const functionCallValue =
    typeof props.functionCall === "string"
      ? props.functionCall
      : props.functionCall?.name;

  const isEditing = useMemo(
    () => Boolean(props.setFunctions && props.onSubmit),
    [props.setFunctions, props.onSubmit],
  );

  const contextValue = useMemo(
    () => ({
      ...DEFAULT_CONTEXT,
      functions: props.functions,
      setFunctions: props.setFunctions || DEFAULT_CONTEXT.setFunctions,
      functionCall: props.functionCall,
      setFunctionCall: props.setFunctionCall || DEFAULT_CONTEXT.setFunctionCall,
      functionCallValue,
      functionsType: props.functionsType,
      setFunctionsType:
        props.setFunctionsType || DEFAULT_CONTEXT.setFunctionsType,
      isAddingFunction,
      setIsAddingFunction,
      editingFunction,
      setEditingFunction,
      isEditing,
      parameterEditorMode,
      setParameterEditorMode,
      onSubmit: props.onSubmit || DEFAULT_CONTEXT.onSubmit,
    }),
    [
      props.functions,
      props.setFunctions,
      props.functionCall,
      props.setFunctionCall,
      props.functionsType,
      props.setFunctionsType,
      props.onSubmit,
      functionCallValue,
      isAddingFunction,
      editingFunction,
      isEditing,
      parameterEditorMode,
    ],
  );

  const schema = useJSONSchema() || props.jsonSchema;
  const form = usePromptBlueprint();

  const handleSchemaSubmit = (schema: SchemaDefinition) => {
    form.setValue(
      "metadata.model.parameters.response_format.json_schema",
      schema,
    );
    form.setValue(
      "metadata.model.parameters.response_format.type",
      "json_schema",
    );
    setIsModalOpen(false);
  };
  return (
    <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
      <FunctionDialogContext.Provider value={contextValue}>
        <DialogTrigger asChild>
          <Button
            variant="outline"
            className={`relative ${
              // schema is just true if schema option is available
              (contextValue.functions.length > 0 ||
                (schema && typeof schema !== "boolean")) &&
              "after:absolute after:right-2 after:top-2 after:h-2 after:w-2 after:rounded-full after:bg-blue-500"
            }`}
          >
            <TerminalIcon className="mr-1 inline h-4 w-4" />
            Functions & Output
          </Button>
        </DialogTrigger>
        <DialogContent className="flex max-h-[800px] min-h-[600px] max-w-6xl flex-col ">
          <DialogHeader className="text-center text-xl">
            <div className="flex flex-col">
              <Label className="text-xl font-bold">
                Function & Output Schema Editor
              </Label>
              <p className="text-sm text-gray-500">
                <a
                  href={
                    selectedTabIndex === 0
                      ? "https://platform.openai.com/docs/guides/gpt/function-calling"
                      : "https://json-schema.org/understanding-json-schema/"
                  }
                  target="_blank"
                  rel="noreferrer"
                  className="text-sm text-gray-500 hover:text-gray-400"
                >
                  Learn more on the{" "}
                  {selectedTabIndex === 0 ? "OpenAI" : "JSON Schema"} docs
                  <Link className="inline h-4 w-4 pl-1" />
                </a>
              </p>
            </div>
          </DialogHeader>
          <Tab.Group
            selectedIndex={selectedTabIndex}
            onChange={setSelectedTabIndex}
          >
            <Tab.List className="sticky top-0 z-10 mb-6 flex rounded-xl bg-slate-50 p-1.5 shadow-sm ring-1 ring-slate-200/60">
              <Tab
                className={({ selected }) =>
                  `group relative w-full rounded-lg px-4 py-3 text-sm font-medium leading-5 transition-all duration-200 ease-in-out
                  ${
                    selected
                      ? "bg-white text-slate-900 shadow-sm ring-1 ring-slate-200"
                      : "text-slate-600 hover:bg-white/50 hover:text-slate-900"
                  }`
                }
              >
                <div className="flex items-center justify-center space-x-2">
                  <TerminalIcon className="h-4 w-4" />
                  <span>Functions</span>
                </div>
                <span className="absolute left-1/2 top-full z-50 mt-2 hidden -translate-x-1/2 whitespace-nowrap rounded-lg bg-slate-900 px-4 py-2.5 text-xs text-slate-50 shadow-xl ring-1 ring-slate-800/5 group-hover:block">
                  Define functions and tools that can be called
                </span>
              </Tab>
              <Tab
                className={({ selected }) =>
                  `group relative w-full rounded-lg px-4 py-3 text-sm font-medium leading-5 transition-all duration-200 ease-in-out
                  ${!schema ? "cursor-not-allowed opacity-50" : ""}
                  ${
                    selected
                      ? "bg-white text-slate-900 shadow-sm ring-1 ring-slate-200"
                      : "text-slate-600 hover:bg-white/50 hover:text-slate-900"
                  }`
                }
                disabled={!schema}
              >
                <div className="flex items-center justify-center space-x-2">
                  <CurlyBracesIcon className="h-4 w-4" />
                  <span>Structured Output</span>
                  {!schema && (
                    <ExclamationCircleIcon className="h-4 w-4 text-amber-500" />
                  )}
                </div>
                <span className="absolute left-1/2 top-full z-50 mt-2 hidden -translate-x-1/2 whitespace-nowrap rounded-lg bg-slate-900 px-4 py-2.5 text-xs text-slate-50 shadow-xl ring-1 ring-slate-800/5 group-hover:block">
                  {!schema
                    ? "The current model does not support structured outputs"
                    : "Define JSON schema for structured model outputs"}
                </span>
              </Tab>
            </Tab.List>
            <Tab.Panels className="flex-1 overflow-auto px-4">
              <Tab.Panel className="h-full rounded-xl focus:outline-none focus-visible:ring-2 focus-visible:ring-slate-400 focus-visible:ring-offset-2">
                {isAddingFunction || editingFunction ? (
                  <FunctionEditorDialog />
                ) : (
                  <FunctionOverviewDialog />
                )}
              </Tab.Panel>
              <Tab.Panel
                className={`${
                  !isEditing && " select-none opacity-75 "
                }h-full rounded-xl focus:outline-none focus-visible:ring-2 focus-visible:ring-slate-400 focus-visible:ring-offset-2`}
              >
                <SchemaEditor
                  readonly={!isEditing}
                  initialValues={schema}
                  setIsOpen={setIsModalOpen}
                  handleSubmit={handleSchemaSubmit}
                />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </DialogContent>
      </FunctionDialogContext.Provider>
    </Dialog>
  );
};

export const useFunctionDialogContext = () => {
  const context = useContext(FunctionDialogContext);
  if (!context) {
    throw new Error(
      "useFunctionDialogContext must be used within a FunctionDialogProvider",
    );
  }
  return context;
};
