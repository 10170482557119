import Widget, { WidgetProps } from "@/components/Widgets/Widget";

import { WidgetTypes } from "@/components/Widgets";
import { useCallback } from "react";
import { createRoot } from "react-dom/client";
import { v4 as uuidv4 } from "uuid";
import { Provider, useContentArea } from "../content-area-context";

const useCustomComponent = (ref: React.RefObject<HTMLDivElement>) => {
  const content = useContentArea();
  const { savedSelection } = content;

  const triggerInputEvent = useCallback(() => {
    const input = ref.current;
    input?.dispatchEvent(new Event("input", { bubbles: true }));
  }, [ref]);

  const insertCustomComponent = useCallback(
    (widgetType: WidgetTypes) => {
      if (ref.current) {
        let range: Range;

        if (savedSelection) {
          range = savedSelection;
        } else {
          const selection = window.getSelection();
          range = selection?.getRangeAt(0) || document.createRange();

          // If there's no valid selection, create a new range at the end of the content
          if (!ref.current.contains(range.commonAncestorContainer)) {
            range.selectNodeContents(ref.current);
            range.collapse(false);
          }
        }

        // Create a single node for the component
        const componentNode = document.createElement("section");
        componentNode.setAttribute("contenteditable", "false");
        componentNode.style.display = "inline-block";
        componentNode.style.whiteSpace = "nowrap";
        componentNode.style.verticalAlign = "baseline";
        const componentId = uuidv4();
        componentNode.setAttribute("data-custom-component-id", componentId);

        const newComponent: WidgetProps = {
          id: componentId,
          type: widgetType,
        };

        // Wrap CustomComponent with ContentAreaProvider to ensure context is accessible
        const customComponent = (
          <Provider value={content}>
            <Widget {...newComponent} />
          </Provider>
        );
        const root = createRoot(componentNode);
        root.render(customComponent);
        // Insert the component at the current cursor position
        const startNode = range.startContainer;
        const startOffset = range.startOffset - 1; // Offset by 1 character

        if (startNode.nodeType === Node.TEXT_NODE) {
          const textNode = startNode as Text;
          const beforeText = textNode.substringData(0, startOffset);
          const afterText = textNode.substringData(
            startOffset,
            textNode.length - startOffset - 1,
          );

          if (beforeText) {
            const beforeTextNode = document.createTextNode(beforeText);
            textNode.parentNode?.insertBefore(beforeTextNode, textNode);
          }

          textNode.parentNode?.insertBefore(componentNode, textNode);

          if (afterText) {
            const afterTextNode = document.createTextNode(afterText);
            textNode.parentNode?.insertBefore(afterTextNode, textNode);
          }

          textNode.parentNode?.removeChild(textNode);

          range.setStartAfter(componentNode);
          range.setEndAfter(componentNode);
        } else {
          range.insertNode(componentNode);
          range.setStartAfter(componentNode);
          range.setEndAfter(componentNode);
        }

        const selection = window.getSelection();
        selection?.removeAllRanges();
        selection?.addRange(range);

        // Ensure the cursor is visible and component is in view
        requestAnimationFrame(() => {
          componentNode.scrollIntoView({
            block: "nearest",
            inline: "nearest",
            behavior: "smooth",
          });

          // Force browser to focus on the contenteditable area
          ref.current?.focus();
        });

        setTimeout(triggerInputEvent, 1);
      }
    },
    [content, ref, triggerInputEvent, savedSelection],
  );

  return { insertCustomComponent };
};

export default useCustomComponent;
