import { useCallback } from "react";

const usePasteHandling = () => {
  const handlePaste = useCallback((event: React.ClipboardEvent) => {
    event.preventDefault();
    const text = event.clipboardData.getData("text/plain");
    document.execCommand("insertText", false, text);
  }, []);

  return { handlePaste };
};
export default usePasteHandling;
