import React from "react";
import { Button } from "./ui/button";
import { Input } from "./ui/input";

interface CreateCommitMessageProps {
  onSubmit: (message: string) => void;
  onCancel: () => void;
}

const CreateCommitMessage: React.FC<CreateCommitMessageProps> = ({
  onSubmit,
  onCancel,
}) => {
  const [commitMessage, setCommitMessage] = React.useState("");

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(commitMessage);
  };

  const handleCancel = (e: React.MouseEvent) => {
    e.preventDefault();
    onCancel();
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="space-y-2">
        <p className="text-[0.8rem] text-gray-700 text-muted-foreground">
          Prompt version change message (Optional)
        </p>
        <Input
          placeholder="Commit message"
          value={commitMessage}
          onChange={(e) => setCommitMessage(e.target.value)}
        />
      </div>
      <div className="mt-4 flex justify-end space-x-2">
        <Button
          type="button"
          className="bg-transparent text-sm"
          variant="outline"
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button type="submit">Save Version</Button>
      </div>
    </form>
  );
};

export default CreateCommitMessage;
