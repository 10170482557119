import { CheckIcon, DocumentDuplicateIcon } from "@heroicons/react/outline";
import { useState } from "react";

import { Button } from "./button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./tooltip";

export const CopyButton: React.FC<{
  text: string;
  iconSize?: string;
  className?: string;
}> = ({ text, className, iconSize = "h-5 w-5" }) => {
  const [isCopied, setIsCopied] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const copyText = async (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    await navigator.clipboard.writeText(text);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };

  return (
    <TooltipProvider delayDuration={0}>
      <Tooltip open={isCopied || isHovered}>
        <TooltipTrigger asChild>
          <Button
            onClick={(e) => {
              copyText(e);
              setIsCopied(true);
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            variant={"ghost"}
            size={"tinyIcon"}
            className={`group relative text-gray-600 hover:text-gray-800 focus:outline-none ${className}`}
            aria-label="Copy to clipboard"
          >
            {isCopied ? (
              <CheckIcon className={iconSize} />
            ) : (
              <DocumentDuplicateIcon className={iconSize} />
            )}
          </Button>
        </TooltipTrigger>
        <TooltipContent
          side="top"
          align="center"
          sideOffset={5}
          className="rounded bg-gray-800 px-2 py-1 text-sm text-white"
        >
          {isCopied ? "Copied!" : "Copy"}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
