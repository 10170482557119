import { useUser } from "@/context/user-context";
import { Span } from "@/types/spans";
import { getDuration } from "@/utils/duration";
import { keepRelevantSearchParams } from "@/utils/utils";
import { ListTree } from "lucide-react";
import moment from "moment-timezone";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import SidebarCard from "./SidebarCard";

const SpanCard = ({ span, ...props }: { span: Span }) => {
  const startTime = span.start;
  const navigate = useNavigate();
  const { traceId } = useParams();
  const [searchParams] = useSearchParams();
  const userContext = useUser();

  // Convert UTC time to local time
  const localStartTime = moment.utc(startTime).local();

  let timestamp = localStartTime.fromNow();
  if (moment().isSame(localStartTime, "day")) {
    timestamp = localStartTime.format("h:mm:ss A");
  }

  return (
    <SidebarCard
      onClick={(e) => {
        const preservedParams = keepRelevantSearchParams(searchParams);
        const url = `/workspace/${userContext?.activeWorkspaceId}/traces/${span.trace_id}?${preservedParams}`;
        navigate(url);
      }}
      id={span.trace_id}
      active={span.trace_id === traceId}
    >
      <div className="flex w-full flex-row items-center justify-between">
        <div className="mr-2 min-w-0 flex-1">
          <div className="truncate font-mono text-sm font-medium text-black">
            {span.name}
          </div>
        </div>
        <div className="flex-shrink-0 text-xs font-normal text-gray-500">
          {getDuration(span.start, span.end)}
        </div>
      </div>
      <div className="flex w-full flex-row items-center justify-between gap-x-2">
        <div className="flex flex-row items-center gap-x-1 text-xs font-light text-gray-400">
          <ListTree size={12} />
          <span className="max-w-[72px] truncate">{span.span_id}</span>
        </div>
        <div className="text-xs font-light text-gray-400">{timestamp}</div>
      </div>
    </SidebarCard>
  );
};

export default SpanCard;
