import { TemplateFormat } from "@/types";
import { usePromptBlueprint } from "./use-prompt-blueprint";

const useTemplateFormat = () => {
  const form = usePromptBlueprint();
  const promptTemplate = form.watch("prompt_template");
  if (promptTemplate.type === "completion") {
    const value = promptTemplate.template_format;
    const onChange = (template_format: TemplateFormat) => {
      form.setValue("prompt_template.template_format", template_format);
    };
    return { value, onChange };
  }
  const messages = promptTemplate.messages;
  const firstMessage = messages.at(0);
  const value = firstMessage?.template_format ?? "f-string";
  const onChange = (template_format: TemplateFormat) => {
    form.setValue(
      "prompt_template.messages",
      messages.map((m) => ({ ...m, template_format })),
    );
  };
  return { value, onChange };
};

export { useTemplateFormat };
