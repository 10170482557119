import folderGraphic from "@/assets/folder.svg";
import FolderPath from "@/components/FolderPath";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { ToastType } from "@/enums";
import { useFolders, useMovePromptTemplate } from "@/queries";
import { Folder } from "@/types/folders";
import { displayToast } from "@/utils/toast";
import { HomeIcon } from "@heroicons/react/solid";
import { FC, useMemo, useState } from "react";

type MoveToFolderModalProps = {
  open: boolean;
  prompt: any;
  setOpen: (open: boolean) => void;
};

export const MoveToFolderModal: FC<MoveToFolderModalProps> = ({
  open,
  prompt,
  setOpen,
}) => {
  const [selectedFolderId, setSelectedFolderId] = useState<number | null>(
    prompt?.folder_id,
  );
  const authContext = useAuth();
  const userContext = useUser();
  const workspaceId = userContext.activeWorkspaceId!;
  const userToken = authContext!.userToken;
  const movePromptTemplate = useMovePromptTemplate(userToken!);
  const foldersQuery = useFolders(userToken!, workspaceId!);

  const folderList = useMemo((): Folder[] => {
    return foldersQuery.data?.folders ?? [];
  }, [foldersQuery.data?.folders]);

  const handleSelectFolder = (folder: Folder) => {
    setSelectedFolderId(folder.id);
  };

  const handleMove = async () => {
    try {
      await movePromptTemplate.mutateAsync({
        folder_id: selectedFolderId,
        prompt_template_id: prompt?.id,
      });
      displayToast("Template moved", ToastType.success);
      setOpen(false);
    } catch (error: unknown) {
      console.log(error);
    }
  };

  const renderOptions = () => {
    return folderList.map((folder) => (
      <div
        className={`flex h-12 cursor-pointer items-center rounded-md px-2 hover:bg-gray-100 ${
          selectedFolderId === folder.id ? "bg-gray-200" : ""
        }`}
        key={folder.id}
        onClick={() => handleSelectFolder(folder)}
      >
        <img
          alt="Folder graphic"
          className="w-8 text-blue-600"
          src={folderGraphic}
        />
        <span className="ml-4 font-medium text-gray-900">
          <FolderPath folder={folder} />
        </span>
      </div>
    ));
  };

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className="break-all pt-3.5">
            Move {prompt?.prompt_name} to Folder
          </DialogTitle>
        </DialogHeader>
        <div className="my-4 max-h-[360px] overflow-y-auto">
          <div
            className={`flex h-12 cursor-pointer items-center rounded-md px-2 hover:bg-gray-100 ${
              selectedFolderId === null ? "bg-gray-200" : ""
            }`}
            onClick={() => setSelectedFolderId(null)}
          >
            <HomeIcon aria-hidden="true" className="w-8" />
            <span className="ml-4 font-medium text-gray-900">Home</span>
          </div>
          {renderOptions()}
        </div>
        <DialogFooter>
          <Button
            className="bg-transparent text-sm"
            onClick={() => setOpen(false)}
            variant="outline"
          >
            Cancel
          </Button>
          <Button
            disabled={selectedFolderId === prompt?.folder_id}
            onClick={handleMove}
          >
            Move
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
