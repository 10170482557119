import { Button, DivButton } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import React from "react";

interface AddReleaseLabelButtonProps {
  onSave: (label: string) => Promise<unknown>;
}

const AddReleaseLabelButton: React.FC<AddReleaseLabelButtonProps> = ({
  onSave,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [label, setLabel] = React.useState("");
  const [isSaving, setIsSaving] = React.useState(false);

  const handleSave = async () => {
    setIsSaving(true);
    try {
      await onSave(label);
      setIsOpen(false);
      setLabel("");
    } catch (error) {
      console.error("Error saving label:", error);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <DivButton className="flex cursor-pointer items-center rounded-md border border-primary bg-white p-2 text-xs font-normal text-gray-700 shadow-md hover:bg-gray-50 hover:text-gray-900">
          Add Release Label
        </DivButton>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Create a Label</DialogTitle>
        </DialogHeader>
        <div>
          <Input
            placeholder="+ Add release label..."
            className="w-full"
            value={label}
            onChange={(e) => setLabel(e.target.value)}
          />
          <p className="mt-2 text-[0.8rem] text-gray-500">
            This value will be used to uniquely identify a prompt version. For
            example, label a version as "prod".
          </p>
        </div>
        <DialogFooter>
          <Button
            type="submit"
            onClick={handleSave}
            disabled={isSaving || !label.trim()}
          >
            {isSaving ? "Saving..." : "Create Label"}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default AddReleaseLabelButton;
