import LoadingSpinner from "@/components/LoadingSpinner";
import { useTemplatesData } from "@/components/Registry/hooks/useTemplatesData";
import TemplateGrid from "@/components/Registry/TemplateGrid";
import { usePromptRegistryObjects } from "@/queries";
import { Folder } from "@/types/folders";
import { PromptRegistry } from "@/types/prompt-registry";

const Templates = ({
  searchQuery,
  templates,
  folderId,
  folders,
  promptRegistryObjects,
}: {
  searchQuery: string;
  templates: PromptRegistry[];
  folderId: string | undefined;
  folders: Folder[];
  promptRegistryObjects: ReturnType<typeof usePromptRegistryObjects>;
}) => {
  const { ref, displayHeading, filteredTemplates } = useTemplatesData({
    templates,
    folderId,
    folders,
    promptRegistryObjects,
    searchQuery,
  });

  return (
    <>
      {displayHeading && <div className="mt-10">Templates</div>}
      <div className="mt-4 flex-1 pb-8">
        <TemplateGrid templates={filteredTemplates} />
        {promptRegistryObjects.hasNextPage && (
          <div ref={ref} className="mt-4 flex justify-center py-2">
            <LoadingSpinner size={5} />
          </div>
        )}
      </div>
    </>
  );
};

export default Templates;
