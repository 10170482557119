import { TerminalIcon } from "@heroicons/react/solid";

const OutputLabel: React.FC = () => (
  <span className="mr-2 flex items-center rounded-full bg-blue-100 px-2 py-1 text-sm font-medium text-blue-500">
    <TerminalIcon className="mr-1 h-4 w-auto" />
    Output
  </span>
);

export default OutputLabel;
