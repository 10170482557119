import { Button } from "@/components/ui/button";

import Checkbox from "@/components/ui/checkbox";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { GenericTablePagination } from "@/components/ui/table-pagination";
import { BasicTooltip as Tooltip } from "@/components/ui/tooltip";
import { useAuth } from "@/context/auth-context";
import { useDatasetRows } from "@/queries";
import {
  CheckIcon,
  Cross1Icon,
  Cross2Icon,
  MagnifyingGlassIcon,
  ReloadIcon,
} from "@radix-ui/react-icons";
import { observer } from "mobx-react-lite";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useExamples } from "./example-widget-store";
import SelectedExamplesBanner from "./SelectedExamplesBanner";

interface Example {
  id: number;
  value: Record<string, any>;
}

interface ExampleSelectionDialogProps {
  datasetId: number;
  onAddExample: (example: Example) => void;
  onClose?: () => void;
  selectedExamples: Example[];
}

const ITEMS_PER_PAGE = 10;
const pageSizeOptions = [10, 20, 30, 40, 50];

const TableLoadingState = () => (
  <div className="space-y-3 p-4">
    {[...Array(ITEMS_PER_PAGE)].map((_, i) => (
      <div
        key={i}
        className="h-10 animate-pulse rounded bg-gray-100"
        role="progressbar"
        aria-label="Loading"
      />
    ))}
  </div>
);

const CellContent = ({
  value,
  searchQuery,
}: {
  value: any;
  searchQuery: string;
}) => {
  const displayValue =
    !value || value === "" ? (
      <span className="text-gray-400">-</span>
    ) : (
      highlightText(
        typeof value === "string" ? value : JSON.stringify(value),
        searchQuery,
      )
    );
  return (
    <div className="max-h-[100px] overflow-x-auto overflow-y-auto break-words">
      {displayValue}
    </div>
  );
};

const highlightText = (text: string, query: string) => {
  if (!query) return text;
  const parts = text.split(new RegExp(`(${query})`, "gi"));
  return parts.map((part, index) =>
    part.toLowerCase() === query.toLowerCase() ? (
      <span key={index} className="rounded bg-yellow-200 px-0.5">
        {part}
      </span>
    ) : (
      part
    ),
  );
};

const ExampleSelectionDialog = ({
  datasetId,
  onAddExample,
  onClose,
}: ExampleSelectionDialogProps) => {
  const store = useExamples();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(ITEMS_PER_PAGE);

  const { userToken } = useAuth() || {};

  if (!userToken) {
    throw new Error("User token is required");
  }

  const {
    data: rowsData,
    isLoading: isQueryLoading,
    isFetching,
    error,
  } = useDatasetRows(
    userToken,
    datasetId,
    currentPage,
    pageSize,
    "id",
    "desc",
    searchQuery,
  );

  if (error) {
    console.error("Failed to fetch dataset rows:", error);
  }

  const isLoading = isQueryLoading || isFetching;
  const totalPages = rowsData?.pages ?? 0;
  const hasNext = currentPage < totalPages;

  const resultData = useMemo(
    () =>
      rowsData?.dataset_rows?.map(
        ({
          variables,
          id,
        }: {
          variables: Record<string, any>;
          id: number;
        }) => ({ value: variables, id }),
      ),
    [rowsData],
  );

  useEffect(() => {
    const firstRowValue = resultData?.[0]?.value;

    if (!firstRowValue) return;

    const allColumns = Object.keys(firstRowValue);

    if (store.visibleColumns.length === 0) {
      store.setVisibleColumns(allColumns);
      return;
    }

    const totalCurrentColumns =
      store.visibleColumns.length + store.removedColumns.length;
    if (totalCurrentColumns !== allColumns.length) {
      const newRemovedColumns = allColumns.filter(
        (column) => !store.visibleColumns.includes(column),
      );
      store.setRemovedColumns(newRemovedColumns);
    }
  }, [resultData, store, store.visibleColumns.length]);

  useEffect(() => {
    setIsSearching(!!searchQuery);
  }, [searchQuery]);

  useEffect(() => {
    if (!isQueryLoading && rowsData?.dataset_rows) {
      setIsSearching(false);
    }
  }, [isQueryLoading, rowsData?.dataset_rows]);

  const handleSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  }, []);

  const toggleColumn = useCallback(
    (columnName: string) => {
      store.toggleColumn(columnName);
    },
    [store],
  );

  const isExampleSelected = useCallback(
    (example: Example) =>
      store.selectedExamples.some((selected) => selected.id === example.id),
    [store.selectedExamples],
  );

  const handleClose = useCallback(() => {
    setIsOpen(false);
    onClose?.();
  }, [onClose]);

  const handleAddExample = useCallback(
    (example: Example) => {
      const value = store.visibleColumns.reduce(
        (acc, key) => {
          acc[key] = example.value[key];
          return acc;
        },
        {} as Record<string, any>,
      );
      onAddExample({ id: example.id, value });
    },
    [onAddExample, store.visibleColumns],
  );

  const handleSelectAll = useCallback(() => {
    resultData?.forEach((example: Example) => {
      if (!isExampleSelected(example)) {
        handleAddExample(example);
      }
    });
  }, [resultData, handleAddExample, isExampleSelected]);

  const handleDeselectAll = useCallback(() => {
    store.selectedExamples.forEach((example) => {
      onAddExample(example);
    });
  }, [onAddExample, store.selectedExamples]);

  const handleSetPageSize = useCallback((newSize: number, page: number = 1) => {
    setPageSize(newSize);
    setCurrentPage(page);
  }, []);

  const allSelected =
    resultData?.length > 0 && resultData?.every(isExampleSelected);

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <SelectedExamplesBanner
          onClick={() => setIsOpen(true)}
          selectedCount={store.selectedExamples.length}
        />
      </DialogTrigger>
      <DialogContent className="max-h-[90vh] w-full overflow-hidden sm:max-w-[900px]">
        <div className="relative mt-4 w-full space-y-4 overflow-hidden">
          <div className="flex items-center justify-between">
            <h2 className="text-xl font-semibold text-gray-800">
              Add New Examples
            </h2>
            <div className="flex items-center gap-3">
              {store.selectedExamples.length > 0 && (
                <div className="animate-fadeIn rounded-full bg-blue-100 px-3 py-1 text-sm font-medium text-blue-700">
                  {store.selectedExamples.length} example
                  {store.selectedExamples.length !== 1 ? "s" : ""} selected
                </div>
              )}
              <div className="flex gap-2">
                <Button
                  size="sm"
                  variant="outline"
                  onClick={handleSelectAll}
                  disabled={isLoading || !resultData?.length || allSelected}
                  className="flex h-8 items-center gap-2 whitespace-nowrap px-3"
                >
                  <CheckIcon className="h-4 w-4" />
                  Select All on Page
                </Button>
                <Button
                  size="sm"
                  variant="outline"
                  onClick={handleDeselectAll}
                  disabled={isLoading || !store.selectedExamples.length}
                  className="flex h-8 items-center gap-2 whitespace-nowrap px-3"
                >
                  <Cross1Icon className="h-4 w-4" />
                  Clear All Selections
                </Button>
              </div>
            </div>
          </div>

          <div className="flex items-center gap-4">
            <div className="relative flex-1">
              <MagnifyingGlassIcon className="absolute left-3 top-1/2 h-5 w-5 -translate-y-1/2 text-gray-400" />
              <Input
                placeholder="Search examples..."
                value={searchQuery}
                onChange={handleSearchChange}
                className="h-10 pl-10 transition-colors focus:ring-2 focus:ring-blue-500"
                aria-label="Search examples"
              />
            </div>
            {store.removedColumns.length > 0 && (
              <Tooltip content="Restore last removed column">
                <Button
                  size="sm"
                  variant="outline"
                  onClick={() =>
                    toggleColumn(
                      store.removedColumns[store.removedColumns.length - 1],
                    )
                  }
                  disabled={isLoading}
                  className="h-10 whitespace-nowrap hover:bg-gray-50"
                >
                  <ReloadIcon className="mr-2 h-4 w-4" />
                  Restore Column
                </Button>
              </Tooltip>
            )}
          </div>

          <div className="overflow-hidden rounded-lg border border-gray-200 shadow-sm">
            {isLoading || isSearching ? (
              <TableLoadingState />
            ) : resultData?.length ? (
              <div className="max-h-[500px] max-w-[900px] overflow-auto">
                <Table className="w-full">
                  <TableHeader className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50">
                    <TableRow>
                      <TableHead className="sticky left-0 z-20 w-16 bg-gray-50">
                        <span className="sr-only">Selection column</span>
                      </TableHead>
                      {store.visibleColumns.map((key) => (
                        <TableHead
                          key={key}
                          className="group relative min-w-[150px] bg-gray-50 pr-10 font-semibold"
                        >
                          <div className="overflow-x-auto">{key}</div>
                          {store.visibleColumns.length > 1 && (
                            <Tooltip content={`Remove ${key} column`}>
                              <Button
                                size="tinyIcon"
                                variant="ghost"
                                className="absolute right-1 top-1/2 -translate-y-1/2 opacity-0 transition-opacity group-hover:opacity-100"
                                onClick={() => toggleColumn(key)}
                              >
                                <Cross2Icon className="h-4 w-4" />
                              </Button>
                            </Tooltip>
                          )}
                        </TableHead>
                      ))}
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {resultData.map((example: Example) => {
                      const selected = isExampleSelected(example);
                      return (
                        <TableRow
                          key={example.id}
                          className={`group transition-colors ${
                            selected
                              ? "bg-blue-50 hover:bg-blue-100"
                              : "hover:bg-gray-50"
                          }`}
                          onClick={() => handleAddExample(example)}
                          style={{ cursor: "pointer" }}
                        >
                          <TableCell
                            className="sticky left-4 z-10"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <Checkbox
                              checked={selected}
                              onChange={() => handleAddExample(example)}
                              className="h-5 w-5 transition-all duration-200"
                            />
                          </TableCell>
                          {store.visibleColumns.map((key) => (
                            <TableCell
                              key={key}
                              className="min-w-[150px] max-w-[300px] py-2"
                            >
                              <CellContent
                                value={example.value[key]}
                                searchQuery={searchQuery}
                              />
                            </TableCell>
                          ))}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
            ) : (
              <div className="flex h-32 items-center justify-center text-gray-500">
                No results found
              </div>
            )}
          </div>

          <div className="flex items-center justify-between pt-2">
            <GenericTablePagination
              pages={totalPages}
              hasNext={hasNext}
              page={currentPage}
              pageSize={pageSize}
              pageSizeOptions={pageSizeOptions}
              setPageIndex={setCurrentPage}
              setPageSize={handleSetPageSize}
            />

            <div className="ml-auto flex gap-2">
              <Button
                size="default"
                variant="outline"
                onClick={handleClose}
                disabled={isLoading}
                className="hover:bg-gray-50"
              >
                Cancel
              </Button>
              <Button
                size="default"
                onClick={handleClose}
                disabled={isLoading}
                className="hover:bg-blue-600"
              >
                Done
              </Button>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default observer(ExampleSelectionDialog);
