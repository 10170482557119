import { ExclamationIcon } from "@heroicons/react/outline";
import { Terminal } from "lucide-react";
import { useEffect, useRef, useState } from "react";

import { ButtonCopy, ButtonMaximize, ToolCalls } from "@/components";
import ContentArea from "@/components/ContentArea";
import ImageVariableModal from "@/components/ImageVariableModal";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { Message, TemplateFormat } from "@/types";
import { PromptVersionSnippet } from "@/types/apiGetters";
import {
  copyTextToClipboard,
  getImageVariable,
  getStringContent,
} from "@/utils/utils";
import { ImageIcon } from "@radix-ui/react-icons";

const MessagePromptTemplate = ({
  messageTemplate,
  renderedMessageTemplate,
  invalidSnippets,
  sourcedSnippets = [],
  isXrayMode = false,
  isFirstMessage = false,
}: {
  messageTemplate: Message;
  renderedMessageTemplate?: Message | null;
  templateFormat: TemplateFormat;
  invalidSnippets?: string[];
  highlightInvalid?: boolean | false;
  sourcedSnippets?: Array<PromptVersionSnippet>;
  isXrayMode?: boolean;
  isFirstMessage?: boolean;
}) => {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLTextAreaElement>(null);
  const getContent = () => {
    if (messageTemplate.role === "assistant") {
      if (messageTemplate.function_call)
        return messageTemplate.function_call.arguments;
    }
    if (messageTemplate.role === "placeholder") return messageTemplate.name;
    return getStringContent(messageTemplate);
  };

  useEffect(() => {
    if (ref.current) {
      ref.current.style.height = "auto";
      ref.current.style.height = ref.current.scrollHeight + "px";
    }
  }, [ref]);

  const isRoleUser = messageTemplate.role === "user";

  const messageForm = (
    <div className="flex h-full flex-col gap-2 overflow-auto p-1">
      <Chat
        message={messageTemplate}
        open={open}
        invalidSnippets={invalidSnippets}
        sourcedSnippets={sourcedSnippets}
        isXrayMode={isXrayMode}
        renderedTemplate={
          renderedMessageTemplate
            ? getStringContent(renderedMessageTemplate)
            : ""
        }
      />
      <div
        className={`text-xs text-gray-500 ${
          messageTemplate.role === "placeholder" ? "" : "hidden"
        }`}
      >
        This placeholder is used to inject one or more messages into the prompt
      </div>
      <div className="grid grid-cols-4 gap-2 py-1">
        {isRoleUser && messageTemplate.content
          ? messageTemplate.content.map((content, index) =>
              content.type === "image_url" ? (
                <div
                  key={index}
                  className={`w-full1 relative h-40 ${
                    content.image_url.url ? "" : "hidden"
                  }`}
                >
                  <img
                    src={content.image_url.url}
                    className="h-full w-full rounded-md object-cover"
                    alt="User uploaded"
                  />
                </div>
              ) : null,
            )
          : null}
      </div>
    </div>
  );

  const renderImageVariableIcon = () => {
    const imageVariable = getImageVariable(messageTemplate);
    return imageVariable ? (
      <ImageVariableModal imageVariable={imageVariable}>
        <div className="flex items-center gap-1">
          <div className="flex items-center gap-2 rounded-md bg-gray-100 px-3 py-1 text-sm text-gray-800">
            <ImageIcon className="h-4 w-4" />
            {imageVariable}
          </div>
        </div>
      </ImageVariableModal>
    ) : null;
  };

  return (
    <div
      className={`group flex flex-col ${
        isFirstMessage ? "h-full" : "border-b hover:bg-gray-50"
      } p-2`}
    >
      <div className={`flex flex-col gap-2 ${isFirstMessage ? "h-full" : ""}`}>
        {messageForm}
        <div className="flex justify-end gap-2">
          {renderImageVariableIcon()}
          <div className="flex items-center">
            <ButtonCopy
              onClick={async () => await copyTextToClipboard(getContent())}
            />
          </div>
          <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild>
              <ButtonMaximize />
            </DialogTrigger>
            <DialogContent className="group h-full max-w-full">
              {messageForm}
            </DialogContent>
          </Dialog>
        </div>
      </div>
    </div>
  );
};

function Chat({
  message,
  open,
  invalidSnippets,
  sourcedSnippets = [],
  isXrayMode = false,
  renderedTemplate = null,
}: {
  message: Message;
  open: boolean;
  invalidSnippets?: string[];
  sourcedSnippets?: Array<PromptVersionSnippet>;
  isXrayMode?: boolean;
  renderedTemplate?: string | null;
}) {
  const parseFunctionArguments = (functionArguments: string | undefined) => {
    try {
      return JSON.stringify(
        JSON.parse(functionArguments ?? "{}"),
        undefined,
        2,
      );
    } catch (e) {
      const unparsableText = functionArguments ?? "{}";
      return (
        <>
          {message.role === "assistant" && (
            <div className="mb-2 italic text-red-500">
              <ExclamationIcon className="mr-2 inline-block h-4 w-4" />
              Error parsing below JSON
            </div>
          )}
          <div
            className={
              message.role === "assistant" ? "text-gray-600" : "text-gray-700"
            }
          >
            {unparsableText}
          </div>
        </>
      );
    }
  };

  let content;
  const isAssistantFunctionCall =
    message.role === "assistant" && message.function_call;
  if (message.role === "assistant") {
    if (message.function_call) {
      content = parseFunctionArguments(message.function_call.arguments);
    } else if (message.tool_calls) {
      content = (
        <ToolCalls message={message} sourcedSnippets={sourcedSnippets} />
      );
    } else {
      content = getStringContent(message);
    }
  } else if (message.role === "tool") {
    content = (
      <div>
        <div className="font-mono font-bold">
          <Terminal className="mr-1 inline-block h-4 w-4" />
          {message.name ?? "Tool"}
        </div>
        <div className="whitespace-pre-wrap font-mono">
          {parseFunctionArguments(getStringContent(message))}
        </div>
      </div>
    );
  } else if (message.role === "placeholder") {
    content = message.name;
  } else {
    content = getStringContent(message);
  }
  return (
    <div className={`flex flex-col gap-2 text-sm ${open && "h-full"}`}>
      <div className={`font-bold ${open ? "text-base" : "text-sm"} uppercase`}>
        <span
          className={`${
            !open && "group-hover:border-b group-hover:border-gray-600"
          }`}
        >
          {message.role}
        </span>
      </div>
      <div
        className={`overflow-auto ${
          message.role === "function" || isAssistantFunctionCall
            ? "whitespace-pre-wrap font-mono"
            : ""
        } ${open ? "h-full rounded-none border border-gray-200" : ""}`}
      >
        <div className={`flex flex-col gap-2 ${open && "p-2 px-3"}`}>
          {(isAssistantFunctionCall || message.role === "function") && (
            <div className="font-mono font-bold">
              <Terminal className="mr-1 inline-block h-4 w-4" />
              {message.role === "assistant"
                ? message.function_call?.name
                : message.name}
            </div>
          )}
          <div
            className={`whitespace-pre-wrap ${
              (isAssistantFunctionCall || message.role === "function") && "pl-1"
            } ${
              message.role === "placeholder"
                ? "rounded bg-gray-100 px-2 font-mono"
                : ""
            }`}
          >
            {message.role === "tool" ||
            (message.role === "assistant" && message.tool_calls) ? (
              content
            ) : typeof content === "string" ? (
              <ContentArea
                className="border-none "
                sourcedSnippets={sourcedSnippets}
                readOnly
                isXrayMode={isXrayMode}
                value={
                  isXrayMode
                    ? renderedTemplate
                        ?.replace(/</g, "&lt;")
                        ?.replace(/>/g, "&gt;") || content
                    : content
                }
              />
            ) : (
              content
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MessagePromptTemplate;
