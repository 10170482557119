import { WorkflowNodeType } from "@/components/Workflows/types";
import { formatRawNodeType } from "@/components/Workflows/utils";
import NodeTypeIcon from "../NodeTypeIcon";

const NodeTypeLabel: React.FC<{
  type: WorkflowNodeType;
  iconBackgroundColor: string;
  iconTextColor: string;
}> = ({ type, iconBackgroundColor, iconTextColor }) =>
  type && (
    <span className="text-md flex flex-row items-center gap-x-1 font-medium text-gray-500">
      {formatRawNodeType(type) || ""}
      <NodeTypeIcon
        type={type}
        backgroundColor={iconBackgroundColor}
        textColor={iconTextColor}
      />
    </span>
  );

export default NodeTypeLabel;
