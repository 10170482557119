import { PromptVersionSnippet } from "@/types/apiGetters";
import { useLocalObservable } from "mobx-react-lite";
import React, { createContext, useContext } from "react";
import TextAreaStore, { ITextAreaStore } from "./store";

const ContentAreaContext = createContext<ITextAreaStore | null>(null);
export const Provider = ContentAreaContext.Provider;

interface ContentAreaProviderProps {
  children: React.ReactNode;
  defaultValue?: ITextAreaStore;
  value?: string | number | readonly string[] | undefined;
  sourcedSnippets?: Array<PromptVersionSnippet>;
}

const ContentAreaProvider = React.forwardRef<
  ITextAreaStore,
  ContentAreaProviderProps
>(({ children, defaultValue, value, sourcedSnippets }, ref) => {
  const store = useLocalObservable(
    () =>
      defaultValue ||
      new TextAreaStore(value?.toString() || "", sourcedSnippets),
  );

  React.useImperativeHandle(ref, () => store, [store]);

  return <Provider value={store}>{children}</Provider>;
});

const useContentArea = () => {
  const context = useContext(ContentAreaContext);
  if (context === null) {
    throw new Error("useContentArea must be used within a ContentAreaProvider");
  }
  return context as ITextAreaStore;
};

export { ContentAreaProvider, useContentArea };
