import { DatasetEditorContext } from "@/components/DatasetEditor";
import LoadingSpinner from "@/components/LoadingSpinner";
import TableCell from "@/components/ui/table/core/TableCell";
import { displayErrorToast } from "@/utils/toast";
import { Cell } from "@tanstack/react-table";
import { ReactNode, useCallback, useContext } from "react";
import CellBody from "./CellBody";
import JSONIndicator from "./JSONIndicator";
import useCellEditing from "./hooks/useCellEditing";
import useCellType from "./hooks/useCellType";

interface DatasetViewerTableCellProps {
  cell?: Cell<any, any>;
  children: ReactNode;
  index: number;
  readOnly: boolean;
  [key: string]: any;
}

const DatasetViewerTableCell = (props: DatasetViewerTableCellProps) => {
  const { cell, children, index, readOnly } = props;
  const { isDraft } = useContext(DatasetEditorContext);

  const { isEditable, isActionsCell, isJSONCell, isPlaceholder } = useCellType(
    cell,
    index,
  );

  const { isLoading, onInput, currentValue, setValue, isInlineEditingRef } =
    useCellEditing(cell);

  const toggleDataType = useCallback(() => {
    try {
      const str = isJSONCell
        ? JSON.stringify(currentValue, null, 2)
        : JSON.parse(currentValue);
      if (String(str) === String(currentValue)) throw new Error();
      else {
        isInlineEditingRef.current = true;
        setValue(str);
      }
    } catch (e) {
      displayErrorToast("Invalid JSON");
    }
  }, [currentValue, isInlineEditingRef, isJSONCell, setValue]);

  return (
    <TableCell key={cell?.id} placeholder={isPlaceholder}>
      {!isPlaceholder && !isActionsCell && isLoading && (
        <div className="absolute bottom-2 right-0  z-[1] flex items-center justify-center">
          <LoadingSpinner size={3} />
        </div>
      )}
      {!isPlaceholder &&
        isDraft &&
        !isActionsCell &&
        currentValue &&
        String(currentValue).trim() !== "" && ( // Check if currentValue is not empty (don't show JSON indicator for empty cells)
          <JSONIndicator
            disabled={isLoading}
            isJSON={isJSONCell}
            onClick={toggleDataType}
          />
        )}

      <CellBody
        cell={cell}
        children={children}
        value={currentValue}
        index={index}
        onInput={onInput}
        contentEditable={!readOnly && isEditable && !isLoading}
        isInlineEditingRef={isInlineEditingRef}
        suppressContentEditableWarning
      />
    </TableCell>
  );
};

export default DatasetViewerTableCell;
