import { useNavigate } from "react-router-dom";

import RequestDisplay from "@/components/RequestDisplay";
import { useRequest } from "@/queries";
import LoadingSpinner from "./LoadingSpinner";

export const RequestContent = ({ requestId }: { requestId: string }) => {
  const navigate = useNavigate();
  const request = useRequest(requestId);
  const requestInfo = request.data;

  if (requestId === "null") {
    console.log("Navigating to home");
    navigate("/");
  }

  if (request.isLoading) {
    return <LoadingSpinner size={5} />;
  }

  return <RequestDisplay requestId={requestId} requestInfo={requestInfo} />;
};
