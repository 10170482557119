import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { ReportColumn, VariableConfiguration } from "@/types/evaluate";
import { useState } from "react";
import { useBandaid } from "../utils/BandaidContext";
import { ModalStep } from "./ModalRouter";

const VariableBuilder = ({
  columnData,
  navigatePrevious,
  saveColumnAction,
  editable,
}: {
  columnData: Partial<ReportColumn>;
  navigatePrevious: () => void;
  saveColumnAction: (newColumnDataArg: ReportColumn) => void;
  editable: boolean;
}) => {
  const [name, setName] = useState<string | null>(columnData.name || null);
  const [type, setType] = useState<string | null>(
    columnData.configuration?.value?.type ?? null,
  );
  const [value, setValue] = useState<string | null>(
    columnData.configuration?.value?.value || null,
  );

  const [dataIsValid, setDataIsValid] = useState<{
    name: boolean;
    type: boolean;
    value: boolean;
  }>({
    name: true,
    type: true,
    value: true,
  });

  const saveEndpointAction = () => {
    if (!name) {
      setDataIsValid({ name: false, type: true, value: true });
      return;
    } else if (!type) {
      setDataIsValid({ name: true, type: false, value: true });
      return;
    } else if (!value) {
      setDataIsValid({ name: true, type: true, value: false });
      return;
    }

    setDataIsValid({ name: true, type: true, value: true });

    saveColumnAction({
      ...columnData,
      name: name,
      configuration: {
        value: {
          type,
          value,
        },
      } as VariableConfiguration,
    } as ReportColumn);
  };

  const bandaid = useBandaid();

  return (
    <ModalStep
      navigatePrevious={navigatePrevious}
      navigateNext={saveEndpointAction}
      nextButtonText={editable ? "Save Step" : "Done"}
    >
      <div className="flex flex-col gap-4">
        <div>
          <div className="text-lg font-semibold">Configure Static Value</div>
          <div className="max-w-md text-sm text-gray-500">
            This step will add a new static value to the{" "}
            {bandaid ? "node" : "report"}
          </div>
        </div>
        {!bandaid && (
          <div className="flex items-center">
            <label htmlFor="name" className="flex w-1/3 items-center">
              {bandaid ? "Node" : "Column"} name:
            </label>
            <input
              id="name"
              className={`w-2/3 rounded border ${
                !dataIsValid.name ? "border-red-500" : "border-gray-300"
              } px-2 py-1 disabled:cursor-not-allowed disabled:bg-gray-50`}
              value={name || ""}
              onChange={(e) => setName(e.target.value)}
              disabled={!editable}
            />
          </div>
        )}
        {!dataIsValid.name && (
          <div className="flex items-center">
            <div className="w-1/3">&nbsp;</div>
            <div className="w-2/3 text-left text-xs text-red-500">
              Name is required
            </div>
          </div>
        )}

        <div className="flex items-center">
          <label htmlFor="type" className="flex w-1/3 items-center">
            Type:
          </label>
          <div className="w-2/3">
            <DropdownMenu>
              <DropdownMenuTrigger className="w-full">
                {types.find((t) => t.value === type)?.label || (
                  <span className="font-normal text-gray-500">
                    Select a type...
                  </span>
                )}
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                {types.map((type) => (
                  <DropdownMenuItem
                    key={type.value}
                    onSelect={() => setType(type.value)}
                  >
                    {type.label}
                  </DropdownMenuItem>
                ))}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
        {!dataIsValid.type && (
          <div className="flex items-center">
            <div className="w-1/3">&nbsp;</div>
            <div className="w-2/3 text-left text-xs text-red-500">
              Type is required
            </div>
          </div>
        )}

        <div className="flex items-center">
          <label htmlFor="value" className="flex w-1/3 items-center">
            Value:
          </label>
          <textarea
            id="value"
            className={`w-2/3 rounded border ${
              !dataIsValid.value ? "border-red-500" : "border-gray-300"
            } px-2 py-1 disabled:cursor-not-allowed disabled:bg-gray-50`}
            value={value || ""}
            onChange={(e) => setValue(e.target.value)}
            disabled={!editable}
          />
        </div>
        {!dataIsValid.value && (
          <div className="flex items-center">
            <div className="w-1/3">&nbsp;</div>
            <div className="w-2/3 text-left text-xs text-red-500">
              Value is required
            </div>
          </div>
        )}
      </div>
    </ModalStep>
  );
};

const types = [
  { value: "string", label: "String" },
  { value: "json", label: "JSON" },
] as const;

export default VariableBuilder;
