import React, { forwardRef, useImperativeHandle } from "react";

import { useLocalObservable } from "mobx-react-lite";
import { WorkflowStore, WorkflowStoreProps } from "./workflow-store";

const WorkflowContext = React.createContext<WorkflowStore | null>(null);

const useWorkflow = () => {
  const context = React.useContext(WorkflowContext);
  if (!context) {
    throw new Error(
      "useWorkflowContext must be used within a WorkflowProvider",
    );
  }
  return context;
};

interface WorkflowProviderProps {
  children: React.ReactNode;
  workflow: WorkflowStoreProps;
}

const WorkflowProvider = forwardRef(
  ({ children, workflow }: WorkflowProviderProps, ref) => {
    const workflowStore = useLocalObservable(
      () =>
        new WorkflowStore({
          ...workflow,
          nodes: workflow.nodes,
        }),
    );
    useImperativeHandle(ref, () => workflowStore);
    return (
      <WorkflowContext.Provider value={workflowStore}>
        {children}
      </WorkflowContext.Provider>
    );
  },
);

export { useWorkflow, WorkflowContext, WorkflowProvider };
