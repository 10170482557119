import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { observer } from "mobx-react-lite";
import { useCallback, useMemo } from "react";

type DisplayType = "json" | "xml";

interface DataPreviewProps {
  data: Record<string, any>;
  displayType: DisplayType;
  onChange?: (type: DisplayType) => void;
}

const XMLRenderer = (
  obj: any,
  nodeName: string = "example",
  level: number = 0,
): string => {
  const indent = "  ".repeat(level);

  if (level === 0) {
    let xml = `${indent}<examples>\n`;
    xml += XMLRenderer(obj, nodeName, level + 1);
    xml += `${indent}  ⋮\n`; // Added vertical dots
    xml += `${indent}</examples>\n`;
    return xml;
  }

  if (typeof obj !== "object" || obj === null) {
    return `${indent}<${nodeName}>${obj}</${nodeName}>\n`;
  }

  let xml = `${indent}<${nodeName}>\n`;
  for (const [key, value] of Object.entries(obj)) {
    xml += XMLRenderer(value, key, level + 1);
  }

  xml += `${indent}</${nodeName}>\n`;
  return xml;
};

const DataPreview = ({ data, displayType, onChange }: DataPreviewProps) => {
  const handleTypeChange = useCallback(
    (newType: DisplayType) => {
      onChange?.(newType);
    },
    [onChange],
  );

  const formattedData = useMemo(() => {
    if (displayType === "json") {
      const jsonStr = JSON.stringify([data], null, 2);
      return jsonStr.slice(0, -2) + ",\n  ⋮\n]"; // Added vertical dots
    }
    return XMLRenderer(data);
  }, [displayType, data]);

  const isEmpty = !data || Object.keys(data).length === 0;

  return (
    <div className="rounded-lg border border-gray-200 bg-white p-6 shadow-sm transition-shadow duration-200 hover:shadow-md">
      <div className="mb-4 flex items-center justify-between">
        <div
          className="flex gap-2"
          role="tablist"
          aria-label="Data format selection"
        >
          <div className="flex flex-col gap-1.5">
            <label className="text-sm font-medium text-gray-700">
              Insert as
            </label>
            <Select
              value={displayType}
              onValueChange={handleTypeChange}
              defaultValue="json"
            >
              <SelectTrigger className="w-36 border-gray-200 bg-gray-50 text-gray-700 hover:bg-gray-100 focus:ring-1 focus:ring-gray-200">
                <SelectValue placeholder="Select format" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="json">JSON</SelectItem>
                <SelectItem value="xml">XML</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>
        <span className="text-xs font-medium uppercase tracking-wide text-gray-400">
          Preview
        </span>
      </div>
      <pre
        className="overflow-auto rounded-lg border border-gray-100 bg-gray-50 p-5 font-mono text-sm"
        role="tabpanel"
      >
        {isEmpty ? (
          <span className="text-xs font-normal text-gray-400">
            Data preview will appear here...
          </span>
        ) : (
          formattedData
        )}
      </pre>
    </div>
  );
};

export default observer(DataPreview);
