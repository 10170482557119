import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { cn } from "@/lib/utils";
import { Table as TableType } from "@tanstack/react-table";
import { ChevronsUpDown } from "lucide-react";
import React from "react";

interface ShimmerProps extends React.HTMLAttributes<HTMLDivElement> {
  width?: string | number;
  height?: string | number;
  borderRadius?: string | number;
}

const Skeleton: React.FC<ShimmerProps> = ({
  width = "100%",
  height = "1rem",
  borderRadius = "0.25rem",
  className,
  ...props
}) => {
  return (
    <div
      className={cn(
        "animate-pulse bg-gradient-to-r from-gray-200 via-gray-300 to-gray-200 bg-[length:200%_100%]",
        className,
      )}
      style={{
        width,
        height,
        borderRadius,
      }}
      {...props}
    />
  );
};

interface TableShimmerProps<TData> {
  table: TableType<TData>;
  rowCount?: number;
}

const TableShimmer = <TData,>({
  table,
  rowCount = 5,
}: TableShimmerProps<TData>) => {
  return (
    <div className="rounded-md border">
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHead key={header.id} className="whitespace-nowrap">
                  <Button variant="ghost" className="-ml-3 h-8" disabled>
                    <Skeleton width="100px" height="1rem" />
                    <ChevronsUpDown className="ml-2 h-4 w-4 text-gray-300" />
                  </Button>
                </TableHead>
              ))}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {Array.from({ length: rowCount }).map((_, index) => (
            <TableRow key={index}>
              {table.getAllColumns().map((column) => (
                <TableCell key={column.id}>
                  <Skeleton width="100%" height="1rem" />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export { Skeleton, TableShimmer };
