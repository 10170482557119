import { DivButton } from "@/components/ui/button";

import { ToastType } from "@/enums";
import { useEditInputVariables } from "@/queries";
import { displayToast } from "@/utils/toast";
import { PlusCircleIcon } from "lucide-react";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useWorkflow } from "../../../workflow-context";
import InputVariableList from "./InputVariablesList";

const InputVariableConfigurator = observer(() => {
  const workflow = useWorkflow();
  const [inputVariables, setInputVariables] = useState<
    Array<{ id: string; key: string; value: string }>
  >(() =>
    Object.entries(workflow.input_variables).map(([key, value]) => ({
      id: uuidv4(),
      key,
      value,
    })),
  );
  const [isSaving, setIsSaving] = useState(false);
  const editInputVariables = useEditInputVariables(
    workflow.userToken!,
    workflow.workspace_id!,
  );

  const handleAddVariable = () => {
    const newVariable = {
      id: uuidv4(),
      key: `var_${inputVariables.length + 1}`,
      value: "",
    };
    setInputVariables((prev) => [...prev, newVariable]);
  };

  const handleUpdateVariable = (
    id: string,
    field: "key" | "value",
    newValue: string,
  ) => {
    setInputVariables((prev) =>
      prev.map((variable) =>
        variable.id === id ? { ...variable, [field]: newValue } : variable,
      ),
    );
  };

  const handleRemoveVariable = (id: string) => {
    setInputVariables((prev) => prev.filter((variable) => variable.id !== id));
  };

  const handleSaveChanges = () => {
    setIsSaving(true);
    const variablesObject = inputVariables.reduce(
      (acc, { key, value }) => ({ ...acc, [key]: value }),
      {},
    );

    editInputVariables.mutate(
      {
        input_variables: variablesObject,
      },
      {
        onSuccess: () => {
          workflow.updateInputVariables(variablesObject);
          displayToast("Successfully saved input variables", ToastType.success);
          setIsSaving(false);
        },
        onError: () => {
          setIsSaving(false);
        },
      },
    );
  };

  return (
    <div className="h-full w-full overflow-auto bg-white p-6">
      <h2 className="mb-2 text-2xl font-semibold text-gray-800">
        Configure Input Variables
      </h2>
      <p className="mb-6 text-sm text-gray-600">
        Note: Only variable names are saved. Values are only used at runtime
        preview.
      </p>
      {inputVariables.length > 0 ? (
        <>
          <div className="mb-4 flex items-center justify-between">
            <div className="text-lg font-semibold text-gray-800">
              Input Variables ({inputVariables.length})
            </div>
          </div>
          <div className="mb-2 grid grid-cols-3 gap-4 rounded-t-lg bg-gray-100 p-3 text-sm font-medium text-gray-700">
            <div>Name</div>
            <div className="col-span-2">Value (for runtime preview)</div>
          </div>
          <InputVariableList
            variables={inputVariables}
            onUpdate={handleUpdateVariable}
            onRemove={handleRemoveVariable}
          />
          <p className="mt-4 rounded-lg bg-blue-50 p-3 text-sm text-blue-700">
            <span className="font-medium">Note:</span> Values are for runtime
            preview only and will not be saved with the workflow.
          </p>
        </>
      ) : (
        <div className="flex h-[200px] items-center justify-center rounded-lg border-2 border-gray-300 bg-gray-50">
          <p className="text-center text-gray-500">
            No input variables defined. Add some to get started!
          </p>
        </div>
      )}
      <div className="mt-6 flex items-center justify-between">
        <DivButton
          onClick={handleAddVariable}
          variant={"outline"}
          className="select-none"
        >
          <PlusCircleIcon size={18} className="mr-2" />
          Add Variable
        </DivButton>
        <div className="flex items-center space-x-2">
          <DivButton
            onClick={handleSaveChanges}
            variant={"default"}
            className="select-none"
            disabled={isSaving}
          >
            {isSaving ? "Saving..." : "Save Variable Names"}
          </DivButton>
        </div>
      </div>
    </div>
  );
});

export default InputVariableConfigurator;
