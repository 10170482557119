import { organizeTemplates } from "@/components/Registry/utils";
import { Folder } from "@/types/folders";
import { PromptRegistry } from "@/types/prompt-registry";
import { useParams } from "react-router-dom";

export function useFoldersData({
  templates,
  folders,
  folderPath,
}: {
  templates: PromptRegistry[];
  folders: Folder[];
  folderPath: string[];
}) {
  const { folderId } = useParams();

  if (!folders.length) {
    return { filteredFolders: [], foldersWithTemplates: [], folderId };
  }

  // Filter folders based on whether we're at the root level
  const filteredFolders =
    folderPath.length <= 0
      ? folders.filter((folder: Folder) => !folder.parent_id)
      : folders;

  // Organize templates into folders
  const templateFolderMap = organizeTemplates(templates);
  const foldersArray = Array.from(templateFolderMap.values());

  // Get folders that have templates and are not the current folder
  const foldersWithTemplates = foldersArray.filter(
    (folder) =>
      folder.id !== null &&
      folder.templates.length > 0 &&
      folder.id !== parseInt(folderId ?? ""),
  );

  return { filteredFolders, foldersWithTemplates, folderId };
}
