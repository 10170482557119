import { unstable_useBlocker as useBlocker } from "react-router-dom";

interface ExitPageConfirmationProps {
  when: boolean;
  message: string;
  execute: () => void;
}

interface Location {
  pathname: string;
}

interface Transition {
  currentLocation: Location;
  nextLocation: Location;
}

const ExitPageConfirmation = (props: ExitPageConfirmationProps) => {
  const isPathMatch = (path1: string, path2: string, matcher: string) => {
    return path1.includes(matcher) && path2.includes(matcher);
  };

  const isValidPromptId = (path: string) => {
    const id = path.split("prompt/")[1]?.split("/")[0];
    const numId = Number(id);
    return !isNaN(numId) && numId !== 0;
  };

  const shouldSkipConfirmation = (transition: Transition) => {
    const { currentLocation, nextLocation } = transition;
    const current = currentLocation.pathname;
    const next = nextLocation.pathname;

    return (
      // Chat mode switch - template edit
      isPathMatch(current, next, "create-prompt") ||
      // Chat mode switch - playground
      isPathMatch(current, next, "playground") ||
      // Submit creation - template creation
      (current.includes("create-prompt") &&
        next.includes("prompt/") &&
        isValidPromptId(next)) ||
      // Cancel edition - template edit
      (current.includes("prompt") &&
        next.includes("prompt/") &&
        isValidPromptId(next))
    );
  };

  useBlocker((transition) => {
    if (props.when && !shouldSkipConfirmation(transition)) {
      if (window.confirm(props.message)) {
        props.execute();
        return false;
      }
      return true;
    }
    props.execute();
    return false;
  });

  return <div key={props.message} />;
};

export default ExitPageConfirmation;
