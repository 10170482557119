export const useIsCurrentPageNumber = () => {
  const isCurrentPageNumber = (navigationItem: {
    name: string;
    href: string;
    altHrefs?: string[];
  }) => {
    // Get the current page from the URL
    const path = window.location.pathname;
    return (
      path.includes(`/${navigationItem.href}`) ||
      (navigationItem.altHrefs &&
        navigationItem.altHrefs.some((alt) => path.includes(`/${alt}`)))
    );
  };

  return isCurrentPageNumber;
};
