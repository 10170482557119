import { URL_PARAM_KEYS } from "@/utils/utils";
import { EmojiSadIcon, InformationCircleIcon } from "@heroicons/react/outline";
import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const useDefaultDateRange = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [defaultDateRangeLimitIsOn, setDefaultDateRangeLimitIsOn] =
    useState(true);

  useEffect(() => {
    const value =
      (searchParams.get(URL_PARAM_KEYS.DEFAULT_DATE_RANGE_LIMIT_IS_ON) ||
        "true") === "true";
    setDefaultDateRangeLimitIsOn(value);
  }, [searchParams]);

  const turnOffDefaultDateRangeLimit = useCallback(() => {
    const newParams = new URLSearchParams(searchParams);
    newParams.set(URL_PARAM_KEYS.DEFAULT_DATE_RANGE_LIMIT_IS_ON, "false");
    setSearchParams(newParams);
  }, [searchParams, setSearchParams]);
  return [defaultDateRangeLimitIsOn, turnOffDefaultDateRangeLimit] as [
    boolean,
    () => void,
  ];
};

const NoResultsFooter = ({ requestsOn = false }: { requestsOn?: boolean }) => {
  const [defaultDateRangeLimitIsOn, turnOffDefaultDateRangeLimit] =
    useDefaultDateRange();
  return (
    <div className="flex flex-col items-center justify-center break-words py-2">
      <p className="text-gray-500">
        No {requestsOn ? "requests" : "traces"} found{" "}
        <EmojiSadIcon className="-mt-1 inline h-6 w-auto text-gray-500" />
      </p>
      {!requestsOn && (
        <p className="mt-2 px-2 text-center text-xs text-gray-500">
          Make sure to enter exact values when searching by{" "}
          <span className="font-mono ">trace_id</span> or metadata key/value
          combinations.
        </p>
      )}
      {requestsOn && defaultDateRangeLimitIsOn && (
        <p
          className="items-top my-4 flex max-w-[200px] cursor-pointer justify-center gap-x-2 rounded-md border border-blue-200 bg-blue-50 p-3 text-left text-xs text-blue-500 hover:bg-blue-100 hover:text-blue-400"
          onClick={turnOffDefaultDateRangeLimit}
        >
          <InformationCircleIcon className="h-5 w-5" />
          Click here to turn off the default date range limit
        </p>
      )}
    </div>
  );
};

export default NoResultsFooter;
