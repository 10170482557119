import {
  useCompositionHandling,
  useKeyboardHandling,
  usePasteHandling,
  useCursorHandling,
} from "./handlers";

const useHandlers = (
  ref: React.RefObject<HTMLDivElement>,
  widgetsEnabled = true,
) => {
  const { isComposing, handleCompositionStart, handleCompositionEnd } =
    useCompositionHandling(ref);
  const { handleKeyDown, handleInput } = useKeyboardHandling(
    isComposing,
    widgetsEnabled,
  );
  const { handlePaste } = usePasteHandling();
  const { handleClick, handleKeyUp } = useCursorHandling();

  return {
    onKeyDown: handleKeyDown,
    onPaste: handlePaste,
    onCompositionStart: handleCompositionStart,
    onCompositionEnd: handleCompositionEnd,
    onInput: handleInput,
    onClick: handleClick,
    onKeyUp: handleKeyUp,
  };
};

export default useHandlers;
