import { Edge, Node } from "@xyflow/react";
import { generateEdgesFromNodes, positionNodes } from "../../../utils";
import { useWorkflow } from "../../workflow-context";
import { useMemo } from "react";

const useInitialNodes = (readonly = false) => {
  const { nodes } = useWorkflow();

  const positionedNodesAndEdges = useMemo(() => {
    const sortedNodes = [...nodes].sort((a, b) => a.name.localeCompare(b.name));
    const positionedNodes = positionNodes(sortedNodes).map((node) => ({
      ...node,
      draggable: !readonly,
    }));
    const edges = generateEdgesFromNodes(sortedNodes);
    return [positionedNodes, edges] as [Node[], Edge[]];
  }, [nodes, readonly]);

  return positionedNodesAndEdges;
};

export default useInitialNodes;
