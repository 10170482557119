import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { useDatasetGroups } from "@/queries";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useExamples } from "../example-widget-store";
import { Dataset } from "../types";

const VersionSelect = observer(({ isReadOnly }: { isReadOnly: boolean }) => {
  const store = useExamples();
  const versionSelectRef = useRef<HTMLButtonElement>(null);
  const { data } = useDatasetGroups(
    useAuth()?.userToken!,
    Number(useUser()?.activeWorkspaceId),
  );
  const dataset_groups = useMemo(() => data?.dataset_groups || [], [data]);

  const versions = useMemo(
    () =>
      dataset_groups
        .find(({ name }: { name: string }) => name === store.selectedDataset)
        ?.datasets.map((dataset: Dataset) => dataset.version_number)
        .sort((a: number, b: number) => b - a) || [],
    [dataset_groups, store.selectedDataset],
  );

  useEffect(() => {
    if (
      store.selectedDataset &&
      store.selectedVersion <= 0 &&
      versions.length > 0
    ) {
      runInAction(() => {
        store.setSelectedVersion(versions[0]);
      });
    }
  }, [store, store.selectedDataset, store.selectedVersion, versions]);

  const handleVersionChange = useCallback(
    (value: string) => {
      runInAction(() => {
        store.setSelectedVersion(Number(value));
      });
    },
    [store],
  );

  return (
    <Select
      onValueChange={handleVersionChange}
      value={String(store.selectedVersion || "")}
      disabled={isReadOnly || !store.selectedDataset}
    >
      <SelectTrigger className="w-full bg-white" ref={versionSelectRef}>
        <SelectValue placeholder="Select Version">
          {(store.selectedVersion > 0 &&
            `Version #${store.selectedVersion}`) || (
            <span className="text-muted-foreground">Select a version</span>
          )}
        </SelectValue>
      </SelectTrigger>
      <SelectContent className="max-h-[300px] overflow-y-auto">
        {versions
          .filter((version: number) => version > 0)
          .map((version: number) => (
            <SelectItem key={version} value={String(version)}>
              Version #{version}
            </SelectItem>
          ))}
      </SelectContent>
    </Select>
  );
});

export default VersionSelect;
