import { Button } from "@/components/ui/button";
import { forwardRef } from "react";

const SelectedExamplesBanner = forwardRef<
  HTMLButtonElement,
  {
    onClick?: () => void;
    selectedCount?: number;
  }
>(({ onClick, selectedCount = 0 }, ref) => {
  return (
    <Button
      ref={ref}
      className="inline-block items-center justify-between"
      onClick={onClick}
    >
      + Add Example(s)
    </Button>
  );
});

SelectedExamplesBanner.displayName = "SelectedExamplesBanner";

export default SelectedExamplesBanner;
