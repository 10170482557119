import { ChooseTemplateModal } from "@/components/ChooseTemplateModal";
import { useContentArea } from "@/components/ContentArea/content-area-context";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { AuthProvider } from "@/context/auth-context";
import { UserProvider, useUser } from "@/context/user-context";
import { PromptVersionSnippet } from "@/types/apiGetters";
import { CodeIcon, TrashIcon } from "@heroicons/react/outline";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Link } from "lucide-react";
import { useMemo, useState } from "react";
import { WidgetProps } from "../../Widget";
import useWidget from "../../useWidget";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

interface SnippetDisplayProps {
  promptName: string;
  version?: string;
  label?: string;
  isReadOnly?: boolean;
  onDelete?: () => void;
  href?: string;
  className?: string;
  onClick?: () => void;
}
const SnippetDisplay = ({
  promptName,
  version,
  label,
  isReadOnly,
  onDelete,
  href,
  className,
  onClick,
}: SnippetDisplayProps) => {
  const Wrapper = href ? "a" : "button";
  const wrapperProps = href
    ? { href, target: "_blank", rel: "noreferrer" }
    : { onClick };

  return (
    <Wrapper
      {...wrapperProps}
      className={`group/Snippet flex items-center justify-between rounded-full border border-gray-200 bg-gray-50/80 px-3 py-1.5 shadow-sm transition-all duration-150 ease-in-out hover:border-gray-300 hover:bg-white hover:shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500/20 active:bg-gray-100/90 ${
        className || ""
      }`}
    >
      <div className="flex items-center">
        <span className="mr-2.5 flex items-center justify-center rounded-full bg-gray-200/80 p-1.5 text-gray-500 shadow-sm transition-colors group-hover/Snippet:bg-gray-300/90 group-hover/Snippet:text-gray-600">
          <CodeIcon className="h-3.5 w-3.5" />
        </span>
        <span className="font-mono text-sm font-semibold text-gray-700 transition-colors group-hover/Snippet:text-gray-900">
          {promptName ||
            (isReadOnly ? "No snippet selected" : "Select snippet")}
        </span>
      </div>
      <div className="ml-3 flex items-center space-x-2">
        {label && (
          <span
            className={`rounded-full bg-blue-50 px-2.5 py-1 text-xs font-medium text-blue-600 shadow-sm transition-colors ${
              href ? "hover:bg-blue-100" : ""
            }`}
          >
            {label}
          </span>
        )}
        {!label && (
          <span
            className={`rounded-full bg-gray-100/90 px-2.5 py-1 text-xs font-medium text-gray-600 shadow-sm transition-all ${
              href
                ? "group-hover/Snippet:bg-gray-200/90 group-hover/Snippet:text-gray-700"
                : "duration-150 ease-in-out group-hover:bg-gray-200/90 group-hover:text-gray-700"
            }`}
          >
            {version ? `v${version}` : "Default"}
          </span>
        )}
        {!isReadOnly && onDelete && (
          <Button
            variant="destructiveLink"
            size="tinyIcon"
            onClick={(e) => {
              e.stopPropagation();
              onDelete();
            }}
          >
            <TrashIcon className="h-3.5 w-3.5" />
          </Button>
        )}
      </div>
    </Wrapper>
  );
};

const getTemplate = (
  promptName: string,
  sourcedSnippets: Array<PromptVersionSnippet>,
  version?: number,
  label?: string,
) => {
  const snippet = sourcedSnippets.find(
    (snippet) =>
      snippet.prompt_template_name === promptName &&
      (version ? snippet.prompt_version_number === version : true) &&
      (label ? snippet.label === label : true),
  );
  return snippet;
};

const SnippetsWidgetProviders: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <UserProvider>{children}</UserProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
};

const SnippetsWidgetContent = (props: WidgetProps) => {
  const { sourcedSnippets } = useContentArea();
  const activeWorkspaceId = useUser()?.activeWorkspaceId;
  const isReadOnly = props.readOnly ?? false;
  const [open, setOpen] = useState(!props.match);
  const [configuration, setConfiguration] = useState<any>(() => {
    const match = props.match || "";
    const parts = match.split(/(@version_number:|@label:)/);
    const name = parts[0].replace(/^@@@/, "").replace(/@@@$/, "");
    let version_number, label;

    for (let i = 1; i < parts.length; i += 2) {
      if (parts[i] === "@version_number:") {
        version_number = parts[i + 1].replace(/@@@$/, "");
      } else if (parts[i] === "@label:") {
        label = parts[i + 1].replace(/@@@$/, "");
      }
    }

    return {
      template: {
        name,
        version_number: version_number || undefined,
        label: label || undefined,
      },
    };
  });

  const promptName = useMemo(
    () => configuration?.template?.name || "",
    [configuration?.template?.name],
  );
  const version = useMemo(
    () => configuration?.template?.version_number || "",
    [configuration?.template?.version_number],
  );
  const label = useMemo(
    () => configuration?.template?.label || "",
    [configuration?.template?.label],
  );

  const { deleteWidget } = useWidget(
    props.id,
    `@@@${promptName}${version ? `@version_number:${version}` : ""}${
      label ? `@label:${label}` : ""
    }@@@`,
  );

  if (isReadOnly) {
    const linked_template =
      sourcedSnippets &&
      getTemplate(promptName, sourcedSnippets, Number(version), label);

    const uri = `/workspace/${activeWorkspaceId}/prompt/${linked_template?.prompt_registry_id}/version/${linked_template?.prompt_version_number}`;

    return (
      <SnippetDisplay
        promptName={promptName}
        version={version}
        label={label}
        isReadOnly={true}
        href={uri}
      />
    );
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <SnippetDisplay
          promptName={promptName}
          version={version}
          label={label}
          onDelete={deleteWidget}
          onClick={() => setOpen(true)}
          className="w-full"
        />
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Link a snippet</DialogTitle>
          <DialogDescription className="mt-1 text-sm text-gray-500">
            Use a prompt template as a reference snippet in your current
            template, compiled at runtime.
            <a
              href="https://docs.promptlayer.com/features/prompt-registry/snippets"
              target="_blank"
              rel="noreferrer"
              className="text-sm text-blue-500 hover:text-blue-400"
            >
              {" "}
              Learn more. <Link className="inline h-4 w-4 pl-1" />
            </a>
          </DialogDescription>
        </DialogHeader>
        <ChooseTemplateModal
          navigateAway={() => {
            setOpen(false);
          }}
          navigateBack={() => setOpen(false)}
          submitText="Add"
          cancelText="Cancel"
          editable={true}
          configuration={configuration}
          setConfiguration={(config) => {
            if (typeof config === "function") {
              setConfiguration({ ...config(configuration) });
            } else {
              setConfiguration(config);
            }
          }}
          showSnippetWarning={true}
          sourceTemplateId={0}
          isSnippet={true}
        />
      </DialogContent>
    </Dialog>
  );
};

const SnippetsWidget = (props: WidgetProps) => {
  return (
    <SnippetsWidgetProviders>
      <SnippetsWidgetContent {...props} />
    </SnippetsWidgetProviders>
  );
};

export default SnippetsWidget;
