import { usePromptRegistryObjects } from "@/queries";
import { useEffect, useState } from "react";

const useTemplateSearch = (
  userToken: string,
  workspaceId: number,
  isSnippet: boolean,
  sourceTemplateId: number | null,
) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(1);

  const templateSet = usePromptRegistryObjects(userToken, {
    workspaceId,
    page,
    perPage: Number.MAX_SAFE_INTEGER, // TODO: Remove this when we have pagination
    q: searchQuery,
    templateType: isSnippet ? "completion" : undefined,
  });

  const availablePromptTemplates =
    templateSet.data?.pages
      .flatMap((page) => page.items)
      .filter((item) => item.id !== sourceTemplateId) || [];

  useEffect(() => {
    setPage(1);
  }, [searchQuery]);

  return {
    searchQuery,
    setSearchQuery,
    templateSet,
    availablePromptTemplates,
  };
};

export default useTemplateSearch;
