import React, { useRef } from "react";
import { CopyButton } from "./copy-button";

interface ActionProps {
  className?: string;
  onClick: () => void;
  children: React.ReactNode;
}

const Action: React.FC<ActionProps> = ({ className, onClick, children }) => (
  <button
    className={`${className} rounded-md bg-white p-1 shadow-lg transition-opacity duration-200 ease-in-out`}
    onClick={onClick}
  >
    {children}
  </button>
);

const CalloutActions: React.FC<{
  actions: React.ReactNode[];
  copy: boolean;
  text: string;
}> = ({ actions, copy, text }) => {
  const hasActions = actions.length > 0;
  return (
    <div
      className={`absolute right-4 top-2 flex space-x-2 rounded-md bg-opacity-90 p-1 ${
        hasActions ? "bg-white shadow-sm" : "bg-transparent"
      } opacity-0 transition-opacity duration-200 ease-in-out group-hover:opacity-100`}
    >
      <div>{copy && <CopyButton text={text} />}</div>
      {actions.map((action, index) => (
        <React.Fragment key={index}>{action}</React.Fragment>
      ))}
    </div>
  );
};

interface CalloutProps {
  children: string | React.ReactNode;
  className?: string;
  copy?: boolean;
  actions?: React.ReactNode[];
  [key: string]: any;
}

const Callout: React.FC<CalloutProps> = ({
  children,
  copy = true,
  className,
  actions = [],
  ...props
}) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <div className="group pointer-events-auto relative">
      <CalloutActions
        copy={copy}
        text={ref.current?.textContent || ""}
        actions={actions}
      />
      <div
        className={`${className} mb-4 mt-2 max-h-[250px] overflow-y-auto rounded-md border bg-gray-50 px-2 py-4 transition-all duration-200 ease-in-out group-hover:border-gray-300`}
        {...props}
        ref={ref}
      >
        <div className="flex">
          <div className="ml-3 text-sm font-medium text-blue-800">
            <p className="inline">{children}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

interface CalloutWithTitleProps extends CalloutProps {
  title: string;
  titleSize?: "text-sm" | "text-base" | "text-lg" | "text-xl" | "text-2xl";
}

const CalloutWithTitle: React.FC<CalloutWithTitleProps> = ({
  children,
  title,
  titleSize = "text-lg",
  ...props
}) => {
  return (
    <>
      <h4 className={`mb-2 font-semibold text-gray-700 ${titleSize}`}>
        {title}
      </h4>
      <Callout {...props}>{children}</Callout>
    </>
  );
};

export { Action, CalloutWithTitle };

export default Callout;
