import AddRowButton from "@/components/DatasetEditor/DatasetContent/DatasetTable/AddRowButton";
import { isObjectOrArray } from "@/utils/playground";
import { forceJSONString } from "@/utils/strings";
import { Cell } from "@tanstack/react-table";
import React, { useMemo } from "react";
import useCellType from "./hooks/useCellType";
import useDynamicWidth from "./hooks/useDynamicWidth";

interface CellBodyProps {
  cell?: Cell<any, any>;
  children: any;
  index: number;
  [key: string]: any;
}

interface CellBodyContentProps {
  isInlineEditingRef: React.MutableRefObject<boolean>;
  value: string;
  readonly: boolean;
  onInput: (value: string) => void;
}

class CellBodyContent extends React.Component<CellBodyContentProps> {
  shouldComponentUpdate(nextProps: Readonly<CellBodyContentProps>): boolean {
    return (
      this.props.value !== nextProps.value &&
      !this.props.isInlineEditingRef.current
    );
  }
  render() {
    const { onInput, value, readonly } = this.props;
    return (
      <div
        className={`relative box-border h-full max-h-[200px] min-h-[50px] w-full cursor-text content-center whitespace-pre-wrap break-all p-4 outline-none`} // keep cell className for use in useCellEditing
        onInput={(e) => {
          e.preventDefault();
          const next = (e.target as HTMLDivElement).innerText
            .replace(/[\u2018\u2019]/g, "'")
            .replace(/[\u201C\u201D]/g, '"'); //replace smart quotes with regular quotes
          onInput(next);
        }}
        aria-disabled={readonly}
        role="textbox"
        contentEditable={!readonly}
        onPaste={(e) => {
          e.preventDefault(); // Force plain text on pasted content
          const text = e.clipboardData.getData("text/plain");
          document.execCommand("insertHTML", false, text);
        }}
        suppressContentEditableWarning
      >
        {value}
      </div>
    );
  }
}

const CellBody = (props: CellBodyProps) => {
  const { cell, children, index, value, contentEditable, isInlineEditingRef } =
    props;
  const cellWidth = useDynamicWidth(value);

  const initialValue = useMemo(
    () => (isObjectOrArray(value) ? forceJSONString(value) : value),
    [value],
  );

  const { isActionsCell, isAddRowCell, isPlaceholder, isJSONCell } =
    useCellType(cell, index);

  if (isActionsCell) return children;

  if (isAddRowCell) {
    return (
      <div className={`block h-full w-full ${cellWidth}`}>
        <div className="relative h-full w-full p-4">
          <AddRowButton />
        </div>
      </div>
    );
  }

  if (!isPlaceholder) {
    return (
      <div className={`${isJSONCell && "font-mono"} ${cellWidth}`}>
        <CellBodyContent
          value={initialValue}
          onInput={props.onInput}
          readonly={!contentEditable}
          isInlineEditingRef={isInlineEditingRef}
        />
      </div>
    );
  }
  return null;
};
export default CellBody;
