//export widgets as a component with a

import { CodeIcon } from "@heroicons/react/outline";
import { CardStackIcon } from "@radix-ui/react-icons";
import ExamplesWidget from "./types/ExamplesWidget";
import SnippetsWidget from "./types/SnippetsWidget";
import { WidgetProps } from "./Widget";

export enum WidgetTypes {
  Examples = "Examples",
  Snippet = "Snippets",
}

const Widgets = {
  [WidgetTypes.Examples]: [
    ExamplesWidget,
    /«{3,}(dataset):([^:]+)?:(\d+)?:(\d+(?:,\d+)*)?:([^:]+)?:([^»]+)»{3,}/g,
    CardStackIcon,
    "Add example use cases or scenarios to illustrate your point",
  ] as const,
  [WidgetTypes.Snippet]: [
    SnippetsWidget,
    "@@@(.+?)?@@@",
    CodeIcon,
    "Insert a reusable code snippet or syntax highlight",
  ] as const,
} as const;

export const getWidgetMapping = (type: WidgetTypes, props: WidgetProps) =>
  Widgets[type][0](props);
export const getWidgetRegex = (type: WidgetTypes) => Widgets[type][1];
export default Widgets;
