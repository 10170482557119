import LoadingSpinner from "@/components/LoadingSpinner";
import { useInfiniteScroll } from "@/components/SidebarSearchLayout/hooks/useInfiniteScroll";
import { useKeyboardNavigation } from "@/components/SidebarSearchLayout/hooks/useKeyboardNavigation";
import NoResultsFooter from "@/components/SidebarSearchLayout/NoResultsFooter";
import SpanCard from "@/components/SidebarSearchLayout/SpanCard";
import { SIDEBAR_MAX } from "@/constants";
import { useSpans, useSpansQuery } from "@/queries";
import { Span } from "@/types/spans";
import { RefObject, useCallback, useRef } from "react";
import { useParams } from "react-router-dom";

const Spans = ({ inputRef }: { inputRef: RefObject<HTMLInputElement> }) => {
  const { traceId } = useParams();

  const sidebarLogContainerRef = useRef<HTMLDivElement | null>(null);

  const spansQuery = useSpansQuery();
  const spans = useSpans();

  const fetchNextPage = spansQuery.fetchNextPage;

  const getNextId = useCallback(
    (id?: string) => {
      const index = spans.findIndex((item) => item.trace_id === id);
      if (index < spans.length - 1) {
        const nextItem = spans[index + 1];
        return { id: nextItem.trace_id, type: "traces" };
      }
      return null;
    },
    [spans],
  );

  const getPreviousId = useCallback(
    (id?: string) => {
      const index = spans.findIndex((item) => item.trace_id === id);
      if (index > 0) {
        const prevItem = spans[index - 1];
        return { id: prevItem.trace_id, type: "traces" };
      }
      return null;
    },
    [spans],
  );

  const scrollToLogBox = useCallback(
    (id: string) => {
      if (sidebarLogContainerRef.current) {
        const container = sidebarLogContainerRef.current;
        const element = container.querySelector(
          `[data-request-id="${id}"]`,
        ) as HTMLElement;

        if (element) {
          const containerRect = container.getBoundingClientRect();
          const elementRect = element.getBoundingClientRect();
          const offsetTop = elementRect.top - containerRect.top;

          // Calculate the desired scroll position
          let scrollTo = container.scrollTop + offsetTop;

          // If the element is at the top, scroll to show the full element
          if (offsetTop <= 0) {
            scrollTo = container.scrollTop + offsetTop - 16; // Add some padding
          }

          // If we're near the bottom, adjust scroll to show as much as possible
          const containerHeight = container.clientHeight;
          const elementHeight = element.clientHeight;
          if (offsetTop + elementHeight > containerHeight) {
            scrollTo =
              container.scrollTop +
              offsetTop -
              containerHeight +
              elementHeight +
              16; // Add some padding
          }

          // Perform the scroll
          container.scrollTo({
            top: scrollTo,
            behavior: "smooth",
          });
        }
      }
    },
    [sidebarLogContainerRef],
  );

  useKeyboardNavigation(
    inputRef,
    spans,
    getNextId,
    getPreviousId,
    scrollToLogBox,
    traceId ?? "",
    "traces",
  );

  const infiniteScrollRef = useInfiniteScroll(fetchNextPage);

  const hasNextPage = spansQuery.hasNextPage;
  const isFetchingNewRequests = spansQuery.isFetching;
  const isFetching = spansQuery.isFetching && !hasNextPage;
  const noRequestsFound = !(spans.length > 0);
  return (
    <nav
      className="flex flex-1 flex-col space-y-1 overflow-y-auto px-1 pb-4"
      ref={sidebarLogContainerRef}
    >
      {(spans || []).slice(0, SIDEBAR_MAX).map((item: Span, idx: number) => (
        <SpanCard key={idx} span={item} />
      ))}
      {hasNextPage && (
        <div ref={infiniteScrollRef} className="flex justify-center py-2">
          <LoadingSpinner size={5} />
        </div>
      )}
      {isFetchingNewRequests && isFetching && (
        <div className="flex justify-center py-2">
          <LoadingSpinner size={5} />
        </div>
      )}
      {noRequestsFound && <NoResultsFooter />}
    </nav>
  );
};

export default Spans;
