import { ConnectionLineComponentProps } from "@xyflow/react";

const ConnectionLine = ({
  fromX,
  fromY,
  toX,
  toY,
}: ConnectionLineComponentProps) => {
  return (
    <g>
      <path
        fill="none"
        strokeWidth={1.5}
        className="animated stroke-gray-400"
        d={`M${fromX},${fromY} C ${fromX} ${toY} ${fromX} ${toY} ${toX},${toY}`}
      />
      <circle
        cx={toX}
        cy={toY}
        className="bg-white stroke-gray-400"
        r={3}
        strokeWidth={1.5}
      />
    </g>
  );
};

export default ConnectionLine;
