import folderGraphic from "@/assets/folder.svg";
import FolderPath from "@/components/FolderPath";
import FolderGrid from "@/components/Registry/FolderGrid";
import { useFoldersData } from "@/components/Registry/hooks/useFoldersData";
import TemplateGrid from "@/components/Registry/TemplateGrid";
import { FolderNode } from "@/components/Registry/utils";
import { Folder } from "@/types/folders";
import { PromptRegistry } from "@/types/prompt-registry";

const Folders = ({
  searchQuery,
  templates,
  folders,
  folderPath,
}: {
  searchQuery: string;
  templates: PromptRegistry[];
  folders: Folder[];
  folderPath: string[];
}) => {
  const { filteredFolders, foldersWithTemplates } = useFoldersData({
    templates,
    folders,
    folderPath,
  });

  if (!folders.length) return null;

  return (
    <>
      {searchQuery === "" ? (
        <>
          <div className="mt-10">Folders</div>
          <div className="mt-4 flex-1">
            <FolderGrid folders={filteredFolders} />
          </div>
        </>
      ) : (
        foldersWithTemplates.map((folder) => (
          <div key={folder.id}>
            <FolderHeader folder={folder} />
            <div className="mt-4 flex-1">
              <TemplateGrid templates={folder.templates} />
            </div>
          </div>
        ))
      )}
    </>
  );
};

const FolderHeader = ({ folder }: { folder: FolderNode }) => (
  <div className="mt-10">
    <img src={folderGraphic} alt="folder" className="mr-2 inline h-5 w-auto" />
    <strong>Folder:</strong>{" "}
    <FolderPath
      folder={{
        path: folder.path as Folder["path"],
        name: folder.name as Folder["name"],
      }}
    />
  </div>
);

export default Folders;
