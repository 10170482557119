import { useCallback } from "react";

const useCursorHandling = () => {
  const handleClick = useCallback((event: React.MouseEvent) => {
    setTimeout(() => {
      const selection = window.getSelection();
      if (!selection || !selection.rangeCount) return;

      const range = selection.getRangeAt(0);
      const startContainer = range.startContainer;
      const startOffset = range.startOffset;

      if (startContainer.nodeType === Node.TEXT_NODE) {
        const parentElement = startContainer.parentElement;
        if (
          parentElement &&
          parentElement.hasAttribute("data-custom-component-id")
        ) {
          if (startOffset === 0) {
            range.setStartBefore(parentElement);
          } else {
            range.setStartAfter(parentElement);
          }
          range.collapse(true);
          selection.removeAllRanges();
          selection.addRange(range);
        }
      }
    }, 0);
  }, []);

  const handleKeyUp = useCallback((event: React.KeyboardEvent) => {
    const selection = window.getSelection();
    if (!selection || !selection.rangeCount) return;

    const range = selection.getRangeAt(0);
    const startContainer = range.startContainer;
    const startOffset = range.startOffset;

    if (startContainer.nodeType === Node.ELEMENT_NODE) {
      const element = startContainer as HTMLElement;
      const childNodes = element.childNodes;

      if (startOffset > 0 && startOffset <= childNodes.length) {
        const prevNode = childNodes[startOffset - 1];
        const nextNode = childNodes[startOffset];
        if (
          prevNode &&
          prevNode.nodeType === Node.ELEMENT_NODE &&
          (prevNode as HTMLElement).hasAttribute("data-custom-component-id")
        ) {
          range.setStartAfter(prevNode);
          range.collapse(true);
          selection.removeAllRanges();
          selection.addRange(range);
        } else if (
          nextNode &&
          nextNode.nodeType === Node.ELEMENT_NODE &&
          (nextNode as HTMLElement).hasAttribute("data-custom-component-id")
        ) {
          range.setStartBefore(nextNode);
          range.collapse(true);
          selection.removeAllRanges();
          selection.addRange(range);
        }
      }
    }
  }, []);

  return { handleClick, handleKeyUp };
};

export default useCursorHandling;
