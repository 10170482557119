import { observer } from "mobx-react-lite";
import InputVariableItem from "./InputVariableItem";

interface InputVariableListProps {
  variables: Array<{ id: string; key: string; value: string }>;
  onUpdate: (id: string, field: "key" | "value", newValue: string) => void;
  onRemove: (id: string) => void;
}

const InputVariableList = observer(
  ({ variables, onUpdate, onRemove }: InputVariableListProps) => {
    return (
      <div className="max-h-[300px] space-y-4 overflow-y-auto">
        {variables.map(({ id, key, value }) => (
          <InputVariableItem
            key={id}
            id={id}
            keyProp={key}
            value={value}
            onUpdate={onUpdate}
            onRemove={onRemove}
          />
        ))}
      </div>
    );
  },
);

export const TooltipVariableList = ({
  variables,
}: {
  variables: Record<string, string>;
}) => (
  <div className="max-h-[150px] overflow-y-auto">
    {Object.entries(variables).map(([key, value], i) => (
      <div key={`Variable_${i}`}>
        <span className="font-bold">{key}</span>
      </div>
    ))}
  </div>
);

export const ReadOnlyVariableList = ({
  variables,
}: {
  variables: Record<string, string>;
}) => (
  <div className="max-h-[300px] space-y-4 overflow-y-auto">
    {Object.entries(variables).map(([key, value]) => (
      <div
        key={key}
        className="flex items-center space-x-4 rounded-lg border border-gray-200 bg-gray-50 p-4 shadow-sm"
      >
        <div className="w-1/3 font-mono text-sm text-gray-800">{key}</div>
      </div>
    ))}
  </div>
);

export default InputVariableList;
