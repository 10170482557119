import { useEffect } from "react";

const useAutoFocus = (
  isOpen: boolean,
  inputRef: React.RefObject<HTMLInputElement>,
  setSearchQuery: (query: string) => void,
) => {
  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        inputRef.current?.focus();
        inputRef.current?.select();
      }, 0);
    } else {
      setSearchQuery("");
    }
  }, [inputRef, isOpen, setSearchQuery]);
};

export default useAutoFocus;
