import { Button, DivButton } from "@/components/ui/button";
import { CopyButton } from "@/components/ui/copy-button";
import { useUser } from "@/context/user-context";
import { ColumnType, ReportCell, ReportColumn } from "@/types/evaluate";
import { forceJSONString } from "@/utils/strings";
import { RefreshIcon } from "@heroicons/react/outline";
import { CheckCircle } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { CompareDiffCell } from "./CompareDiffCell";
import { FunctionCallCell } from "./FunctionCallCell";
import { JsonBadge } from "@/components/JsonBadge";

const renderCellValue = (
  cell: ReportCell,
  column: ReportColumn,
  setCopyValue: (value: string) => void,
) => {
  let cellValue = null;

  switch (cell.display_value.type) {
    case "string":
      cellValue = cell.display_value.value;
      setCopyValue(cell.display_value.value);
      break;
    case "number":
      const value =
        column.column_type === ColumnType.ABSOLUTE_NUMERIC_DISTANCE ||
        column.column_type === ColumnType.COSINE_SIMILARITY
          ? Number(cell.display_value.value).toFixed(2)
          : cell.display_value.value;
      cellValue = <span>{value}</span>;
      setCopyValue(cell.display_value.value.toString());
      break;
    case "function":
      cellValue = <FunctionCallCell cell={cell} />;
      setCopyValue(JSON.stringify(cell.display_value.value, null, 2));
      break;
    case "tool_calls":
      cellValue = <FunctionCallCell cell={cell} />;
      setCopyValue(JSON.stringify(cell.display_value.value, null, 2));
      break;
    case "object":
      cellValue = (
        <pre className={`whitespace-pre-wrap font-mono text-gray-800`}>
          <JsonBadge />
          {forceJSONString(cell.display_value.value)}
        </pre>
      );
      setCopyValue(JSON.stringify(cell.display_value.value, null, 2));
      break;
    case "boolean":
      setCopyValue(cell.display_value.value ? "true" : "false");
      if (column.column_type === ColumnType.COMPARE) {
        if (!cell.display_value.value) {
          cellValue = (
            <CompareDiffCell key={`${cell?.id}-compare`} cell={cell} />
          );
        } else {
          cellValue = (
            <span className="flex items-center justify-center text-gray-400">
              <CheckCircle className="mr-1 h-4 w-4 text-green-300" /> Values are
              the same
            </span>
          );
        }
      } else {
        cellValue = (
          <div className="text-gray-800">
            {cell.display_value.value ? "true" : "false"}
          </div>
        );
      }
      break;
    case "unknown":
      if (column.column_type === ColumnType.COMPARE) {
        return <CompareDiffCell key={`${cell?.id}-compare`} cell={cell} />;
      }
      cellValue = <span className="flex justify-center text-gray-300">—</span>;
      setCopyValue("");
      break;
  }

  return cellValue;
};

export const CompletedCell = ({
  cell,
  column,
  retryReportCell,
  retryReportCellIsLoading,
}: {
  cell: ReportCell;
  column: ReportColumn;
  retryReportCell: () => void;
  retryReportCellIsLoading: boolean;
}) => {
  const [copyValue, setCopyValue] = useState("");
  const [url, setUrl] = useState("");
  const { activeWorkspaceId: workspaceId } = useUser();

  useEffect(() => {
    if (cell.value && cell.value.value && cell.value.value.request_id) {
      setUrl(
        `/workspace/${workspaceId}/request/${cell.value.value.request_id}`,
      );
    }
  }, [cell.value, workspaceId]);

  const setStringCopyValue = (value: any) => {
    if (typeof value === "string") {
      setCopyValue(value);
    } else {
      setCopyValue(JSON.stringify(value, null, 2));
    }
  };

  const cellValue = useMemo(
    () => renderCellValue(cell, column, setCopyValue),
    [cell, column, setCopyValue],
  );

  const plLinkButton =
    url !== "" ? (
      <a href={url} target="_blank" rel="noreferrer">
        <Button size="tinyIcon" variant="outline" className="-mt-1">
          🍰
        </Button>
      </a>
    ) : null;

  const retryButton =
    column.column_type !== ColumnType.DATASET ? (
      <DivButton
        size="tinyIcon"
        variant="outline"
        className="-mt-1"
        onClick={retryReportCell}
        isLoading={retryReportCellIsLoading}
      >
        <RefreshIcon className="h-4 w-4 cursor-pointer text-gray-600 hover:text-gray-700" />
      </DivButton>
    ) : null;

  const Tools = (
    <div className="sticky right-2 top-2 z-10 flex h-0 justify-end gap-x-1 overflow-visible p-1 opacity-0 transition-opacity duration-200 group-hover/Cell:opacity-100">
      {cellValue && (
        <DivButton
          variant="outline"
          size="tinyIcon"
          className="-mt-1"
          onClick={() => setStringCopyValue(cell.value.value)}
          isLoading={false}
        >
          <CopyButton text={copyValue} />
        </DivButton>
      )}
      {plLinkButton}
      {retryButton}
    </div>
  );

  return (
    <div className="group relative max-h-[250px] overflow-auto">
      {Tools}
      <div className="min-h-[32px] whitespace-pre-wrap break-words px-2  py-1">
        {cellValue}
      </div>
    </div>
  );
};
