import { BasicNode, BasicNodeProps } from "./BasicNode";

const CompletedNode = (props: BasicNodeProps) => (
  <BasicNode
    {...props}
    className="border-green-500 bg-green-100 transition-all duration-300 ease-in-out"
  />
);

export default CompletedNode;
