import { Button, DivButton } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import useActiveWorkspace from "@/hooks/useActiveWorkspace";
import { usePromptVersions, useWorkflowVersions } from "@/queries";
import { ListPromptVersion } from "@/types";
import {
  Conditional,
  DynamicReleaseLabel,
  OperationConditional,
} from "@/types/conditionals";
import { CodeIcon } from "@heroicons/react/outline";
import { MinusCircleIcon, PlusIcon } from "@heroicons/react/solid";
import { useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { WorkflowVersion } from "../Workflows/types";
import RegistryName from "./RegistryName";

interface ReleaseLabelTableProps {
  conditional: Conditional;
  dynamicReleaseLabels: DynamicReleaseLabel[];
  promptLabelName: string;
  promptRegistryId: string;
  setConditionals: (conditionals: any) => void;
  type: "workflow" | "prompt";
}

const ReleaseLabelTable = ({
  conditional,
  dynamicReleaseLabels,
  promptLabelName,
  promptRegistryId,
  type,
  setConditionals,
}: ReleaseLabelTableProps) => {
  const auth = useAuth();
  const userToken = auth?.userToken!;
  const [selectedVersion, setSelectedVersion] = useState<
    ListPromptVersion | WorkflowVersion | null
  >(null);
  const activeWorkspace = useActiveWorkspace();
  const userContext = useUser();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const getIsRealLabel = () => {
    if (type === "prompt") {
      const promptVersionLabelNames = (
        selectedVersion as ListPromptVersion
      )?.labels.map(({ name }) => name);
      return promptVersionLabelNames?.includes(promptLabelName) || false;
    }
    return false;
  };

  const totalPercentageIsCorrect = useMemo(() => {
    const percentages = dynamicReleaseLabels.map(
      ({ percentage }) => percentage,
    );
    const total = percentages.reduce((total, current) => total + current, 0);
    return total === 100;
  }, [dynamicReleaseLabels]);

  const handleDeleteClick = useCallback(
    (versionId: number, isRealLabel: boolean) => {
      setConditionals((prevConditionals: Conditional[]) => {
        return prevConditionals.map((prevConditional: Conditional) => {
          if (prevConditional === conditional) {
            return {
              ...prevConditional,
              dynamic_release_labels:
                prevConditional.dynamic_release_labels.filter(
                  (label) =>
                    (type === "prompt"
                      ? label.prompt_version_id
                      : label.workflow_version_id) !== versionId ||
                    label.is_real_label !== isRealLabel,
                ),
            };
          }
          return prevConditional;
        });
      });
    },
    [conditional, setConditionals, type],
  );

  const handleDynamicReleaseLabelPercentageChange = useCallback(
    (versionId: number, newPercentage: number) => {
      setConditionals((prevConditionals: Conditional[]) =>
        prevConditionals.map((prevConditional) => {
          if (prevConditional === conditional) {
            const updatedLabels = prevConditional.dynamic_release_labels.map(
              (label) => {
                if (
                  (type === "prompt"
                    ? label.prompt_version_id
                    : label.workflow_version_id) === versionId
                ) {
                  return { ...label, percentage: newPercentage };
                }
                return label;
              },
            );
            return {
              ...prevConditional,
              dynamic_release_labels: updatedLabels,
            };
          }
          return prevConditional;
        }),
      );
    },
    [conditional, setConditionals, type],
  );

  const handleDynamicReleaseLabelSave = () => {
    setConditionals((prevConditionals: any) => {
      return prevConditionals.map((prevConditional: Conditional) => {
        if (
          (prevConditional.is_default && conditional.is_default) ||
          (!prevConditional.is_default &&
            !conditional.is_default &&
            (prevConditional as OperationConditional).field ===
              (conditional as OperationConditional).field &&
            (prevConditional as OperationConditional).operator ===
              (conditional as OperationConditional).operator &&
            (prevConditional as OperationConditional).value ===
              (conditional as OperationConditional).value)
        ) {
          const newDynamicReleaseLabel: DynamicReleaseLabel = {
            is_real_label: getIsRealLabel(),
            percentage: 0,
            prompt_registry_id: parseInt(promptRegistryId),
            prompt_version_id:
              type === "prompt"
                ? (selectedVersion as ListPromptVersion).id
                : (selectedVersion as WorkflowVersion).id,
            prompt_version_number:
              type === "prompt"
                ? (selectedVersion as ListPromptVersion).number
                : (selectedVersion as WorkflowVersion).number,
          };

          const newWorkflowDynamicReleaseLabel: DynamicReleaseLabel = {
            is_real_label: getIsRealLabel(),
            percentage: 0,
            workflow_id: parseInt(promptRegistryId),
            workflow_version_id:
              type === "prompt"
                ? (selectedVersion as ListPromptVersion).id
                : (selectedVersion as WorkflowVersion).id,
            workflow_version_number:
              type === "prompt"
                ? (selectedVersion as ListPromptVersion).number
                : (selectedVersion as WorkflowVersion).number,
          };

          return {
            ...prevConditional,
            dynamic_release_labels: [
              ...prevConditional.dynamic_release_labels,
              type === "prompt"
                ? newDynamicReleaseLabel
                : newWorkflowDynamicReleaseLabel,
            ],
          };
        }

        return prevConditional;
      });
    });

    setIsModalOpen(false);
    setSelectedVersion(null);
  };

  const workspaceId = userContext.activeWorkspaceId!;

  const promptVersionsQuery = usePromptVersions(userToken, {
    workspaceId,
    promptRegistryId: parseInt(promptRegistryId),
    perPage: Number.MAX_SAFE_INTEGER,
  });

  const { data: workflowVersionsData } = useWorkflowVersions(
    promptRegistryId,
    userToken,
  );

  const workflowVersions = workflowVersionsData?.workflow_versions;

  const versionOptions =
    type === "prompt"
      ? promptVersionsQuery.data?.pages.flatMap((page) => page.items) ?? []
      : workflowVersions ?? [];

  const renderAddElement = () => {
    if (versionOptions.length === 0) return null;

    const getVersionNames = (version: ListPromptVersion | WorkflowVersion) => {
      if (type === "prompt") {
        const namesList =
          (version as ListPromptVersion).labels.length > 0
            ? (version as ListPromptVersion).labels.map((label) => label.name)
            : [];
        return namesList.map((label) => (
          <span
            key={label}
            className="mx-1 inline-block rounded-sm border border-gray-200 px-2 py-0.5 text-xs font-medium text-gray-500"
          >
            {label}
          </span>
        ));
      }
      return null;
    };

    return (
      <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
        <div className="mt-2 flex items-center">
          <DialogTrigger asChild>
            <DivButton variant="link">
              <PlusIcon className="h-4 w-4" />
              <span className="text-sm">Add Version</span>
            </DivButton>
          </DialogTrigger>
        </div>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              Choose {type === "prompt" ? "Prompt" : "Workflow"} Version
            </DialogTitle>
            <DialogDescription>
              Add a dynamic release label to a new{" "}
              {type === "prompt" ? "prompt template" : "workflow"} version.
            </DialogDescription>
          </DialogHeader>
          <div className="mt-4">
            <DropdownMenu>
              <DropdownMenuTrigger className="w-full">
                {selectedVersion ? (
                  <span>
                    Version {selectedVersion.number}
                    {getVersionNames(selectedVersion)}
                  </span>
                ) : (
                  <span className="text-gray-400">Select version...</span>
                )}
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                {versionOptions.map((version) =>
                  dynamicReleaseLabels.some(
                    (label) =>
                      (type === "prompt"
                        ? label.prompt_version_id
                        : label.workflow_version_id) === version.id,
                  ) ? (
                    <DropdownMenuItem key={version.id} disabled>
                      Version {version.number} (already added){" "}
                      {getVersionNames(version)}
                    </DropdownMenuItem>
                  ) : (
                    <DropdownMenuItem
                      key={version.id}
                      onSelect={() => setSelectedVersion(version)}
                    >
                      Version {version.number} {getVersionNames(version)}
                    </DropdownMenuItem>
                  ),
                )}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
          <div className="mt-4 flex justify-end gap-2">
            <Button onClick={() => setIsModalOpen(false)} variant="outline">
              Cancel
            </Button>
            <Button
              disabled={!selectedVersion}
              onClick={handleDynamicReleaseLabelSave}
            >
              Add
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    );
  };

  const dynamicReleaseLabelRows = useMemo(() => {
    return dynamicReleaseLabels
      .sort((a, b) =>
        type === "prompt"
          ? a.prompt_version_number! - b.prompt_version_number!
          : a.prompt_version_number! - b.prompt_version_number!,
      )
      .sort((a, b) => Number(b.is_real_label) - Number(a.is_real_label))
      .map((dynamicReleaseLabel) => (
        <tr
          className={`${
            dynamicReleaseLabel.is_real_label ? "h-24" : "h-12"
          } border border-gray-200 hover:bg-gray-50`}
          key={`${
            type === "prompt"
              ? dynamicReleaseLabel.prompt_registry_id
              : dynamicReleaseLabel.workflow_id
          }-${
            type === "prompt"
              ? dynamicReleaseLabel.prompt_version_id
              : dynamicReleaseLabel.workflow_version_id
          }`}
        >
          <td className="p-3">
            {dynamicReleaseLabel.is_real_label ? (
              <div className="group relative w-[300px] rounded-md border-none px-3 py-2 text-gray-600 transition-all hover:border hover:border-dashed hover:border-gray-200">
                <div className="absolute inset-0 flex flex-col items-center justify-center space-y-2 bg-white text-xs text-gray-500 opacity-0 transition-opacity group-hover:opacity-100">
                  <div className="">Tied to original label</div>
                  <div className="">
                    Currently:{" "}
                    <span className="font-semibold">
                      Version #
                      {type === "prompt"
                        ? dynamicReleaseLabel.prompt_version_number
                        : dynamicReleaseLabel.workflow_version_number}
                    </span>
                  </div>
                </div>
                <Link
                  className="cursor-pointer transition-all group-hover:invisible"
                  to={
                    activeWorkspace
                      ? `/workspace/${activeWorkspace.id}/${type}/${
                          dynamicReleaseLabel.prompt_registry_id
                        }/version/${
                          type === "prompt"
                            ? dynamicReleaseLabel.prompt_version_number
                            : dynamicReleaseLabel.workflow_version_number
                        }`
                      : "/"
                  }
                >
                  <div className="flex items-center gap-x-2 py-2">
                    <CodeIcon className="h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-blue-500" />
                    <div className="flex flex-col">
                      <span className="flex items-center gap-x-2 text-sm font-semibold text-gray-700 group-hover:text-blue-600">
                        <div>Original</div>
                        <div className="flex-1">
                          <div className="mx-auto inline-block rounded-sm border border-gray-200 px-2 py-0.5 text-xs font-medium text-gray-500">
                            {promptLabelName}
                          </div>
                        </div>
                      </span>
                      <span className="text-xs text-gray-500 group-hover:text-blue-600">
                        <RegistryName
                          id={dynamicReleaseLabel.prompt_registry_id!}
                          type={type}
                        />
                      </span>
                    </div>
                  </div>
                </Link>
              </div>
            ) : (
              <Link
                className="group flex w-[300px] cursor-pointer items-center gap-x-2 rounded-md px-3 py-2 transition-all"
                to={
                  activeWorkspace
                    ? `/workspace/${activeWorkspace.id}/${type}/${
                        dynamicReleaseLabel.prompt_registry_id ||
                        dynamicReleaseLabel.workflow_id
                      }/version/${
                        type === "prompt"
                          ? dynamicReleaseLabel.prompt_version_number
                          : dynamicReleaseLabel.workflow_version_number
                      }`
                    : "/"
                }
              >
                <CodeIcon className="h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-blue-500" />
                <div className="flex flex-col">
                  <span className="text-sm font-semibold text-gray-700 group-hover:text-blue-600">
                    Version{" "}
                    {type === "prompt"
                      ? dynamicReleaseLabel.prompt_version_number
                      : dynamicReleaseLabel.workflow_version_number}
                  </span>
                  <span className="text-xs text-gray-500 group-hover:text-blue-600">
                    <RegistryName
                      id={dynamicReleaseLabel.prompt_registry_id!}
                      type={type}
                    />
                  </span>
                </div>
              </Link>
            )}
          </td>
          <td className="px-4 py-2 text-center">
            <input
              className={`w-[100px] rounded-sm px-2 py-1 focus:outline-none focus:ring-2 ${
                totalPercentageIsCorrect
                  ? "border-gray-300 focus:border-blue-600 focus:ring-blue-500"
                  : "border-red-500 focus:border-red-600 focus:ring-red-500"
              }`}
              id="percentage"
              onChange={(e) =>
                handleDynamicReleaseLabelPercentageChange(
                  type === "prompt"
                    ? dynamicReleaseLabel.prompt_version_id!
                    : dynamicReleaseLabel.workflow_version_id!,
                  Number(e.target.value),
                )
              }
              placeholder="%"
              type="number"
              value={dynamicReleaseLabel.percentage ?? ""}
              min={0}
              max={100}
            />
          </td>
          <td className="h-12 p-2 text-center">
            {!(conditional.is_default && dynamicReleaseLabel.is_real_label) && (
              <Button
                variant="ghost"
                size="sm"
                onClick={() =>
                  handleDeleteClick(
                    type === "prompt"
                      ? dynamicReleaseLabel.prompt_version_id!
                      : dynamicReleaseLabel.workflow_version_id!,
                    dynamicReleaseLabel.is_real_label,
                  )
                }
              >
                <MinusCircleIcon className="h-5 w-5 text-red-500 hover:text-red-400" />
              </Button>
            )}
          </td>
        </tr>
      ));
  }, [
    activeWorkspace,
    conditional.is_default,
    dynamicReleaseLabels,
    handleDeleteClick,
    handleDynamicReleaseLabelPercentageChange,
    promptLabelName,
    totalPercentageIsCorrect,
    type,
  ]);

  return (
    <>
      <table className="w-full max-w-2xl table-auto border-collapse rounded-2xl border border-gray-200">
        {dynamicReleaseLabels.length > 0 && (
          <thead>
            <tr className="bg-gray-100 text-xs font-medium uppercase tracking-wider text-gray-500">
              <th className="px-4 py-3 text-left">
                {type === "prompt" ? "Template" : "Workflow"} Version
              </th>
              <th className="px-4 py-3 text-center">Release %</th>
              <th className="px-4 py-3 text-right">&nbsp;</th>
            </tr>
          </thead>
        )}
        <tbody className="divide-y divide-gray-200 bg-white">
          {dynamicReleaseLabelRows}
        </tbody>
      </table>

      {renderAddElement()}
    </>
  );
};

export default ReleaseLabelTable;
