import { useConnection } from "@xyflow/react";
import { runInAction } from "mobx";
import { useEffect } from "react";
import { useWorkflow } from "../../workflow-context";

const useFlowConnectionMirror = () => {
  const conn = useConnection();
  const workflow = useWorkflow();
  const isConnecting = conn.fromNode && !conn.toNode;

  useEffect(() => {
    runInAction(() => workflow.setIsConnecting(!!isConnecting));
  }, [isConnecting, workflow]);
  return true;
};

const FlowConnectionMirrorProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => useFlowConnectionMirror() && children;

export default useFlowConnectionMirror;
export { FlowConnectionMirrorProvider };
