import { ObservableWorkflowNode } from "@/components/Workflows/types";
import { IWorkflow } from "@/components/Workflows/WorkflowCanvas/workflow-store";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";

interface EditableLabelProps {
  workflow: IWorkflow;
  initialLabel: string;
  className?: string;
  node: ObservableWorkflowNode;
}

const EditableNodeLabel = observer(
  ({ initialLabel, workflow, node, className }: EditableLabelProps) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editedLabel, setEditedLabel] = useState(initialLabel);
    const inputRef = useRef<HTMLInputElement>(null);

    const { readonly } = workflow;

    const onLabelEdit = (newLabel: string) => {
      if (!readonly) {
        runInAction(() => {
          node!.name = newLabel;
        });
        workflow.saveNode(node!, true);
      }
    };

    useEffect(() => {
      if (isEditing && inputRef.current) {
        inputRef.current.focus();
        inputRef.current.select();
      }
    }, [isEditing]);

    useEffect(() => {
      setEditedLabel(initialLabel);
    }, [initialLabel]);

    const handleLabelClick = (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      if (workflow.isSidebarOpen && workflow.activeNodeId !== node?.id) {
        workflow.closeSidebar();
        workflow.removeActiveNode();
      }
      if (!readonly) {
        setIsEditing(true);
      }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setEditedLabel(e.target.value);
    };

    const handleInputBlur = () => {
      setIsEditing(false);
      if (editedLabel !== initialLabel) {
        onLabelEdit(editedLabel);
      }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        handleInputBlur();
      } else if (e.key === "Escape") {
        setIsEditing(false);
        setEditedLabel(initialLabel);
      }
    };

    return isEditing && !readonly ? (
      <input
        ref={inputRef}
        type="text"
        value={editedLabel}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        onKeyDown={handleKeyDown}
        className="w-auto min-w-0 rounded border-2 border-blue-500 px-2 py-1 text-center text-base transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-blue-300"
      />
    ) : (
      <strong
        className={`block w-fit ${
          readonly ? "cursor-default" : "cursor-text"
        } truncate break-words text-xl ${className} rounded px-2 py-1 transition-colors duration-200 ${
          !readonly && "hover:bg-blue-500 hover:bg-opacity-[15%]"
        }`}
        onClick={handleLabelClick}
      >
        {editedLabel}
      </strong>
    );
  },
);

export default EditableNodeLabel;
