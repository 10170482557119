import { WorkflowNodeType } from "@/components/Workflows/types";
import { useWorkflow } from "@/components/Workflows/WorkflowCanvas/workflow-context";
import React, { useRef } from "react";
import ContextMenuButton from "./ContextMenuButton";
import NodeTypeLabel from "./NodeTypeLabel";
import OutputLabel from "./OutputLabel";

interface NodeHeaderProps {
  type: WorkflowNodeType;
  isOutput?: boolean;
  iconBackgroundColor: string;
  iconTextColor: string;
}

const NodeHeader: React.FC<NodeHeaderProps> = ({
  type,
  isOutput,
  iconBackgroundColor,
  iconTextColor,
}) => {
  const { readonly } = useWorkflow();
  const header = useRef<HTMLDivElement>(null);

  return (
    <div
      ref={header}
      className="flex w-full flex-row items-center justify-between px-4"
    >
      <NodeTypeLabel
        type={type}
        iconBackgroundColor={iconBackgroundColor}
        iconTextColor={iconTextColor}
      />
      <div className="flex items-center">
        {isOutput && <OutputLabel />}
        {!readonly && (
          <ContextMenuButton
            iconBackgroundColor={iconBackgroundColor}
            headerRef={header}
          />
        )}
      </div>
    </div>
  );
};

export default NodeHeader;
