export interface WidgetHeaderProps {
  title: string;
  description?: string;
  icon?: React.ReactNode;
  actions?: React.ReactNode[];
  className?: string;
  titleClassName?: string;
  descriptionClassName?: string;
  actionsClassName?: string;
}

const WidgetHeader: React.FC<WidgetHeaderProps> = ({
  title,
  description,
  icon,
  actions,
  className = "",
  titleClassName = "text-xl font-bold text-gray-800",
  descriptionClassName = "text-sm text-gray-600 mb-2",
  actionsClassName = "flex flex-wrap items-center gap-2 mb-2",
}) => {
  return (
    <div className={`flex flex-col ${className} w-full overflow-auto`}>
      <div className="flex items-center justify-between">
        {icon && <div className="mr-2">{icon}</div>}
        <p className={`text-xs ${titleClassName}`}>{title}</p>
      </div>
      {actions && <div className={actionsClassName}>{actions}</div>}
      {description && <p className={descriptionClassName}>{description}</p>}
    </div>
  );
};

export default WidgetHeader;
