import { DatasetEditorContext } from "@/components/DatasetEditor";
import { useAuth } from "@/context/auth-context";
import { useEditDatasetRow } from "@/queries";
import { displayErrorToast } from "@/utils/toast";
import { Cell } from "@tanstack/react-table";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

const useCellEditing = (cell?: Cell<any, any>) => {
  const cellValue = useMemo(() => cell?.getValue() || "", [cell]);

  const [currentValue, setValue] = useState<any>(cellValue);

  const auth = useAuth();
  const userToken = auth?.userToken;
  const { mutateAsync: editDatasetRow, isLoading } = useEditDatasetRow(
    userToken!,
  );
  const prevValue = useRef<string | undefined>(currentValue);
  // Ref to track if the change is from inlineEditing
  const isInlineEditingRef = useRef<boolean>(false);

  // Sync currentValue with cellValue when cellValue changes
  useEffect(() => {
    if (cellValue === prevValue.current) return;

    isInlineEditingRef.current = false; // Reset the flag
    setValue(cellValue);
  }, [cellValue]);

  const { setSavingCell, isSavingCell } = useContext(DatasetEditorContext);

  const onSave = useCallback(
    async (value: string, callback: () => void) => {
      const processedData = {
        ...cell?.row.original,
        [cell?.column.id as string]: value,
      };

      delete processedData.dataset_row_id;
      try {
        const response = await editDatasetRow({
          datasetRowId: Number(cell?.row.original.dataset_row_id),
          variables: processedData,
        });
        if (!response.success) {
          setValue(prevValue.current);
          displayErrorToast(
            response.error || "There was an error updating the cell",
          );
        }
        callback();
      } catch (error: unknown) {
        console.log(error);
      }
      setSavingCell(false);
    },
    [setSavingCell, cell?.row.original, cell?.column.id, editDatasetRow],
  );

  const onInput = useCallback((textContent: string) => {
    isInlineEditingRef.current = true; // Indicate that the change is from inlineEditing
    setValue(textContent);
  }, []);

  useEffect(() => {
    if (currentValue === prevValue.current) return;

    if (!isInlineEditingRef.current) {
      // Change came from external update, do not save
      prevValue.current = currentValue;
      return;
    }
    if (!isSavingCell) setSavingCell(true);

    const saveData = setTimeout(
      () => onSave(currentValue, () => (prevValue.current = currentValue)),
      800,
    );

    return () => clearTimeout(saveData);
  }, [cell, currentValue, isSavingCell, onSave, setSavingCell]);

  return { isLoading, currentValue, onInput, setValue, isInlineEditingRef };
};

export default useCellEditing;
