import { useWorkflow } from "@/components/Workflows/WorkflowCanvas/workflow-context";
import { Position } from "@xyflow/react";
import { observer } from "mobx-react-lite";
import { useNode } from "../../node-context";
import EditableNodeLabel from "./EditableNodeLabel";
import Handle from "./Handle";

interface NodeBodyProps {
  isConnectable: boolean;
  showHandles: boolean;
  label: string;
}

const NodeBody = ({ showHandles, isConnectable, label }: NodeBodyProps) => {
  const node = useNode();
  const workflow = useWorkflow();

  return (
    <div className="relative my-4 flex items-center justify-center px-24">
      {showHandles && (
        <Handle
          type="target"
          id={`${label}-target`}
          position={Position.Left}
          isConnectable={isConnectable}
        />
      )}
      <div className="max-w-full overflow-hidden text-center">
        <EditableNodeLabel
          initialLabel={node?.name || label}
          workflow={workflow}
          node={node!}
        />
      </div>
      {showHandles && (
        <Handle
          type="source"
          id={`${label}-source`}
          position={Position.Right}
          isConnectable={isConnectable}
        />
      )}
    </div>
  );
};

export default observer(NodeBody);
