import { createContext, useContext } from "react";

interface Config {
  features: {
    widgets: boolean;
  };
}

const defaultConfig: Config = {
  features: {
    widgets: true,
  },
};

const ConfigContext = createContext<Config>(defaultConfig);

export const ContentAreaConfigProvider: React.FC<{
  children: React.ReactNode;
  config?: Partial<Config>;
}> = ({ children, config }) => {
  const finalConfig = { ...defaultConfig, ...config };

  return (
    <ConfigContext.Provider value={finalConfig}>
      {children}
    </ConfigContext.Provider>
  );
};

export const useContentAreaConfig = () => {
  const context = useContext(ConfigContext);
  if (!context) {
    return defaultConfig;
  }
  return context;
};
