import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { cn } from "@/lib/utils";
import { useRef, useState } from "react";
import { Skeleton } from "../ui/skeleton";
import FolderGroup from "./FolderGroup";
import useAutoFocus from "./hooks/useAutoFocus";
import useFolderOrganization from "./hooks/useFolderOrganization";
import useTemplateSearch from "./hooks/useTemplateSearch";
import SearchInput from "./SearchInput";

interface TemplateSelectDropdownProps {
  selectedTemplateName: string | undefined;
  handleTemplateSelection: ({ id, name }: { id: number; name: string }) => void;
  dropdownTriggerclassName?: string;
  sourceTemplateId?: number | null;
  isSnippet?: boolean;
  disabled?: boolean;
}

export default function TemplateSelectDropdown({
  selectedTemplateName,
  handleTemplateSelection,
  dropdownTriggerclassName,
  sourceTemplateId = null,
  isSnippet = false,
  disabled = false,
}: TemplateSelectDropdownProps) {
  const userContext = useUser();
  const auth = useAuth();
  const userToken = auth?.userToken!;
  const workspaceId = userContext.activeWorkspaceId!;
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const { searchQuery, setSearchQuery, templateSet, availablePromptTemplates } =
    useTemplateSearch(userToken, workspaceId, isSnippet, sourceTemplateId);

  const {
    folderIdsToFolders,
    folderIdToTemplateList,
    templatesWithoutFolders,
  } = useFolderOrganization(userToken, workspaceId, availablePromptTemplates);

  useAutoFocus(isOpen, inputRef, setSearchQuery);

  return (
    <DropdownMenu onOpenChange={setIsOpen}>
      <DropdownMenuTrigger
        disabled={disabled}
        className={cn("w-full", dropdownTriggerclassName)}
      >
        {selectedTemplateName ? (
          selectedTemplateName
        ) : (
          <span className="font-normal text-gray-500">
            {availablePromptTemplates?.length > 0
              ? "Select a template..."
              : templateSet.isLoading
              ? "Loading..."
              : "No templates found"}
          </span>
        )}
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-[300px]">
        <SearchInput
          inputRef={inputRef}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
        />
        {templateSet.isLoading ? (
          <>
            {Array.from({ length: 3 }).map((_, i) => (
              <div key={i} className="px-2 py-2">
                <Skeleton className="h-6" />
              </div>
            ))}
          </>
        ) : searchQuery && availablePromptTemplates.length === 0 ? (
          <div className="px-2 py-2 text-sm text-gray-500">
            No templates found
          </div>
        ) : (
          <>
            {Object.keys(folderIdToTemplateList).map((folderId) => (
              <FolderGroup
                key={folderId}
                folder={folderIdsToFolders[Number(folderId)]}
                templates={folderIdToTemplateList[Number(folderId)]}
                handleTemplateSelection={handleTemplateSelection}
              />
            ))}
            {templatesWithoutFolders
              .sort((a, b) => a.prompt_name.localeCompare(b.prompt_name))
              .map((template) => (
                <DropdownMenuItem
                  key={template.prompt_name}
                  onSelect={() =>
                    handleTemplateSelection({
                      id: template.id,
                      name: template.prompt_name,
                    })
                  }
                >
                  {template.prompt_name}
                </DropdownMenuItem>
              ))}
          </>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
