import { useAuth } from "@/context/auth-context";
import { useDebounce } from "@/hooks";
import { useParseInputVariables } from "@/queries";
import { PromptBlueprint } from "@/types";
import { usePromptBlueprint } from "./use-prompt-blueprint";

export const useInputVariables = () => {
  const form = usePromptBlueprint();
  const authContext = useAuth();
  const userToken = authContext!.userToken!;
  const promptBlueprint = JSON.parse(
    useDebounce(JSON.stringify(form.watch()), 500),
  ) as PromptBlueprint;
  const parseInputVariables = useParseInputVariables(
    userToken,
    promptBlueprint,
  );
  const data = parseInputVariables.data;
  if (parseInputVariables.isLoading || !data)
    return {
      invalidPromptTemplate: false,
      inputVariables: [],
    };
  if (data.success) {
    return {
      invalidPromptTemplate: false,
      inputVariables: data.input_variables,
    };
  }
  const fullMessage = data.message[0].msg || "";
  const locationOfIndex = fullMessage.indexOf("index:");
  const message = fullMessage
    .substring(
      fullMessage.indexOf("msg:") + 4,
      locationOfIndex !== -1 ? locationOfIndex : undefined,
    )
    .trim();
  let index = fullMessage.substring(locationOfIndex + 6).trim();
  return {
    invalidPromptMessage: message,
    invalidPromptIndex: index,
    invalidPromptTemplate: true,
    inputVariables: [],
  };
};
