import React from "react";

const BandaidContext = React.createContext<any>(false);

interface BandaidProviderProps {
  readonly?: boolean;
  children: React.ReactNode;
}

const BandaidProvider = ({ children, readonly }: BandaidProviderProps) => (
  <BandaidContext.Provider value={{ readonly: !!readonly }}>
    {children}
  </BandaidContext.Provider>
);

const useBandaid = () => React.useContext(BandaidContext);

export { BandaidProvider, useBandaid };
