import { useCallback } from "react";
import { useContentArea } from "../../content-area-context";

const useSelectionHandler = (contentRef: React.RefObject<HTMLDivElement>) => {
  const content = useContentArea();

  const saveSelection = useCallback(() => {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      //content.setSavedSelection(selection.getRangeAt(0).cloneRange());
    }
  }, []);

  const restoreSelection = useCallback(() => {
    if (content.savedSelection && contentRef.current) {
      const selection = window.getSelection();
      selection?.removeAllRanges();
      selection?.addRange(content.savedSelection);
      contentRef.current.focus();
    }
  }, [content, contentRef]);

  return { saveSelection, restoreSelection };
};

export default useSelectionHandler;
