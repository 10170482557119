import { SearchIcon } from "@heroicons/react/outline";
import { Input } from "../ui/input";

const SearchInput = ({ inputRef, searchQuery, setSearchQuery }: any) => (
  <div className="px-2 py-2" onClick={(e) => e.stopPropagation()}>
    <div className="relative">
      <Input
        ref={inputRef}
        placeholder="Search templates..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        className="h-8 pl-8"
        onKeyDown={(e) => e.stopPropagation()}
      />
      <SearchIcon className="absolute left-2 top-2 h-4 w-4 text-gray-500" />
    </div>
  </div>
);

export default SearchInput;
