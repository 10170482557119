import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { usePromptRegistry } from "@/queries";
import { keepRelevantSearchParams } from "@/utils/utils";
import { StarIcon as StarIconSolid } from "@heroicons/react/solid";
import { Folder } from "lucide-react";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import SidebarCard from "./SidebarSearchLayout/SidebarCard";

export const SidebarRequestLogCard = ({
  active,
  logId,
  descriptor,
  tags,
  startTime,
  starred,
  prompt,
  response,
  responseIsFunction,
  type,
  cardinality,
  promptId,
}: {
  active: boolean;
  logId: number;
  descriptor: string;
  tags: string[];
  startTime: string;
  starred: boolean;
  prompt: string;
  response: string;
  responseIsFunction?: boolean;
  type: string | "request";
  cardinality: number | undefined;
  promptId?: number;
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const userContext = useUser();
  const auth = useAuth();
  const promptRegistryQuery = usePromptRegistry(auth?.userToken!, promptId);
  const prompt_name = promptRegistryQuery.data?.prompt_name;

  let timestamp = moment.utc(startTime).local().fromNow();
  if (moment.utc().isSame(moment.utc(startTime).local(), "day")) {
    timestamp = moment.utc(startTime).local().format("h:mm:ss A");
  }

  const [tagsList, setTagsList] = useState<string[]>(tags || []);

  useEffect(() => {
    setTagsList(tags || []);
  }, [tags]);

  return (
    <SidebarCard
      active={active}
      type={type}
      id={String(logId)}
      onClick={() => {
        const preservedParams = keepRelevantSearchParams(searchParams);
        let url = "";

        if (type === "group") {
          url = `/workspace/${userContext?.activeWorkspaceId}/group/${logId}?${preservedParams}`;
        } else {
          url = `/workspace/${userContext?.activeWorkspaceId}/request/${logId}?${preservedParams}`;
        }

        navigate(url);
      }}
    >
      <div className="flex items-center gap-2">
        {starred ? (
          <StarIconSolid
            className="h-4 w-auto text-yellow-500"
            aria-hidden="true"
          />
        ) : null}
        <div
          className={`font-light ${active ? "text-gray-700" : "text-gray-600"}`}
        >
          {descriptor}
        </div>
      </div>
      <div>
        <div
          className={`line-clamp-2 ${
            active ? "text-gray-600" : "text-gray-500"
          }`}
          title={prompt}
        >
          {type !== "group" ? (
            <span className="text-xs font-medium text-gray-700">Prompt: </span>
          ) : (
            <span className="text-xs font-light text-gray-700">Group </span>
          )}
          {type === "group" && (
            <span
              className={`${active ? "font-normal" : "font-light"}`}
            >{`[${logId}]`}</span>
          )}
          {type !== "group" && (
            <span className={`${active ? "font-normal" : "font-light"}`}>
              {typeof prompt === "string" ? prompt : ""}
            </span>
          )}
        </div>
        <div
          className={`line-clamp-2 ${
            active ? "text-gray-600" : "text-gray-500"
          } mt-1`}
          title={response}
        >
          <span className="text-xs font-medium text-gray-700">
            {type !== "group" ? (
              <span className="text-xs font-medium text-gray-700">
                Response:{" "}
              </span>
            ) : (
              <span className="text-xs font-medium text-gray-700">Size: </span>
            )}
          </span>
          {type !== "group" ? (
            <span
              className={`${active ? "font-normal" : "font-light"} ${
                responseIsFunction && "font-mono"
              }`}
            >
              {typeof response === "string" ? response : ""}
              {responseIsFunction && "()"}
            </span>
          ) : (
            <span className={`${active ? "font-normal" : "font-light"}`}>
              {cardinality} {cardinality === 1 ? "request" : "requests"}
            </span>
          )}
        </div>
      </div>
      <div className="space-y-1">
        {tagsList?.map((tag: string) => (
          <div
            key={tag}
            className={`mr-1 inline-flex items-center rounded-sm px-1.5 py-0.5 font-medium text-gray-400 ${
              active ? "bg-blue-100 text-gray-600" : "bg-gray-100"
            }`}
          >
            {tag}
          </div>
        ))}
      </div>
      <div
        className={`flex gap-x-4 ${
          prompt_name ? "justify-between" : "justify-end"
        }`}
      >
        {prompt_name === null || !prompt_name ? null : (
          <div
            className={`inline-flex items-center gap-1 ${
              active ? "text-gray-500" : "text-gray-400"
            } flex-grow truncate font-light`}
          >
            <Folder className="h-3 w-3 flex-shrink-0" />
            <span className="truncate">{prompt_name}</span>
          </div>
        )}
        <div
          title={startTime}
          className={`whitespace-nowrap ${
            active ? "text-gray-500" : "text-gray-400"
          } font-light`}
        >
          {timestamp}
        </div>
      </div>
    </SidebarCard>
  );
};
