import { Folder } from "@/types/folders";
import { PromptRegistry } from "@/types/prompt-registry";

export interface FolderNode {
  id: number | null;
  name: string;
  parent_id: number | null;
  path: FolderPath[] | null;
  templates: PromptRegistry[];
}

interface FolderPath {
  id: number;
  name: string;
}

type FolderMap = Map<number | null | "no_folder", FolderNode>;

/**
 * Helper function to get an existing folder node from the map or create a new one.
 * Updates the folder node's parent_id and path if necessary.
 *
 * @param id - The unique identifier of the folder. Can be a number or "no_folder".
 * @param name - The name of the folder.
 * @param parentId - The parent folder's ID. Null if it's a root folder.
 * @param path - An array representing the path to the folder.
 * @param foldersMap - The map containing all folder nodes.
 * @returns The folder node corresponding to the given ID.
 */
const getOrCreateFolder = (
  id: number | "no_folder",
  name: string,
  parentId: number | null,
  path: FolderPath[] | null,
  foldersMap: FolderMap,
): FolderNode => {
  let folderNode: FolderNode;

  if (!foldersMap.has(id)) {
    // Folder node doesn't exist in the map, so create a new one
    folderNode = {
      id: id === "no_folder" ? null : id, // If id is "no_folder", set it to null
      name,
      parent_id: parentId,
      path,
      templates: [],
    };
    foldersMap.set(id, folderNode);
  } else {
    // Folder node exists, retrieve it
    folderNode = foldersMap.get(id)!;

    // Update parent_id if it's undefined and parentId is provided
    if (folderNode.parent_id === undefined && parentId !== undefined) {
      folderNode.parent_id = parentId;
    }

    // Update path if it's null and a path is provided
    if (folderNode.path === null && path !== null) {
      folderNode.path = path;
    }
  }

  return folderNode;
};

/**
 * Organizes a list of templates into a hierarchical folder structure.
 *
 * @param templates - An array of PromptRegistry templates to organize.
 * @returns A map where each key is a folder ID and the value is a FolderNode containing templates.
 */
export function organizeTemplates(templates: PromptRegistry[]): FolderMap {
  const foldersMap = new Map<number | null | "no_folder", FolderNode>();

  // Iterate over each template to build the folder hierarchy
  for (const template of templates) {
    // Determine the folder ID and name for the template
    const folderId =
      template.folder_id !== null ? template.folder_id : "no_folder";
    const folderName = template.folder_name || "";
    const folderPath = template.folder_path || []; // The path leading to the folder

    let parentId: number | null = null; // Initialize parentId as null for root folders

    // Process each folder in the template's folder_path to build the hierarchy
    for (let i = 0; i < folderPath.length; i++) {
      const folderInPath = folderPath[i];
      const folderNodeId = folderInPath.id;
      const folderNodeName = folderInPath.name;

      // The path leading up to the current folder (excluding the current folder)
      const pathUpToCurrent = folderPath.slice(0, i);

      // Get or create the folder node for the current folder in the path
      getOrCreateFolder(
        folderNodeId,
        folderNodeName,
        parentId, // The parent ID is the previous folder's ID
        pathUpToCurrent, // The path up to the current folder
        foldersMap,
      );

      // Update parentId for the next iteration (next folder in the path)
      parentId = folderNodeId;
    }

    // Ensure the folder node for the template's folder is created
    const folderNode = getOrCreateFolder(
      folderId,
      folderName,
      parentId, // The parent ID is the last folder in the folderPath
      folderPath, // The full path to this folder
      foldersMap,
    );

    // Add the template to the folder's templates array
    folderNode.templates.push(template);
  }

  // At this point, foldersMap contains all folders with their templates and hierarchical information
  return foldersMap;
}

export const getNavigationUrl = (
  folderPath: string[],
  folderPathWithIds: Folder["path"],
  workspaceId: number | null,
  folderId?: string,
) => {
  if (folderPath?.length > 0) {
    const currentfolderIndex = folderPathWithIds.findIndex(
      (folder) => folder.id === parseInt(folderId ?? ""),
    );
    if (currentfolderIndex > 0) {
      return `/workspace/${workspaceId}/prompt-folder/${
        folderPathWithIds[currentfolderIndex - 1].id
      }`;
    }
    return `/workspace/${workspaceId}/prompt`;
  }
  return `/workspace/${workspaceId}/home`;
};
