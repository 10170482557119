import { useAuth } from "@/context/auth-context";
import { useDeleteWorkflow, useWorkflows } from "@/queries";
import { displayErrorToast } from "@/utils/toast";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DeleteItem } from "../DeleteItem";
import { Workflow } from "../Workflows/types";
import DefaultNameDialog from "./DefaultNameDialog";
import Registry from "./Registry";
import WorkflowCard from "./WorkflowCard";

const WorkflowGrid: React.FC = () => {
  const { workspaceId } = useParams();
  const navigate = useNavigate();
  const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);
  const [itemToDelete, setItemToDelete] = useState<Workflow | null>(null);
  const auth = useAuth();
  const userToken = auth?.userToken || "";
  const deleteWorkflow = useDeleteWorkflow(userToken);

  const renderCard = (
    item: Workflow,
    workspaceId: string,
    onDelete: (item: Workflow) => void,
  ): React.ReactNode => {
    return (
      <WorkflowCard
        workflow={item}
        workspaceId={workspaceId}
        onDelete={onDelete}
      />
    );
  };

  const handleDeleteTrigger = (item: Workflow): void => {
    setItemToDelete(item);
    setIsDeleteOpen(true);
  };

  const handleDeleteConfirm = (): void => {
    if (itemToDelete) {
      deleteWorkflow.mutate(
        { workflow_id: itemToDelete.id },
        {
          onSuccess: () => {
            setIsDeleteOpen(false);
            setItemToDelete(null);
          },
          onError: () => {
            displayErrorToast("Error deleting workflow");
          },
        },
      );
    }
  };

  const [isDefaultNameDialogOpen, setDefaultNameDialogOpen] = useState(false);

  return (
    <>
      <Registry<Workflow>
        title={"Workflow"}
        subtitle={
          <>
            Build workflows of one or more prompts.{" "}
            <a
              href="https://docs.promptlayer.com/why-promptlayer/workflows"
              target="_blank"
              rel="noreferrer"
              className="text-blue-500 hover:text-blue-600"
            >
              Learn more.
            </a>
          </>
        }
        itemsKey={"workflows"}
        createButtonText={"Create Workflow"}
        createButtonOnClick={() => setDefaultNameDialogOpen(true)}
        useDataHook={useWorkflows}
        renderCard={renderCard}
        onDeleteItem={handleDeleteTrigger}
      />
      <DeleteItem
        open={isDeleteOpen}
        setOpen={setIsDeleteOpen}
        setDelete={handleDeleteConfirm}
        itemName={itemToDelete?.name || ""}
        itemType={"workflow"}
      />
      <DefaultNameDialog
        isOpen={isDefaultNameDialogOpen}
        onClose={() => setDefaultNameDialogOpen(false)}
        onSubmit={(entryName) => {
          navigate(
            `/workspace/${workspaceId}/create-workflow?name=${entryName}`,
          );
        }}
        dialogTitle={"Enter Workflow Name"}
        dialogDescription="Give your workflow a name to get started!"
        inputPlaceholder={"Type name here..."}
      />
    </>
  );
};

export default WorkflowGrid;
