import AdvancedControls from "@/components/ModelProviderSelection/AdvancedControls";
import { SelectModelFromConfigsDropdown } from "@/components/ModelProviderSelection/SelectModelFromConfigsDropdown";
import { SelectProviderFromConfigs } from "@/components/ModelProviderSelection/SelectProviderFromConfigs";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import useProviderBaseURLNameOptions from "@/hooks/useProviderBaseURLNameOptions";
import { ModelConfigMap } from "@/modelConfig";
import {
  usePromptRegistryObjects,
  usePromptVersion,
  usePromptVersions,
} from "@/queries";
import { PromptTemplateConfiguration } from "@/types/evaluate";
import { ChevronDownIcon } from "@heroicons/react/outline";
import React, { useMemo, useState } from "react";
import { ModalStep } from "../ModalRouter";

type IndexParam = {
  paramKey: string;
  paramValue: number;
  id: number;
};

const getCustomParametersFromConfiguration = (
  config: Partial<PromptTemplateConfiguration>,
  previousCustomParams: { paramKey: string; paramValue: number; id: number }[],
): { paramKey: string; paramValue: number; id: number }[] => {
  const configParams = config.engine?.parameters;

  if (!configParams) {
    return [];
  }

  const fullCustomParams = Object.entries(configParams).map(
    ([key, value]: [string, any]) => {
      const existingParam = previousCustomParams.find(
        (param) => param.paramKey === key,
      );
      return {
        paramKey: key,
        paramValue: value,
        id: existingParam
          ? existingParam.id
          : Math.floor(Math.random() * 1000000),
      };
    },
  );

  return fullCustomParams;
};

const ChooseEngineStep = ({
  navigateAway,
  configuration,
  setConfiguration,
  modelConfigs,
  editable,
}: {
  navigateAway: () => void;
  configuration: Partial<PromptTemplateConfiguration>;
  setConfiguration: (
    data:
      | Partial<PromptTemplateConfiguration>
      | ((
          prevState: Partial<PromptTemplateConfiguration>,
        ) => Partial<PromptTemplateConfiguration>),
  ) => void;
  modelConfigs: ModelConfigMap;
  editable: boolean;
}) => {
  const [model, setModel] = useState<string>(configuration.engine?.model || "");
  const [provider, setProvider] = useState<string>(
    configuration.engine?.provider || "",
  );
  const [parameters, setParameters] = useState<IndexParam[]>(
    getCustomParametersFromConfiguration(configuration, []),
  );

  const modelConfig =
    model && provider && modelConfigs[provider] && modelConfigs[provider][model]
      ? modelConfigs[provider][model]
      : null;
  const params: {
    regular: { [key: string]: any };
    custom: IndexParam[];
  } = useMemo(() => {
    if (!modelConfig) {
      return { regular: {}, custom: parameters };
    }

    const knownParams = parameters.filter(
      (param) => param.paramKey in modelConfig.params,
    );
    const regulars: { [key: string]: any } = {};
    knownParams.forEach((param) => {
      regulars[param.paramKey] = param.paramValue;
    });

    const customs: IndexParam[] = parameters.filter(
      (param) => !(param.paramKey in modelConfig.params),
    );
    return { regular: regulars, custom: customs };
  }, [modelConfig, parameters]);

  const [selectedProviderBaseURLName, setSelectedProviderBaseURLName] =
    useState<string | null>(
      configuration?.engine?.provider_base_url_name || null,
    );

  const saveAction = () => {
    navigateAway();

    setConfiguration((previousConfig: Partial<PromptTemplateConfiguration>) => {
      const newConfig = { ...previousConfig };

      if (isCustom) {
        if (provider && model) {
          newConfig.engine = {
            provider_base_url_name: selectedProviderBaseURLName || null,
            provider,
            model,
            parameters: {},
          };
          parameters.forEach((param) => {
            newConfig.engine!.parameters![param.paramKey] = param.paramValue;
          });
        } else if ("engine" in newConfig) {
          delete newConfig.engine;
        }
      } else if ("engine" in newConfig) {
        delete newConfig.engine;
      }

      return newConfig;
    });
  };

  const [isCustom, setIsCustom] = useState(configuration.engine ? true : false);

  const userContext = useUser();
  const auth = useAuth();
  const userToken = auth?.userToken!;
  const workspaceId = userContext.activeWorkspaceId!;

  const promptRegistryObjects = usePromptRegistryObjects(userToken, {
    workspaceId,
    perPage: Number.MAX_SAFE_INTEGER,
  });

  const availablePromptTemplates =
    promptRegistryObjects.data?.pages.flatMap((page) => page.items) || [];

  const selectedPromptRegistry = availablePromptTemplates.find(
    (template) => template.prompt_name === configuration.template?.name,
  );

  const promptVersionQuery = usePromptVersions(userToken, {
    workspaceId,
    promptRegistryId: selectedPromptRegistry?.id,
    perPage: Number.MAX_SAFE_INTEGER,
  });
  const promptVersions =
    promptVersionQuery.data?.pages.flatMap((page) => page.items) || [];

  const version =
    promptVersions.find(
      (version) => version.number === configuration.template?.version_number,
    ) || promptVersions.at(0);

  const promptTemplateVersionQuery = usePromptVersion(
    userToken,
    // @ts-ignore
    selectedPromptRegistry?.id,
    version?.number,
  );

  const selectedPromptTemplateVersion = promptTemplateVersionQuery.data;

  const isChat = selectedPromptTemplateVersion?.prompt_template?.type
    ? selectedPromptTemplateVersion?.prompt_template.type === "chat"
    : false;

  const [showAdvancedControls, setShowAdvancedControls] = useState(false);
  const toggleShowAdvancedControls = () => {
    setShowAdvancedControls(!showAdvancedControls);
  };

  const isCustomModel = !(
    provider &&
    model &&
    modelConfigs[provider] &&
    modelConfigs[provider][model]
  );

  const handleUpdateRegularParameter = (key: string, value: any) => {
    const isNewKey = !parameters.find((param) => param.paramKey === key);
    if (isNewKey) {
      parameters.push({
        paramKey: key,
        paramValue: value,
        id: Math.floor(Math.random() * 1000000),
      });
    } else {
      parameters.map((param) =>
        param.paramKey === key
          ? { paramKey: key, paramValue: value, id: param.id }
          : param,
      );
    }
  };

  const handleUpdateCustomParameter = (updatedParams: IndexParam[]) => {
    const newParams: IndexParam[] = [...updatedParams];
    Object.entries(params.regular).forEach(([key, value]) => {
      newParams.push({
        paramKey: key,
        paramValue: value,
        id: Math.floor(Math.random() * 1000000),
      });
    });
    setParameters(newParams);
  };

  const handleModelParamChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setParameters(
      parameters.map((param) =>
        param.paramKey === event.target.name
          ? {
              paramKey: param.paramKey,
              paramValue: Number(event.target.value),
              id: param.id,
            }
          : param,
      ),
    );
  };

  const handleModelParamChangeDropDown = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const newParameters = [...parameters];
    const paramKeyNames = newParameters.map((parameter) => parameter.paramKey);

    if (!paramKeyNames.includes(event.target.name)) {
      newParameters.push({
        paramKey: event.target.name,
        paramValue:
          typeof event.target.value === "string"
            ? JSON.parse(event.target.value)
            : event.target.value,
        id: Math.floor(Math.random() * 1000000),
      });
    }

    setParameters(
      newParameters.map((param) =>
        param.paramKey === event.target.name
          ? {
              paramKey: param.paramKey,
              paramValue:
                typeof event.target.value === "string"
                  ? JSON.parse(event.target.value)
                  : event.target.value,
              id: param.id,
            }
          : param,
      ),
    );
  };

  const handleSelectProvider = (provider: string) => {
    setProvider(provider);
    setModel("");
    setParameters([]);
  };

  const handleSelectModel = (model: string) => {
    // Start with current params
    const newParams: IndexParam[] = model.includes("o1") ? [] : parameters;

    // Pre-fill model param defaults
    if (
      model &&
      provider &&
      modelConfigs[provider] &&
      modelConfigs[provider][model]
    ) {
      const defaultParams = model.includes("o1")
        ? {}
        : modelConfigs[provider][model].params;

      // Fill in missing params with defaults
      Object.entries(defaultParams).forEach(([key, value]) => {
        if (
          value.default &&
          typeof value.default === "number" &&
          !newParams.some((param) => param.paramKey === key)
        ) {
          newParams.push({
            paramKey: key,
            paramValue: value.default,
            id: Math.floor(Math.random() * 1000000),
          });
        }
      });
    }

    setModel(model);
    setParameters(newParams);
  };

  const handleToggleIsCustom = () => {
    const newIsCustom = !isCustom;

    if (!newIsCustom) {
      setParameters([]);
    } else {
      // Build a starting configuration
      let newProvider = "openai";
      let newModel = isChat ? "gpt-3.5-turbo" : "gpt-3.5-turbo-instruct";
      let newModelParams: IndexParam[] = [];

      // Grab engine from chosen prompt template
      const chosenPromptTemplateVersion = selectedPromptTemplateVersion;

      // Pre-fill if relevant
      if (chosenPromptTemplateVersion) {
        const versionModel = chosenPromptTemplateVersion?.metadata?.model;
        if (versionModel) {
          if (versionModel.provider) {
            newProvider = versionModel.provider;
          }
          if (versionModel.name) {
            newModel = versionModel.name;
          }
          if (versionModel.parameters) {
            newModelParams = Object.entries(versionModel.parameters).map(
              ([key, value]) => {
                return {
                  paramKey: key,
                  paramValue: value as number,
                  id: Math.floor(Math.random() * 1000000),
                };
              },
            );
          }
        }
      }

      // If still empty, fill out modelParams with defaults from modelConfig
      if (newModelParams.length === 0) {
        const config = modelConfigs[newProvider];
        if (newModel in config) {
          Object.entries(modelConfigs[newProvider][newModel].params).forEach(
            ([key, value]) => {
              if (value.default && typeof value.default === "number") {
                newModelParams.push({
                  paramKey: key,
                  paramValue: value.default,
                  id: Math.floor(Math.random() * 1000000),
                });
              }
            },
          );
        } else {
        }
      }

      setProvider(newProvider);
      setModel(newModel);
      setParameters(newModelParams);
    }
    setIsCustom(newIsCustom);
  };

  const providerBaseURLNameOptions = useProviderBaseURLNameOptions(
    configuration.engine?.provider_base_url_name,
  );

  const renderProviderBaseURLRow = () => {
    if (!providerBaseURLNameOptions.length) return null;

    let value = selectedProviderBaseURLName === "" ? "" : undefined;

    if (value === undefined) {
      value =
        selectedProviderBaseURLName ||
        configuration.engine?.provider_base_url_name ||
        "";
    }

    const italicStyling = value === "" ? "italic text-gray-500" : "";

    return (
      <div className="flex-0 mb-4 md:mb-0">
        <div className="mb-4">
          <Label className="col-span-2" htmlFor="providerBaseURL">
            Provider Base URL
          </Label>
          <div className="col-span-2">
            <Select
              onValueChange={(providerName) => {
                setSelectedProviderBaseURLName(providerName);
              }}
              value={value}
            >
              <SelectTrigger
                className={`flex items-center justify-between break-all rounded-sm border border-gray-300 bg-white py-2 pl-4 pr-2 text-sm font-medium text-gray-800 shadow-sm hover:bg-gray-50 ${italicStyling}`}
                id="providerBaseURL"
              >
                <SelectValue placeholder="Default" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  {providerBaseURLNameOptions.map((providerName) => (
                    <SelectItem
                      className={
                        providerName === "" ? "italic text-gray-500" : ""
                      }
                      key={providerName}
                      value={providerName}
                    >
                      {providerName || "Default"}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
        </div>
      </div>
    );
  };

  return (
    <ModalStep
      navigateNext={editable ? saveAction : undefined}
      nextButtonText={editable ? "Save" : undefined}
      navigatePrevious={editable ? undefined : saveAction}
    >
      <div className="pb-4">
        <div className="flex items-center">
          <input
            type="checkbox"
            className="mr-2 cursor-pointer"
            id="customize-engine"
            checked={isCustom}
            onChange={handleToggleIsCustom}
            disabled={!editable}
            key={isCustom.toString()}
          />
          <label
            htmlFor="customize-engine"
            className="cursor-pointer text-base font-medium"
          >
            Customize engine & parameters?
          </label>
        </div>
        <div className="ml-6 text-sm font-light text-gray-500">
          By default, the engine and model parameters will be pulled from the
          Prompt Registry.
        </div>
      </div>
      {!isCustom && (
        <div className={`rounded-md border border-gray-200 bg-gray-50 p-3`}>
          The parameters will be pulled at runtime from your Prompt Template :)
        </div>
      )}
      {isCustom && (
        <div className={`rounded-md border border-gray-200 bg-gray-50 p-3`}>
          <div className="flex-0 mb-4 md:mb-0">
            <div className="mb-4">
              <label
                htmlFor="version-select"
                className="mb-2 block text-sm font-medium text-gray-700"
              >
                LLM Provider
              </label>
              <div className="relative">
                {editable ? (
                  <SelectProviderFromConfigs
                    selectedProvider={provider || ""}
                    handleSelectProvider={handleSelectProvider}
                    modelConfigs={modelConfigs}
                  />
                ) : (
                  <div className="font-mono font-semibold text-gray-800">
                    {provider}
                  </div>
                )}
              </div>
            </div>
          </div>

          {provider && (
            <div className="flex-0 mb-4 md:mb-0">
              <div className="mb-4">
                <label
                  htmlFor="version-select"
                  className="mb-2 block text-sm font-medium text-gray-700"
                >
                  {provider === "huggingface" ? "Inference Client" : "Model"}
                </label>
                <div className="relative">
                  {editable ? (
                    <SelectModelFromConfigsDropdown
                      model={model || ""}
                      provider={provider}
                      setModel={handleSelectModel}
                      filterOptions={{ is_chat: isChat }}
                    />
                  ) : (
                    <div className="font-mono font-semibold text-gray-800">
                      {model}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {provider !== "huggingface" && renderProviderBaseURLRow()}

          {model && (
            <div className="mb-4 mt-2 flex justify-end">
              <button
                onClick={toggleShowAdvancedControls}
                className="flex items-center text-xs text-blue-500 hover:text-blue-700 focus:outline-none"
              >
                Show Advanced Controls
                <ChevronDownIcon
                  className={`ml-1 h-4 w-4 transform ${
                    showAdvancedControls ? "rotate-180" : ""
                  }`}
                />
              </button>
            </div>
          )}
          {showAdvancedControls && provider && model && (
            // TODO: Advanced Controls should save everything, not only edits
            <AdvancedControls
              isCustomModel={isCustomModel}
              selectedProvider={provider}
              selectedModel={model}
              modelParams={params.regular}
              handleModelParamChange={handleModelParamChange}
              handleModelParamChangeDropDown={handleModelParamChangeDropDown}
              updateCandidate={
                editable ? handleUpdateRegularParameter : () => {}
              }
              customParameters={params.custom}
              setCustomParameters={
                editable ? handleUpdateCustomParameter : () => {}
              }
              modelConfigs={modelConfigs}
            />
          )}
        </div>
      )}
    </ModalStep>
  );
};

export default ChooseEngineStep;
