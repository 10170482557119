import FineTuneModal from "@/components/FineTuneModal/FineTuneModal";
import { useUser } from "@/context/user-context";
import { keepRelevantSearchParams } from "@/utils/utils";
import { AdjustmentsIcon, DocumentReportIcon } from "@heroicons/react/outline";
import { useLocation, useSearchParams, Link } from "react-router-dom";

const FineTuneDatasets = ({ mobile = false }: { mobile?: boolean }) => {
  const location = useLocation();
  const user = useUser();
  const [searchParams] = useSearchParams();
  return (
    <div
      className={`${
        mobile && "flex-col"
      }  my-2 flex justify-center gap-x-6  text-sm text-gray-600`}
    >
      <FineTuneModal>
        <div className="flex cursor-pointer items-center rounded-md px-4 py-2 hover:bg-gray-200 hover:text-gray-800">
          <AdjustmentsIcon className="mr-2 h-4 w-4" />
          Fine-Tune
        </div>
      </FineTuneModal>
      <Link
        to={{
          pathname: `/workspace/${user?.activeWorkspaceId}/datasets`,
          search: `${keepRelevantSearchParams(searchParams)}`,
        }}
        className={`flex cursor-pointer items-center rounded-md px-4 py-2 hover:bg-gray-200 hover:text-gray-800 ${
          location.pathname.includes(
            `/workspace/${user?.activeWorkspaceId}/dataset`,
          )
            ? "bg-gray-200 text-gray-800"
            : ""
        }`}
      >
        <DocumentReportIcon className="mr-2 h-4 w-4" />
        Datasets
      </Link>
    </div>
  );
};

export default FineTuneDatasets;
