import { API_URL, ENDPOINTS } from "@/api/application-api";
import {
  Workflow,
  WorkflowInfo,
  WorkflowNode,
  WorkflowNodesResponse,
  WorkflowVersion,
  WorkflowVersionExecution,
  WorkflowVersionResponse,
} from "@/components/Workflows/types";
import { queryKeys } from "@/constants";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { promptLabel } from "@/schemas";
import {
  BasePromptTemplate,
  CreatePromptTemplate,
  CreatePromptVersion,
  InferenceClient,
  InferenceClientRequestBody,
  ListPromptVersions,
  ListPromptVersionsParams,
  PromptBlueprint,
} from "@/types";
import { PromptTemplateFromRegistry, PromptVersion } from "@/types/apiGetters";
import { Comment } from "@/types/comments";
import { Conditional } from "@/types/conditionals";
import { DatasetGroupRead, DatasetGroupWrite } from "@/types/dataset-groups";
import { ValidationError } from "@/types/errors";
import { Report, ReportColumn } from "@/types/evaluate";
import {
  ListPromptRegistryObjects,
  PaginatedPromptRegistryObjects,
  PromptRegistry,
} from "@/types/prompt-registry";
import { ReleaseLabelGroupReadList } from "@/types/release-label-groups";
import { RequestLogFilterParams } from "@/types/request-log-filter-params";
import { Scores } from "@/types/requests";
import { ErrorResponse } from "@/types/response";
import { Span } from "@/types/spans";
import { Thread } from "@/types/threads";
import { Workspace } from "@/types/workspaces";
import { handleResponse } from "@/utils/errorResponseHandlers";
import { authHeader, jsonAuthHeaders } from "@/utils/headers";
import { displayErrorToast } from "@/utils/toast";
import { URL_PARAM_KEYS } from "@/utils/utils";
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "@tanstack/react-query";
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

const fetchRequests = (
  userToken: string,
  searchParams: Record<string, any>,
  pageParam: number,
  workspace_id: number | null,
) => {
  const url = new URL(ENDPOINTS.get_requests);
  url.searchParams.set("page", pageParam.toString());
  if (searchParams.q) url.searchParams.set("q", searchParams.q);

  if (workspace_id) {
    url.searchParams.set("workspace_id", workspace_id.toString());
  }

  if (searchParams.selectedTags?.length)
    searchParams.selectedTags.map((tag: string) =>
      url.searchParams.append("tags_and", tag),
    );
  if (searchParams.starred)
    url.searchParams.set("starred", searchParams.starred);
  if (searchParams.metadataFields?.length > 0)
    url.searchParams.set(
      "metadata_and",
      JSON.stringify(searchParams.metadataFields),
    );
  if (searchParams.startTime)
    url.searchParams.set("start_time", searchParams.startTime);
  if (searchParams.endTime)
    url.searchParams.set("end_time", searchParams.endTime);
  if (searchParams.scores?.length > 0)
    url.searchParams.set("scores", JSON.stringify(searchParams.scores));
  if (searchParams.promptTemplate)
    url.searchParams.set(
      "prompt_template",
      JSON.stringify(searchParams.promptTemplate),
    );

  return fetch(url, {
    headers: authHeader(userToken),
    credentials: "include",
  }).then(handleResponse);
};

export const useRequests = (
  userToken: string,
  searchParams: Record<string, any>,
  workspace_id: number | null,
  enabledFlag: boolean = true,
) =>
  useInfiniteQuery(
    [queryKeys.requests, userToken, searchParams, workspace_id],
    ({ pageParam = 1 }) =>
      fetchRequests(userToken, searchParams, pageParam, workspace_id),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.has_next) {
          return lastPage.next_num;
        }
      },
      placeholderData: {
        pageParams: [1],
        pages: [
          {
            has_next: false,
            has_prev: false,
            items: [],
            next_num: null,
            page: 1,
            pages: 1,
            per_page: 100,
            prev_num: null,
            total: 1,
          },
        ],
      },
      enabled: enabledFlag,
    },
  );

export const useDuplicatePromptTemplateToWorkspace = (userToken: string) => {
  return useMutation(
    ({
      prompt_template_id,
      workspace_id,
    }: {
      prompt_template_id: string;
      workspace_id: number;
    }) => {
      return fetch(`${ENDPOINTS.duplicate_prompt_template_to_workspace}`, {
        method: "POST",
        headers: jsonAuthHeaders(userToken),
        body: JSON.stringify({
          prompt_template_id,
          workspace_id,
        }),
      }).then(handleResponse);
    },
  );
};

export const useBranchToWorkspace = (userToken: string) => {
  return useMutation(
    ({
      prompt_template_id,
      version_id,
      new_template_name,
      workspace_id,
      existing_template_id,
    }: {
      prompt_template_id: string;
      version_id: number;
      new_template_name: string | null;
      workspace_id: number;
      existing_template_id: number | null;
    }) => {
      return fetch(`${ENDPOINTS.branch_prompt_template_version}`, {
        method: "POST",
        headers: jsonAuthHeaders(userToken),
        body: JSON.stringify({
          prompt_template_id,
          workspace_id,
          version_id,
          new_template_name,
          existing_template_id,
        }),
      }).then(handleResponse);
    },
  );
};

export const useRequestPrompt = (
  promptId: string,
  workspaceId: number,
  version: string,
  userToken: string,
  pageSize: number,
  pageNumber: number,
  sortBy: string,
  sortOrder: string,
  enabled: boolean = true,
) =>
  useQuery(
    [
      "request-prompt",
      promptId,
      workspaceId,
      version,
      pageSize,
      pageNumber,
      sortBy,
      sortOrder,
    ],
    () =>
      fetch(
        `${
          ENDPOINTS.get_requests_for_prompt
        }?prompt_id=${promptId}&workspace_id=${workspaceId}&version=${version}&per_page=${pageSize}&page=${pageNumber}&sortBy=${
          sortBy ? sortBy : "id"
        }&sortOrder=${sortOrder ? sortOrder : "desc"}`,
        {
          headers: authHeader(userToken),
        },
      ).then(handleResponse),
    {
      enabled: enabled,
    },
  );

export const useRequestStats = (
  promptId: string,
  version: string,
  userToken: string,
  workspaceId: string,
) =>
  useQuery(
    [ENDPOINTS.get_request_stats_for_prompt, promptId, version, workspaceId],
    () =>
      fetch(
        `${ENDPOINTS.get_request_stats_for_prompt}?prompt_id=${promptId}&version=${version}&workspace_id=${workspaceId}`,
        {
          headers: authHeader(userToken),
        },
      ).then(handleResponse),
    {
      placeholderData: {
        success: false,
        stats: {
          averageLatency: 0,
          totalCost: 0,
          totalRequests: 0,
          averageScore: 0,
          totalScoredRequests: 0,
        },
      },
    },
  );

export const useRequestCountsForVersions = (
  promptId: string,
  workspaceId: string,
  userToken: string,
) =>
  useQuery(["get-request-counts-for-versions", promptId, workspaceId], () =>
    fetch(
      `${ENDPOINTS.get_request_counts_for_versions}?prompt_id=${promptId}&workspace_id=${workspaceId}`,
      {
        headers: authHeader(userToken),
      },
    ).then(handleResponse),
  );

export const useEvaluationScoresForVersions = (
  promptId: string,
  userToken: string,
) =>
  useQuery(["version-evaluation-scores", promptId], () =>
    fetch(
      `${ENDPOINTS.prompt_templates}/${promptId}/version-evaluation-scores`,
      {
        headers: authHeader(userToken),
      },
    ).then(handleResponse),
  );

export const useGroupRequests = (groupId: string, userToken: string) =>
  useQuery(["get-group", groupId], () =>
    fetch(`${ENDPOINTS.get_group}?id=${groupId}`, {
      headers: authHeader(userToken),
    }).then(handleResponse),
  );

type UseAnalyticsParams = {
  userToken: string;
  startDate: string;
  endDate: string;
  tags?: string[];
  metadata: { key: string; value: string }[];
  starred?: boolean;
  workspace_id: number | null;
};

export const useAnalytics = ({
  userToken,
  startDate,
  endDate,
  tags,
  metadata,
  starred,
  workspace_id,
}: UseAnalyticsParams) =>
  useQuery(
    ["analytics", startDate, endDate, tags, metadata, starred, workspace_id],
    () => {
      const url = new URL(ENDPOINTS.statistics_page);
      if (startDate) url.searchParams.set("start_date", startDate);
      if (endDate) url.searchParams.set("end_date", endDate);
      if (workspace_id) {
        url.searchParams.set("workspace_id", workspace_id.toString());
      }

      if (tags && tags.length)
        tags.map((tag: string) => url.searchParams.append("tags", tag));
      if (metadata.length > 0)
        url.searchParams.set("metadata", JSON.stringify(metadata));
      if (starred) url.searchParams.set("starred", "true");

      let res = fetch(url, {
        headers: authHeader(userToken),
      }).then(handleResponse);

      return res;
    },
    {
      placeholderData: {
        success: false,
        averageLatency: 0,
        totalCost: 0,
        totalRequests: 0,
        requestsCount: [],
        tokensCount: [],
        mostUsedModels: [],
        cost: [],
        latency: [],
      },
    },
  );

type useRequestStatsByPromptParams = {
  endDate: string;
  metadata: { key: string; value: string }[];
  page: number;
  perPage: number;
  sortBy?: string;
  sortOrder?: string;
  startDate: string;
  tags: string[];
  userToken: string;
  workspace_id?: number;
  starred?: boolean;
};

export const useRequestStatsByPrompt = ({
  endDate,
  metadata,
  page,
  perPage,
  sortBy = "total_cost",
  sortOrder = "desc",
  startDate,
  tags,
  userToken,
  workspace_id,
  starred,
}: useRequestStatsByPromptParams) => {
  const url = new URL(ENDPOINTS.request_stats_by_prompt);
  url.searchParams.set("page", page.toString());
  url.searchParams.set("per_page", perPage.toString());
  url.searchParams.set("sort_by", sortBy);
  url.searchParams.set("sort_order", sortOrder);
  if (workspace_id)
    url.searchParams.set("workspace_id", workspace_id.toString());
  if (startDate) url.searchParams.set("start_date", startDate);
  if (endDate) url.searchParams.set("end_date", endDate);
  if (tags) url.searchParams.set("tags", JSON.stringify(tags));
  if (metadata) url.searchParams.set("metadata", JSON.stringify(metadata));
  if (starred !== undefined)
    url.searchParams.set("starred", starred.toString());

  return useQuery(
    [
      "request_stats_by_prompt",
      endDate,
      metadata,
      page,
      perPage,
      sortBy,
      sortOrder,
      startDate,
      tags,
      workspace_id,
      starred,
    ],
    () =>
      fetch(url, {
        headers: authHeader(userToken),
      }).then(handleResponse),
    {
      initialData: {
        items: [],
        totalCost: 0,
      },
    },
  );
};

export const useTags = (
  userToken: string,
  workspace_id: number | null,
  page = 1,
  searchQuery?: string,
) => {
  return useQuery(
    [ENDPOINTS.tags, userToken, workspace_id, page, searchQuery],
    () => {
      const url = new URL(ENDPOINTS.tags);

      if (workspace_id) {
        url.searchParams.set("workspace_id", workspace_id.toString());
        url.searchParams.set("page", String(page));
        if (searchQuery) url.searchParams.set("q", searchQuery);
      }

      return fetch(url, {
        headers: authHeader(userToken),
      }).then(handleResponse);
    },
    {
      placeholderData: [],
    },
  );
};

export const useSharedRequest = (shareHash: string) =>
  useQuery(
    [ENDPOINTS.get_shared_request, shareHash],
    async () => {
      const response = await fetch(
        `${ENDPOINTS.get_shared_request}?share_hash=${shareHash}`,
      );
      if (!response.ok) {
        throw new Error(String(response.status)); // throw the status as an error
      }
      return response.json();
    },
    { retry: 1 },
  );

export const useRequest = (requestId: string) => {
  const auth = useAuth();
  const userToken = auth?.userToken!;
  return useQuery([queryKeys.request, requestId], () =>
    fetch(`${ENDPOINTS.request_logs}/${requestId}`, {
      headers: authHeader(userToken),
      credentials: "include",
    }).then(handleResponse),
  );
};

export const useRequestGroups = (
  requestId: string | undefined,
  userToken: string,
) =>
  useQuery(
    [ENDPOINTS.get_groups_per_request, requestId],
    () =>
      fetch(`${ENDPOINTS.get_groups_per_request}/${requestId}`, {
        headers: authHeader(userToken),
      }).then(handleResponse),
    { enabled: requestId !== undefined },
  );

export const useMetadataFields = (
  userToken: string,
  workspace_id: number | null,
) => {
  return useQuery(
    [queryKeys.metadataFields, userToken, workspace_id],
    () => {
      const url = new URL(ENDPOINTS.metadata_fields);

      if (workspace_id) {
        url.searchParams.set("workspace_id", workspace_id.toString());
      }

      return fetch(url, {
        headers: authHeader(userToken),
      }).then(handleResponse);
    },
    {
      placeholderData: {
        success: true,
        metadataFields: [],
      },
    },
  );
};

export const useScoreNames = (userToken: string, workspace_id: number | null) =>
  useQuery(
    [queryKeys.scoreNames, userToken, workspace_id],
    () => {
      const url = new URL(ENDPOINTS.score_names);

      if (workspace_id) {
        url.searchParams.set("workspace_id", workspace_id.toString());
      }

      return fetch(url, {
        headers: authHeader(userToken),
      }).then(handleResponse);
    },
    {
      placeholderData: {
        success: true,
        scoreNames: [],
      },
    },
  );

export const useIntercomVerification = (userToken: string) =>
  useQuery([ENDPOINTS.get_intercom_verification, userToken], () =>
    fetch(ENDPOINTS.get_intercom_verification, {
      headers: authHeader(userToken),
    }).then(handleResponse),
  );

export const useUserSubscriptionStatus = (userToken: string) =>
  useQuery([ENDPOINTS.get_user_subscription_status, userToken], () =>
    fetch(ENDPOINTS.get_user_subscription_status, {
      headers: authHeader(userToken),
    }).then(handleResponse),
  );

export const useCreateStripeCheckoutSession = (userToken: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    (priceId: number) =>
      fetch(ENDPOINTS.create_stripe_checkout_session, {
        method: "POST",
        headers: jsonAuthHeaders(userToken),
        body: JSON.stringify({ price_id: priceId }),
      }).then(handleResponse),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          ENDPOINTS.create_stripe_checkout_session,
        ]);
      },
    },
  );
};

export const useSetUserToFreePlan = (userToken: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    () =>
      fetch(ENDPOINTS.set_user_to_free_plan, {
        method: "POST",
        headers: jsonAuthHeaders(userToken),
      }).then(handleResponse),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ENDPOINTS.set_user_to_free_plan]);
      },
    },
  );
};

export const useCreateIndividualRun = (userToken: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    (body: {
      input_variables: any;
      prompt_blueprint: PromptBlueprint;
      workspace_id: number;
    }) =>
      fetch(ENDPOINTS.create_individual_run, {
        method: "POST",
        headers: jsonAuthHeaders(userToken),
        body: JSON.stringify(body),
      }).then(handleResponse),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ENDPOINTS.create_individual_run]);
      },
    },
  );
};

export const useReplaceDataset = (
  userToken: string,
  workspaceId: number,
  blueprintId: number,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    (body: { dataset_group_id: number }) =>
      fetch(`${ENDPOINTS.reports}/${blueprintId}/dataset`, {
        method: "PATCH",
        headers: jsonAuthHeaders(userToken),
        body: JSON.stringify(body),
      }).then(handleResponse),
    {
      onMutate: () => {
        queryClient.invalidateQueries([ENDPOINTS.report_columns, blueprintId]);
        queryClient.invalidateQueries([ENDPOINTS.report_cells, blueprintId]);
        queryClient.invalidateQueries([ENDPOINTS.reports, workspaceId]);
      },
      onSettled: () => {
        queryClient.refetchQueries([ENDPOINTS.report_columns, blueprintId]);
        queryClient.refetchQueries([ENDPOINTS.report_cells, blueprintId]);
        queryClient.refetchQueries([ENDPOINTS.reports, workspaceId]);
      },
    },
  );
};

export const useSetOrRemoveProviderApiKey = (userToken: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    (body: {
      api_key: string | null;
      provider: string;
      workspace_id: number;
    }) =>
      fetch(ENDPOINTS.set_or_remove_provider_api_key, {
        method: "POST",
        headers: jsonAuthHeaders(userToken),
        body: JSON.stringify(body),
      }).then(handleResponse),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          ENDPOINTS.set_or_remove_provider_api_key,
        ]);
      },
    },
  );
};

export const useSetOrRemoveBedrockKeys = (userToken: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    (body: {
      bedrock_aws_access_key?: string;
      bedrock_aws_secret_key?: string;
      bedrock_aws_region?: string;
      workspace_id: number;
    }) =>
      fetch(ENDPOINTS.set_or_remove_bedrock_keys, {
        method: "POST",
        headers: jsonAuthHeaders(userToken),
        body: JSON.stringify(body),
      }).then(handleResponse),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ENDPOINTS.set_or_remove_bedrock_keys]);
      },
    },
  );
};

export const useSetOrRemoveAzureKeys = (userToken: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    (body: {
      azure_api_key?: string;
      azure_endpoint?: string;
      azure_api_version?: string;
      workspace_id: number;
    }) =>
      fetch(ENDPOINTS.set_or_remove_azure_keys, {
        method: "POST",
        headers: jsonAuthHeaders(userToken),
        body: JSON.stringify(body),
      }).then(handleResponse),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ENDPOINTS.set_or_remove_azure_keys]);
      },
    },
  );
};

export const useGetProviderApiKeyStatus = (
  userToken: string,
  workspace_id: number,
) => {
  return useQuery(
    [ENDPOINTS.get_provider_api_key_status, workspace_id],
    () => {
      const url = new URL(ENDPOINTS.get_provider_api_key_status);

      if (workspace_id !== null) {
        url.searchParams.set("workspace_id", workspace_id.toString());
      }

      return fetch(url, {
        headers: authHeader(userToken),
      }).then(handleResponse);
    },
    {
      placeholderData: {
        success: false,
        apiKeys: [],
      },
    },
  );
};

export const useDeletePromptLayerApiKey = (
  userToken: string,
  workspace_id: number,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    (promptLayerApiKeyId: number) =>
      fetch(
        `${ENDPOINTS.get_api_keys}/${promptLayerApiKeyId}?workspace_id=${workspace_id}`,
        {
          method: "DELETE",
          headers: authHeader(userToken),
        },
      ).then(() => {}),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ENDPOINTS.get_api_keys]);
      },
    },
  );
};

export const useRefreshPromptLayerApiKeys = () => {
  const queryClient = useQueryClient();

  return () => {
    queryClient.invalidateQueries([ENDPOINTS.get_api_keys]);
  };
};

export const usePromptLabelsDelete = (userToken: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    (body: { promptId: number; versionId: number; id: number }) =>
      fetch(`${ENDPOINTS.prompt_labels}/${body.id}`, {
        method: "DELETE",
        headers: authHeader(userToken),
      }).then((res) => {
        if (res.ok) return;
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ENDPOINTS.prompt_versions]);
      },
    },
  );
};

export const usePromptLabelsPatch = (userToken: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    (body: { promptId: number; versionId: number; id: number; name: string }) =>
      fetch(`${ENDPOINTS.prompt_labels}/${body.id}`, {
        method: "PATCH",
        headers: jsonAuthHeaders(userToken),
        body: JSON.stringify({
          prompt_version_id: body.versionId,
        }),
      }).then((res) => (res.ok ? res.json() : Promise.reject(res))),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ENDPOINTS.prompt_versions]);
      },
    },
  );
};

export const useCreatePromptLabel = (userToken: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    (body: { promptId: number; versionId: number; name: string }) =>
      fetch(`${ENDPOINTS.prompts}/${body.promptId}/label`, {
        headers: jsonAuthHeaders(userToken),
        method: "POST",
        body: JSON.stringify({
          name: body.name,
          prompt_version_id: body.versionId,
        }),
      }).then(async (res) => {
        if (res.ok) {
          const result = await res.json();
          if (result.success) return result.data;
        }
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ENDPOINTS.prompt_versions]);
      },
    },
  );
};

export const useDeletePromptTemplate = (userToken: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    (body: { id: number }) => {
      return fetch(ENDPOINTS.delete_prompt_template, {
        method: "POST",
        headers: jsonAuthHeaders(userToken),
        body: JSON.stringify({
          prompt_id: body.id,
        }),
      });
    },
    {
      onSuccess: (_, variables) => {
        queryClient.removeQueries([ENDPOINTS.prompt_templates, variables.id]);
        queryClient.invalidateQueries([ENDPOINTS.prompt_registry_objects]);
      },
    },
  );
};

export const usePromptLabel = () =>
  useMutation(
    (params: { prompt_id: number; name: string; authToken: string }) =>
      fetch(
        `${ENDPOINTS.prompts}/${params.prompt_id}/label?name=${params.name}`,
        {
          method: "GET",
          headers: authHeader(params.authToken),
        },
      ).then(async (res) => {
        if (res.ok) {
          return (await res.json()) as promptLabel.Response;
        }
      }),
  );

export const useFineTunedJob = (userToken: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      model_suffix,
      base_model_name,
      request_query_params,
      workspace_id,
    }: {
      model_suffix: string | null;
      base_model_name: string;
      request_query_params: RequestLogFilterParams;
      workspace_id: number;
    }) =>
      fetch(ENDPOINTS.fine_tuned_job, {
        method: "POST",
        headers: jsonAuthHeaders(userToken),
        body: JSON.stringify({
          model_suffix,
          base_model_name,
          request_query_params,
          workspace_id,
        }),
      }).then(handleResponse),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ENDPOINTS.fine_tuned_job]);
      },
    },
  );
};

export const useFineTunedModels = (
  userToken: string,
  workspace_id: number,
  status?: string,
) => {
  return useQuery([ENDPOINTS.fine_tuned_model, status], () => {
    const url = new URL(ENDPOINTS.fine_tuned_model);

    url.searchParams.set("workspace_id", workspace_id.toString());
    if (status) url.searchParams.set("status", status);

    return fetch(url.toString(), {
      headers: authHeader(userToken),
    }).then(handleResponse);
  });
};

interface FineTunedModelInfo {
  base_model_name: string;
  created_at: string;
  error_message: string | null;
  id: number;
  model_suffix: string;
  name: string | null;
  openai_fine_tuned_job_id: string | null;
  request_query_params: RequestLogFilterParams;
  status: string;
  status_last_updated_at: string;
  training_requests_count: number;
  user_id: number;
  workspace_id: number;
}

export const useFineTunedModelInfo = (userToken: string, modelId: string) => {
  return useQuery<FineTunedModelInfo>(
    [ENDPOINTS.fine_tuned_model, modelId],
    () =>
      fetch(`${ENDPOINTS.fine_tuned_model}/${modelId}`, {
        headers: authHeader(userToken),
      }).then(handleResponse),
  );
};

export const getWorkspaces = (
  userToken: string,
): Promise<{ workspaces: Workspace[] }> =>
  fetch(ENDPOINTS.workspaces, {
    headers: authHeader(userToken),
  }).then(handleResponse);

export const useWorkspaces = (userToken: string) =>
  useQuery([ENDPOINTS.workspaces, userToken], () => getWorkspaces(userToken));

export const useCreateWorkspace = (userToken: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ name }: { name: string }) =>
      fetch(ENDPOINTS.workspaces, {
        method: "POST",
        headers: jsonAuthHeaders(userToken),
        body: JSON.stringify({
          name,
        }),
      }).then(handleResponse),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ENDPOINTS.workspaces]);
      },
    },
  );
};

export const useDeleteWorkspace = (userToken: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    (workspaceId: number) =>
      fetch(`${ENDPOINTS.workspaces}/${workspaceId}`, {
        method: "DELETE",
        headers: authHeader(userToken),
      }).then(() => {}),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ENDPOINTS.workspaces]);
      },
    },
  );
};

export const useWorkspaceMembers = (
  userToken: string,
  workspace_id: number | null,
) => {
  return useQuery(
    [ENDPOINTS.workspace_members, userToken, workspace_id],
    () => {
      const url = new URL(ENDPOINTS.workspace_members);

      if (workspace_id) {
        url.searchParams.set("workspace_id", workspace_id.toString());
      }

      return fetch(url, {
        headers: authHeader(userToken),
      }).then(handleResponse);
    },
    {
      placeholderData: [],
    },
  );
};

export const useCreateWorkspaceMember = (userToken: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ user_id, workspace_id }: { user_id: number; workspace_id: number }) =>
      fetch(ENDPOINTS.workspace_members, {
        method: "POST",
        headers: jsonAuthHeaders(userToken),
        body: JSON.stringify({
          user_id,
          workspace_id,
        }),
      }).then(handleResponse),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ENDPOINTS.workspace_members]);
      },
    },
  );
};

export const useDeleteWorkspaceMember = (userToken: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    (workspaceMemberId: number) =>
      fetch(`${ENDPOINTS.workspace_members}/${workspaceMemberId}`, {
        method: "DELETE",
        headers: authHeader(userToken),
      }).then(() => {}),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ENDPOINTS.workspaces]);
        queryClient.invalidateQueries([ENDPOINTS.workspace_members]);
      },
      onError: (error) => {
        console.error(error);
      },
    },
  );
};

export const useUpload = () => {
  return useMutation(
    ({ userToken, file }: { userToken: string; file: File }) => {
      const formData = new FormData();
      formData.append("file", file);
      return fetch(ENDPOINTS.upload, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        body: formData,
      }).then((res) => {
        if (res.ok) {
          return res.json() as Promise<{ success: true; file_url: string }>;
        }
      });
    },
  );
};

export const useWebhook = (userToken: string, workspace_id: number) => {
  return useQuery([ENDPOINTS.webhooks, userToken, workspace_id], () => {
    const url = new URL(ENDPOINTS.webhooks);
    url.searchParams.set("workspace_id", workspace_id.toString());
    return fetch(url, {
      headers: authHeader(userToken),
    }).then(handleResponse);
  });
};

export const useCreateWebhook = (userToken: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ url, workspace_id }: { url: string; workspace_id: number }) =>
      fetch(ENDPOINTS.webhooks, {
        method: "POST",
        headers: jsonAuthHeaders(userToken),
        body: JSON.stringify({
          url,
          workspace_id,
        }),
      }).then(handleResponse),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ENDPOINTS.webhooks]);
      },
    },
  );
};

export const useDeleteWebhook = (userToken: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    (id: number) =>
      fetch(`${ENDPOINTS.webhooks}/${id}`, {
        method: "DELETE",
        headers: authHeader(userToken),
      }).then(() => {}),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ENDPOINTS.webhooks]);
      },
      onError: (error) => {
        console.error(error);
      },
    },
  );
};

export const useFolders = (
  userToken: string,
  workspace_id: number,
  folderId?: string,
) => {
  return useQuery(
    [ENDPOINTS.folders, userToken, workspace_id, folderId],
    () => {
      const url = new URL(ENDPOINTS.folders);
      url.searchParams.set("workspace_id", workspace_id.toString());
      if (folderId) {
        url.searchParams.set("parent_id", folderId);
      }
      return fetch(url, {
        headers: authHeader(userToken),
      }).then(handleResponse);
    },
    {
      placeholderData: [],
    },
  );
};

export const useCreateFolder = (userToken: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      name,
      workspace_id,
      parent_folder_id,
    }: {
      name: string;
      workspace_id: number;
      parent_folder_id?: string;
    }) =>
      fetch(ENDPOINTS.folders, {
        method: "POST",
        headers: jsonAuthHeaders(userToken),
        body: JSON.stringify({
          name,
          workspace_id,
          parent_id: parent_folder_id,
        }),
      }).then(handleResponse),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ENDPOINTS.folders]);
      },
    },
  );
};

export const useDeleteFolder = (userToken: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    (folderId: number) =>
      fetch(`${ENDPOINTS.folders}/${folderId}`, {
        method: "DELETE",
        headers: authHeader(userToken),
      }).then((res) => res?.json()),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ENDPOINTS.folders]);
        queryClient.invalidateQueries([ENDPOINTS.prompt_registry_objects]);
      },
    },
  );
};

export const useEditFolder = (userToken: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ folder_id, name }: { folder_id: number; name: string }) =>
      fetch(`${ENDPOINTS.folders}/${folder_id}`, {
        method: "PATCH",
        headers: jsonAuthHeaders(userToken),
        body: JSON.stringify({
          name,
        }),
      }).then(handleResponse),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ENDPOINTS.folders]);
      },
    },
  );
};

export const useMovePromptTemplate = (userToken: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      folder_id,
      prompt_template_id,
    }: {
      folder_id: number | null;
      prompt_template_id: string;
    }) =>
      fetch(`${ENDPOINTS.move_prompt_template}`, {
        method: "PATCH",
        headers: jsonAuthHeaders(userToken),
        body: JSON.stringify({
          folder_id,
          prompt_template_id,
        }),
      }).then(handleResponse),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ENDPOINTS.prompt_registry_objects]);
      },
    },
  );
};

export const useCreateBlueprint = (userToken: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({
      dataset_group_id,
      name,
    }: {
      dataset_group_id: number;
      name: string;
    }) => {
      return fetch(ENDPOINTS.reports, {
        method: "POST",
        headers: jsonAuthHeaders(userToken),
        body: JSON.stringify({
          dataset_group_id,
          name,
        }),
      }).then(handleResponse);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ENDPOINTS.reports]);
      },
    },
  );
};

export const useGetBlueprints = (
  userToken: string,
  workspaceId: number | null,
  page: number = 1,
  perPage: number = 100,
  search: string | null = null,
  includeColumns?: boolean,
) => {
  return useQuery<{ reports: Array<Report>; pages: number; total: number }>(
    [
      ENDPOINTS.reports,
      workspaceId,
      true,
      includeColumns,
      page,
      perPage,
      search,
    ],
    () => {
      if (workspaceId === null) {
        return Promise.resolve(null);
      }
      const url = new URL(ENDPOINTS.reports);
      url.searchParams.set("workspace_id", workspaceId.toString());
      url.searchParams.set("is_blueprint", "true");
      url.searchParams.set("page", page.toString());
      url.searchParams.set("per_page", perPage.toString());
      if (search) url.searchParams.set("search", search);
      if (includeColumns) {
        url.searchParams.set("include_columns", "true");
      }
      return fetch(url.toString(), {
        headers: authHeader(userToken),
      }).then(handleResponse);
    },
  );
};

export const useReportColumnsIncludePromptTemplateLatestVersion = (
  userToken: string,
  reportId: number,
  promptName: string,
) => {
  return useQuery<{ includes_latest_version: boolean; success: boolean }>(
    [
      ENDPOINTS.report_columns_include_prompt_template_latest_version,
      userToken,
      reportId,
      promptName,
    ],
    () => {
      const url = new URL(
        ENDPOINTS.report_columns_include_prompt_template_latest_version,
      );
      url.searchParams.set("report_id", reportId.toString());
      url.searchParams.set("prompt_name", promptName);
      return fetch(url.toString(), {
        headers: authHeader(userToken),
      }).then(handleResponse);
    },
  );
};

export const useGetReports = (
  userToken: string,
  workspaceId: number | null,
  page: number = 1,
  perPage: number = 200,
  search: string | null = null,
  hasPromptVersions: boolean | null = null,
) => {
  return useQuery(
    [ENDPOINTS.reports, workspaceId, page, perPage, search, hasPromptVersions],
    () => {
      if (workspaceId === null) {
        return Promise.resolve(null);
      }
      const url = new URL(ENDPOINTS.reports);
      url.searchParams.set("workspace_id", workspaceId.toString());
      url.searchParams.set("is_blueprint", "false");
      url.searchParams.set("page", page.toString());
      url.searchParams.set("per_page", perPage.toString());
      if (search) url.searchParams.set("search", search);
      if (hasPromptVersions !== null)
        url.searchParams.set(
          "has_prompt_versions",
          hasPromptVersions.toString(),
        );
      return fetch(url.toString(), {
        headers: authHeader(userToken),
      }).then(handleResponse);
    },
  );
};

export const useGetReport = (userToken: string, reportId: number | null) => {
  const queryClient = useQueryClient();
  return useQuery([ENDPOINTS.reports, reportId], () => {
    if (reportId === null) {
      return Promise.resolve(null);
    }
    const url = new URL(`${ENDPOINTS.reports}/${reportId.toString()}`);
    return fetch(url.toString(), {
      headers: authHeader(userToken),
    })
      .then(handleResponse)
      .then((responseJson) => {
        if (responseJson?.success) {
          queryClient.invalidateQueries([ENDPOINTS.report_columns, reportId]);
          return responseJson.report;
        }
      });
  });
};

export const useRenameReport = (userToken: string, reportId: number) => {
  const queryClient = useQueryClient();
  return useMutation((reportName: string) => {
    const url = new URL(`${ENDPOINTS.reports}/${reportId.toString()}/rename`);
    return fetch(url.toString(), {
      method: "PATCH",
      headers: jsonAuthHeaders(userToken),
      body: JSON.stringify({
        name: reportName,
      }),
    })
      .then(handleResponse)
      .then((json) => {
        // on success, update the cache
        if (json.success) {
          queryClient.setQueryData(
            [ENDPOINTS.reports, reportId],
            (old: any) => ({
              ...old,
              name: reportName,
            }),
          );
        }

        return json;
      });
  });
};

export const useConfigureReportScoreCard = (
  userToken: string,
  reportId: number,
) => {
  const queryClient = useQueryClient();
  return useMutation((columnNames: string[]) => {
    const url = new URL(
      `${ENDPOINTS.reports}/${reportId.toString()}/score-card`,
    );
    return fetch(url.toString(), {
      method: "PATCH",
      headers: jsonAuthHeaders(userToken),
      body: JSON.stringify({
        column_names: columnNames,
      }),
    })
      .then(handleResponse)
      .then((json) => {
        if (json.success) {
          // on success, refetch report & report columns
          queryClient.invalidateQueries([ENDPOINTS.reports, reportId]);
          queryClient.invalidateQueries([ENDPOINTS.report_columns, reportId]);
        }

        return json;
      });
  });
};

export const useDeleteReport = (userToken: string, reportId: number) => {
  const queryClient = useQueryClient();
  return useMutation(() => {
    const url = new URL(`${ENDPOINTS.reports}/${reportId.toString()}`);
    return fetch(url.toString(), {
      method: "DELETE",
      headers: authHeader(userToken),
    }).then((res) => {
      if (res.ok) {
        queryClient.removeQueries([ENDPOINTS.reports, reportId]);
        queryClient.invalidateQueries([ENDPOINTS.reports]);
      }
    });
  });
};

export const useUpdateReportComment = (userToken: string, reportId: number) => {
  const queryClient = useQueryClient();
  return useMutation((comment: string) => {
    const url = new URL(`${ENDPOINTS.reports}/${reportId.toString()}/comment`);
    return fetch(url.toString(), {
      method: "PATCH",
      headers: jsonAuthHeaders(userToken),
      body: JSON.stringify({
        comment,
      }),
    })
      .then(handleResponse)
      .then((json) => {
        // on success, update the cache
        if (json.success) {
          queryClient.setQueryData(
            [ENDPOINTS.reports, reportId],
            (old: any) => ({
              ...old,
              comment,
            }),
          );
        }

        return json;
      });
  });
};

export const useGetReportColumns = (
  userToken: string,
  reportId: number | null,
) => {
  return useQuery([ENDPOINTS.report_columns, reportId], () => {
    if (reportId === null) {
      return Promise.resolve(null);
    }
    const url = new URL(ENDPOINTS.report_columns);
    url.searchParams.set("report_id", reportId.toString());
    return fetch(url.toString(), {
      headers: authHeader(userToken),
    })
      .then(handleResponse)
      .then((responseJson) => {
        if (responseJson?.success) {
          return responseJson.report_columns;
        }
      });
  });
};
export const useGetReportColumnStats = (
  userToken: string,
  reportColumnId?: number | null,
) => {
  return useQuery([ENDPOINTS.report_columns, reportColumnId, "stats"], () => {
    if (!reportColumnId || reportColumnId === null) {
      return Promise.resolve(null);
    }
    return fetch(`${ENDPOINTS.report_columns}/${reportColumnId}/stats`, {
      headers: authHeader(userToken),
    })
      .then(handleResponse)
      .then((responseJson) => {
        if (responseJson?.success) {
          return responseJson.stats;
        }
      });
  });
};

export const downloadReport = async (
  userToken: string,
  reportName: string,
  reportId: number,
) => {
  const reportUrl = new URL(`${ENDPOINTS.reports}/${reportId}/export`);
  const res = await fetch(reportUrl.toString(), {
    headers: {
      "Content-Type": "text/csv",
      Authorization: `Bearer ${userToken}`,
    },
  });
  const blob = await res.blob();
  const blobUrl = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = blobUrl;
  a.download = `${reportName}_promptlayer_export.csv`;
  document.body.appendChild(a);
  a.click();
  a.remove();
};

export const useCreateReportColumn = (userToken: string, reportId: number) => {
  const queryClient = useQueryClient();

  return useMutation(
    (columnData: ReportColumn) =>
      fetch(ENDPOINTS.report_columns, {
        method: "POST",
        headers: jsonAuthHeaders(userToken),
        body: JSON.stringify(columnData),
      })
        .then(handleResponse)
        .then((responseJson) => {
          if (responseJson?.success) {
            return responseJson;
          } else {
            return Promise.reject(responseJson);
          }
        }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ENDPOINTS.report_columns, reportId]);
        queryClient.invalidateQueries([ENDPOINTS.report_cells, reportId]);
        queryClient.invalidateQueries([ENDPOINTS.reports, reportId]);
      },
    },
  );
};

export const useDeleteReportColumn = (userToken: string, reportId: number) => {
  const queryClient = useQueryClient();

  return useMutation(
    (columnId: number) =>
      fetch(`${ENDPOINTS.report_columns}/${columnId}`, {
        method: "DELETE",
        headers: authHeader(userToken),
      }).then(() => {}),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ENDPOINTS.report_columns, reportId]);
        queryClient.invalidateQueries([ENDPOINTS.report_cells, reportId]);
        queryClient.invalidateQueries([ENDPOINTS.reports, reportId]);
      },
    },
  );
};

export const usePatchReportColumn = (userToken: string, reportId: number) => {
  const queryClient = useQueryClient();

  return useMutation(
    (column: ReportColumn) =>
      fetch(`${ENDPOINTS.report_columns}/${column.id}`, {
        method: "PATCH",
        headers: jsonAuthHeaders(userToken),
        body: JSON.stringify(column),
      })
        .then(handleResponse)
        .then((responseJson) => {
          if (responseJson?.success) {
            return responseJson;
          } else {
            return Promise.reject(responseJson);
          }
        }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ENDPOINTS.report_columns, reportId]);
        queryClient.invalidateQueries([ENDPOINTS.report_cells, reportId]);
        queryClient.invalidateQueries([ENDPOINTS.reports, reportId]);
      },
    },
  );
};

export const useDuplicateReportColumn = (
  userToken: string,
  reportId: number,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    (columnId: number) =>
      fetch(`${ENDPOINTS.report_columns}/${columnId}/duplicate`, {
        method: "POST",
        headers: jsonAuthHeaders(userToken),
      }).then(handleResponse),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ENDPOINTS.report_columns, reportId]);
        queryClient.invalidateQueries([ENDPOINTS.report_cells, reportId]);
        queryClient.invalidateQueries([ENDPOINTS.reports, reportId]);
      },
    },
  );
};

export const usePatchReportCell = (
  userToken: string,
  reportId: number,
  reportCellId: number,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ value }: { value: any }) =>
      fetch(`${ENDPOINTS.report_cells}/${reportCellId}`, {
        method: "PATCH",
        headers: jsonAuthHeaders(userToken),
        body: JSON.stringify({
          value: value,
        }),
      })
        .then(handleResponse)
        .then((responseJson) => {
          if (responseJson?.success) {
            return responseJson;
          } else {
            return Promise.reject(responseJson);
          }
        }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ENDPOINTS.report_cells, reportId]);
      },
    },
  );
};

export const useRetryReportCell = (
  userToken: string,
  reportId: number,
  reportCellId: number,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    () =>
      fetch(`${ENDPOINTS.report_cells}/${reportCellId}/retry`, {
        method: "POST",
        headers: jsonAuthHeaders(userToken),
      })
        .then(handleResponse)
        .then((responseJson) => {
          if (responseJson?.success) {
            return responseJson;
          } else {
            return Promise.reject(responseJson);
          }
        }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ENDPOINTS.report_cells, reportId]);
      },
    },
  );
};

export const useGetReportCells = (
  userToken: string,
  reportId: number | null,
) => {
  const queryClient = useQueryClient();
  return useQuery(
    [ENDPOINTS.report_cells, reportId],
    () => {
      if (reportId === null) {
        return Promise.resolve(null);
      }
      const url = new URL(ENDPOINTS.report_cells);
      url.searchParams.set("report_id", reportId.toString());
      return fetch(url.toString(), {
        headers: authHeader(userToken),
      })
        .then(handleResponse)
        .then((responseJson) => {
          if (responseJson?.success) {
            return responseJson.report_cells;
          }
        });
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries([ENDPOINTS.reports, reportId]);
      },
      refetchInterval: 3000, // three seconds
    },
  );
};

export const useCreateFinalReport = (userToken: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ name, reportId }: { name: string; reportId: number }) =>
      fetch(ENDPOINTS.final_reports, {
        method: "POST",
        headers: jsonAuthHeaders(userToken),
        body: JSON.stringify({
          name,
          report_id: reportId,
        }),
      }).then(handleResponse),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ENDPOINTS.reports]);
      },
    },
  );
};

export const useUpdateRequestScore = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({
      score,
      scoreName,
      requestId,
      resetScore,
      userToken,
    }: {
      score: number;
      scoreName: string;
      requestId: string;
      resetScore: boolean;
      userToken: string;
    }) =>
      fetch(ENDPOINTS.track_score, {
        method: "POST",
        headers: jsonAuthHeaders(userToken),
        body: JSON.stringify({
          score: score,
          name: scoreName,
          request_id: requestId,
          reset_score: resetScore,
        }),
      }),
    {
      onMutate: (variables) => {
        const buildNewScores = (oldRequest: any): Scores => {
          let newScores: Scores =
            oldRequest && oldRequest?.scores ? oldRequest.scores : [];

          // Remove the score if it exists
          newScores = newScores.filter(
            (score) => !score.hasOwnProperty(variables.scoreName),
          );

          // Update the score
          if (
            !variables.resetScore &&
            variables.score !== null &&
            variables.score !== undefined
          ) {
            newScores = [
              ...newScores,
              { [variables.scoreName]: variables.score },
            ];
          }

          return newScores;
        };

        // Update the query data for the specific request (open to request)
        queryClient.setQueryData(
          [queryKeys.request, variables.requestId],
          (old: any) => {
            if (!old) {
              return undefined;
            } else {
              return {
                ...old,
                scores: buildNewScores(old),
              };
            }
          },
        );
      },
    },
  );
};

export const useToggleStarredRequest = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({
      id,
      newVal,
      userToken,
    }: {
      id: string;
      newVal: boolean;
      userToken: string;
    }) =>
      fetch(ENDPOINTS.toggle_starred_request, {
        method: "POST",
        headers: jsonAuthHeaders(userToken),
        body: JSON.stringify({
          request_id: id,
          new_value: newVal,
        }),
      }),
    {
      onMutate: (variables) => {
        // Correctly update the single request's is_starred status
        queryClient.setQueryData(
          [queryKeys.request, variables.id],
          (old: any) => {
            if (!old) {
              return undefined;
            }
            return {
              ...old,
              is_starred: variables.newVal,
            };
          },
        );

        // Correctly update the is_starred status within the list of requests
        queryClient.setQueriesData([queryKeys.requests], (old: any) => {
          return {
            ...old,
            pages: old.pages.map((page: any) => ({
              ...page,
              items: page.items.map((request: any) => {
                if (request.id === parseInt(variables.id)) {
                  return {
                    ...request,
                    is_starred: variables.newVal,
                  };
                }
                return request;
              }),
            })),
          };
        });
      },
    },
  );
};

export const useUpdateRequestTags = (userToken: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({
      request_id,
      new_tags_list,
    }: {
      request_id: string;
      new_tags_list: string[];
    }) =>
      fetch(ENDPOINTS.update_tags, {
        method: "POST",
        headers: jsonAuthHeaders(userToken),
        body: JSON.stringify({
          request_id,
          new_tags_list,
        }),
      }),
    {
      onMutate: (variables) => {
        queryClient.setQueryData(
          [queryKeys.request, variables.request_id],
          (old: any) => {
            if (!old) {
              return undefined;
            } else {
              return {
                ...old,
                tags_array: [...variables.new_tags_list],
              };
            }
          },
        );
        queryClient.setQueriesData([queryKeys.requests], (old: any) => {
          return {
            ...old,
            pages: old.pages.map((page: any) => ({
              ...page,
              items: page.items?.map((request: any) => {
                if (!request || request.id !== parseInt(variables.request_id)) {
                  return request;
                }
                return {
                  ...request,
                  tags_array: [...variables.new_tags_list],
                };
              }),
            })),
          };
        });
      },
    },
  );
};

export const useUpdateRequestMetadata = (userToken: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({
      request_id,
      metadata,
    }: {
      request_id: string;
      metadata: Record<string, string>;
    }) =>
      fetch(ENDPOINTS.track_metadata, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({
          request_id,
          metadata,
        }),
      }),
    {
      onMutate: (variables) => {
        const newMetadata = Object.keys(variables.metadata).map((key) => ({
          [key]: variables.metadata[key],
        }));
        queryClient.setQueryData(
          [queryKeys.request, variables.request_id],
          (old: any) => {
            if (!old) {
              return undefined;
            } else {
              return {
                ...old,
                metadata: newMetadata,
              };
            }
          },
        );
        queryClient.setQueriesData([queryKeys.requests], (old: any) => {
          return {
            ...old,
            pages: old.pages.map((page: any) => ({
              ...page,
              items: page.items?.map((request: any) => {
                if (!request || request.id !== parseInt(variables.request_id)) {
                  return request;
                }
                return {
                  ...request,
                  metadata: newMetadata,
                };
              }),
            })),
          };
        });
      },
    },
  );
};

export const useParsePromptTemplateInputVariables = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({
      prompt_version,
      userToken,
    }: {
      prompt_version: PromptBlueprint;
      userToken: string;
    }) =>
      fetch(ENDPOINTS.parse_input_variables, {
        method: "POST",
        headers: jsonAuthHeaders(userToken),
        body: JSON.stringify({
          prompt_version: prompt_version,
        }),
      })
        .then((res) => res.json())
        .catch((err) => Promise.reject(err)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ENDPOINTS.parse_input_variables]);
      },
    },
  );
};

export const useParseInputVariables = (
  userToken: string,
  prompt_version: PromptBlueprint,
) =>
  useQuery([ENDPOINTS.parse_input_variables, prompt_version], () =>
    fetch(ENDPOINTS.parse_input_variables, {
      method: "POST",
      headers: jsonAuthHeaders(userToken),
      body: JSON.stringify({
        prompt_version: prompt_version,
      }),
    })
      .then(
        (res) =>
          res.json() as Promise<
            | { input_variables: Array<string>; success: true }
            | { message: Array<ValidationError>; success: false }
          >,
      )
      .catch((err) => Promise.reject(err)),
  );

export const useCreatePromptTemplate = (userToken: string) =>
  useMutation((body: CreatePromptTemplate) =>
    fetch(ENDPOINTS.prompt_templates, {
      method: "POST",
      headers: jsonAuthHeaders(userToken),
      body: JSON.stringify(body),
    })
      .then(
        (res) =>
          res.json() as Promise<
            | {
                prompt_template: PromptTemplateFromRegistry;
                prompt_version: PromptVersion;
              }
            | ErrorResponse
          >,
      )
      .catch((err) => Promise.reject(err)),
  );

export const useCreatePromptVersion = (userToken: string) => {
  return useMutation((body: CreatePromptVersion) =>
    fetch(ENDPOINTS.prompt_versions, {
      method: "POST",
      headers: jsonAuthHeaders(userToken),
      body: JSON.stringify(body),
    })
      .then((res) => {
        return res.json() as Promise<PromptVersion | ErrorResponse>;
      })
      .catch((err) => Promise.reject(err)),
  );
};

export const useEditPromptTemplate = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (body: {
      userToken: string;
      prompt_id: number;
      activeWorkspaceId: number;
      basePromptTemplate: Partial<BasePromptTemplate>;
    }) =>
      fetch(`${ENDPOINTS.prompt_templates}/${body.prompt_id}`, {
        method: "PATCH",
        headers: jsonAuthHeaders(body.userToken),
        body: JSON.stringify(body.basePromptTemplate),
      })
        .then(
          (res) =>
            res.json() as Promise<PromptTemplateFromRegistry | ErrorResponse>,
        )
        .catch((err) => Promise.reject(err)),
    {
      onSuccess: (data) => {
        if ("message" in data) displayErrorToast(data.message);
        queryClient.invalidateQueries([ENDPOINTS.prompt_registry_objects]);
      },
    },
  );
};

type UseRequestStatsByTagFieldParams = {
  page: number;
  perPage: number;
  sortBy?: string;
  sortOrder: string;
  userToken: string;
  workspace_id: number;
  startDate?: string;
  endDate?: string;
  tags?: string[];
  metadata?: any;
  starred?: boolean;
};

export const useRequestStatsByTag = ({
  page,
  perPage,
  sortBy = "total_cost",
  sortOrder = "desc",
  userToken,
  workspace_id,
  startDate,
  endDate,
  tags,
  metadata,
  starred,
}: UseRequestStatsByTagFieldParams) => {
  const url = new URL(ENDPOINTS.request_stats_by_tag);

  url.searchParams.set("page", page.toString());
  url.searchParams.set("per_page", perPage.toString());
  url.searchParams.set("sort_by", sortBy);
  url.searchParams.set("sort_order", sortOrder);
  url.searchParams.set("workspace_id", workspace_id.toString());
  if (startDate) url.searchParams.set("start_date", startDate);
  if (endDate) url.searchParams.set("end_date", endDate);
  if (tags) url.searchParams.set("tags", JSON.stringify(tags));
  if (metadata) url.searchParams.set("metadata", JSON.stringify(metadata));
  if (starred !== undefined)
    url.searchParams.set("starred", starred.toString());

  return useQuery(
    [
      ENDPOINTS.request_stats_by_tag,
      page,
      perPage,
      sortBy,
      sortOrder,
      workspace_id,
      startDate,
      endDate,
      tags,
      metadata,
      starred,
    ],
    () =>
      fetch(url, {
        headers: authHeader(userToken),
      }).then(handleResponse),
    {
      initialData: {
        items: [],
      },
    },
  );
};

type UseRequestStatsByMetadataFieldParams = {
  metadataFieldName: string;
  page: number;
  perPage: number;
  sortBy?: string;
  sortOrder: string;
  userToken: string;
  workspace_id: number;
  startDate?: string;
  endDate?: string;
  tags?: string[];
  metadata?: any;
  starred?: boolean;
};

export const useRequestStatsByMetadataField = ({
  metadataFieldName,
  page,
  perPage,
  sortBy = "total_cost",
  sortOrder = "desc",
  userToken,
  workspace_id,
  startDate,
  endDate,
  tags,
  metadata,
  starred,
}: UseRequestStatsByMetadataFieldParams) => {
  const url = new URL(ENDPOINTS.request_stats_by_metadata_field);
  url.searchParams.set("metadata_field_name", metadataFieldName);
  url.searchParams.set("page", page.toString());
  url.searchParams.set("per_page", perPage.toString());
  url.searchParams.set("sort_by", sortBy);
  url.searchParams.set("sort_order", sortOrder);
  url.searchParams.set("workspace_id", workspace_id.toString());
  if (startDate) url.searchParams.set("start_date", startDate);
  if (endDate) url.searchParams.set("end_date", endDate);
  if (tags) url.searchParams.set("tags", JSON.stringify(tags));
  if (metadata) url.searchParams.set("metadata", JSON.stringify(metadata));
  if (starred !== undefined)
    url.searchParams.set("starred", starred.toString());

  return useQuery(
    [
      ENDPOINTS.request_stats_by_metadata_field,
      metadataFieldName,
      page,
      perPage,
      sortBy,
      sortOrder,
      workspace_id,
      startDate,
      endDate,
      tags,
      metadata,
      starred,
    ],
    () =>
      fetch(url, {
        headers: authHeader(userToken),
      }).then(handleResponse),
    {
      initialData: {
        items: [],
      },
    },
  );
};

export const useWSTokenRequest = (userToken: string) => {
  return useQuery(
    [ENDPOINTS.ws_token_request, userToken],
    () =>
      fetch(ENDPOINTS.ws_token_request, {
        method: "POST",
        headers: jsonAuthHeaders(userToken),
      }).then(handleResponse),
    {
      refetchInterval: 600000, // 10 minutes
    },
  );
};

export const usePromptLayerApiKeys = (
  userToken: string,
  workspace_id: number,
) => {
  return useQuery([ENDPOINTS.get_api_keys, userToken, workspace_id], () => {
    const url = new URL(ENDPOINTS.get_api_keys);
    url.searchParams.set("workspace_id", workspace_id.toString());
    return fetch(url, {
      headers: authHeader(userToken),
    }).then(handleResponse);
  });
};

export const useProviderBaseURLs = (
  userToken: string,
  workspace_id: number,
) => {
  return useQuery(
    [ENDPOINTS.provider_base_urls, userToken, workspace_id],
    () => {
      const url = new URL(ENDPOINTS.provider_base_urls);
      url.searchParams.set("workspace_id", workspace_id.toString());
      return fetch(url, {
        headers: authHeader(userToken),
      }).then(handleResponse);
    },
    {
      placeholderData: [],
    },
  );
};

export const useUsage = (userToken: string, workspace_id: number) => {
  return useQuery([ENDPOINTS.usage, userToken, workspace_id], () => {
    const url = new URL(ENDPOINTS.usage);
    url.searchParams.set("workspace_id", workspace_id.toString());
    return fetch(url, {
      headers: authHeader(userToken),
    }).then(handleResponse);
  });
};

export const useCreateProviderBaseURL = (userToken: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      api_key,
      name,
      provider,
      url,
      workspace_id,
    }: {
      api_key: string;
      name: string;
      provider: string;
      url: string;
      workspace_id: number;
    }) =>
      fetch(ENDPOINTS.provider_base_urls, {
        method: "POST",
        headers: jsonAuthHeaders(userToken),
        body: JSON.stringify({
          api_key,
          name,
          provider,
          url,
          workspace_id,
        }),
      }).then(handleResponse),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ENDPOINTS.provider_base_urls]);
      },
    },
  );
};

export const useDeleteProviderBaseURL = (userToken: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    (providerBaseURLId: number) =>
      fetch(`${ENDPOINTS.provider_base_urls}/${providerBaseURLId}`, {
        method: "DELETE",
        headers: authHeader(userToken),
      }).then(() => {}),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ENDPOINTS.provider_base_urls]);
      },
    },
  );
};

export const fetchDatasetGroup = async (
  userToken: string,
  dataset_group_id: string,
): Promise<DatasetGroupRead & DatasetGroupWrite> => {
  const url = new URL(`${ENDPOINTS.dataset_groups}/${dataset_group_id}`);
  const response = await fetch(url, {
    headers: authHeader(userToken),
  });
  const { dataset_group, success, error } = await response.json();

  if (success) {
    return dataset_group;
  }
  return Promise.reject(error.message);
};

export const useDatasetGroup = (
  userToken: string,
  dataset_group_id: string,
) => {
  return useQuery<DatasetGroupRead & DatasetGroupWrite, Error>(
    [ENDPOINTS.dataset_groups, dataset_group_id],
    () => fetchDatasetGroup(userToken, dataset_group_id),
  );
};

export const useDatasetGroups = (
  userToken: string,
  workspace_id: number,
  min_version_number?: number,
) => {
  return useQuery(
    [ENDPOINTS.dataset_groups, userToken, workspace_id, min_version_number],
    () => {
      const url = new URL(ENDPOINTS.dataset_groups);
      url.searchParams.set("workspace_id", workspace_id.toString());
      if (min_version_number !== undefined) {
        url.searchParams.set(
          "min_version_number",
          min_version_number.toString(),
        );
      }
      return fetch(url, {
        headers: authHeader(userToken),
      }).then(handleResponse);
    },
  );
};

export const useCreateDatasetGroup = (userToken: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ name, workspace_id }: { name: string; workspace_id: number }) =>
      fetch(ENDPOINTS.dataset_groups, {
        method: "POST",
        headers: jsonAuthHeaders(userToken),
        body: JSON.stringify({
          name,
          workspace_id,
        }),
      }).then(handleResponse),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ENDPOINTS.dataset_groups]);
      },
    },
  );
};

export const useEditDatasetGroup = (userToken: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ dataset_group_id, name }: { dataset_group_id: number; name: string }) =>
      fetch(`${ENDPOINTS.dataset_groups}/${dataset_group_id}`, {
        method: "PATCH",
        headers: jsonAuthHeaders(userToken),
        body: JSON.stringify({
          name,
        }),
      }).then(handleResponse),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ENDPOINTS.dataset_groups]);
      },
    },
  );
};

export const useDatasets = (userToken: string, dataset_group_id: string) => {
  return useQuery(
    [ENDPOINTS.datasets, userToken, dataset_group_id],
    () => {
      const url = new URL(ENDPOINTS.datasets);
      url.searchParams.set("dataset_group_id", dataset_group_id);
      return fetch(url, {
        headers: authHeader(userToken),
      }).then(handleResponse);
    },
    {
      placeholderData: [],
    },
  );
};

export const useDatasetRows = (
  userToken: string,
  dataset_id: number | undefined,
  page: number,
  pageSize: number,
  sortBy: string,
  sortOrder: string,
  q?: string,
) => {
  return useQuery(
    [
      ENDPOINTS.dataset_rows,
      userToken,
      dataset_id,
      page,
      pageSize,
      sortBy,
      sortOrder,
      q,
    ],
    () => {
      if (!dataset_id) return [];
      const url = new URL(ENDPOINTS.dataset_rows);
      url.searchParams.set("dataset_id", dataset_id.toString());
      url.searchParams.set("page", page.toString());
      url.searchParams.set("per_page", pageSize.toString());
      url.searchParams.set("sort_by", sortBy);
      url.searchParams.set("sort_order", sortOrder);
      if (q) {
        url.searchParams.set("q", q);
      }
      return fetch(url, {
        headers: authHeader(userToken),
      }).then(handleResponse);
    },
    {
      placeholderData: [],
    },
  );
};

export const useSaveDataset = (userToken: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ dataset_id }: { dataset_id: string }) =>
      fetch(ENDPOINTS.save_dataset, {
        method: "POST",
        headers: jsonAuthHeaders(userToken),
        body: JSON.stringify({
          dataset_id,
        }),
      }).then(handleResponse),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ENDPOINTS.datasets]);
        queryClient.invalidateQueries([ENDPOINTS.save_dataset]);
      },
    },
  );
};

export const downloadDataset = async (userToken: string, datasetId: string) => {
  const url = new URL(`${ENDPOINTS.datasets}/${datasetId}/export`);
  const response = await fetch(url.toString(), {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userToken}`,
    },
  });
  if (!response.ok) throw new Error("Failed to download dataset");

  const blob = await response.blob();
  const blobUrl = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = blobUrl;
  a.download = `${datasetId}.csv`;
  document.body.appendChild(a);
  a.click();
  a.remove();
};

export const useAddRequestLogToDataset = (userToken: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      dataset_group_id,
      request_log_id,
    }: {
      dataset_group_id: number;
      request_log_id: number;
    }) =>
      fetch(ENDPOINTS.add_request_log_to_dataset, {
        method: "POST",
        headers: jsonAuthHeaders(userToken),
        body: JSON.stringify({
          dataset_group_id,
          request_log_id,
        }),
      }).then(handleResponse),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ENDPOINTS.add_request_log_to_dataset]);
      },
    },
  );
};

export const useCreateDatasetColumn = (userToken: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ dataset_id, name }: { dataset_id: number; name: string }) =>
      fetch(ENDPOINTS.dataset_columns, {
        method: "POST",
        headers: jsonAuthHeaders(userToken),
        body: JSON.stringify({
          dataset_id,
          name,
        }),
      }).then(handleResponse),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ENDPOINTS.dataset_rows]);
      },
    },
  );
};

export const useRenameDatasetColumn = (userToken: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      dataset_id,
      old_name,
      new_name,
    }: {
      dataset_id: number;
      old_name: string;
      new_name: string;
    }) =>
      fetch(`${ENDPOINTS.dataset_columns}/${dataset_id}`, {
        method: "PATCH",
        headers: jsonAuthHeaders(userToken),
        body: JSON.stringify({
          old_name,
          new_name,
        }),
      }).then(handleResponse),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ENDPOINTS.dataset_rows]);
      },
    },
  );
};

export const useCreateDatasetRow = (userToken: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      dataset_id,
      variables,
    }: {
      dataset_id: number;
      variables: Record<string, any>;
    }) =>
      fetch(ENDPOINTS.dataset_rows, {
        method: "POST",
        headers: jsonAuthHeaders(userToken),
        body: JSON.stringify({
          dataset_id,
          variables,
        }),
      }).then(handleResponse),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ENDPOINTS.dataset_rows]);
      },
    },
  );
};

export const useEditDatasetRow = (userToken: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      datasetRowId,
      variables,
    }: {
      datasetRowId: number;
      variables: Record<string, any>;
    }) =>
      fetch(`${ENDPOINTS.dataset_rows}/${datasetRowId}`, {
        method: "PATCH",
        headers: jsonAuthHeaders(userToken),
        body: JSON.stringify({
          variables,
        }),
      }).then(handleResponse),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ENDPOINTS.dataset_rows]);
        queryClient.invalidateQueries([ENDPOINTS.dataset_rows]);
      },
    },
  );
};

export const useDeleteDatasetRow = (userToken: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    (datasetRowId: number) =>
      fetch(`${ENDPOINTS.dataset_rows}/${datasetRowId}`, {
        method: "DELETE",
        headers: authHeader(userToken),
      }).then(() => {}),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ENDPOINTS.dataset_rows]);
        queryClient.invalidateQueries([ENDPOINTS.dataset_rows]);
      },
    },
  );
};

export const useSetDraftDatasetStateFromDataset = (userToken: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ dataset_id }: { dataset_id: number }) =>
      fetch(ENDPOINTS.draft_dataset_state_from_dataset, {
        method: "PATCH",
        headers: jsonAuthHeaders(userToken),
        body: JSON.stringify({
          dataset_id,
        }),
      }).then(handleResponse),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ENDPOINTS.datasets]);
        queryClient.invalidateQueries([ENDPOINTS.dataset_rows]);
      },
    },
  );
};

export const getDraftDatasetInformation = async (
  userToken: string,
  dataset_group_id: string,
): Promise<{
  draft_dataset_exists: boolean;
  draft_dataset_id: string;
  is_draft_dataset_populated: boolean;
  success: boolean;
}> => {
  const url = new URL(ENDPOINTS.draft_dataset_information);
  url.searchParams.set("dataset_group_id", dataset_group_id);
  const response = await fetch(url, {
    headers: authHeader(userToken),
  });
  return await response.json();
};

export const useDeleteDatasetGroup = (userToken: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    (datasetGroupId: number) =>
      fetch(`${ENDPOINTS.dataset_groups}/${datasetGroupId}`, {
        method: "DELETE",
        headers: authHeader(userToken),
      }).then(() => {}),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ENDPOINTS.dataset_groups]);
      },
    },
  );
};

export const useDeleteDataset = (userToken: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    (datasetId: number) =>
      fetch(`${ENDPOINTS.datasets}/${datasetId}`, {
        method: "DELETE",
        headers: authHeader(userToken),
      }).then(() => {}),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ENDPOINTS.datasets]);
        queryClient.invalidateQueries([ENDPOINTS.dataset_rows]);
        queryClient.invalidateQueries([ENDPOINTS.dataset_groups]);
      },
    },
  );
};

export const useDatasetRowsFromFilterParams = (userToken: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      dataset_id,
      start_time,
      end_time,
      limit,
      metadata_and,
      prompt_template,
      q,
      scores,
      tags_and,
      starred,
    }: {
      dataset_id: number;
      start_time?: Date;
      end_time?: Date;
      limit?: number;
      metadata_and?: { key: string; value: string }[];
      prompt_template?: { name: string; version_numbers?: number[] };
      q?: string;
      scores?: { name: string; operator: string; value: number }[];
      tags_and?: string[];
      starred?: boolean;
    }) =>
      fetch(ENDPOINTS.dataset_rows_from_filter_params, {
        method: "POST",
        headers: jsonAuthHeaders(userToken),
        body: JSON.stringify({
          dataset_id,
          start_time,
          end_time,
          limit,
          metadata_and,
          prompt_template,
          q,
          scores,
          tags_and,
          starred,
        }),
      }).then(handleResponse),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ENDPOINTS.datasets]);
        queryClient.invalidateQueries([ENDPOINTS.dataset_rows]);
      },
    },
  );
};

export const useDatasetInfo = (
  userToken: string,
  datasetId: number | undefined,
) => {
  return useQuery(
    [ENDPOINTS.dataset_info, userToken, datasetId],
    () => {
      const url = `${ENDPOINTS.dataset_info}/${datasetId}`;
      return fetch(url, {
        headers: authHeader(userToken),
      }).then(handleResponse);
    },
    {
      enabled: datasetId !== undefined,
      placeholderData: null,
    },
  );
};

export const useDatasetColumnToJson = (userToken: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      column_name,
      dataset_id,
    }: {
      column_name: string;
      dataset_id: number;
    }) =>
      fetch(ENDPOINTS.dataset_column_to_json, {
        method: "PATCH",
        headers: jsonAuthHeaders(userToken),
        body: JSON.stringify({
          column_name,
          dataset_id,
        }),
      }).then(handleResponse),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ENDPOINTS.dataset_rows]);
      },
    },
  );
};

export const usePromptLabels = (
  userToken: string,
  workspace_id: number,
  prompt_id?: number,
) => {
  return useQuery<promptLabel.Response[]>(
    [ENDPOINTS.prompt_labels, userToken, workspace_id, prompt_id],
    () => {
      const url = new URL(ENDPOINTS.prompt_labels);
      url.searchParams.set("workspace_id", workspace_id.toString());
      if (prompt_id) {
        url.searchParams.set("prompt_id", prompt_id.toString());
      }
      return fetch(url, {
        headers: authHeader(userToken),
      }).then(handleResponse);
    },
  );
};

export const useAvailableReleaseLabels = (
  userToken: string,
  workspace_id: number,
) => {
  return useQuery<{
    available_prompt_label_names: string[];
    success: boolean;
  }>([ENDPOINTS.available_release_labels, userToken, workspace_id], () => {
    const url = new URL(ENDPOINTS.available_release_labels);
    url.searchParams.set("workspace_id", workspace_id.toString());
    return fetch(url, {
      headers: authHeader(userToken),
    }).then(handleResponse);
  });
};

export const useAvailableWorkflowReleaseLabels = (
  userToken: string,
  workspace_id: number,
) => {
  return useQuery<{
    available_workflow_label_names: string[];
    success: boolean;
  }>(
    [ENDPOINTS.available_workflow_release_labels, userToken, workspace_id],
    () => {
      const url = new URL(ENDPOINTS.available_workflow_release_labels);
      url.searchParams.set("workspace_id", workspace_id.toString());
      return fetch(url, {
        headers: authHeader(userToken),
      }).then(handleResponse);
    },
  );
};

export const useReleaseLabelGroups = (
  userToken: string,
  workspace_id: number,
) => {
  return useQuery<{
    release_label_groups: ReleaseLabelGroupReadList[];
    success: boolean;
  }>([ENDPOINTS.release_label_groups, userToken, workspace_id], () => {
    const url = new URL(ENDPOINTS.release_label_groups);
    url.searchParams.set("workspace_id", workspace_id.toString());
    return fetch(url, {
      headers: authHeader(userToken),
    }).then(handleResponse);
  });
};

export const useWorkflowReleaseLabelGroups = (
  userToken: string,
  workspace_id: number,
) => {
  return useQuery<{
    workflow_release_label_groups: WorkflowReleaseLabelGroup[];
    success: boolean;
  }>([ENDPOINTS.workflow_release_label_groups, userToken, workspace_id], () => {
    const url = new URL(ENDPOINTS.workflow_release_label_groups);
    url.searchParams.set("workspace_id", workspace_id.toString());
    return fetch(url, {
      headers: authHeader(userToken),
    }).then(handleResponse);
  });
};

interface WorkflowReleaseLabelGroup {
  created_at: string;
  id: number;
  workflow_label_name: string;
  workspace_id: number;
}

export const useCreateReleaseLabelGroup = (userToken: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      prompt_label_name,
      prompt_registry_ids,
      workspace_id,
    }: {
      prompt_label_name: string;
      prompt_registry_ids: number[];
      workspace_id: number;
    }) =>
      fetch(ENDPOINTS.release_label_groups, {
        method: "POST",
        headers: jsonAuthHeaders(userToken),
        body: JSON.stringify({
          prompt_label_name,
          prompt_registry_ids,
          workspace_id,
        }),
      }).then(handleResponse),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ENDPOINTS.release_label_groups]);
      },
    },
  );
};

export const useCreateWorkflowReleaseLabelGroup = (userToken: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      workflow_label_name,
      workflow_ids,
      workspace_id,
    }: {
      workflow_label_name: string;
      workflow_ids: number[];
      workspace_id: number;
    }) =>
      fetch(ENDPOINTS.workflow_release_label_groups, {
        method: "POST",
        headers: jsonAuthHeaders(userToken),
        body: JSON.stringify({
          workflow_label_name,
          workflow_ids,
          workspace_id,
        }),
      }).then(handleResponse),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          ENDPOINTS.workflow_release_label_groups,
        ]);
      },
    },
  );
};

export const useSetReleaseLabelGroup = (
  userToken: string,
  workspace_id: number,
  releaseLabelGroupId: string,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    (conditionals: Conditional[]) =>
      fetch(`${ENDPOINTS.release_label_groups}/${releaseLabelGroupId}`, {
        method: "PUT",
        headers: jsonAuthHeaders(userToken),
        body: JSON.stringify({
          workspace_id,
          conditionals,
        }),
      }).then(handleResponse),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ENDPOINTS.release_label_groups]);
      },
    },
  );
};

export const useDeleteReleaseLabelGroup = (userToken: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    (releaseLabelGroupId: number) =>
      fetch(`${ENDPOINTS.release_label_groups}/${releaseLabelGroupId}`, {
        method: "DELETE",
        headers: authHeader(userToken),
      }).then(() => {}),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ENDPOINTS.available_release_labels]);
        queryClient.invalidateQueries([ENDPOINTS.release_label_groups]);
      },
    },
  );
};

export const usePromptRegistryObjects = (
  userToken: string,
  params: ListPromptRegistryObjects,
) => {
  return useInfiniteQuery<PaginatedPromptRegistryObjects>(
    [ENDPOINTS.prompt_registry_objects, userToken, params],
    ({ pageParam = 1 }) => {
      const url = new URL(ENDPOINTS.prompt_registry_objects);
      url.searchParams.set("workspace_id", params.workspaceId.toString());
      url.searchParams.set("page", pageParam.toString());
      if (params.promptLabelName)
        url.searchParams.set("prompt_label_name", params.promptLabelName);
      if (params.perPage)
        url.searchParams.set("per_page", params.perPage.toString());
      if (params.q) url.searchParams.set("q", params.q);
      if (params.folderId) url.searchParams.set("folder_id", params.folderId);
      if (params.tags?.length)
        params.tags.forEach((tag) => url.searchParams.append("tags", tag));
      if (params.templateType)
        url.searchParams.set("template_type", params.templateType);
      if (params.searchFolders) url.searchParams.set("search_folders", "True");
      return fetch(url, {
        headers: authHeader(userToken),
      }).then(handleResponse);
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.has_next) {
          return lastPage.next_num;
        }
      },
    },
  );
};

export const useDynamicReleaseLabelsByPromptRegistry = (
  userToken: string,
  promptRegistryId: string,
) => {
  return useQuery<{
    success: boolean;
    dynamic_release_labels: {
      [promptVersionId: number]: Array<{
        prompt_label_name: string;
        release_label_group_id: number;
      }>;
    };
  }>(
    [
      ENDPOINTS.dynamic_release_labels_by_prompt_registry,
      userToken,
      promptRegistryId,
    ],
    () => {
      const url = `${ENDPOINTS.dynamic_release_labels_by_prompt_registry}/${promptRegistryId}`;
      return fetch(url, {
        headers: authHeader(userToken),
      }).then(handleResponse);
    },
  );
};

export const usePromptRegistryTags = (
  userToken: string,
  params: { workspaceId: number },
) => {
  return useQuery<Array<string>>(
    [ENDPOINTS.prompt_registry_tags, userToken, params],
    () => {
      const url = new URL(ENDPOINTS.prompt_registry_tags);
      url.searchParams.set("workspace_id", params.workspaceId.toString());
      return fetch(url, {
        headers: authHeader(userToken),
      }).then(handleResponse);
    },
  );
};

export const usePromptVersions = (
  userToken: string,
  params: ListPromptVersionsParams,
) => {
  return useInfiniteQuery<ListPromptVersions>(
    [ENDPOINTS.prompt_versions, userToken, params],
    ({ pageParam = 1 }) => {
      const url = new URL(ENDPOINTS.prompt_versions);
      url.searchParams.set("workspace_id", params.workspaceId.toString());
      if (params.promptRegistryId)
        url.searchParams.set(
          "prompt_registry_id",
          params.promptRegistryId.toString(),
        );
      url.searchParams.set("page", pageParam.toString());
      if (params.perPage)
        url.searchParams.set("per_page", params.perPage.toString());
      if (params.label) url.searchParams.set("label", params.label);
      return fetch(url, {
        headers: authHeader(userToken),
      }).then(handleResponse);
    },
    {
      getNextPageParam: (lastPage) =>
        lastPage.has_next ? lastPage.next_num : undefined,
    },
  );
};
export const usePromptRegistry = (userToken: string, id?: number) => {
  return useQuery<PromptRegistry>(
    [ENDPOINTS.prompt_registry_objects, userToken, id],
    () => {
      const url = `${ENDPOINTS.prompt_registry_objects}/${id}`;
      return fetch(url, {
        headers: authHeader(userToken),
      }).then(handleResponse);
    },
    { enabled: !!id },
  );
};

export const usePromptVersion = (
  userToken: string,
  promptRegistryId: number,
  promptVersionNumber: number,
) => {
  return useQuery<PromptVersion>(
    [
      ENDPOINTS.prompt_versions,
      userToken,
      promptRegistryId,
      promptVersionNumber,
    ],
    () => {
      const url = `${ENDPOINTS.prompt_registry_objects}/${promptRegistryId}/prompt-versions/${promptVersionNumber}`;
      return fetch(url, {
        headers: authHeader(userToken),
      }).then(handleResponse);
    },
  );
};

export const usePrompt = (
  userToken: string,
  body: {
    workspace_id: number;
    prompt_name: string;
    number?: number;
    label?: string;
  },
) => {
  return useQuery<PromptVersion>(
    [ENDPOINTS.get_prompt, body],
    () =>
      fetch(ENDPOINTS.get_prompt, {
        method: "POST",
        headers: jsonAuthHeaders(userToken),
        body: JSON.stringify(body),
      }).then(handleResponse),
    {
      enabled: !!body.workspace_id && !!body.prompt_name,
    },
  );
};

export const useThreads = (
  userToken: string,
  promptRegistryId: number,
  latestCommentDate: string | null,
  promptVersionNumber?: number,
) => {
  return useQuery<{ threads: Thread[] }>(
    [
      ENDPOINTS.threads,
      promptRegistryId,
      latestCommentDate,
      promptVersionNumber,
    ],
    () => {
      const url = new URL(ENDPOINTS.threads);
      url.searchParams.set("prompt_registry_id", promptRegistryId.toString());

      if (promptVersionNumber) {
        url.searchParams.set(
          "prompt_version_number",
          promptVersionNumber.toString(),
        );
      }

      return fetch(url.toString(), {
        headers: authHeader(userToken),
      }).then(handleResponse);
    },
    {
      enabled: !!promptRegistryId,
      keepPreviousData: true,
    },
  );
};

export const useCreateThread = (userToken: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    (body: {
      initial_comment: string;
      prompt_registry_id: number;
      prompt_version_number: number;
      workspace_id: number;
    }) =>
      fetch(ENDPOINTS.threads, {
        method: "POST",
        headers: jsonAuthHeaders(userToken),
        body: JSON.stringify(body),
      }).then(handleResponse),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ENDPOINTS.threads]);
        queryClient.invalidateQueries([ENDPOINTS.comments]);
      },
    },
  );
};

export const useComments = (
  userToken: string,
  threadId: number,
  latestCommentDate: string | null,
) => {
  return useQuery<{ comments: Comment[] }>(
    [ENDPOINTS.comments, threadId, latestCommentDate],
    () => {
      const url = new URL(ENDPOINTS.comments);
      url.searchParams.set("thread_id", threadId.toString());
      return fetch(url.toString(), {
        headers: authHeader(userToken),
      }).then(handleResponse);
    },
    {
      enabled: !!threadId,
      keepPreviousData: true,
    },
  );
};

export const useCreateComment = (userToken: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    (body: { thread_id: number; text: string }) =>
      fetch(ENDPOINTS.comments, {
        method: "POST",
        headers: jsonAuthHeaders(userToken),
        body: JSON.stringify(body),
      }).then(handleResponse),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ENDPOINTS.comments]);
        queryClient.invalidateQueries([ENDPOINTS.threads]);
      },
    },
  );
};

export const useCreateBacktest = (userToken: string) => {
  return useMutation((body: { promptVersionId: number; workspaceId: number }) =>
    fetch(ENDPOINTS.backtests, {
      method: "POST",
      headers: jsonAuthHeaders(userToken),
      body: JSON.stringify({
        prompt_version_id: body.promptVersionId,
        workspace_id: body.workspaceId,
      }),
    }).then(handleResponse),
  );
};

export const useCreateABtest = (userToken: string) => {
  return useMutation(
    (body: {
      promptVersionId: number;
      promptId: number;
      labelId?: number | null;
      workspaceId: number;
    }) =>
      fetch(ENDPOINTS.ab_tests, {
        method: "POST",
        headers: jsonAuthHeaders(userToken),
        body: JSON.stringify({
          prompt_version_id: body.promptVersionId,
          prompt_id: body.promptId,
          label_id: body.labelId,
          workspace_id: body.workspaceId,
        }),
      }).then(handleResponse),
  );
};

export const useCheckDynamicReleaseLabel = (
  releaseLabelName: string,
  promptTemplateName: string,
  workspaceId: number,
  userToken: string,
) => {
  return useQuery<{ exists: boolean; success: boolean }>(
    [
      ENDPOINTS.verify_dynamic_release_label_existence,
      releaseLabelName,
      promptTemplateName,
      workspaceId,
    ],
    () => {
      const url = new URL(ENDPOINTS.verify_dynamic_release_label_existence);
      url.searchParams.set("release_label_name", releaseLabelName);
      url.searchParams.set("prompt_template_name", promptTemplateName);
      url.searchParams.set("workspace_id", String(workspaceId));

      return fetch(url.toString(), {
        headers: authHeader(userToken),
      }).then(handleResponse);
    },
  );
};

export const useMultipartUpload = (userToken: string, workspaceId: number) =>
  useMutation(
    async (body: {
      fileName?: string | null;
      uploadId?: string | null;
      partNumber?: number | null;
      part?: string | null;
      parts?: { ETag: unknown; PartNumber: number }[] | null;
      datasetId: number;
    }) => {
      return fetch(ENDPOINTS.file_upload_multipart, {
        method: "POST",
        headers: jsonAuthHeaders(userToken),
        body: JSON.stringify({
          file_name: body.fileName,
          upload_id: body.uploadId,
          part_number: body.partNumber,
          part: body.part,
          parts: body.parts,
          dataset_id: body.datasetId,
          workspace_id: workspaceId,
        }),
      }).then(handleResponse);
    },
  );

export const useSpanFeed = ({
  userToken,
  searchParams,
  workspace_id,
  parent_id,
  page = 1,
  per_page = 20,
  enabledFlag = true,
}: {
  userToken: string;
  searchParams?: Record<string, any>;
  workspace_id: number;
  parent_id?: string | "none";
  page?: number;
  per_page?: number;
  enabledFlag?: boolean;
}) => {
  return useInfiniteQuery<
    {
      success: boolean;
      spans: Span[];
      total: number;
      page: number;
      per_page: number;
      total_pages: number;
      has_next: boolean;
      next_num: number;
    },
    Error
  >(
    [
      ENDPOINTS.spans,
      workspace_id,
      ...Object.values(searchParams || {}),
      parent_id,
      page,
      per_page,
    ],
    async ({ pageParam = 1 }) => {
      const url = new URL(ENDPOINTS.spans);
      url.searchParams.set("workspace_id", workspace_id.toString());
      url.searchParams.set("page", pageParam.toString());
      url.searchParams.set("per_page", per_page.toString());

      searchParams &&
        Object.keys(searchParams).forEach((key) => {
          const value = searchParams[key];
          if (value) url.searchParams.set(key, value);
        });

      if (parent_id !== undefined) {
        url.searchParams.set("parent_id", parent_id);
      }

      const response = await fetch(url.toString(), {
        headers: authHeader(userToken),
        credentials: "include",
      });

      return handleResponse(response);
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.has_next) return lastPage.next_num;
      },
      enabled: enabledFlag,
    },
  );
};

export const useTraceSpans = ({
  userToken,
  traceId,
  workspace_id,
}: {
  userToken: string;
  traceId: string;
  workspace_id?: number;
}) => {
  return useQuery<
    {
      success: boolean;
      trace_id: string;
      spans: Span[];
    },
    Error
  >([ENDPOINTS.trace, traceId, workspace_id], async () => {
    const url = new URL(`${ENDPOINTS.trace}/${traceId}`);

    if (workspace_id) {
      url.searchParams.set("workspace_id", workspace_id.toString());
    }

    const response = await fetch(url.toString(), {
      headers: authHeader(userToken),
    });

    return handleResponse(response);
  });
};

export const useDeleteDatasetRows = (userToken: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    (datasetId: string) =>
      fetch(`${ENDPOINTS.datasets}/${datasetId}/dataset-rows`, {
        method: "DELETE",
        headers: authHeader(userToken),
      }).then(() => {}),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ENDPOINTS.dataset_rows]);
      },
    },
  );
};
export const useWorkflowNodes = (
  workflowVersionId: number,
  userToken: string,
) => {
  return useQuery<WorkflowNodesResponse>(
    [ENDPOINTS.workflow_nodes, workflowVersionId],
    () => {
      const url = new URL(ENDPOINTS.workflow_nodes);

      url.searchParams.set("workflow_version_id", String(workflowVersionId));
      return fetch(url.toString(), {
        headers: authHeader(userToken),
      }).then(handleResponse);
    },
    {
      enabled: !!workflowVersionId && !!userToken,
      keepPreviousData: true,
    },
  );
};

export const useWorkflowVersions = (workflowId: string, userToken: string) => {
  return useQuery<WorkflowVersionResponse>(
    [ENDPOINTS.workflow_versions, workflowId],
    () => {
      const url = new URL(ENDPOINTS.workflow_versions);
      url.searchParams.set("workflow_id", workflowId);
      return fetch(url.toString(), {
        headers: authHeader(userToken),
      }).then(handleResponse);
    },
    {
      enabled: !!workflowId && !!userToken,
      keepPreviousData: true,
    },
  );
};

export const useWorkflowDetails = (workflowId: string, userToken: string) => {
  return useQuery<{ success: boolean; workflow: Workflow }>(
    [ENDPOINTS.workflows, workflowId, userToken],
    () => {
      const url = new URL(`${ENDPOINTS.workflows}/${workflowId}`);
      return fetch(url.toString(), {
        headers: authHeader(userToken),
      }).then(handleResponse);
    },
  );
};

export const useWorkflows = (
  workspaceId: string,
  userToken: string,
  workflow_label_name?: string,
) => {
  return useInfiniteQuery<
    {
      success: boolean;
      workflows: WorkflowInfo[];
    },
    Error
  >(
    [ENDPOINTS.workflows, workspaceId, userToken, workflow_label_name],
    ({ pageParam = 1 }) => {
      const url = new URL(ENDPOINTS.workflows);
      url.searchParams.set("page", pageParam.toString());
      url.searchParams.set("per_page", "100");
      url.searchParams.set("workspace_id", workspaceId);
      if (workflow_label_name) {
        url.searchParams.set("workflow_label_name", workflow_label_name);
      }
      return fetch(url.toString(), {
        headers: authHeader(userToken),
      }).then(handleResponse);
    },
  );
};

export const useWorkflowVersionExecutions = (
  workflowVersionId: number | undefined,
  workspaceId: number,
  userToken: string,
  page: number,
  perPage: number,
) => {
  return useInfiniteQuery<
    {
      success: boolean;
      workflow_version_executions: WorkflowVersionExecution[];
      pages: number;
      page: number;
    },
    Error
  >(
    [
      ENDPOINTS.workflow_version_executions,
      workflowVersionId,
      workspaceId,
      userToken,
      page,
      perPage,
    ],
    ({ pageParam = 1 }) => {
      const url = new URL(ENDPOINTS.workflow_version_executions);
      url.searchParams.set("page", page.toString());
      url.searchParams.set("per_page", perPage.toString());
      url.searchParams.set("workspace_id", workspaceId.toString());
      url.searchParams.set("workflow_version_id", String(workflowVersionId));
      return fetch(url.toString(), {
        headers: authHeader(userToken),
      }).then(handleResponse);
    },
    { enabled: !!workflowVersionId },
  );
};

export const useDeleteWorkflowNode = (userToken: string) => {
  return useMutation((workflowNodeId: string) =>
    fetch(`${ENDPOINTS.workflow_nodes}/${workflowNodeId}`, {
      method: "DELETE",
      headers: authHeader(userToken),
    }).then(handleResponse),
  );
};

export const useWorkflowEditorState = (
  workspaceId?: number,
  userToken?: string,
) => {
  return useQuery<{
    sucess: boolean;
    workflow: Workflow;
    workflow_nodes: WorkflowNode[];
    workflow_version: WorkflowVersion;
    workflow_version_execution: WorkflowVersionExecution;
  }>(
    [ENDPOINTS.workflow_editor_state, workspaceId],
    () => {
      const url = new URL(ENDPOINTS.workflow_editor_state);
      url.searchParams.set("workspace_id", String(workspaceId));
      return fetch(url.toString(), {
        headers: authHeader(userToken!),
      }).then(handleResponse);
    },
    {
      enabled: !!workspaceId && !!userToken,
      keepPreviousData: false,
    },
  );
};

export const useWorkflowVersionEditorState = (
  workflow_version_id?: number,
  userToken?: string,
) => {
  return useQuery<{
    success: boolean;
    workflow: Workflow;
    workflow_nodes: WorkflowNode[];
    workflow_version: WorkflowVersion;
    workflow_version_execution: WorkflowVersionExecution;
  }>(
    [ENDPOINTS.workflow_editor_state, workflow_version_id],
    async () => {
      if (!workflow_version_id) {
        throw new Error("Workflow version ID is required");
      }
      const url = new URL(ENDPOINTS.workflow_editor_state);
      url.searchParams.set("workflow_version_id", String(workflow_version_id));
      const response = await fetch(url.toString(), {
        headers: authHeader(userToken!),
      });
      if (!response.ok) {
        throw new Error("Failed to fetch workflow editor state");
      }
      const data = await handleResponse(response);
      if (!data.success) {
        throw new Error(
          data.message || "Failed to fetch workflow editor state",
        );
      }
      return data;
    },
    {
      enabled: !!workflow_version_id && !!userToken,
      keepPreviousData: false,
      retry: 3,
      retryDelay: 1000,
    },
  );
};

export const useEditInputVariables = (
  userToken: string,
  workspace_id: number,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ input_variables }: { input_variables: Record<string, string> }) =>
      fetch(`${ENDPOINTS.workflow_editor_input_variables}`, {
        method: "PATCH",
        headers: jsonAuthHeaders(userToken),
        body: JSON.stringify({
          input_variables,
          workspace_id,
        }),
      }).then(handleResponse),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ENDPOINTS.workflow_versions]);
      },
    },
  );
};

export const useCreateWorkflowLabel = (userToken: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    (body: {
      name: string;
      workflow_id: number;
      workflow_version_id: number;
    }) =>
      fetch(ENDPOINTS.workflow_labels, {
        method: "POST",
        headers: jsonAuthHeaders(userToken),
        body: JSON.stringify(body),
      }).then(handleResponse),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ENDPOINTS.workflow_labels]);
      },
    },
  );
};

export const useSetWorkflowReleaseLabelGroup = (
  userToken: string,
  workspace_id: number,
  workflowReleaseLabelGroupId: string,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    (conditionals: Conditional[]) =>
      fetch(
        `${ENDPOINTS.workflow_release_label_groups}/${workflowReleaseLabelGroupId}`,
        {
          method: "PUT",
          headers: jsonAuthHeaders(userToken),
          body: JSON.stringify({
            workspace_id,
            conditionals,
          }),
        },
      ).then(handleResponse),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          ENDPOINTS.workflow_release_label_groups,
        ]);
      },
    },
  );
};

export const useInferenceClientsList = (
  userToken: string,
  workspaceId: string,
): UseQueryResult<{
  inference_clients: InferenceClient[];
  success: boolean;
}> => {
  return useQuery<{
    inference_clients: InferenceClient[];
    success: boolean;
  }>([ENDPOINTS.inference_clients, userToken], async () => {
    const url = new URL(ENDPOINTS.inference_clients);
    url.searchParams.set("workspace_id", workspaceId);
    const response = await fetch(url.toString(), {
      headers: authHeader(userToken),
    });
    return handleResponse(response);
  });
};

export const useInferenceClients = (userToken: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    (body: InferenceClientRequestBody) =>
      fetch(ENDPOINTS.inference_clients, {
        method: "POST",
        headers: jsonAuthHeaders(userToken),
        body: JSON.stringify(body),
      }).then(handleResponse),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ENDPOINTS.inference_clients]);
      },
    },
  );
};

export const useDeleteInferenceClient = (userToken: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    (body: { id: number }) =>
      fetch(`${ENDPOINTS.inference_clients}/${body.id}`, {
        method: "DELETE",
        headers: authHeader(userToken),
        body: JSON.stringify(body),
      }).then(() => {}),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ENDPOINTS.inference_clients]);
      },
    },
  );
};

export const useRequestLogs = (enabledFlag: boolean = true) => {
  const queryClient = useQueryClient();
  const auth = useAuth();
  const userToken = auth?.userToken!;
  const user = useUser();
  const activeWorkspaceId = user?.activeWorkspaceId;

  const [searchParams] = useSearchParams();
  const q = searchParams.get(URL_PARAM_KEYS.Q) ?? "";
  const scores = JSON.parse(searchParams.get(URL_PARAM_KEYS.SCORES) ?? "[]");
  const selectedTags = JSON.parse(
    searchParams.get(URL_PARAM_KEYS.TAGS) ?? "[]",
  );
  const showFavorites = searchParams.get(URL_PARAM_KEYS.FAVORITES) === "true";
  const metadataFields = JSON.parse(
    searchParams.get(URL_PARAM_KEYS.METADATA) ?? "[]",
  );
  const promptTemplate = JSON.parse(
    searchParams.get(URL_PARAM_KEYS.PROMPT_TEMPLATE) ?? "null",
  );
  const defaultDateRangeLimitIsOn =
    searchParams.get(URL_PARAM_KEYS.DEFAULT_DATE_RANGE_LIMIT_IS_ON) !== "false";
  const startTimeParam = searchParams.get(URL_PARAM_KEYS.START_TIME);
  const endTimeParam = searchParams.get(URL_PARAM_KEYS.END_TIME);
  // if defaultDateRangeLimitIsOn is true, set the start time to 14 days ago and end time to null
  let computedStartTime: Date | null = defaultDateRangeLimitIsOn
    ? new Date(
        new Date(Date.now() - 14 * 24 * 60 * 60 * 1000)
          .toISOString()
          .split("T")[0],
      )
    : null;
  let computedEndTime: Date | null = null;
  // if startTime or endTime exists, use both those values
  if (startTimeParam || endTimeParam) {
    computedStartTime = startTimeParam ? new Date(startTimeParam) : null;
    computedEndTime = endTimeParam ? new Date(endTimeParam) : null;
  }

  const requestsQuery = useRequests(
    userToken,
    {
      q,
      scores,
      selectedTags,
      starred: showFavorites,
      metadataFields,
      startTime: computedStartTime?.toISOString(),
      endTime: computedEndTime?.toISOString(),
      promptTemplate,
    },
    activeWorkspaceId!,
    enabledFlag,
  );

  requestsQuery.data?.pages?.forEach((page) => {
    page.items?.forEach((item: any) => {
      queryClient.setQueryData([queryKeys.request, String(item.id)], item);
    });
  });

  return requestsQuery;
};

export const useCreateDatasetFromEvaluationReport = (userToken: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    (body: { dataset_group_id: number; report_id: number }) =>
      fetch(ENDPOINTS.create_dataset_from_evaluation, {
        method: "POST",
        headers: jsonAuthHeaders(userToken),
        body: JSON.stringify(body),
      }).then(handleResponse),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ENDPOINTS.inference_clients]);
      },
    },
  );
};

export const useSpansQuery = (enabledFlag: boolean = true) => {
  const auth = useAuth();
  const user = useUser();
  const userToken = auth?.userToken!;
  const activeWorkspaceId = user?.activeWorkspaceId;
  const [searchParams] = useSearchParams();
  const q = searchParams.get(URL_PARAM_KEYS.Q) ?? "";
  const metadataFields = JSON.parse(
    searchParams.get(URL_PARAM_KEYS.METADATA) ?? "[]",
  ) as { key: string; value: string }[];
  return useSpanFeed({
    userToken: userToken,
    searchParams: {
      trace_id: q,
      ...(metadataFields && metadataFields.length > 0
        ? metadataFields.map(({ key, value }) => ({
            attribute_key: key,
            attribute_value: value,
          }))
        : [{}])[0],
    },
    workspace_id: activeWorkspaceId!,
    parent_id: "none",
    enabledFlag,
  });
};

export const useSpans = () => {
  const spansQuery = useSpansQuery();
  return useMemo(
    () => spansQuery.data?.pages?.map((page) => page.spans).flat() ?? [],
    [spansQuery.data],
  );
};

export const useNewRequests = () => {
  const user = useUser();
  const auth = useAuth();

  const userToken = auth?.userToken!;
  const workspaceId = user?.activeWorkspaceId!;

  return useQuery<{ count_requests: number }>(
    [ENDPOINTS.new_requests, userToken, workspaceId],
    async () => {
      const url = new URL(`${API_URL}/workspaces/${workspaceId}/requests/new`);
      return fetch(url.toString(), {
        headers: authHeader(userToken),
        credentials: "include",
      }).then(handleResponse);
    },
    {
      refetchInterval:
        Number(process.env.REACT_APP_REQUESTS_REFRESH_INTERVAL) || 5000,
    },
  );
};

export const useDeleteWorkflow = (userToken: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ workflow_id }: { workflow_id: number }) =>
      fetch(`${ENDPOINTS.workflows}/${workflow_id}`, {
        method: "DELETE",
        headers: jsonAuthHeaders(userToken),
      }).then(handleResponse),
    {
      onSuccess: (res) => {
        if (!res.success) {
          console.error(res.message || res.error || "");
        }
        queryClient.invalidateQueries([ENDPOINTS.workflows]);
        queryClient.invalidateQueries([ENDPOINTS.workflow_versions]);
      },
      onError: (error) => {
        console.log("Error deleting workflow", error);
      },
    },
  );
};

export const useNewSpans = () => {
  const user = useUser();
  const auth = useAuth();

  const userToken = auth?.userToken!;
  const workspaceId = user?.activeWorkspaceId!;

  return useQuery<{ count_spans: number }>(
    [ENDPOINTS.new_spans, userToken, workspaceId],
    async () => {
      const url = new URL(`${API_URL}/workspaces/${workspaceId}/spans/new`);
      return fetch(url.toString(), {
        headers: authHeader(userToken),
        credentials: "include",
      }).then(handleResponse);
    },
    {
      refetchInterval:
        Number(process.env.REACT_APP_REQUESTS_REFRESH_INTERVAL) || 5000,
    },
  );
};
