import { useCallback } from "react";

import { getWidgetMapping, WidgetTypes } from ".";

export interface WidgetProps {
  id: string;
  type: WidgetTypes;
  name?: string;
  match?: string;
  children?: React.ReactNode;
  readOnly?: boolean;
  isXrayMode?: boolean;
}

const Widget = (props: WidgetProps) => {
  const WidgetWrapper = useCallback(
    ({ children }: { children: React.ReactNode }) => (
      <div className="m-2">{children}</div>
    ),
    [],
  );

  return <WidgetWrapper>{getWidgetMapping(props.type, props)}</WidgetWrapper>;
};

export default Widget;
