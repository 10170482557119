import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  AbsoluteNumericDistanceConfiguration,
  ReportColumn,
} from "@/types/evaluate";
import { formatInputVariable } from "@/utils/evaluate";
import { useState } from "react";
import { useBandaid } from "../../utils/BandaidContext";
import { ModalStep } from "../ModalRouter";

const AbsoluteNumericDistanceBuilder = ({
  columnData,
  availableColumns,
  navigatePrevious,
  saveColumnAction,
  editable,
}: {
  columnData: Partial<ReportColumn>;
  availableColumns: ReportColumn[];
  navigatePrevious: () => void;
  saveColumnAction: (newColumnDataArg: ReportColumn) => void;
  editable: boolean;
}) => {
  const [name, setName] = useState<string | null>(columnData.name || null);
  const [columnOneName, setColumnOneName] = useState<string | null>(
    columnData.configuration?.first_column || null,
  );
  const [columnTwoName, setColumnTwoName] = useState<string | null>(
    columnData.configuration?.second_column || null,
  );

  const [dataIsValid, setDataIsValid] = useState<{
    name: boolean;
    firstColumn: boolean;
    secondColumn: boolean;
  }>({
    name: true,
    firstColumn: true,
    secondColumn: true,
  });

  const saveEndpointAction = () => {
    if (!name) {
      setDataIsValid({ name: false, firstColumn: true, secondColumn: true });
      return;
    } else if (!columnOneName) {
      setDataIsValid({ name: true, firstColumn: false, secondColumn: true });
      return;
    } else if (!columnTwoName) {
      setDataIsValid({ name: true, firstColumn: true, secondColumn: false });
      return;
    }

    setDataIsValid({ name: true, firstColumn: true, secondColumn: true });

    saveColumnAction({
      ...columnData,
      name: name,
      configuration: {
        first_column: columnOneName,
        second_column: columnTwoName,
      } as AbsoluteNumericDistanceConfiguration,
    } as ReportColumn);
  };

  const bandaid = useBandaid();

  return (
    <ModalStep
      navigatePrevious={navigatePrevious}
      navigateNext={saveEndpointAction}
      nextButtonText={editable ? "Save Step" : "Done"}
    >
      <div className="grid grid-cols-3 items-center gap-4">
        <div className="col-span-3">
          <div className="text-lg font-semibold">
            Configure Absolute Numeric Distance
          </div>
          <div className="max-w-md text-sm text-gray-500">
            This step performs an absolute numeric distance between the two
            values.
          </div>
        </div>
        <div
          className="col-span-3 grid grid-cols-3 items-center gap-4"
          style={{ display: bandaid ? "none" : "grid" }}
        >
          <label htmlFor="name" className="col-span-1 text-left">
            {bandaid ? "Node name:" : "Column name:"}
          </label>
          <div className="col-span-2">
            <input
              id="name"
              className={`w-full rounded border ${
                !dataIsValid.name ? "border-red-500" : "border-gray-300"
              } px-2 py-1 disabled:cursor-not-allowed disabled:bg-gray-50`}
              value={name || ""}
              onKeyDown={(e) => {
                if (e.key === " ") {
                  // Only prevent default and handle manually for spaces
                  e.preventDefault();
                  setName(name + " ");
                }
                // Let all other keys (including backspace) work normally
              }}
              onChange={(e) => setName(e.target.value)}
              disabled={!editable}
            />
          </div>
        </div>
        {!dataIsValid.name && (
          <div className="col-span-3 grid grid-cols-3 items-center gap-4">
            <div className="col-span-1">&nbsp;</div>
            <div className="col-span-2 text-left text-xs text-red-500">
              Name is required
            </div>
          </div>
        )}
        <div className="col-span-3 grid grid-cols-3 items-center gap-4">
          <div className="col-span-1 text-left">
            {bandaid ? "Comparison nodes:" : "Comparison columns:"}
          </div>
          <div className="col-span-2 rounded-md border border-gray-200 bg-gray-50 px-4 py-3">
            <div
              className="grid grid-cols-3 items-center gap-4"
              key={`column-one`}
            >
              <div className="col-span-1 text-left font-mono text-sm text-gray-600">
                Target
              </div>
              <div className="col-span-2 w-full">
                <DropdownMenu>
                  <DropdownMenuTrigger disabled={!editable} className="w-full">
                    {columnOneName || (
                      <span className="font-normal text-gray-500">
                        {bandaid ? "Select a node..." : "Select a column..."}
                      </span>
                    )}
                  </DropdownMenuTrigger>
                  <DropdownMenuContent>
                    {availableColumns.map((column) => (
                      <DropdownMenuItem
                        key={column.name}
                        onSelect={() => setColumnOneName(column.name)}
                      >
                        {formatInputVariable(column.column_type, column.name)}
                      </DropdownMenuItem>
                    ))}
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            </div>
            {!dataIsValid.firstColumn && (
              <div className="grid grid-cols-3 items-center gap-4">
                <div className="col-span-1">&nbsp;</div>
                <div className="col-span-2 text-left text-xs text-red-500">
                  Source {bandaid ? "node" : "column"} input is required for
                  comparison
                </div>
              </div>
            )}
            <div
              className="mt-2 grid grid-cols-3 items-center gap-4"
              key={`column-two`}
            >
              <div className="col-span-1 text-left font-mono text-sm text-gray-600">
                Source
              </div>
              <div className="col-span-2 w-full">
                <DropdownMenu>
                  <DropdownMenuTrigger disabled={!editable} className="w-full">
                    {columnTwoName || (
                      <span className="font-normal text-gray-500">
                        {bandaid ? "Select a node..." : "Select a column..."}
                      </span>
                    )}
                  </DropdownMenuTrigger>
                  <DropdownMenuContent>
                    {availableColumns.map((column) => (
                      <DropdownMenuItem
                        key={column.name}
                        onSelect={() => setColumnTwoName(column.name)}
                      >
                        {formatInputVariable(column.column_type, column.name)}
                      </DropdownMenuItem>
                    ))}
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            </div>
            {!dataIsValid.secondColumn && (
              <div className="grid grid-cols-3 items-center gap-4">
                <div className="col-span-1">&nbsp;</div>
                <div className="col-span-2 text-left text-xs text-red-500">
                  Target {bandaid ? "node" : "column"} input is required for
                  comparison
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </ModalStep>
  );
};

export default AbsoluteNumericDistanceBuilder;
