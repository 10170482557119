import { Edge, Node, useReactFlow } from "@xyflow/react";
import { makeAutoObservable, runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
} from "react";
import { useWorkflow } from "../../workflow-context";

class HistoryStore {
  past: HistoryItem[] = [];
  future: HistoryItem[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  get canUndo() {
    return this.past.length > 0;
  }

  get canRedo() {
    return this.future.length > 0;
  }

  takeSnapshot(
    nodes: Node[],
    edges: Edge[],
    viewport: Viewport,
    isSidebarOpen: boolean,
    maxHistorySize: number,
  ) {
    this.past = [
      ...this.past.slice(-maxHistorySize + 1),
      { nodes, edges, viewport, isSidebarOpen },
    ];
    this.future = [];
  }

  updateHistory(
    currentState: HistoryItem,
    sourceArray: HistoryItem[],
    targetArray: HistoryItem[],
  ): HistoryItem | null {
    const state = sourceArray[sourceArray.length - 1];
    if (state) {
      sourceArray.pop();
      targetArray.push(currentState);
      return state;
    }
    return null;
  }

  undo(
    currentNodes: Node[],
    currentEdges: Edge[],
    currentViewport: Viewport,
    currentSidebarOpen: boolean,
  ) {
    return this.updateHistory(
      {
        nodes: currentNodes,
        edges: currentEdges,
        viewport: currentViewport,
        isSidebarOpen: currentSidebarOpen,
      },
      this.past,
      this.future,
    );
  }

  redo(
    currentNodes: Node[],
    currentEdges: Edge[],
    currentViewport: Viewport,
    currentSidebarOpen: boolean,
  ) {
    return this.updateHistory(
      {
        nodes: currentNodes,
        edges: currentEdges,
        viewport: currentViewport,
        isSidebarOpen: currentSidebarOpen,
      },
      this.future,
      this.past,
    );
  }
}

type HistoryContextType = {
  store: HistoryStore;
  undo: () => void;
  redo: () => void;
  takeSnapshot: () => void;
};

const HistoryContext = createContext<HistoryContextType | undefined>(undefined);

type UseUndoRedoOptions = {
  maxHistorySize: number;
  enableShortcuts: boolean;
  viewportAnimationDuration?: number;
};

type HistoryItem = {
  nodes: Node[];
  edges: Edge[];
  viewport: Viewport;
  isSidebarOpen: boolean;
};

type Viewport = {
  x: number;
  y: number;
  zoom: number;
};

const defaultOptions: UseUndoRedoOptions = {
  maxHistorySize: 100,
  enableShortcuts: true,
  viewportAnimationDuration: 300,
};

export const HistoryProvider: React.FC<{
  children: React.ReactNode;
  options?: UseUndoRedoOptions;
}> = observer(({ children, options = defaultOptions }) => {
  const workflow = useWorkflow();
  const isSidebarOpen = workflow.isSidebarOpen;

  const store = React.useMemo(() => new HistoryStore(), []);
  const { setNodes, setEdges, getNodes, getEdges, setViewport, getViewport } =
    useReactFlow();

  const takeSnapshot = useCallback(() => {
    store.takeSnapshot(
      getNodes(),
      getEdges(),
      getViewport(),
      isSidebarOpen,
      options.maxHistorySize,
    );
  }, [
    getNodes,
    getEdges,
    getViewport,
    isSidebarOpen,
    options.maxHistorySize,
    store,
  ]);

  const updateFlowState = useCallback(
    (state: HistoryItem | null) => {
      if (state) {
        setNodes(state.nodes);
        setEdges(state.edges);
        setViewport(state.viewport, {
          duration: options.viewportAnimationDuration,
        });
        runInAction(() => workflow.setSidebarOpen(state.isSidebarOpen));
      }
    },
    [
      setNodes,
      setEdges,
      setViewport,
      workflow,
      options.viewportAnimationDuration,
    ],
  );

  const undo = useCallback(() => {
    updateFlowState(
      store.undo(getNodes(), getEdges(), getViewport(), isSidebarOpen),
    );
  }, [getNodes, getEdges, getViewport, isSidebarOpen, store, updateFlowState]);

  const redo = useCallback(() => {
    updateFlowState(
      store.redo(getNodes(), getEdges(), getViewport(), isSidebarOpen),
    );
  }, [getNodes, getEdges, getViewport, isSidebarOpen, store, updateFlowState]);

  useEffect(() => {
    if (!options.enableShortcuts) return;

    const keyDownHandler = (event: KeyboardEvent) => {
      const isUndo = event.key === "z" && (event.ctrlKey || event.metaKey);
      const isRedo = isUndo && event.shiftKey;

      if (isRedo) {
        redo();
      } else if (isUndo) {
        undo();
      }
    };

    document.addEventListener("keydown", keyDownHandler);
    return () => document.removeEventListener("keydown", keyDownHandler);
  }, [undo, redo, options.enableShortcuts]);

  const value = {
    store,
    undo,
    redo,
    takeSnapshot,
  };

  return (
    <HistoryContext.Provider value={value}>{children}</HistoryContext.Provider>
  );
});

export const useHistory = () => {
  const context = useContext(HistoryContext);
  if (!context) {
    throw new Error("useHistory must be used within a HistoryProvider");
  }
  return context;
};

export default HistoryProvider;
