import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { cn } from "@/lib/utils";
import { Check, ChevronsUpDown } from "lucide-react";
import { useState } from "react";

export function SelectModelDropdown({
  model,
  setModel,
  options,
  width = "[200px]",
  size = "base",
}: {
  model: string | null;
  setModel: (model: string | null) => void;
  options: string[];
  width?: string;
  size?: "base" | "lg";
}) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");

  const onSelect = (name: string) => {
    setOpen(false);
    setModel(name);
  };

  return (
    <Popover open={open} onOpenChange={setOpen} modal={true}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className={`w-${width} justify-between ${
            size === "lg"
              ? "font-base border-gray-300 py-2 pl-4 pr-2 text-sm hover:border-gray-400 hover:bg-gray-50"
              : ""
          }`}
        >
          {model ?? "Select model..."}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className={`w-${width} p-0`}>
        <Command>
          <CommandInput
            placeholder={
              width === "full"
                ? "Search or enter fine-tuned model..."
                : "Enter a model..."
            }
            value={value}
            onValueChange={setValue}
            className={`focus:ring-0`}
          />
          <CommandEmpty>No model found.</CommandEmpty>
          <div className="max-h-[calc(40vh)] overflow-y-auto">
            <CommandGroup>
              {options.map((m) => (
                <CommandItem
                  key={m}
                  onSelect={() => onSelect(m)}
                  className="w-full"
                >
                  <Check
                    className={cn(
                      "mr-2 h-4 w-4",
                      model === m ? "opacity-100" : "opacity-0",
                    )}
                  />
                  {m}
                </CommandItem>
              ))}
              <CommandItem onSelect={() => onSelect(value)} className="w-full">
                <Check
                  className={cn(
                    "mr-2 h-4 w-4",
                    model === value ? "opacity-100" : "opacity-0",
                  )}
                />
                {value}
              </CommandItem>
            </CommandGroup>
          </div>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
