import { useReportContext } from "@/components/Evaluate/report-context";
import LoadingSpinner from "@/components/LoadingSpinner";
import { TablePagination } from "@/components/ui/table-pagination";
import { useSearchProvider } from "@/components/ui/table/Search/search-context";
import { usePagination } from "@/hooks/UsePagination";
import { ReportColumn } from "@/types/evaluate";
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { memo, useEffect, useMemo, useState } from "react";
import FormattedTable from "./FormattedTable";
import TableControls from "./TableControls";

const columnHelper = createColumnHelper<number>();

const Body = () => {
  const reportContext = useReportContext();
  const columnsAreLoading: boolean = reportContext.reportColumnsIsLoading;
  const columns: ReportColumn[] = reportContext.sortedColumns;
  const rowIds = reportContext.rowIds;
  const headerCellsList = reportContext.headerCellsList;
  const tableCellsMap = reportContext.tableCellsMap;
  const columnsCount = reportContext.columnsCount;
  const { sortedRowIds, filters } = reportContext;

  const shouldPaginate = useMemo(() => {
    return rowIds.length > 15;
  }, [rowIds]);

  // State for current page and page size
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  // Pagination range
  const paginationRange = usePagination({
    totalCount: rowIds.length,
    pageSize,
  });

  // Reset current page when page size changes
  useEffect(() => {
    setCurrentPage(1);
  }, [pageSize]);

  // Memoized column accessors
  const columnAccessors = useMemo(
    () =>
      columns.map((column: ReportColumn, index: number) =>
        columnHelper.display({
          id: (column.id || "").toString(),
          header: () => headerCellsList[index](),
          cell: ({ row }) => {
            const rowId: number = row.original;
            const columnId: number = column.id || -1;
            const item = tableCellsMap[`${rowId}-${columnId}`];
            return item ? item() : <></>;
          },
        }),
      ),
    [columns, headerCellsList, tableCellsMap],
  );

  const cellsTable = useReactTable({
    columns: columnAccessors,
    data: sortedRowIds,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <FormattedTable
        key={columnsCount}
        table={cellsTable}
        emptyMessage={
          sortedRowIds.length === 0 ? (
            <div className="flex items-center justify-center p-4">
              <p className="text-gray-600">
                {filters.length > 0
                  ? "No rows found matching the current filters."
                  : "No rows found."}
              </p>
            </div>
          ) : (
            <div className="flex items-center justify-center space-x-1">
              <LoadingSpinner size="10" />
            </div>
          )
        }
        isLoading={columnsAreLoading}
        currentPage={shouldPaginate ? currentPage : undefined}
        pageSize={shouldPaginate ? pageSize : undefined}
      />
      {shouldPaginate && sortedRowIds.length > 0 && (
        <div className="flex w-full py-2">
          <TablePagination
            table={cellsTable}
            pages={paginationRange?.length || 0}
            hasNext={paginationRange?.length > currentPage}
            page={currentPage}
            pageSize={pageSize}
            pageSizeOptions={[10, 20, 50, 100]}
            setPageIndex={setCurrentPage}
            setPageSize={setPageSize}
          />
        </div>
      )}
    </>
  );
};

const EvalTableSection = ({ searchable }: { searchable?: boolean }) => {
  const [SearchProvider, data] = useSearchProvider();
  if (!searchable) return <Body />;
  return (
    <>
      <TableControls data={data} />
      <SearchProvider>
        <Body />
      </SearchProvider>
    </>
  );
};

export default memo(EvalTableSection);
