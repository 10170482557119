const getIconColor = (
  baseStyle: string,
): { background: string; text: string } => {
  const colorMap: {
    [key: string]: { background: string; text: string };
  } = {
    "bg-red-50": { background: "bg-red-100", text: "text-red-700" },
    "bg-yellow-50": {
      background: "bg-yellow-100",
      text: "text-yellow-700",
    },
    "bg-gray-100": { background: "bg-gray-200", text: "text-gray-700" },
    "bg-blue-50": { background: "bg-blue-100", text: "text-blue-600" },
    "bg-blue-100": { background: "bg-blue-200", text: "text-blue-700" },
    "bg-amber-100": {
      background: "bg-amber-200",
      text: "text-amber-700",
    },
    "bg-green-100": {
      background: "bg-green-200",
      text: "text-green-700",
    },
  };

  const defaultColor = {
    background: "bg-gray-200",
    text: "text-gray-700",
  };
  return (
    Object.entries(colorMap).find(([key]) => baseStyle.includes(key))?.[1] ||
    defaultColor
  );
};

const getBackgroundColor = (
  pending?: boolean,
  preview?: boolean,
  hasErrors?: boolean,
): string => {
  if (hasErrors) return "border-red-500 bg-red-50";
  if (pending) return "bg-yellow-50 opacity-75";
  if (preview) return "bg-blue-100";
  return "bg-white";
};

const getActiveStyle = (): string => {
  return "border-blue-500 bg-blue-50";
};

export { getIconColor, getBackgroundColor, getActiveStyle };
