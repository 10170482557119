import { Message, Template, TemplateFormat } from "@/types";
import { PromptVersionSnippet } from "@/types/apiGetters";
import { copyTextToClipboard } from "@/utils/utils";
import ButtonCopy from "../button-copy";
import ButtonMaximize from "../button-maximize";
import ContentArea from "../ContentArea";
import { Dialog, DialogContent, DialogTrigger } from "../ui/dialog";
import MessagePromptTemplate from "./MessagePromptTemplate";

type ChatTemplateProps = {
  template: string;
  template_id?: string;
  setTemplate?: (template: string) => void;
  messageTemplates: Message[];
  setMessageTemplates?: (messageTemplates: Message[]) => void;
  templateFormat: TemplateFormat;
  invalidSnippets?: string[];
  invalidPromptTemplate?: boolean;
  invalidPromptIndex?: number | null;
  sourcedSnippets?: Array<PromptVersionSnippet>;
  renderedTemplate?: Template;
  isXrayMode?: boolean;
};

const ChatTemplate = (props: ChatTemplateProps) => {
  const isXrayMode = props.isXrayMode ?? false;
  const renderedTemplate = props.renderedTemplate;

  const renderedMessageTemplates =
    renderedTemplate?.type === "chat"
      ? renderedTemplate?.messages?.slice(1)
      : [];

  const renderedMessage =
    (renderedTemplate?.type === "chat" &&
      renderedTemplate?.messages[0]?.content?.[0] &&
      renderedTemplate?.messages[0]?.content?.[0].type === "text" &&
      renderedTemplate?.messages[0]?.content?.[0]?.text) ||
    "";

  const renderedFirstMessage =
    renderedTemplate?.type === "chat" ? renderedTemplate?.messages[0] : null;

  const firstMessageRole = renderedFirstMessage?.role || "System";

  if (!renderedFirstMessage) return null;
  return (
    <div className="grid h-full flex-1 grid-cols-1 overflow-hidden sm:text-sm md:grid-cols-2">
      <div className="h-full overflow-auto p-2">
        <div
          className={`group flex h-full flex-col gap-2 border p-2 ${
            props.invalidPromptTemplate && props.invalidPromptIndex === 0
              ? "border-red-400"
              : ""
          }`}
        >
          <div className=" flex items-center justify-between">
            <div className="ml-2 border-b border-transparent font-bold group-hover:border-gray-600">
              {firstMessageRole.toUpperCase()}
            </div>
            <Dialog>
              <DialogTrigger asChild>
                <ButtonMaximize />
              </DialogTrigger>
              <DialogContent className="h-full max-w-full">
                <div className="flex h-full flex-col gap-2">
                  <div className="font-bold">
                    {firstMessageRole.toUpperCase()}
                  </div>
                  <div className="flex h-full max-h-[90vh] min-h-[60px] w-full overflow-auto rounded-none border border-input bg-transparent px-3 py-2 text-sm shadow-sm placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-auto disabled:opacity-100">
                    <ContentArea
                      readOnly
                      sourcedSnippets={props.sourcedSnippets}
                      isXrayMode={isXrayMode}
                      value={isXrayMode ? renderedMessage : props.template}
                      className="h-full w-full resize-none border-none p-0 text-sm focus:ring-0"
                    />
                  </div>
                </div>
              </DialogContent>
            </Dialog>
          </div>
          <div className="flex flex-grow flex-col overflow-auto">
            <div className="flex-grow whitespace-pre-wrap text-sm">
              <ContentArea
                readOnly
                sourcedSnippets={props.sourcedSnippets}
                isXrayMode={isXrayMode}
                value={isXrayMode ? renderedMessage : props.template}
                className="h-full w-full resize-none border-none p-0 text-sm focus:ring-0"
              />
            </div>
            <div className="mt-2 flex justify-end">
              <ButtonCopy
                onClick={async () => await copyTextToClipboard(props.template)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex h-full flex-col overflow-auto p-2">
        <div className="flex h-full flex-col overflow-auto">
          {props.messageTemplates
            ?.slice(1)
            ?.map((messageTemplate, i) => (
              <MessagePromptTemplate
                key={i}
                messageTemplate={messageTemplate}
                templateFormat={props.templateFormat}
                invalidSnippets={props.invalidSnippets}
                highlightInvalid={
                  props.invalidPromptIndex === i + 1 &&
                  props.invalidPromptTemplate
                }
                sourcedSnippets={props.sourcedSnippets}
                renderedMessageTemplate={renderedMessageTemplates[i]}
                isXrayMode={isXrayMode}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default ChatTemplate;
