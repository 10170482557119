import { Node, useViewport } from "@xyflow/react";
import { useCallback, useState } from "react";
import { ContextMenuType } from "../ContextMenu";
import { useWorkflow } from "../../workflow-context";

interface ContextMenuProps {
  id: string;
  top: number;
  left: number;
  type: ContextMenuType;
}

const useContextMenu = (ref: React.RefObject<any>) => {
  const [menuOpen, setMenuOpen] = useState<ContextMenuProps | null>(null);
  const workflow = useWorkflow();
  const { isSidebarOpen } = workflow;

  const viewport = useViewport();
  const onNodeContextMenu = useCallback(
    (event: React.MouseEvent, node: Node) => {
      event.preventDefault();
      event.stopPropagation();

      if (isSidebarOpen) workflow.closeSidebar();
      const pane = ref.current.getBoundingClientRect();

      // Transform the mouse position to the correct viewport coordinates
      const x = (event.clientX - pane.left - viewport.x) / viewport.zoom;
      const y = (event.clientY - pane.top - viewport.y) / viewport.zoom;

      const workflowNode = workflow.getNodeById(node.id);

      workflow.setActiveNode(workflowNode);
      setMenuOpen({
        id: node.id,
        top: y,
        left: x,
        type: ContextMenuType.Node,
      });
    },
    [isSidebarOpen, ref, viewport.x, viewport.y, viewport.zoom, workflow],
  );

  // Generic context menu with option to add new node
  const onContextMenu = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      if (isSidebarOpen) workflow.closeSidebar();
      const pane = ref.current.getBoundingClientRect();
      // Transform the mouse position to the correct viewport coordinates
      const x = (event.clientX - pane.left - viewport.x) / viewport.zoom;
      const y = (event.clientY - pane.top - viewport.y) / viewport.zoom;
      setMenuOpen({
        id: "generic-context-menu",
        top: y,
        left: x,
        type: ContextMenuType.Background,
      });
    },
    [isSidebarOpen, ref, viewport.x, viewport.y, viewport.zoom, workflow],
  );

  return [
    [menuOpen, setMenuOpen],
    { onNodeContextMenu, onContextMenu },
  ] as const;
};

export default useContextMenu;
