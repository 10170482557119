import { Dialog, DialogContent } from "@/components/ui/dialog";
import { useState } from "react";
import CommitMessageDialog from "./CommitMessageDialog";
import { Button } from "./ui/button";

interface SaveWithCommitButtonProps {
  onSave?: (commitMessage: string) => void;
  onCancel?: () => void;
  name?: string;
  Extra?: React.ReactNode;
}

const SaveWithCommitButton = ({
  onSave,
  onCancel,
  Extra,
  name,
}: SaveWithCommitButtonProps) => {
  const [showCommitMessage, setShowCommitMessage] = useState(false);

  const handleSubmit = (commitMessage: string) => {
    setShowCommitMessage(false);
    if (onSave) onSave(commitMessage);
  };

  const handleCancel = () => {
    setShowCommitMessage(false);
    if (onCancel) onCancel();
  };

  return (
    <>
      <Button
        size={"sm"}
        onClick={() => setShowCommitMessage(true)}
        className="flex items-center gap-2"
      >
        {name || "Save With Commit Message"}
      </Button>
      <Dialog open={showCommitMessage} onOpenChange={setShowCommitMessage}>
        <DialogContent>
          <CommitMessageDialog
            onSubmit={handleSubmit}
            onCancel={handleCancel}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SaveWithCommitButton;
