import { useFolders } from "@/queries";
import { Folder } from "@/types/folders";
import { PromptRegistry } from "@/types/prompt-registry";

const useFolderOrganization = (
  userToken: string,
  workspaceId: number,
  availablePromptTemplates: PromptRegistry[],
) => {
  const foldersQuery = useFolders(userToken, workspaceId);
  const folders: Folder[] = foldersQuery.data?.folders ?? [];

  const folderIdsToFolders = folders.reduce<{ [key: number]: Folder }>(
    (acc, folder) => ({
      ...acc,
      [folder.id]: folder,
    }),
    {},
  );

  const folderIdToTemplateList = availablePromptTemplates.reduce<{
    [key: number]: Array<PromptRegistry>;
  }>((acc, template) => {
    const folderId = template.folder_id;
    if (folderId) {
      if (!acc[folderId]) {
        acc[folderId] = [];
      }
      acc[folderId].push(template);
    }
    return acc;
  }, {});

  const templatesWithoutFolders = availablePromptTemplates.filter(
    (template) => !template.folder_id,
  );

  return {
    folderIdsToFolders,
    folderIdToTemplateList,
    templatesWithoutFolders,
  };
};

export default useFolderOrganization;
