import { cn } from "@/lib/utils";
import { reaction } from "mobx";
import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import { TextareaProps } from "../ui/textarea";
import { WidgetTypes } from "../Widgets";
import useInitialWidgets from "../Widgets/useInitialWidgets";
import { useContentArea } from "./content-area-context";
import ContextMenu from "./ContextMenu";
import { useContentAreaConfig } from "./helpers/content-area-config-context";
import { useCustomComponent } from "./hooks";
import useCursorPosition from "./hooks/handlers/useCursorPosition";
import useHandlers from "./hooks/useContentHandlers";

interface ContentProps extends TextareaProps {
  readOnly?: boolean;
  isXrayMode?: boolean;
  placeholder?: string;
}

const Content = observer((props: ContentProps) => {
  const {
    readOnly = false,
    isXrayMode = false,
    placeholder = "Type...",
    ...textareaProps
  } = props;
  const content = useContentArea();
  const initialContent = content.html;
  const [isEmpty, setIsEmpty] = useState(!initialContent);

  const contentRef = useRef<HTMLDivElement>(null);
  useCursorPosition(contentRef);
  const { insertCustomComponent } = useCustomComponent(contentRef);

  const {
    features: { widgets },
  } = useContentAreaConfig();

  const handles = useHandlers(contentRef, widgets);
  useInitialWidgets(contentRef, initialContent, readOnly, isXrayMode);

  useEffect(() => {
    content.setContent(content.html);

    const disp = reaction(
      () => content.getContent(),
      (newContent) => {
        setIsEmpty(!newContent);
        textareaProps?.onChange?.({
          target: { value: newContent },
        } as any);
      },
    );
    return disp;
  }, [content, textareaProps, textareaProps.onChange]);

  return (
    <div className="relative inline-block h-full w-full overflow-hidden">
      <div
        ref={contentRef}
        contentEditable={!readOnly}
        suppressContentEditableWarning
        {...(readOnly ? {} : handles)}
        onKeyDown={(e) => {
          if (!readOnly) {
            handles.onKeyDown(e);
            textareaProps.onKeyDown?.(e as any);
          }
        }}
        onBlur={(e) => {
          const hasContent =
            e.currentTarget.textContent ||
            contentRef.current?.innerHTML?.includes("<section");
          setIsEmpty(!hasContent);
        }}
        style={{
          minHeight: "60px",
          height: "100%",
          lineHeight: "1.5",
          whiteSpace: "pre-line",
          wordBreak: "break-word",
          display: "inline-block",
          width: "100%",
        }}
        role={readOnly ? "region" : "textbox"}
        aria-readonly={readOnly}
        aria-multiline={!readOnly}
        className={cn(
          "relative z-10 w-full overflow-auto border border-gray-200 px-3 py-2 transition-colors duration-200",
          readOnly
            ? "cursor-default"
            : "hover:border-gray-300 focus:border-transparent  ",
          props.className,
          !props.className && "shadow-sm",
        )}
      />
      {isEmpty && !readOnly && (
        <div
          className="pointer-events-none absolute left-3 top-2 z-10 text-gray-400"
          aria-hidden="true"
        >
          {placeholder}
        </div>
      )}
      {!readOnly && (
        <ContextMenu
          isVisible={content.showContextMenu}
          position={content.contextMenuPosition}
          onSelect={(item: string) => {
            // Handle the selected item
            insertCustomComponent(item as WidgetTypes);
            content.setShowContextMenu(false);
          }}
          onClose={() => content.setShowContextMenu(false)}
          contentRef={contentRef}
        />
      )}
    </div>
  );
});

export default Content;
