import { WidgetTypes } from "@/components/Widgets";
import { useState, useEffect, useCallback } from "react";
import { WidgetItem } from "../../types";

const useKeyboardNavigation = (
  isVisible: boolean,
  widgetItems: WidgetItem[],
  onSelect: (widgetType: WidgetTypes) => void,
  onClose: () => void,
  restoreSelection: () => void,
) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  useEffect(() => {
    setSelectedIndex(0);
  }, [isVisible]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (!isVisible) return;

      if (["ArrowDown", "ArrowUp", "Enter", "Escape"].includes(event.key)) {
        event.preventDefault();
      }

      switch (event.key) {
        case "ArrowDown":
          setSelectedIndex((prev) =>
            prev === null || prev === widgetItems.length - 1 ? 0 : prev + 1,
          );
          break;
        case "ArrowUp":
          setSelectedIndex((prev) =>
            prev === null || prev === 0 ? widgetItems.length - 1 : prev - 1,
          );
          break;
        case "Enter":
          if (selectedIndex !== null) {
            onSelect(widgetItems[selectedIndex].type);
            restoreSelection();
          }
          break;
        case "Escape":
          onClose();
          restoreSelection();
          break;
      }
    },
    [
      isVisible,
      onClose,
      onSelect,
      restoreSelection,
      selectedIndex,
      widgetItems,
    ],
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown, true); // Added capture phase
    return () => {
      window.removeEventListener("keydown", handleKeyDown, true); // Cleanup with capture phase
    };
  }, [handleKeyDown]);

  return selectedIndex;
};

export default useKeyboardNavigation;
