export function escapeXml(unsafe: any) {
  if (!unsafe) return "";

  // Handle non-string input
  const str = String(unsafe);

  // First escape special characters
  const escaped = str.replace(/[<>&'"]/g, function (c: any) {
    switch (c) {
      case "<":
        return "&lt;";
      case ">":
        return "&gt;";
      case "&":
        // Don't double-escape ampersands that are already part of entities
        if (/&[a-zA-Z0-9#][a-zA-Z0-9#]*;/.test(str)) {
          return "&";
        }
        return "&amp;";
      case "'":
        return "&apos;";
      case '"':
        return "&quot;";
      default:
        return c;
    }
  });

  return escaped;
}

export function unescapeXml(escaped: string) {
  if (!escaped) return "";

  return escaped
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(/&amp;/g, "&")
    .replace(/&apos;/g, "'")
    .replace(/&quot;/g, '"');
}
