import { useCallback, useEffect, useState } from "react";
import { CheckIcon } from "@heroicons/react/solid";
import {
  useCreateStripeCheckoutSession,
  useSetUserToFreePlan,
} from "../queries";
import { useAuth } from "../context/auth-context";

interface SubscriptionStatus {
  success: boolean;
  subscribed: boolean;
  subscription_info: object;
  billing_portal_url: string;
  plan_name: string;
}

const frequencies = [
  { value: "monthly", label: "Monthly", priceSuffix: "/month" },
  { value: "annually", label: "Annually", priceSuffix: "/year" },
];

interface Plan {
  name: string;
  price: number;
  price_id: number;
  description: string;
  features: string[];
}

const plans: Plan[] = [
  {
    name: "Free",
    price: 0,
    price_id: 1,
    description: "For hackers",
    features: ["7 days of logs", "5,000 requests"],
  },
  {
    name: "Pro",
    price: 50,
    price_id: 5,
    description: "For power users and small teams",
    features: [
      "Logs never expire",
      "100,000 requests",
      "Full access to evaluations, fine-tuning, workspaces, and more",
      "Collaborate with other team members",
    ],
  },
  {
    name: "Enterprise",
    price: 0,
    price_id: 4,
    description: "For teams",
    features: [
      "Shared Slack channel",
      "Self-hosted",
      "SOC 2 compliance",
      "Custom service & data agreements",
      "Dedicated evaluation workers",
    ],
  },
];

export default function SubscriptionStatusBanner({
  subscriptionStatus,
  subscriptionStatusisLoading,
  showPricingModal,
  setShowPricingModal,
}: {
  subscriptionStatus: SubscriptionStatus;
  subscriptionStatusisLoading: boolean;
  showPricingModal: boolean;
  setShowPricingModal: (value: boolean) => void;
}) {
  const userNotSubscribed =
    !subscriptionStatusisLoading &&
    subscriptionStatus &&
    !subscriptionStatus?.subscribed;
  const userToken = useAuth()?.userToken!;

  const [frequency] = useState(frequencies[0]);

  const createCheckoutSessionMutation =
    useCreateStripeCheckoutSession(userToken);
  const setUserToFreePlanMutation = useSetUserToFreePlan(userToken);

  const {
    isLoading: isLoadingCreateCheckoutSession,
    mutateAsync: createCheckoutSession,
  } = createCheckoutSessionMutation;

  const {
    isLoading: isLoadingSetUserToFreePlan,
    mutateAsync: setUserToFreePlan,
  } = setUserToFreePlanMutation;

  const isLoading =
    isLoadingCreateCheckoutSession || isLoadingSetUserToFreePlan;

  const onPlanSelect = async (priceId: number) => {
    try {
      if (priceId === 1) {
        // Free plan
        const result = await setUserToFreePlan();
        if (result && result?.success) {
          window.location.reload();
        }
      } else {
        // Open Stripe checkout
        const result = await createCheckoutSession(priceId);
        if (result && result?.success && result?.checkout_session_url) {
          window.open(result.checkout_session_url);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const closeModal = useCallback(() => {
    setShowPricingModal(false);
  }, [setShowPricingModal]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        closeModal();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    // Remove event listener on cleanup
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [closeModal]);

  return (
    <>
      {/* Banner */}
      {(showPricingModal || userNotSubscribed) && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div className="fixed inset-0 bg-gray-800/30 backdrop-blur-sm"></div>
          <div className="relative z-10 mx-4 bg-white p-6 shadow-lg md:mx-auto md:w-full md:max-w-full md:rounded-lg lg:max-w-screen-lg">
            <h2 className="mb-4 border-b pb-2 text-2xl font-semibold text-gray-800">
              PromptLayer Subscription Options
            </h2>
            <div className="mt-6 grid grid-cols-3 gap-4">
              {plans.map((plan) => (
                <div
                  key={plan.price_id}
                  className={`rounded border p-4 ${
                    plan.name === "Startup"
                      ? "border-blue-200 bg-blue-50"
                      : "hover:bg-blue-50"
                  }`}
                >
                  <h3
                    className={`text-lg font-semibold ${
                      plan.name === "Startup" ? "text-blue-800" : ""
                    }`}
                  >
                    {plan.name}
                  </h3>
                  <p className="text-sm leading-6 text-gray-600">
                    {plan.description}
                  </p>
                  <p
                    className={`mt-6 text-4xl font-bold tracking-tight ${
                      plan.name === "Startup"
                        ? "text-blue-800"
                        : "text-gray-900"
                    }`}
                  >
                    {plan.name === "Enterprise"
                      ? "Contact us"
                      : `$${
                          frequency.value === "annually"
                            ? new Intl.NumberFormat().format(
                                Math.floor(12 * plan.price * 0.8),
                              )
                            : new Intl.NumberFormat().format(plan.price)
                        }`}
                    {plan.name !== "Enterprise" && (
                      <span className="pl-1 text-sm font-semibold tracking-normal text-gray-500">
                        {frequency.value === "annually" ? "/year" : "/month"}
                        {plan.name === "Pro" ? "/user" : ""}
                      </span>
                    )}
                  </p>
                  <button
                    onClick={() => {
                      if (!isLoading) {
                        if (plan.name === "Enterprise") {
                          window.location.href =
                            "mailto:hello@promptlayer.com?subject=Enterprise Plan Inquiry";
                        } else {
                          onPlanSelect(plan.price_id);
                        }
                      }
                    }}
                    className={`mt-6 block w-full rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 shadow-sm focus:outline-none disabled:text-gray-400 disabled:opacity-50 ${
                      plan.name === "Pro"
                        ? "bg-blue-600 text-white hover:bg-blue-500 hover:text-white"
                        : "border border-gray-300 bg-white hover:border-blue-400 hover:text-blue-500"
                    }`}
                    disabled={isLoading}
                  >
                    {isLoading
                      ? "Loading..."
                      : plan.name === "Free"
                      ? "Select plan"
                      : plan.name === "Enterprise"
                      ? "Contact Us"
                      : "Subscribe"}
                  </button>

                  <ul className="mt-8 space-y-3 text-sm leading-6 text-gray-600">
                    {plan.features.map((feature) => (
                      <li key={feature} className="flex gap-x-3">
                        <CheckIcon
                          className="h-6 w-5 flex-none text-blue-600"
                          aria-hidden="true"
                        />
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
            <div className="pt-4 text-center text-sm leading-6 text-gray-500">
              Contact us at{" "}
              <a
                className="text-blue-500 hover:text-blue-400"
                href="mailto:hello@promptlayer.com"
              >
                hello@promptlayer.com
              </a>{" "}
              with any questions.
            </div>
            <div className="flex justify-end">
              {!userNotSubscribed && (
                <button
                  className="rounded bg-blue-500 px-4 py-2 font-semibold text-white hover:bg-blue-600"
                  onClick={closeModal}
                >
                  Close
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
