import { ENDPOINTS } from "@/api/application-api";
import { LOCAL_STORAGE, STRIPE_PLAN_DETAILS } from "@/constants";
import {
  useDatasetGroups,
  useIntercomVerification,
  usePromptLayerApiKeys,
  useProviderBaseURLs,
  useUsage,
  useUserSubscriptionStatus,
  useWebhook,
  useWorkspaceMembers,
  useWorkspaces,
} from "@/queries";
import { DatasetGroupRead } from "@/types/dataset-groups";
import { PromptLayerApiKey } from "@/types/promptLayerApiKeys";
import { ProviderBaseURL } from "@/types/provider-base-urls";
import { Usage } from "@/types/usage";
import { Workspace } from "@/types/workspaces";
import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  useNavigate as useNavigateOriginal,
  useParams,
} from "react-router-dom";
import { useAuth } from "./auth-context";

type UserContextType = {
  newDatasetGroups: DatasetGroupRead[];
  promptLayerWorkspaceApiKeys: PromptLayerApiKey[];
  providerBaseURLs: ProviderBaseURL[];
  stripePlanDetails: any;
  stripePlanName: string;
  subscriptionStatus: any;
  usage: Usage;
  user: any;
  userIsFreePlan: boolean;
  workspaceMembers: any[];
  workspaceWebhook: any;
  workspaces: Workspace[];
  workspacePlanName: string | undefined;
  activeWorkspace: Workspace | undefined;
  activeWorkspaceId: number | null;
  setWorkspaceId: (workspaceId: number) => void;
};

const getInitialActiveWorkspaceId = () => {
  const localStorageActiveWorkspaceId = localStorage.getItem(
    LOCAL_STORAGE.ACTIVE_WORKSPACE_ID,
  );
  return localStorageActiveWorkspaceId
    ? parseInt(localStorageActiveWorkspaceId)
    : null;
};

const UserContext = createContext<UserContextType | null>(null);

function UserProvider(props: PropsWithChildren<{}>) {
  const auth = useAuth();
  const userToken = auth!.userToken;
  const useNavigate = () => {
    try {
      return useNavigateOriginal();
    } catch {
      return undefined;
    }
  };
  const navigate = useNavigate();

  useEffect(() => {
    if (!userToken) return;

    fetch(ENDPOINTS.get_user, {
      headers: { Authorization: `Bearer ${userToken}` },
    })
      .then((res) => (res.ok ? res.json() : Promise.reject()))
      .catch(() => auth?.logout());
  }, [auth, userToken]);

  const intercomVerification = useIntercomVerification(userToken!);

  const [activeWorkspaceId, setActiveWorkspaceId] = useState<number | null>(
    getInitialActiveWorkspaceId(),
  );

  const datasetGroupsQuery = useDatasetGroups(userToken!, activeWorkspaceId!);
  const promptLayerApiKeysQuery = usePromptLayerApiKeys(
    userToken!,
    activeWorkspaceId!,
  );
  const providerBaseURLsQuery = useProviderBaseURLs(
    userToken!,
    activeWorkspaceId!,
  );
  const usageQuery = useUsage(userToken!, activeWorkspaceId!);
  const workspacesQuery = useWorkspaces(userToken!);
  const workspaceMembersQuery = useWorkspaceMembers(
    userToken!,
    activeWorkspaceId!,
  );
  const workspaceWebhookQuery = useWebhook(userToken!, activeWorkspaceId!);

  const activeWorkspace = useMemo(
    () =>
      workspacesQuery.data?.workspaces?.find(
        (workspace) => workspace?.id === activeWorkspaceId!,
      ),
    [workspacesQuery.data, activeWorkspaceId],
  );
  const params = useParams();

  useEffect(() => {
    if (
      params &&
      !isNaN(parseInt(params?.workspaceId!)) &&
      activeWorkspaceId !== parseInt(params?.workspaceId!)
    ) {
      setWorkspaceId(parseInt(params.workspaceId!));
    }
  }, [activeWorkspaceId, params, navigate]);

  const subscriptionStatus = useUserSubscriptionStatus(userToken!);

  const userIsFreePlan =
    !subscriptionStatus?.isLoading &&
    (subscriptionStatus?.data?.plan_name === "free" ||
      !subscriptionStatus?.data?.subscribed);

  // Using the plan_name
  const stripePlanName = subscriptionStatus?.data?.plan_name;
  const stripePlanDetails = STRIPE_PLAN_DETAILS[stripePlanName];

  // Workspace plan details for meticolous recorder
  const workspacePlanName = activeWorkspace?.admin_billing_plan_name;

  if (window.location.hostname === "promptlayer.com" && auth?.user) {
    // @ts-ignore
    window.Intercom("update", {
      user_id: auth.user?.id,
      email: auth.user?.email,
      user_hash: intercomVerification.data?.intercom_verification,
    });
  }

  const setWorkspaceId = (workspaceId: number) => {
    setActiveWorkspaceId(workspaceId);
    localStorage.setItem(
      LOCAL_STORAGE.ACTIVE_WORKSPACE_ID,
      workspaceId.toString(),
    );
  };

  return (
    <UserContext.Provider
      value={{
        newDatasetGroups: datasetGroupsQuery.data?.dataset_groups ?? [],
        promptLayerWorkspaceApiKeys:
          promptLayerApiKeysQuery.data?.api_keys ?? [],
        providerBaseURLs: providerBaseURLsQuery.data?.provider_base_urls ?? [],
        stripePlanDetails,
        stripePlanName,
        subscriptionStatus,
        usage: usageQuery.data,
        user: auth?.user,
        userIsFreePlan,
        workspaceMembers: workspaceMembersQuery.data?.workspace_members ?? [],
        workspaceWebhook: workspaceWebhookQuery.data?.webhook ?? null,
        workspaces: workspacesQuery.data?.workspaces ?? [],
        workspacePlanName,
        activeWorkspace: activeWorkspace,
        setWorkspaceId,
        activeWorkspaceId: activeWorkspaceId,
      }}
      {...props}
    />
  );
}

const useUser = () => {
  const user = useContext(UserContext);
  if (!user) throw new Error("useUser must be used within a UserProvider");
  return user;
};

export { UserProvider, useUser };
