import { CogIcon } from "lucide-react";

import { Button } from "../ui/button";
import RegistryName from "./RegistryName";

const ReleaseLabelGroupTabs: React.FC<{
  promptLabelName: string;
  promptRegistryIds: number[];
  type: "workflow" | "prompt";
  activeTab: number | null;
  setActiveTab: (id: number) => void;
  setEditPromptRegistryObjectsModalOpen: (isOpen: boolean) => void;
}> = ({
  promptLabelName,
  promptRegistryIds,
  activeTab,
  setActiveTab,
  setEditPromptRegistryObjectsModalOpen,
  type,
}) => (
  <div className="my-3 flex border-b">
    <div className="mx-2 flex items-center justify-center">
      <div className="rounded-sm border border-gray-200 bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600">
        {promptLabelName}
      </div>
    </div>
    <div className="flex flex-row overflow-x-auto">
      {promptRegistryIds.map((id) => (
        <button
          key={id}
          className={`min-w-fit px-4 py-2 text-sm ${
            activeTab === id
              ? "border-b-2 border-blue-500 text-blue-500"
              : "text-gray-500"
          }`}
          onClick={() => setActiveTab(id)}
        >
          <RegistryName id={id} type={type} />
        </button>
      ))}
    </div>
    <Button
      className="ml-auto px-4 py-2 text-sm text-gray-500 hover:text-gray-700"
      onClick={() => setEditPromptRegistryObjectsModalOpen(true)}
      variant="ghost"
      size="sm"
    >
      <CogIcon className="mr-2 inline-block h-4 w-4" />
      Configure
    </Button>
  </div>
);

export default ReleaseLabelGroupTabs;
