import { useKeepRelevantParams } from "@/components/SidebarSearchLayout/hooks/useKeepRelevantParams";
import { useUser } from "@/context/user-context";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const useKeyboardNavigation = (
  inputRef: React.RefObject<HTMLInputElement>,
  items: any[],
  getNextId: (
    id?: string,
    type?: string,
  ) => { id: string; type: string } | null,
  getPreviousId: (
    id?: string,
    type?: string,
  ) => { id: string; type: string } | null,
  scrollToLogBox: (id: string) => void,
  currentId: string,
  currentType: string,
  onGroupPage: boolean = false,
) => {
  const navigate = useNavigate();
  const location = useLocation();
  const userContext = useUser();
  const keepRelevantParams = useKeepRelevantParams();

  useEffect(() => {
    const handleKeydown = (event: KeyboardEvent) => {
      if (location.pathname.includes("playground")) return;
      const target = event.target as HTMLElement;
      const tagName = target.tagName.toLowerCase();

      if (
        ["input", "textarea", "select"].includes(tagName) ||
        target.isContentEditable
      ) {
        return;
      }

      if (
        window.location.pathname.includes("/request") ||
        window.location.pathname.includes("/home") ||
        window.location.pathname.includes("/group") ||
        window.location.pathname.includes("/traces")
      ) {
        if ((event.metaKey || event.ctrlKey) && event.key === "k") {
          inputRef.current?.focus();
        }
        if (event.key === "j" || event.key === "ArrowDown") {
          const index = getNextId(currentId, currentType);
          if (index) {
            const path = `/workspace/${userContext?.activeWorkspaceId}/${index.type}/${index.id}`;
            navigate({
              pathname: path,
              search: keepRelevantParams(),
            });
            scrollToLogBox(index.id);
          }
        }
        if (event.key === "k" || event.key === "ArrowUp") {
          const index = getPreviousId(currentId, currentType);
          if (index) {
            const path = `/workspace/${userContext?.activeWorkspaceId}/${index.type}/${index.id}`;
            navigate({
              pathname: path,
              search: keepRelevantParams(),
            });
            scrollToLogBox(index.id);
          }
        }
      }
    };

    document.addEventListener("keydown", handleKeydown);
    return () => {
      document.removeEventListener("keydown", handleKeydown);
    };
  }, [
    inputRef,
    items,
    getNextId,
    getPreviousId,
    scrollToLogBox,
    currentId,
    currentType,
    onGroupPage,
    navigate,
    location,
    userContext,
    keepRelevantParams,
  ]);
};
