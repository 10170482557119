import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { useDebounce } from "@/hooks";
import {
  useFolders,
  usePromptRegistryObjects,
  usePromptRegistryTags,
} from "@/queries";
import { Folder } from "@/types/folders";
import { PromptRegistry } from "@/types/prompt-registry";
import { useParams, useSearchParams } from "react-router-dom";

export function useRegistryData() {
  const [searchParams] = useSearchParams();
  const searchQuery = searchParams.get("registrySearch") || "";
  const q = useDebounce(searchQuery, 300);
  const selectedTags = searchParams.getAll("registryTags");

  const { folderId } = useParams();
  const userContext = useUser();
  const auth = useAuth();
  const workspaceId = userContext.activeWorkspaceId!;
  const userToken = auth?.userToken!;
  const searchFolders = !!folderId || !q;

  // Fetch folders
  const foldersQuery = useFolders(userToken, workspaceId, folderId);
  const folders: Folder[] = foldersQuery.data?.folders ?? [];
  const folderPath: string[] =
    foldersQuery.data?.path?.map((folder: Folder) => folder.name) ?? [];
  const folderPathWithIds: Folder["path"] = foldersQuery.data?.path ?? [];

  // Fetch templates
  const promptRegistryObjects = usePromptRegistryObjects(userToken, {
    workspaceId,
    folderId,
    q,
    tags: selectedTags,
    searchFolders,
  });
  const templates: PromptRegistry[] =
    promptRegistryObjects.data?.pages.flatMap((page) => page.items) || [];

  // Fetch all tags
  const promptRegistriesTags = usePromptRegistryTags(userToken, {
    workspaceId,
  });
  const allTemplateTags = promptRegistriesTags.data || [];

  return {
    searchQuery,
    q,
    selectedTags,
    folderId,
    folders,
    folderPath,
    folderPathWithIds,
    promptRegistryObjects,
    templates,
    allTemplateTags,
    workspaceId,
  };
}
